<div class="d-flex justify-content-center align-items-center" style="height: 100vh;" *ngIf="flowState">
    <div class="spinner-border" role="status">
        <span class="sr-only"></span>
    </div>
</div>



<div class="dropdown-container" *ngIf="!flowState">
    
    <!-- <div  style="padding-bottom: 30px;" *ngIf="pageFlagRout">
        <button type="button" class="btn-close main-close-button" aria-label="Close" (click)="toggleChild()"
            style="color: white;"></button>
    </div> -->

    <div *ngFor="let field of searchFields; let i = index" class="dropdown-row d-block">
        <!-- Display selected User tag -->
        <div class="col-12  user-tag" *ngIf="field.selectedOption == 'User'"
            style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">

                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
               
                <h6 *ngIf="newLikeFlag.includes('User'); else showDefault">
                    Like: {{field.inputValue}}
                  </h6>
                  <ng-template #showDefault>
                    <h6>{{ field.inputValue }}</h6>
                  </ng-template>

            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12  user-tag" *ngIf="field.selectedOption == 'Avatar'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                <h6 *ngIf="newLikeFlag.includes('Avatar'); else showDefault">
                    Like: {{ field.inputValue}}
                  </h6>
                  <ng-template #showDefault>
                    <h6>{{ field.inputValue }}</h6>
                  </ng-template>
            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12 user-tag" *ngIf="field.selectedOption == 'Space'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                <h6 *ngIf="newLikeFlag.includes('Space'); else showDefault">
                    Like: {{ field.inputValue }}
                  </h6>
                  <ng-template #showDefault >
                    <h6 (click)="showselectedSpacePanel(field)">{{ field.inputValue }}</h6>
                  </ng-template>
            </h6>
            <button class="user-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
        <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Brand'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 50px; height: 50px;" [src]="getSearchedBrandImage(field.inputValue)" alt="Image">
                <span class="m-1"> {{field.inputValue}}</span>
            </h6>
            <button class=" brand-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>

        <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Garment'">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getSearchedGarmentRealType(field.inputValue)"
                    alt="Image">
                <span class="m-1">{{ field.inputValue.slice(0, 14) }}</span>
            </h6>
            <button class=" brand-remove" (click)="removeField(field, i)">
                X
            </button>
        </div>
    </div>
    
    <!-- <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getImageForOption('Garment')" alt="Image">
            <h6 *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">Like: {{conditionsearchDetails?.searchInput}}
            </h6>
        </h6>
        <button (click)="closeSechcondition()" class="brand-remove">
            X
        </button>
    </div>

    <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'Brand'">
        <h6 style="margin: 5px;" class="d-flex">
            <img style="width: 25px; height: 25px;" [src]="getImageForOption('Brand')" alt="Image">
            <h6 *ngIf="conditionsearchDetails?.selectedValue == 'Brand'">Like: {{conditionsearchDetails?.searchInput}}
            </h6>
        </h6>
        <button (click)="closeSechcondition()" class="brand-remove">
            X
        </button>
    </div> -->
    <div *ngFor="let condition of conditionDetailsArray" class="col-12">
        <!-- Garment Condition -->
        <div *ngIf="condition.selectedValue === 'Garment'" class="brand-tag">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption('Garment')" alt="Image">
                <h6>Like: {{ condition.searchInput }}</h6>
            </h6>
            <button (click)="removeCondition(condition.selectedValue)" class="brand-remove">
                X
            </button>
        </div>
    
        <!-- Brand Condition -->
        <div *ngIf="condition.selectedValue === 'Brand'" class="brand-tag">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="getImageForOption('Brand')" alt="Image">
                <h6>Like: {{ condition.searchInput }}</h6>
            </h6>
            <button (click)="removeCondition(condition.selectedValue)" class="brand-remove">
                X
            </button>
        </div>
    </div>

    <button *ngIf="searchFields && complexCondtion()" 
        class="transparent-button search-button clear-butn" style="margin-left: 111px; margin-top: -11px;" (click)="handleSearchClear()">
        Clear
    </button>


    <div class="button-container">


          <!-- Rounded Plus Button -->
        <button class="transparent-button rounded-plus-button" style="margin-right: -5px !important;"
            (click)="handleHomeButtom()">
            <img src="./assets/home.png" style="width: 15px; height:15px" />
        </button>

        <button class="transparent-button rounded-plus-button"  style="margin-right: -5px !important;"    (click)="handlePlusClick()">
            +
        </button>


        <!-- Search Button -->
        <!-- <button class="transparent-button rounded-plus-button" (click)="handleSearchClick()">
            <img style="width:15px !important;" src="./assets/brand-space/search-button.png"> </button> -->

            <button class="transparent-button new-search-button clear-butn" (click)="handleSearchClick()">
                <span>Search</span>
                <img src="./assets/brand-space/search-button.png" alt="Search Icon">
            </button>
              
      

        <!-- Clear Button -->
        
    </div>

    <div class="div-center class-desktop" *ngIf="showSnapshots && !loadingSnap">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class=""></span>
            </div>
        </div>
    </div>

    <div class="div-center class-desktop" *ngIf="showSnapshots && loadingSnap">
        <div class="row w-100 image-margin">
            <div *ngIf="snapFiltered && snapFiltered?.length < 0 && showsnapbysearch" class="alert alert-danger w-100 text-center">
                No stories available
            </div>
            <div class="col-6 p-0" *ngFor="let snapshot of snapFiltered; let i=index;"
                [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}">
                <div class="d-flex align-items-center justify-content-center">
                    <img class="shadow-1-strong rounded m-1 story-img" (click)="selectImage(snapshot)" [src]="snapshot.SnapshotFileUrlMini">
                </div>
            </div>
        </div>
    </div>
    
    <div  (click)="moreButtonForNext()" class="text-center"> More </div>
</div>

<div class="brand-close" *ngIf="rightPanelFlag">
    <div id="mySidenav" class="searchNav">
        <div class="close-container" (click)="closeRightSearchPanel()"> <span class="cross-close">X</span></div>
        <app-search-selection-panel (selectedUserDetails)="handleUserDetails($event)"
            (selectedSpaceDetails)="handleSpaceDetails($event)" (selectedBrandDetails)="handleBrandDetails($event)"
            (selectedAvatarDetails)="handleAvatarDetails($event)"
            (selectedgGarmentDetails)="handleGarmentDetails($event)"
            (selectedgConditionSearch)="handleConditionSearch($event)" [allsnapObject]="allsnapObject" [Avatarsnapshots]="Avatarsnapshots">
        </app-search-selection-panel>
    </div>
</div>