<div class="manage-x">
    <app-public *ngIf="!logInFlag && menuFlag == undefined && mainMenuFlag == undefined"></app-public>
    <app-yologram-social *ngIf="logInFlag && menuFlag == undefined && mainMenuFlag == undefined"></app-yologram-social>

    <div (click)="closePanel()" [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight  - 60}">

        <button class="btn btn-primary" style="     z-index: 100000;
        position: fixed;
        top: 215px;
        right: 0;" (click)="toggle()"     >
            <i class="bi bi-arrow-right-circle" *ngIf="isShowStats"></i>
            <i class="bi bi-arrow-left-circle" *ngIf="!isShowStats"></i>
        </button>

        <div [class.sd-disabled]="setQuilty === 'HD'" class="quality-toggle-container" (click)="toggleQuality()">
            <img (click)="toggleQuality()" class="quality-toggle-icon" src="./assets/hd.png" />
        </div>

        <div *ngIf="this.loadedCompoent == 'loaded'" (click)="ToggleAR()" class="AR-toggle-container p-2 m-2">
            <img class="AR-toggle-icon" src="./assets/cube.png" />
        </div>


        <div class="manage-w" [ngClass]="flagForShowLottieTemplet ? 'd-none' : ''">
            <div *ngIf="mainMenuFlag == undefined || teamplateFlag != undefined ">
                <div style="width: 290px; height: 470px;">
                    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
                        [ngClass]="WinSizeCall && loadedCompoent === 'loaded' ? 'class-dropdown' : ''">
                        <div *ngIf="WinSizeCall && loadedCompoent === 'loaded'" class="m-1"
                            style="cursor: pointer; z-index: 0;">
                            <!-- <i class="bi bi-eye close-icon-x"></i> -->
                            <span class="d-block">
                                <img (click)="closeThreeDBUTTON()" class="dummy-card" src="./assets/small-card.png" />
                                <img (click)="closeThreeDBUTTON()" class="short-image-card"
                                    [src]="snapshot?.SnapshotFileUrlMini" />
                                <img class="back-card" (click)="closeThreeDBUTTON()"
                                    style="width:20px !important; transform: scaleX(-1);" src="./assets/share_copy.png">
                                <img (click)="handleAnimationList()" class="animation-icon"
                                    src="./assets/animation-icon-white.png" />
                            </span>

                        </div>

                        <!-- <div class="text-center" style="flex-grow: 1; z-index: 0;"
                        *ngIf="( this.WinSizeCall) && loadedCompoent === 'loaded'">
                        <select name="animations" id="animations" class="custom-select-dropdown"
                            (change)="onSelectAnimation($event)">
                            <option value="" disabled selected>Select an animation</option>
                            <option *ngFor="let animation of animationCollection" [value]="animation.storageURL">
                                {{ animation.displayName}}
                            </option>
                        </select>
                    </div> -->

                        <div class="text-center" style="flex-grow: 1; z-index: 0;"
                            *ngIf="(this.WinSizeCall) && loadedCompoent === 'loaded' && animationListShow">
                            <ul class="animation-list">
                                <li *ngFor="let animation of animationCollection"
                                    (click)="onSelectAnimation(animation.storageURL)" class="animation-item">
                                    {{ animation.displayName }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <app-news3-d-templete [flagForThreeJs]="flagForShowThreeJs" [EnvUrl]="envUrl" [setQlty]="setQuilty"
                        [GarmentsGender]="genders" [findFlag]="isChecked" [flag_v2]="flag_v2"
                        [bvhAnimationUrl]="this.selectedAnimation" (errorMessage)="setEroorMessage($event)"
                        (avtarNotFoundMessege)="setAvtarNotFoundMessege($event)"
                        (calledFunction)="setAvatarAndClothLoaded($event)" [FunctionFlag]="functionflagValue"
                        [upperwearUrl]="garmenturl" [bottomwearUrl]="garmentbottom" [BlobUrl]="dataBlobUrl"
                        [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear" [footwearUrl]="footwear"
                        [StoryID]="storyID" [vrmAvatarUrl]="this.vrmAvtarUrl" [storyText]="dummytext"
                        [isShowStats]="isShowStats" [ARdata]="arData" [animationCollection]="animationCollection">
                    </app-news3-d-templete>
                </div>
            </div>

            <div class="card-position" [ngClass]="{
                'd-none': WinSizeCall,
                'card-new-position': pageFlagRout === 'drip',
                
                
                }">

                <div class="allImage vertical-scroll-container card " #scrollableDiv (scroll)="onVerticalScroll()"
                    [ngClass]="{
                    'd-none': this.WinSizeCall == true, 'new-blur-class':  pageFlagRout === 'avatar'  ,'new-blur-class_two': pageFlagRout === 'drip'}">

                    <div class="image-container d-block" *ngFor="let snapshot of paginatedSnapshots; let i = index"
                        [id]="'element' + i" #domElement>
                        <div class="horizontal-scroll-container" (scroll)="onHorizontalScroll($event, i)"
                            #horizontalScrollContainer #target id="{{snapshot?.StoryID}}">
                            <div class="red-background" style="align-content: center;"
                                *ngIf="snapshot?.SnapshotFileUrl == ''" >
                                <div class="spinner-border" style="margin-left: 40%;" role="status">
                                    <span class="sr-only text-black"></span>
                                </div>
                            </div>
                            <div class="red-background" style="align-content: center;"
                            *ngIf="!showImage" >
                            <div class="spinner-border" style="margin-left: 40%;" role="status">
                                <span class="sr-only text-black"></span>
                            </div>
                        </div>
                            <div *ngIf="snapshot?.SnapshotFileUrl !='' && showImage" class="red-background"
                                [id]="'element_card'+'Home' ">
                                <div class="small-box">
                                    <div class="col-sm-12 d-flex">
                                        <div class="user-name">
                                            <div class="text-left font-weight-bold"
                                                (click)="openUserPanel(snapshot,$event)"><a>{{
                                                getDisplayName(snapshot)}}</a></div>
                                            <div class="text-left date-class">
                                                {{convertFirestoreTimestampToDate(snapshot.DateTime) |
                                            date: 'medium'}}
                                            </div>
                                        </div>
                                        <div class="user-avtar ">

                                            <div class="text-right date-class d-flex" 
                                                (click)="openAvatarPanel(snapshot,$event)">
                                                <img style="width: 12px; height: 12px; margin-right: 8px;" src="./assets/avata_gray_icon.png" />
                                                <span> {{snapshot && snapshot?.avatarName == undefined ? this.getAvatartDetails(snapshot):
                                            ( snapshot?.avatarName != '' ? snapshot?.avatarName : ( snapshot?.avatarName
                                            == ''
                                            ||
                                            snapshot?.avatarName == undefined ? snapshot?.AvatarID : ''))}}</span>
                                            </div>

                                            <!-- <div class="text-right date-class d-flex" (click)="openAvatarPanel(snapshot,$event)"> 
                                            <img style="width: 12px; height: 12px;" src="./assets/body.png"/>  {{ ( snapshot?.avatarName != '' ? snapshot.avatarName : snapshot?.AvatarID)}}
                                           </div>  -->


                                            <!-- <div class="text-right date-class" (click)="openAvatarPanel(snapshot,$event)">
                                            🧍🏽{{snapshot?.avatarName || snapshot?.AvatarID}}
                                        </div> -->



                                            <div class="text-right date-class d-flex"
                                                (click)="environmentPanel(snapshot.Environment,$event)">
                                                <img style="width: 11px;
                                                height: 11px;
                                                margin-right: 8px;" src="./assets/space_gray_icon.png" />
                                               <span>{{getEnvironmentName(snapshot.Environment)}}</span> 
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="spinner-border spin-loader" role="status"
                                    *ngIf="snapshot.snapshotLoading != undefined && this.snapshot.snapshotLoading">
                                    <span class="sr-only"></span>
                                </div>

 
                                
                                <!-- <img [offset]="500" [scrollTarget]="scrollableDiv" [lazyLoad]="snapshot?.SnapshotFileUrlMini"
                        [defaultImage]="snapshot?.SnapshotFileUrlMicro || '/assets/no thumbnail.jpg'" [errorImage]="'path/to/error.jpg'"
                        [style.width.px]="imageWidth" [style.height.px]="imageHeight" /> -->

                                <!-- defaultImage="{{ snapshot?.SnapshotFileUrlMini || '/assets/no thumbnail.jpg' }}" -->

                                <div  class="image-container-new" style="width: 290px; height: 345px;">

                                    <img [ngClass]="teamplateFlag == 1  ? 'snashot-watermarking': ''"
                                        class="cropped-image-new"
                                        defaultImage="{{snapshot?.SnapshotFileUrlMini || '/assets/no thumbnail.jpg' }}"
                                        appLazyLoad="{{snapshot?.SnapshotFileUrl }}" [style.width.px]="imageWidth"
                                        [style.height.px]="imageHeight" [snapshot]="snapshot" />

                                    <img [ngClass]="teamplateFlag == 2  ? '': 'd-none' " class="img-fluid fram-IGS"
                                        width="345 !important" height="280" src="./assets/realtype/iDGS-small_font.png">

                                    <div *ngIf="teamplateFlag == 1 ">
                                        <div *ngIf="teamplateFlag == 1" class="story-heading">Business Today</div>
                                        <!-- <div  *ngIf="teamplateFlag == 3"  class="story-heading">IDGS</div> -->
                                        <div class="bottom-color-box-left">&nbsp;</div>
                                        <div class="bottom-color-box-right">&nbsp;</div>
                                    </div>
                                </div>


                                <div class="s-d">
                                    <div class="threeD-image" *ngIf="showloadhingFlage">
                                        <div class="spinner-border new-spin-loader" role="status">
                                            <span class="sr-only"
                                                style="width:20px !important; height: 20px; margin-top: -100px;"></span>
                                        </div>
                                    </div>
                                    <!-- <div class = "ARButton" (click)="ToggleAR()"> AR Button</div> -->
                                    <div class="threeD-image"
                                        *ngIf="snapshot.vrmURL && !showloadhingFlage && snapshot.snapshotLoading == '' ">
                                        <img (click)="openNews3DPassVRMValue(copyTextToClipEven(snapshot.Environment),
                            copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                            (snapshot),
                            copyTextToClipGarment(snapshot.UpperwearID),
                            copyTextToClipGarment(snapshot.BottomwearID),
                            copyTextToClipGarment(snapshot.FootwearID), 
                            copyTextToClipGarment(snapshot.HeadwearID)
                            ,$event)" style="width:20px !important; height: 20px; margin-top: -50px; "
                                            src="./assets/realtype/VRMFiles.png">
                                    </div>
                                    <div class="threeD-image"
                                        *ngIf="!showloadhingFlage && snapshot.snapshotLoading == ''  && snapshot.home == 'Home'">
                                        <img class="image-3d" (click)="openNews3DPassValue(copyTextToClipEven(snapshot.Environment),
                    copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                    (snapshot),
                    copyTextToClipGarment(snapshot.UpperwearID),
                    copyTextToClipGarment(snapshot.BottomwearID),
                    copyTextToClipGarment(snapshot.FootwearID), 
                    copyTextToClipGarment(snapshot.HeadwearID),$event
                    )" style="width:20px !important; height: 20px; " src="./assets/3d-movie.png">
                                    </div>
                                    <span class="lotti-animation"
                                        *ngIf="snapshot.lottieURL != '' && snapshot.snapshotLoading == ''">
                                        <!-- <img style="width:20px !important; height: 20px;"
                                    src="./assets/realtype/LottieFiles.png"
                                    (click)="showLottieFiles(snapshot.lottieURL,snapshot.SnapshotFileUrlMini)"> -->
                                        <img style="width:30px !important; height: 30px;"
                                            src="./assets/360-degrees-8.png"
                                            (click)="showLottieFiles(snapshot.lottieURL,snapshot.SnapshotFileUrlMini)">
                                        <!-- <i style="font-size:20px;   margin-left: 250px; " class="bi bi-binoculars" (click)="showLottieFiles(snaphot.lottieURL)"></i> -->
                                    </span>
                                </div>

                                <div class="s-d-s">


                                    <div class="second-share" *ngIf="this.pageFlagRout != 'edit-news'"><img
                                            (click)="openShareModal(snapshot)" style="width:20px !important;"
                                            src="./assets/share_copy.png">
                                    </div>



                                    <div class="search-icon" *ngIf="snapshot?.avatarName !=  ''"
                                        (click)="openSearchPanel(snapshot,$event)">
                                        <img style="width:25px !important;"
                                        src="./assets/brand-space/imfo-button.png">
                                        <!-- <i  style="color: black; font-size: 18px; text-shadow: 0 0 2px white, 0 0 4px white;" class="bi bi-search"></i> -->
                                    </div>


                                    <div class="second-share" *ngIf="this.pageFlagRout == 'edit-news'">
                                        <a class="" (click)="deleteSnapshotFile(snapshot.id)">
                                            <img style="width:20px !important;" src="./assets/brand-space/bin-save.png">

                                        </a>
                                    </div>

                                </div>
                                <div class="large-box">
                                    <div class="col-12 col-sm-12  d-flex " *ngIf="paginatedSnapshots">
                                        <div class="col-2 col-sm-2">
                                            <img class="m-1"
                                                (click)="openBrandPanel(snapshot.HeadwearID,'brand',$event)"
                                                style="width:45px; height:45px !important; color:black;"
                                                *ngIf="getGarmentrealtype(snapshot .HeadwearID) == '0' "
                                                class="rounded profileImg-brand"
                                                [src]="getBrandImageByGarmetnID(snapshot .HeadwearID)" />
                                        </div>
                                        <div class="col-8 col-sm-8 clothName p-1" [ngStyle]="{ background: getClothNameBackground() }"
                                            *ngIf="snapshot.HeadwearID != undefined && snapshot .HeadwearID != ''">
                                            <img (click)="openGarmentNav(snapshot,'HeadwearID',snapshot.HeadwearID,'headgear',$event)"
                                                class="gar-icon"
                                                *ngIf="getGarmentrealtype(snapshot .HeadwearID) == '0' "
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/newsfeed/headgear-new.png">
                                            <span
                                                (click)="openGarmentNav(snapshot,'HeadwearID',snapshot.HeadwearID,'headgear',$event)"
                                                class="caption-values"
                                                *ngIf="snapshot .HeadwearID != undefined && snapshot .HeadwearID != ''">
                                                <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                    title="Cloth Details">
                                                    {{getGarmentName(snapshot.HeadwearID).substring(0,25)}}
                                                </span>&nbsp;
                                            </span>
                                            <span
                                                *ngIf="!showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                                <a class="pintrest-icon"
                                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                                                    (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                    <img style="width:20px !important; color:black"
                                                        class="img-thumbnail2 pinImage"
                                                        src="./assets/Pinterest-logo.png" />
                                                </a>
                                            </span>
                                            <a (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                                class="pintrest-icon"
                                                *ngIf="showSharedStory(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.HeadwearID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12  d-flex" *ngIf="paginatedSnapshots">
                                        <div class="col-2 col-sm-2 ">
                                            <img (click)="openBrandPanel(snapshot.UpperwearID,'brand',$event)"
                                                class="m-1" style="width:45px; height:45px !important; color:black ; "
                                                *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '1' "
                                                class="rounded profileImg-brand"
                                                [src]="getBrandImageByGarmetnID(snapshot .UpperwearID)" />
                                            <img (click)="openBrandPanel(snapshot.UpperwearID,'brand',$event)"
                                                class="m-1" style="width:45px; height:45px !important; color:black ;"
                                                *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '5' "
                                                class="rounded profileImg-brand"
                                                [src]="getBrandImageByGarmetnID(snapshot .UpperwearID)" />
                                        </div>
                                        <div class="col-8 col-sm-8 clothName p-1"  [ngStyle]="{ background: getClothNameBackground() }"
                                            *ngIf="snapshot .UpperwearID != undefined && snapshot .UpperwearID != ''">
                                            <span
                                                (click)="openGarmentNav(snapshot,'UpperwearID',snapshot.UpperwearID,'upperwear',$event)">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                                    style="width:15px !important; color:black; opacity: 0.4;"
                                                    src="./assets/realtype/upperwear.png">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot .UpperwearID) == '5' "
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/realtype/fullbody.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot .UpperwearID != undefined && snapshot .UpperwearID != ''">
                                                    <span class="garment-name" data-toggle="tooltip"
                                                        data-placement="top" title="Cloth Details">
                                                        {{getGarmentName(snapshot .UpperwearID).substring(0,25)}}
                                                    </span>&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                *ngIf="!showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                                <a class="pintrest-icon"
                                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID)"
                                                    (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                    <img style="width:20px !important; color:black"
                                                        class="img-thumbnail2 pinImage"
                                                        src="./assets/Pinterest-logo.png" /></a>
                                            </span>
                                            <a (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                                class="pintrest-icon"
                                                *ngIf="showSharedStory(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.UpperwearID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                            </a>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 d-flex " *ngIf="paginatedSnapshots">
                                        <div class="col-2 col-sm-2 ">
                                            <img (click)="openBrandPanel(snapshot.BottomwearID,'brand',$event)"
                                                class="m-1" style="width:45px; height:45px !important; color:black ; "
                                                *ngIf="getGarmentrealtype(snapshot .BottomwearID) == '2'"
                                                class="rounded profileImg-brand"
                                                [src]="getBrandImageByGarmetnID(snapshot .BottomwearID)" />

                                        </div>
                                        <div class="col-8 col-sm-8 clothName p-1" [ngStyle]="{ background: getClothNameBackground() }"
                                            *ngIf="snapshot .BottomwearID != undefined && snapshot .BottomwearID != ''">
                                            <span
                                                (click)="openGarmentNav(snapshot,'BottomwearID',snapshot.BottomwearID,'bottomwear',$event)">


                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot .BottomwearID) == '2'"
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/newsfeed/bottomwear-new.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot .BottomwearID != undefined && snapshot .BottomwearID != ''">
                                                    <span class="garment-name" data-toggle="popover"
                                                        data-placement="left"
                                                        title="Cloth Details">{{getGarmentName(snapshot.BottomwearID).substring(0,20)}}
                                                    </span>&nbsp;
                                                </span>
                                            </span>
                                            <!-- <a class="pintrest-icon"
                                    *ngIf=" snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                    (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                                        src="./assets/Pinterest-logo.png" />
                                </a> -->

                                            <span
                                                *ngIf="!showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                                <a class="pintrest-icon"
                                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                                    (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                    <img style="width:20px !important; color:black"
                                                        class="img-thumbnail2 pinImage"
                                                        src="./assets/Pinterest-logo.png" />
                                                </a>
                                            </span>
                                            <a (click)="shareOnPinterest(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                                class="pintrest-icon"
                                                *ngIf="showSharedStory(snapshot.BottomwearID,'bottomwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.BottomwearID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12  d-flex" *ngIf="paginatedSnapshots">
                                        <div class="col-2 col-sm-2 ">
                                            <img (click)="openBrandPanel(snapshot.FootwearID,'brand',$event)"
                                                class="m-1" style="width:45px; height:45px !important; color:black ;"
                                                *ngIf="getGarmentrealtype(snapshot .FootwearID) == '3'"
                                                class="rounded profileImg-brand"
                                                [src]="getBrandImageByGarmetnID(snapshot .FootwearID)" />
                                        </div>

                                        <div class="col-8 col-sm-8 clothName p-1" [ngStyle]="{ background: getClothNameBackground() }"
                                            (click)="openGarmentNav(snapshot,'FootwearID',snapshot.FootwearID,'footwear',$event)"
                                            *ngIf="snapshot .BottomwearID != undefined && snapshot.FootwearID != ''">
                                            <img class="gar-icon"
                                                *ngIf="getGarmentrealtype(snapshot .FootwearID) == '3' "
                                                style="width:15px !important; color:black ; opacity: 0.4;"
                                                src="./assets/realtype/footwear.png">
                                            <span class="caption-values"
                                                *ngIf="snapshot .FootwearID != undefined && snapshot.FootwearID != ''">
                                                <span class="garment-name" data-toggle="popover" data-placement="left"
                                                    title="Cloth Details">
                                                    {{getGarmentName(snapshot .FootwearID).substring(0,25)}}
                                                </span>&nbsp;
                                            </span>
                                            <!-- <a class="pintrest-icon"
                                    *ngIf=" snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                    (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                    <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                                        src="./assets/Pinterest-logo.png" />
                                </a> -->

                                            <span
                                                *ngIf="!showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                                <a class="pintrest-icon"
                                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                                    (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                                    <img style="width:20px !important; color:black"
                                                        class="img-thumbnail2 pinImage"
                                                        src="./assets/Pinterest-logo.png" />
                                                </a>
                                            </span>
                                            <a (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)"
                                                class="pintrest-icon"
                                                *ngIf="showSharedStory(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID) && showPintrestIcon(snapshot.FootwearID)">
                                                <img style="width:20px !important; color:black"
                                                    class="img-thumbnail2 pinImage" src="./assets/pintrest-grey.jpeg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="red-background"
                                *ngIf="snapshot.HeadwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                                [id]="'element_card_' + snapshot.HeadwearID">
                                <div class="scroll-item garment-card " [id]="HeadwearID">
                                    <div class="swip-card-size p-2 m-2 " [ngStyle]="{ background: getGarmentCardBackground()}">
                                        <app-swipe-popup [key]="HeadwearID" [garmentFlagDummy]="dummyFlag"
                                            [garmentId]="snapshot.HeadwearID"
                                            (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                    </div>
                                </div>
                            </div>
                            <div class="red-background" #upperwearCard
                                *ngIf="snapshot.UpperwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                                [id]="'element_card' + snapshot.UpperwearID">
                                <div class="scroll-item garment-card" [id]="UpperwearID">
                                    <div class="swip-card-size p-2 m-2"  [ngStyle]="{ background: getGarmentCardBackground()}">
                                        <app-swipe-popup [key]="UpperwearID" [garmentFlagDummy]="dummyFlag"
                                            [garmentId]="snapshot.UpperwearID"
                                            (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                    </div>
                                </div>
                            </div>
                            <div class="red-background"
                                *ngIf="snapshot.BottomwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                                [id]="'element_card' + snapshot.BottomwearID">
                                <div class="scroll-item garment-card" [id]="BottomwearID">
                                    <div class="swip-card-size p-2 m-2 " [ngStyle]="{ background: getGarmentCardBackground()}">
                                        <app-swipe-popup [key]="BottomwearID" [garmentFlagDummy]="dummyFlag"
                                            [garmentId]="snapshot.BottomwearID"
                                            (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                    </div>
                                </div>
                            </div>
                            <div class="red-background"
                                *ngIf="snapshot.FootwearID != '' && mostVisibleIndex == i && isHorizontalScrolling"
                                [id]="'element_card' + snapshot.FootwearID">
                                <div class="scroll-item garment-card" [id]="FootwearID">
                                    <div class="swip-card-size  p-2 m-2" [ngStyle]="{ background: getGarmentCardBackground()}">
                                        <app-swipe-popup [key]="FootwearID" [garmentFlagDummy]="dummyFlag"
                                            [garmentId]="snapshot.FootwearID"
                                            (garmentPanelOpenByCard)="openGarmentPanelByCard($event)"></app-swipe-popup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center dots-container" style="margin-left: 150px;"
                            *ngIf="mostVisibleIndex == i">
                            <div class="horizontal-dots">
                                <span class="dot" *ngFor="let item of getHorizontalItems(snapshot); let i = index"
                                    [class.active-card]="horizonatlVisibleIndex == i"
                                    (click)="scrollToCard(item)"></span>
                            </div>
                            <button *ngIf="showManualLoadButton" (click)="moreButtonForNext()">Load More</button>
                        </div>

                        <div class="text-center m-1" *ngIf="snapshot.SnapshotFileUrlMini  == undefined">
                            <button type="button" class="btn btn-secondary" (click)="updateThumbNail(snapshot,i)"
                                [disabled]="(snapshot?.SnapshotFileUrlMicro !== '' && 
                snapshot?.SnapshotFileUrlMini) && (snapshot?.SnapshotFileUrlMini !== undefined && snapshot?.SnapshotFileUrlMini !== '') ">
                                Generate Thumbnail
                            </button>
                            <div class="spinner-border" role="status" *ngIf="thumbnilflag">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                    </div>



                    <!-- <div class="brand-close" *ngIf="searchPanel" id="closePannel" (click)=" clickOnPanel($event)">
                    <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight"
                        [style.width.px]="sideNavWidth_Fixed" *ngIf="closeDiv && !wholeDiv">
                        <div [ngClass]="{ 'show-panel': searchPanel, 'hide-panel': !searchPanel }">
                            <app-search-tag-panel (emitrightPanelFlag)="handleSideNavSize($event)"
                                (serchedStory)="handleSerchedStory($event)" [snapData]="singleSnap"
                                [snapshots]="tempSnapShot"></app-search-tag-panel>
                        </div>
                    </div>
                </div> -->

                </div>
            </div>
        </div>

        <div class="loader-overlay" *ngIf="loadPanelLoader">
            <div class="loader"></div>
        </div>

        <div [ngClass]="!flagForShowLottieTemplet ? 'd-none' : ''">
            <div class="m-1" (click)="closeLottieTemplate()" style="cursor: pointer; z-index: 0;"><i
                    class="bi bi-x close-icon-x"></i></div>
            <app-lottie-file-templet [LottieURL]="lottieURL" [SnapURL]="snapSingleUrl"></app-lottie-file-templet>
        </div>

        <div *ngIf="WinSizeCall && loadedCompoent == 'loaded'" class="btn-resize-dwon d-flex">
            <div *ngIf="loadedCompoent == 'loaded' && WinSizeCall" class="story-model-expand"
                (click)="switchFlagforFocus()">
                <span><button class="story" [ngClass]="{ 'active': isCheckedFocus === true}">Story</button></span>
                <span><button class="model" [ngClass]="{ 'active': isCheckedFocus === false}">Model</button></span>
            </div>
        </div>
    </div>
</div>
<div class="brand-close" id="closePannel" *ngIf="(showpanel || mainMenuFlag == undefined) && temFlage "
    (click)=" clickOnPanel($event)">

    <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight" [style.width.px]="sideNavWidth_Fixed"
        [ngClass]="{ 'show-panel': closeDiv && !wholeDiv, 'hide-panel': !closeDiv && wholeDiv && pageFlagRout === 'drip', 'hide-main-panel': pageFlagRout !== 'drip' && !closeDiv && wholeDiv }">

        <div *ngIf="userCollapseFlag && pageFlagRout === 'drip'">
            <app-user-panel [UserShapShotObject]="this.snapshot" [UserID]="this.snapshot.UserID"
                [brandStoryScrollerHeight]="brandStoryScrollerHeight" [userData]="userData" [UserData]="UserData"
                [selectedUserName]="selectedUserName">
            </app-user-panel>
        </div>

        <div *ngIf="avatarCollapseFlag && pageFlagRout === 'drip'">
            <app-avatar-panel [UserShapShotObject]="this.snapshot" [UserID]="this.snapshot.UserID"
                [selectedUserName]="selectedUserName" [selectedAvatarName]="selectedAvatarName"
                [brandStoryScrollerHeight]="brandStoryScrollerHeight" [userData]="userData" [AvatarData]="AvatarData">
            </app-avatar-panel>
        </div>

        <div   [ngClass]="{ 'd-none': !evenCollapseFlag }">
            <app-environment-panel  [ngClass]="{ 'd-none': spaceNot_loaded }" [gltfImgSrc]="gltfImgSrc" [skyBoXall]="skyBoXall" [twoDprivew]="twoDprivew"
                [evsname]="evsname" [evsdiplaynameALL]="evsdiplaynameALL" [environmentFileName]="environmentFileName"
                [hideFlag]="hideFlag" [brandStoryScrollerHeight]="brandStoryScrollerHeight-40"
                [environmentData]="environmentData" (evnCompressUrl)="callSpaceFunction($event)"
                (emitSlectedSnapList)="handlesnapListEmit($event)" 
                (emitSliderArrow)="handleSlider($event)"
                (SnapStoryId)="handleStorySelection($event)"
                (modelViewerData)="handlemodelViewerData($event)"
                (setTabEmit)="handleTabData($event)"
                (emitBrandList)="handleBlistData($event)"
                (showbrandsliderEmit)="handleShowSlider($event)"
               

                (show)="handleShowSlider($event)"
                (emitAvatarNmaeList)="handleShowSAvatarslider($event)"
                [showBrandslider]="showBrandslider"


                (showAvatarsliderEmit)="handleShowAvatarSlider($event)"
                [showBrandAvatarslider]="showBrandAvatarslider"
                
                [brandobject]="selectedBrand"
                [spaceName_Page]="spaceName_Pass"
                [selectedBrand]="selectedBrand"

                [selectednewAvatar]="selectednewAvatar"
                [SpacePanelDefault]="SpacePanelDefault" [showNonSelectedBg]="showNonSelectedBg"
                ></app-environment-panel>
        </div>

        <div *ngIf="germentCollapseFlag">
            <app-drips-garment-panel [garmentId]="selecteGarmentId" [typeOfGarment]="typeOfGarment"
                [selectedSnapShot]="selectedGarmentName" [brandUrl]="brandUrl" [RealType]="realType"
                [garmentData]="garmentData" [typeWear]="garmentTypeID">
            </app-drips-garment-panel>
        </div>


        <div *ngIf="brandCollapseFlag ">
            <app-drips-brand-panel [newselectedBrandId]="selectedBrandId" [brandData]="brandData"
                [newbrandUrl]="newbrandUrl"
                [brandStoryScrollerHeight]="brandStoryScrollerHeight "></app-drips-brand-panel>
        </div>


        <div *ngIf="!closeDiv" class="d-block">
            <div class="menu-arrow text-end tab-box" (click)="handleSearchTab()">
                <img [style.width.px]="20"
                    [src]="isSearchActive ? './assets/brand-space/search-button.png' : './assets/storystyle/story-white.png'"
                    alt="Search Icon">
            </div>
        </div>

        <div [ngClass]="{ 'show-panel': searchPanel, 'hide-search-panel': !searchPanel }">
            <app-search-tag-panel (emitrightPanelFlag)="handleSideNavSize($event)" [showCrossButton]="showCrossButton"
                (selectTheStory)="handleSelectedStory($event)" (closePanelEmitter)="closeSearchPanel($event)"
                (showSearchFlag)="handledleSearchIconFlage($event)" (serchedStory)="handleSerchedStory($event)" 
                (emitTiggerHomeButtom)="handleHomeData($event)"   (emitSpaceNameData)="handleSpaceselection($event)"
                [snapData]="singleSnap" [snapshots]="tempSnapShot" [Avatarsnapshots]="snapshots"></app-search-tag-panel>
        </div>
    </div>
</div>

<div *ngIf="SpacePanelDefault && !evenCollapseFlag && !searchPanel" class="d-block preview-space">
    <div class="menu-arrow text-end tab-box space-box" (click)="openSpaceTempPanel()" >
        <div class="image-space">
            <img class="small-image" src="./assets/search/spaces.png" alt="Open Avatar">
            <img class="large-image" [src]="this.envUrl" alt="Open Avatar">
        </div>
    </div>
</div>




<div *ngIf="evenCollapseFlag">
    
    <button class="brand-remove"  *ngIf="showBrandslider && this.selectSpaceTab === 'Brands'"  (click)="closeSlider()" >
        X
     </button>
     <button class="brand-remove"  *ngIf="showBrandAvatarslider && this.selectSpaceTab === 'Style'"  (click)="closeSlider()" >
        X
     </button>

    <!-- Brands Div -->
    <div class="image-slider-brand" 
         *ngIf="showBrandslider && this.selectSpaceTab === 'Brands'" 
         [ngStyle]="{ 'width.px': windowWidth - 175 }">
      <div class="image-slider-inner-brand " *ngFor="let brand of filterbrandLsist">
        <div class="image-wrapper" (click)="cahngeBrand(brand)">
          <img class="image-slide-brand" [src]="getBrandImageByLogo(brand?.LogoURL)" />
          <div class="image-overlay-brand">
            {{ brand?.BrandName.length > 10 ? (brand.BrandName | slice: 0:10) + '...' : brand?.BrandName }}
          </div>
        </div>
      </div>
    </div>
  
    <!-- Style Div -->
    <div class="image-slider-brand" *ngIf="showBrandAvatarslider && this.selectSpaceTab === 'Style'" [ngStyle]="{ 'width.px': windowWidth - 175 }">
      <div class="image-slider-inner-brand " *ngFor="let avtr of avatrlist">
        <div class="image-wrapper" *ngIf="avtr?.details != undefined && avtr?.avatarName != undefined " (click)="cahngeAvatar(avtr)">
          <img class="image-slide-brand"  *ngIf="avtr?.details" [src]="avtr?.details" />
          <div class="image-overlay-brand" *ngIf="avtr?.details">
            {{ avtr?.avatarName.length > 10 ? (avtr.avatarName | slice: 0:10) + '...' : avtr?.avatarName }}
          </div>
        </div>
      </div>
    </div>
  
    <!-- Fallback Content -->
   
  </div>
  

<div *ngIf="showSpaceSlider && evenCollapseFlag" style="position: fixed; top: 130px; left: 190px; z-index: 51;">
    <button id="above-model-button" (click)="cahngeButton()">Change</button>
  </div>
<model-viewer  *ngIf="showSpaceSlider && evenCollapseFlag" id="poster"
    camera-controls touch-action="pan-y" data-js-focus-visible="false"
    [attr.skybox-image]="newSkybox"
    alt="A 3D model of a sphere reflecting a sunrise" [attr.src]="newGltf" >
</model-viewer>

<div class="image-slider" *ngIf="showSpaceSlider && this.showSpacenNewSlider" [ngStyle]="{ 'width.px': windowWidth - 175 }">
    <div class="image-slider-inner"  *ngFor="let even of evnFileList">
        <div class="image-wrapper" (click)="resetPanel(even)"  *ngIf="even?.displayName != 'default'">
            <img class="image-slide" [src]="even?.evnCompressUrl" />
            <div class="image-overlay">
                {{ even?.displayName.length > 10 ? (even.displayName | slice:0:10) + '...' : even?.displayName }}
            </div>
        </div>
</div>


