<div *ngIf="loading" class="loading">Loading&#8230;</div>
<!-- <div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog" id="garment-details">
        <div class="modal-content imp-block-garment">
            <div class="modal-body">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="row text-center p-2 justify-content-center">
                    <div class="col-12 m-2 details" *ngIf="clothDetails.BrandID != undefined">
                        <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                            <div class="text-center d-flex ">
                                <img data-bs-dismiss="modal"
                                    (click)="openGarmentPopup(clothDetails.BrandID,clothDetails.boardName)"
                                    class="rounded profileImg-brand"
                                    style="width:35px !important; height:35px !important;"
                                    [src]="getBrandImage(clothDetails.LogoURL)">
                            </div>
                            <div class="p-1"><b 
                                    (click)="openGarmentPopup(clothDetails.BrandID,clothDetails.boardName)" data-bs-dismiss="modal">{{clothDetails.BrandName}}</b>
                            </div>
                            <div><button type="button" class="btn btn-sm btn-outline-info follow">Follow</button></div>
                        </div>
                        

                        
                       <b>
                        <h5 data-bs-dismiss="modal"  (click)="getClothProfile(clothDetails.BrandID)"
                            [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                            class="text-black clothName">
                                     <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                            style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/headgear.png"></span>
                                    <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                            style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetails.RealType == '2'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 5px;"
                                            src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetails.RealType == '3'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetails.RealType == '4'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetails.RealType == '5'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/fullbody.png"></span>
                            {{(clothDetails.Name)}}</h5>
                    </b>
                    <b>
                        <h6 class="color-class"> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                            (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                    </b>
                       
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >Collection: {{clothDetails.CollectionName}}</h6>
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >Details: {{clothDetails.Details}}</h6>
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >SKU: {{clothDetails.SKU}}</h6>
                    </div>


                    <div class="row"  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                        <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
                            <span *ngIf="p < counter">
                                <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                                    "
                                        src={{fileList.fileurl}}>
                                </div>
                                <div class="justify-content-center col-6 "
                                    *ngIf="fileList.fileType == 'GarmentUpload3D'">
                                    <div class="justify-content-center col-6 ">
                                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                            src={{fileList.fileurl}} alt="A 3D avatar-model-view"
                                            data-js-focus-visible="" ar-status="not-presenting"
                                            style="height: 100px; width: 150px; float: none;">
                                        </model-viewer>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>

                 
                 

                    <div class="p-2 m-1 text-center d-flex justify-content-center">
                        <h6 data-bs-dismiss="modal" class="text-white brandbtn m-1" (click)="getStoryCloth(clothDetails)">
                            <img style="width:15px !important; color:black" src="./assets/storystyle/story-white.png">
                        </h6>
                        <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(clothDetails)"
                            class="text-white brandbtn m-1">
                            <img style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
                        </h6>
                        <h6  *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                              style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/details-gray.png"></a></h6>

                        <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                              style="width:15px !important" src="./assets/gallary.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>

                        <h6  *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                              style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                              </h6>
                      </div>
                


                      
            

                    <div class="row w-100 m-1"
                        *ngIf="clothShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                        <div class="column d-block m-1" *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                            <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                            (click)="getgarmentfeed(brandDetails,clothDetails)" [src]="snapshot.SnapshotFileUrl">
                        </div>
                        <div class="columnImg d-block m-1">
                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                        </div>
                        <div class="columnImg d-block m-1">
                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                        </div>
                        <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                            (click)="getClothProfile(clothDetails.BrandID)">
                            <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block-brand " *ngIf="brandDetails && brandDetails.BrandName">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3">
                            <img style="width: 75px; height:75px !important;" class="rounded profileImg-brand p-1"
                            data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                                [src]="getBrandImage(brandDetails.LogoURL)" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                {{(brandDetails.BrandName)}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-2 follow">Follow</button></div>
                            <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                {{brandDetails.boardName}} </h6>

                        </div>
                        <div class="p-1 text-center d-flex justify-content-center">
                            <h6 class="m-1 text-white brandbtn"
                                [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                <b>Story</b>
                            </h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'M')"
                                class="m-1 text-white brandbtn">Menswear</h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'F')"
                                class="m-1 text-white brandbtn">Womenswear</h6>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="brandhShapShotsList.length >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of brandhShapShotsList  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="brandhShapShotsList.length > 8"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="Avatar-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3">
                            <img style="width: 70px; height:70px !important;" class="rounded-circle" data-bs-dismiss="modal"
                                (click)="getSnapShotListByUser(profileDetails.uid)" src="{{profileDetails.photoURL}}" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getSnapShotListByUser(profileDetails.uid)">
                                  {{profileDetails.displayName}}</h2>
                            <a (click)="getProfileByAvatorpage(selectedAvtar)">🧍🏽{{selectedAvtar}} </a>
                            <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal"
                                    (click)="getuserfeed(selectedAvtar)">Avatar Feed</button></div>
                        </div>
                        <div class="row d-flex">
                            <div data-bs-dismiss="modal"
                                class="text-center col-6">
                                <span>
                                    <div >
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder.jpg">
                                    </div>
                                </span>
                            </div>
                            <div data-bs-dismiss="modal"
                                class="text-center col-6">
                                <span>
                                    <div >
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder1.jpg">
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
    aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3"
                            *ngIf="evnFileListFileName && evnFileListFileName['displayName']  && evnFiledefault == undefined">
                            <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                {{evnFileListFileName['displayName']}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal">Preview</button></div>
                        </div>
                        <div class="col-12 m-3"
                            *ngIf="evnFiledefault != undefined && evnFileListFileName ==  undefined">
                            <h2 (click)="getEnvironmentProfile(evnFiledefault)">{{evnFiledefault}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal">Preview</button></div>
                        </div>

                        <div class="row w-100 m-2"
                            *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block">
            <h5 type="button" class="btn-close  closBtn"   data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-start p-2" >
                        <div class="col-12 m-3" data-bs-dismiss="modal">
                            <img style="width: 70px; height:70px !important;" class="rounded-circle" 
                                (click)="getSnapShotListByUser(usearDataList?.UserID)" src={{usearDataList?.ProfilePicture}} />

                            <h2 class="w-100"  
                                (click)="getSnapShotListByUser(usearDataList?.UserID)">
                                {{usearDataList?.DisplayName}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                        </div>

                        <div class="row d-flex"   style="
                        justify-content: flex-start;
                        margin-left: 40px;">

                            <div 
                                class="text-center col-6">  <div *ngIf="userFiledetails.length == 0 " class="text-center col-12 justify-content-center" >
                                    <app-slider-two-d [images]="images"></app-slider-two-d>
                               </div>
                               <div *ngIf="userFiledetails.length != 0 " class="text-center col-12 justify-content-center " >
                                   <app-slider-two-d [images]="userFiledetails" [UserId]="selectedId"></app-slider-two-d>
                              </div>

                           
                            </div>

                          
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                (click)="getProfileForUSerFeed(snapshot)"  [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                (click)="getSnapShotListByUser(profileDetails.uid)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->




<!-- <app-yologram-social *ngIf="logInFlag"></app-yologram-social> -->
<!-- <div class="dt-responsive selectlist font-14 manage-w" *ngIf="!loading"  (click)="closePanel()"  >
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div *ngIf="SnapShotFileDetails && SnapShotFileDetails?.length == 0 || !SnapShotFileDetails" class="p-3"><span
                class="danger">
                <div class="alert alert-danger text-center" role="alert">
                    <b>No Post Found.</b>
                </div>
            </span></div>
        <div #snapshotfeeds class="card m-3  slider-css "
            *ngIf="SnapShotFileDetails  != undefined && SnapShotFileDetails.length > 0">
            <div class="slider-img  " [ngClass]="{'active': storyId === snapshot.StoryID}"
                *ngFor="let snapshot of SnapShotFileDetails; let i=index;">
                <div class=" m-2">
                    <h6>
                        <div class="col-lg-12 d-flex">
                            <a class="col-lg-6 w-70 display-name" 
                                (click)="getSnapShotListByUserPopup(snapshot)" >{{snapshot.profileName}}</a>
                                <h6  
                                (click)="openAvatrPopup(snapshot)"class="col-lg-6 text-end avatar-diaplay">🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}}</h6>
                        </div>
                    </h6>
                    <div class="col-lg-12 d-flex">
                        <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mm a'}}</h6>
                        <h6 
                        (click)="newEnvironmentPopup(snapshot.Environment)" class="col-lg-6 evn-font text-end">
                            {{getEnvironmentName(snapshot.Environment)}}</h6>
                    </div>
                    <span class="spinner-border second-spineer" *ngIf="isLoading" role="status"></span>
                </div>
                <div class="col-lg-1 deletbtn">
                    <a class="" (click)="deleteSnapshotFile(snapshot.id)"><i class="bi bi-trash-fill"></i></a>
                </div>
                <img class="img-fluid" width="500 !important" height="300" [src]="snapshot.SnapshotFileUrl" />
                <h6 class="second-txt">
                    {{snapshot.StoryType}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{snapshot.StoryID}} </h6>
               
                    <div class="">
                        <div class="col-12 col-sm-12 slider-box">
                            <div class="col-12 col-sm-12" *ngIf="filterSnapShotFileDetails"><img
                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                    style="width:15px !important; color:black" src="./assets/newsfeed/headgear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details" (click)="openGarmentNav(snapshot,'HeadwearID',snapshot.HeadwearID,'headgear',$event)"

                                      >
                                        {{getGarmentName(snapshot.HeadwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="setBrandDetails(snapshot.HeadwearID)"
                                     >{{getBrandName(snapshot.HeadwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) ">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                    style="width:15px !important; color:black" src="./assets/realtype/upperwear.png">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                    style="width:15px !important; color:black" src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details"
                                        (click)="openGarmentNav(snapshot,'UpperwearID',snapshot.UpperwearID,'upperwear',$event)"

                                    
                                       >
                                        {{getGarmentName(snapshot.UpperwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="setBrandDetails(snapshot.UpperwearID)"
                                       >{{getBrandName(snapshot.UpperwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                    style="width:15px !important; color:black" src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details" (click)="openGarmentNav(snapshot,'BottomwearID',snapshot.BottomwearID,'bottomwear',$event)"
                                       >
                                        {{getGarmentName(snapshot.BottomwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="setBrandDetails(snapshot.BottomwearID)"
                                       >
                                        {{getBrandName(snapshot.BottomwearID)}}
                                    </span>
    
                                    <a 
                                        (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                    style="width:15px !important; color:black" src="./assets/realtype/footwear.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                    (click)="openGarmentNav(snapshot,'FootwearID',snapshot.FootwearID,'footwear',$event)">
                                        {{getGarmentName(snapshot.FootwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="setBrandDetails(snapshot.FootwearID)"
                                        >
                                        {{getBrandName(snapshot.FootwearID)}}
                                    </span>
    
                                    <a
                                        (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2 " src="./assets/Pinterest-logo.png" />
                                    </a>
    
                                </span>
    
                            </div>
                        </div>
                    </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="dots-bars-2" *ngIf="isLoading" role="status">
                    <span class=""></span>
                </div>
            </div>
        </div>
    </div>
    

</div> -->

<div (click)="closePanel()" *ngIf="showStoryCard">
    <app-drips [userSnapShotsInput]="paginatedSnapshots"  [StoryIds]="StoryID" [snapInitCall]="snapInitCall"
        (emmitGarmentNavParameter)="handleGarmentPanel($event)" 
        [paginatedSnapshotsNew]="paginatedSnapshots" >
    </app-drips>
</div>

<!-- <div class="brand-close" *ngIf="rightPanelFlag">
    <div id="mySidenav" class="searchNav" [style.height.px]="sideNavHeight" (click)="clickOnPanel($event)">
        <div class="close-container" (click)="closeRightSearchPanel()"> <span class="cross-close">X</span></div>
        <app-search-selection-panel 
            (selectedUserDetails)="handleUserDetails($event)"
            (selectedSpaceDetails)="handleSpaceDetails($event)"
             (selectedBrandDetails)="handleBrandDetails($event)"
            (selectedAvatarDetails)="handleAvatarDetails($event)"
            (selectedgGarmentDetails)="handleGarmentDetails($event)"
            (selectedgConditionSearch)="handleConditionSearch($event)"
             [allsnapObject]="allsnapObject">
        </app-search-selection-panel>
    </div>
</div> -->

<div class="brand-close" [ngClass]="{'d-none': !(closeDiv && !wholeDiv)}">
    <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight" (click)="clickOnPanel($event)">
        <div *ngIf="germentCollapseFlag">
            <app-edit-news-garment-panel [garmentId]="selecteGarmentId" [typeOfGarment]="typeOfGarment"
                (modalpopupEmitter)="handleModalOpen($event)" (thowduploadEmitter)="handletwoduploadOpen($event)"
                (threedtuploadEmitter)="handlethreeuploadOpen($event)" [selectedSnapShot]="selectedGarmentName"
                [brandUrl]="brandUrl" [RealType]="realType" [garmentData]="garmentData" [typeWear]="garmentTypeID">
            </app-edit-news-garment-panel>
        </div>


        <!-- <div *ngIf="showSearchPanel" class="dropdown-container">
            <div *ngFor="let field of searchFields; let i = index" class="dropdown-row d-block">
                <div class="col-12  user-tag" *ngIf="field.selectedOption == 'User'" style="background-color:transparent !important">
                  <h6 style="margin: 5px;" class="d-flex">

                    <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                  
                    <h6 *ngIf="!conditionsearchDetails || conditionsearchDetails?.selectedValue != 'User'">{{field.inputValue}}</h6> 
                    <h6 *ngIf="conditionsearchDetails?.selectedValue == 'User'">Like: {{conditionsearchDetails?.searchInput}}</h6> 

                  </h6>
                  <button class=" user-remove" (click)="removeField(field, i)">
                    X
                  </button>
                </div>
                <div class="col-12  user-tag" *ngIf="field.selectedOption == 'Avatar'">
                    <h6 style="margin: 5px;" class="d-flex">
                        <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                        <h6 *ngIf="!conditionsearchDetails || conditionsearchDetails?.selectedValue != 'avatar'">{{field.inputValue}}</h6> 
                        <h6 *ngIf="conditionsearchDetails?.selectedValue == 'avatar'">Like: {{conditionsearchDetails?.searchInput}}</h6> 
    
                    </h6>
                    <button class=" user-remove" (click)="removeField(field, i)">
                        X
                    </button>
                </div>
                <div class="col-12 user-tag" *ngIf="field.selectedOption == 'Env'">
                    <h6 style="margin: 5px;" class="d-flex">
                        <img style="width: 25px; height: 25px;" [src]="getImageForOption(field.selectedOption)" alt="Image">
                        <h6 *ngIf="!conditionsearchDetails ||  conditionsearchDetails?.selectedValue != 'space'">{{field.inputValue}}</h6> 
                        <h6 *ngIf="conditionsearchDetails?.selectedValue == 'space'">Like: {{conditionsearchDetails?.searchInput}}</h6> 
                    </h6>
                    <button class=" user-remove" (click)="removeField(field, i)">
                        X
                    </button>
                </div>

                <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Brand'">
                    <h6 style="margin: 5px;" class="d-flex">
                        <img style="width: 50px; height: 50px;" [src]="getSearchedBrandImage(field.inputValue)" alt="Image">
                       <span class="m-1" > {{field.inputValue}}</span>
                    </h6>
                    <button class=" brand-remove" (click)="removeField(field, i)">
                        X
                    </button>
                </div>

                <div class="col-12 brand-tag" *ngIf="field.selectedOption == 'Garment'">
                    <h6 style="margin: 5px;" class="d-flex">
                        <img style="width: 25px; height: 25px;" [src]="getSearchedGarmentRealType(field.inputValue)" alt="Image">
                        <span class="m-1">{{ field.inputValue.slice(0, 14) }}</span>
                    </h6>
                    <button class=" brand-remove" (click)="removeField(field, i)">
                        X
                    </button>
                </div>
            </div>
              
            <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">
                <h6 style="margin: 5px;" class="d-flex">
                    <img style="width: 25px; height: 25px;" [src]="getImageForOption('Garment')" alt="Image">
                    <h6 *ngIf="conditionsearchDetails?.selectedValue == 'Garment'">Like: {{conditionsearchDetails?.searchInput}}</h6> 
                </h6>
                <button (click)="closeSechcondition()" class=" brand-remove">
                    X
                </button>
            </div>

            <div class="col-12 brand-tag" *ngIf="conditionsearchDetails?.selectedValue == 'brand'">
                <h6 style="margin: 5px;" class="d-flex">
                    <img style="width: 25px; height: 25px;" [src]="getImageForOption('Brand')" alt="Image">
                    <h6 *ngIf="conditionsearchDetails?.selectedValue == 'Brand'">Like: {{conditionsearchDetails?.searchInput}}</h6> 
                </h6>
                <button (click)="closeSechcondition()" class=" brand-remove">
                    X
                </button>
            </div>

            <div class="button-container">
        <button class="transparent-button rounded-plus-button" (click)="handleSearchClick()">
            <img style="width:15px !important;" src="./assets/brand-space/search-button.png"> </button>
        <button class="transparent-button rounded-plus-button" (click)="handlePlusClick()">
            +
        </button>
        
        <button class="transparent-button search-button clear-butn" (click)="handleSearchClear()">
            Clear
        </button>
            </div>
            <div class="div-center class-desktop" *ngIf="storyFilterFlag">No Match Found</div>
            <div class="div-center class-desktop" *ngIf="snapFiltered">
                <div class="row w-100 image-margin">
                    <div class="col-6 p-0" *ngFor="let snapshot of snapFiltered; let i=index;"
                        [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}">
                        <div class="d-flex align-items-center justify-content-center">
                            <img class="shadow-1-strong rounded m-1 story-img" (click)="handleSelectedImage(snapshot)"
                                [src]="snapshot.SnapshotFileUrlMini">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="userSnafilter?.length > 12" (click)="moreButtonForNext()" class="text-center"> More </div>
        </div> -->
    </div>
</div>

<div class="modal fade" id="pageModalLabel" tabindex="-1" aria-labelledby="pageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="pageModalLabel">Edit Garment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="m-2">Garment Name</label>
                <input type="text" class="form-control" placeholder="Add Garment Name" (change)="setGarmentName($event)"
                    [value]="clothDetails?.Name">
                <label class="m-2">Collection</label>
                <select class="form-control" aria-label="Default select example" (change)="selectCollection($event)">
                    <option
                        [selected]="selectedOption == undefined || (filterdataGarmentCollection?.status == 'I' && selectedOption != undefined )"
                        value=''>None</option>
                    <option *ngFor="let colection of  collectionFilterData" [selected]="selectedOption == colection?.id"
                        [value]="colection?.id">
                        <div>{{colection?.CollectionName}} </div>
                    </option>
                </select>
                <label class="m-2">Details</label>
                <textarea class="form-control" id="exampleFormControlTextarea5" placeholder="Add Details" rows="5"
                    (change)="setGarmentDetails($event)" [value]="clothDetails?.Details"></textarea>
                <label class="m-2">SKU</label>
                <input type="text" class="form-control" placeholder="Add SKU" (change)="setGarmentSKU($event)"
                    [value]="clothDetails?.SKU">
            </div>
            <label class="m-2"><img style="width:20px !important; " src="./assets/details_black.png"></label>
            <input type="text" class="form-control" placeholder="Add Details Link"
                (change)="setGarmentThumbCenterURL($event)" [value]="clothDetails?.ThumbCenterURL">
            <label class="m-2"><img style="width:20px !important" src="./assets/gallary_black.png"></label>
            <input class="form-control" id="exampleFormControlTextarea5" placeholder="Add Gallery Link" rows="5"
                (change)="setGarmentThumbLeftURL($event)" [value]="clothDetails?.ThumbLeftURL">
            <label class="m-2"><img style="width:20px !important" src="./assets/shop_black.png"></label>
            <input type="text" class="form-control" placeholder="Add Shop Link"
                (change)="setGarmentThumbRightURL($event)" [value]="clothDetails?.ThumbRightURL">
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="updateGarmentDetailsEdit()"
                    data-bs-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>


<!-- <div class="modal fade imp-bg" id="upload2D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 (click)="clear()" type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
            </h5>
            <div class="justify-content-center text-center">
                <h5>Upload File</h5>
            </div>
            <div class="justify-content-center text-center">
                <input type="text" [value]="envName" (change)="setEnvName($event)"
                    class="form-control m-2 p-2 input-wid" placeholder="Enter file name" />
                <span> <input type="file" [value]="uploadFileName" (change)="start2DFileUpload($event)"
                        class="form-control m-2 p-2 input-wid">
                </span>
            </div>
            <div class="p-2" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="upload3D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" (click)="clear()" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
            </h5>
            <div class="justify-content-center text-center">
                <h5>Upload File</h5>
            </div>
            <div class="justify-content-center text-center">
                <input [value]="envName" (change)="setEnvName($event)" class="form-control m-2 p-2 input-wid"
                    type="text" placeholder="Enter file name" />
                <input [value]="uploadFileName" type="file" (change)="start3DFileUpload($event)"
                    class="form-control m-2 p-2 input-wid">
            </div>
            <div class="p-2" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div> -->

<!-- 2D Upload Modal -->
<div class="modal fade imp-bg" id="upload2D" tabindex="-1" aria-labelledby="upload2D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content imp-block">
            <!-- Close Button -->
            <button type="button" class="btn-close closBtn" (click)="clear()" data-bs-dismiss="modal"
                aria-label="Close"></button>

            <!-- Modal Header -->
            <div class="text-center mt-3">
                <h5>Upload 2D File</h5>
            </div>

            <!-- Modal Body -->
            <div class="modal-body text-center">
                <!-- Input for File Name -->
                <input type="text" (change)="setEnvName($event)" class="form-control m-2 p-2 input-wid"
                    placeholder="Enter file name" />

                <!-- Input for File Upload -->
                <input type="file" (change)="start2DFileUpload($event)" class="form-control m-2 p-2 input-wid">
            </div>

            <!-- Progress and Success Message -->
            <div class="p-2 text-center" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100" class="progress-bar"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div>

<!-- 3D Upload Modal -->
<div class="modal fade imp-bg" id="upload3D" tabindex="-1" aria-labelledby="upload3D" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content imp-block">
            <!-- Close Button -->
            <button type="button" class="btn-close closBtn" (click)="clear()" data-bs-dismiss="modal"
                aria-label="Close"></button>

            <!-- Modal Header -->
            <div class="text-center mt-3">
                <h5>Upload 3D File</h5>
            </div>

            <!-- Modal Body -->
            <div class="modal-body text-center">
                <!-- Input for File Name -->
                <input type="text" [value]="envName" (change)="setEnvName($event)"
                    class="form-control m-2 p-2 input-wid" placeholder="Enter file name" />

                <!-- Input for File Upload -->
                <input type="file" [value]="uploadFileName" (change)="start3DFileUpload($event)"
                    class="form-control m-2 p-2 input-wid">
            </div>

            <!-- Progress and Success Message -->
            <div class="p-2 text-center" *ngIf="percentage | async as pct">
                <progress [value]="pct" max="100" class="progress-bar"></progress>
                {{ pct | number }}%
                <div *ngIf="pct == 100" class="alert alert-success">Uploaded Successfully</div>
            </div>
        </div>
    </div>
</div>