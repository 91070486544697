import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { YologramSocialComponent } from './yologram-social/yologram-social.component';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { BrandsComponent } from './yologram-social/threads/threads.component';
import { DialogContentExampleDialog, NewsComponent } from './yologram-social/news/news.component';
import { AvatarUploaderComponent } from './yologram-social/avatar-uploader/avatar-uploader.component';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
// import { BrandDialogComponent } from './yologram-social/threads/brand-dialog/brand-dialog.component';
import { FormsModule } from '@angular/forms';
import { AvatarIndexComponent, GLTFAvatarDialog } from './yologram-social/avatar-uploader/index/avatar-index.component';
import { AvatarUploadComponent } from './yologram-social/avatar-uploader/avatar-upload/avatar-upload.component';
import { AvatarModelViewComponent } from './yologram-social/3D-model-view/avatar-model-view.component';
import { DropzoneDirective } from './directive/dropzone.directive';
import { HttpClientModule } from '@angular/common/http';
import { ApiHttpService } from './services/api-http.service';
import { BrandBoardComponent } from './yologram-social/brand-board/brand-board.component';
import { ViewsFiles, GLTFAvatarDialog1 } from './yologram-social/view-files/view-files.component'
import { AppOverlayModule } from './common/overlay/overlay.module';
import { ProgressSpinnerModule, } from './common/progress-spinner/progress-spinner.module';
import { UnzipBrandPack } from './yologram-social/zip-brand-pack/zip-brand-pack.component';
import { StyleComponent } from './public/style/style.component';
import { PublicComponent } from './public/public.component'
import { PublicNewsComponent, ModelViewerDilog, SocialMediaShareDialog } from './public/news/news.component';
import { MenuPageComponent } from './yologram-social/menu-page/menu-page.component';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessageService } from './services/messaging.service';
import { ChatBoxComponent } from './yologram-social/chat-box/chat-box.component';
import { GroupUserComponent } from './yologram-social/group-user/group-user.component';
import { NotifyUserComponent } from './yologram-social/notify-user/notify-user.component';
import { SecurityGroupComponent } from './yologram-social/security-group/security-group.component';
import { WebPagesComponent } from './yologram-social/web-pages/web-pages.component';
import { GroupRoleComponent } from './yologram-social/group-role/group-role.component';
import { YologramNetworkComponent } from './public/yologram-network/yologram-network.component';
import { SecurityGroupChangeComponent } from './yologram-social/security-group-change/security-group-change.component';
import { UserNewsComponent, UserNewsModelDilog } from './yologram-social/news/user-news/user-news.component';
import { NewsUserComponent } from './public/news-user/news-user.component';
import { DataSharingService } from './services/data-sharing-service';
import { UserFeedComponent } from './yologram-social/news/user-feed/user-feed.component';
import { ShareComponent } from './public/share/share.component';
import { NetworkComponent } from './yologram-social/network/network.component';
import { UserViewFilePopUP, UserViewFilesComponent } from './yologram-social/view-files/user-view-files/user-view-files.component';
import { BrandStoriesComponent } from './yologram-social/brand-stories/brand-stories.component';
import { BrandStoriesNewsComponent, ModelViewerDilogForstories } from './yologram-social/brand-stories/brand-stories-news/brand-stories-news.component';
import { YologramPublicStyleComponent } from './yologram-social/yologram-public-style/yologram-public-style.component';
import { BrandProfileComponent } from './yologram-social/brand-profile/brand-profile.component';
import { PublicBrandProfileComponent } from './public/public-brand-profile/public-brand-profile.component';
import { PublicBrandStoriesComponent } from './public/public-brand-stories/public-brand-stories.component';
import { ModelViewerDilogForPublicstories, PublicBrandStoriesNewsComponent } from './public/public-brand-stories/public-brand-stories-news/public-brand-stories-news.component';
import { BrandStyleComponent } from './yologram-social/brand-style/brand-style.component';
import { PublicBrandStyleComponent } from './public/public-brand-style/public-brand-style.component';
import { LoginComponent } from './login/login.component';
import { GarmentProfileComponent, treedialogContentExampleDialog } from './yologram-social/garment-profile/garment-profile.component';
import { GarmentStyleComponent } from './yologram-social/garment-style/garment-style.component';
import { PublicGarmentProfileComponent } from './public/public-garment-profile/public-garment-profile.component';
import { PublicGarmentStyleComponent } from './public/public-garment-style/public-garment-style.component';
import { PopupTwoDComponent } from './common/popup-two-d/popup-two-d.component';
import { GarmentEditComponent } from './yologram-social/garment-edit/garment-edit.component';
import { EnvironmentProfileComponent } from './yologram-social/environment-profile/environment-profile.component';
import { PublicEvnProfileComponent } from './public/public-evn-profile/public-evn-profile.component';
import { StyleStoriesComponent,ModelViewerDilogForPublicstoriespopup} from './public/style/style-stories/style-stories.component';
import { ModelViewerDilogStyle, ZipStyleComponent } from './yologram-social/zip-brand-pack/zip-style/zip-style.component';
import { PopupThreeDComponent } from './common/popup-three-d/popup-three-d.component';
import { PublicGarmentFeedComponent } from './public/public-garment-feed/public-garment-feed.component';
import { GarmentFeedComponent } from './yologram-social/garment-feed/garment-feed.component';
import { UserProfileFeedComponent } from './yologram-social/user-profile-feed/user-profile-feed.component';
import { PublicUserFeedComponent } from './public/public-user-feed/public-user-feed.component';
import { NewsCommonComponent } from './common/news-common/news-common.component';
import { GarmentPopupComponent } from './common/templets/garment-popup/garment-popup.component';
import { BrandPopupComponent } from './common/templets/brand-popup/brand-popup.component';
import { UserPopupComponent } from './common/templets/user-popup/user-popup.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialMedialStoryComponent } from './common/social-medial-story/social-medial-story.component';
import { UserStoryComponent } from './common/templets/user-story/user-story.component';
import { PinPublishViewerDilog } from './common/templets/pin-publish-popup/pin-publish';
import { swipeUpComponent } from './common/templets/swipeUp-garment/swipe-story.component';
import { ToastComponent } from './common/templets/toast/toast';
import { TwoDpopupComponent } from './common/templets/twoDimagePopUp/twoDpopup';
import { LoginPopupComponent } from './common/templets/login-popup/login-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { GarmentCollectionComponent } from './yologram-social/garment-collection/garment-collection.component';
import { SliderTwoDComponent } from './common/slider-two-d/slider-two-d.component';
import { SliderTwoSingleComponent } from './common/slider-two-single/slider-two-single.component';
import { DummyPublicNewsComponent } from './common/dummy-public-news/dummy-public-news.component';
import { DummyPublicNewsUpdatedComponent } from './common/dummy-public-news-updated/dummy-public-news-updated.component';
import { CommonNewsFeedComponent } from './common/common-news-feed/common-news-feed.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NewDummyPageComponent } from './common/new-dummy-page/new-dummy-page.component';
import { UserEditComponent } from './yologram-social/user-edit/user-edit.component';
import { UserMyProfileComponent } from './yologram-social/user-my-profile/user-my-profile.component';
import { CaraosalPopupComponent } from './common/templets/caraosal-popup/caraosal-popup.component';
import { NewThreeDFileComponent } from './common/new-three-d-file/new-three-d-file.component';
import {  ModelViewerDilogThreeDs, NewsThreeDComponent, SocialMediaShareDialogNewsTreeD } from './public/news-three-d/news-three-d.component';
import { News3DPopupComponent } from './common/templets/news3-d-popup/news3-d-popup.component';
import { News3DTempleteComponent } from './common/templets/news3-d-templete/news3-d-templete.component';
import { UsermenuPopupComponent } from './common/templets/usermenu-popup/usermenu-popup.component';
import { Style3DComponent } from './common/style3-d/style3-d.component';
import { Style3DStoriesComponent } from './common/style3-d/style3-d-stories/style3-d-stories.component';
import { DripsComponent } from './common/drips/drips.component';
import { ThreadsComponent } from './common/threads/threads.component';
import { ThreadsChildComponent } from './common/threads/threads-child/threads-child.component';
import { BrandsComponent } from './yologram-social/brands/brands.component';
import { BrandDialogComponent } from './yologram-social/brands/brand-dialog/brand-dialog.component';
import { InfiniteScrollerClothComponent } from './common/templets/infinite-scroller-cloth/infinite-scroller-cloth.component';
import { ImageSlideComponent } from './common/image-slide/image-slide.component';
import { DummyAnimationComponent } from './common/dummy-animation/dummy-animation.component';
import { DripsImageTempleteComponent } from './common/templets/drips-image-templete/drips-image-templete.component';
import { UserPanelComponent } from './common/panel-templete/user-panel/user-panel.component';
import { EnvironmentPanelComponent } from './common/panel-templete/environment-panel/environment-panel.component';
import { GroupBrandsComponent } from './yologram-social/group-brands/group-brands.component';
import { AvatarPageComponent } from './public/avatar-page/avatar-page.component';
import { BrandAvatarComponent } from './yologram-social/brand-avatar/brand-avatar.component';
import { BrandPortfolioComponent } from './yologram-social/brand-portfolio/brand-portfolio.component';
import { TestLottieanimationComponent } from './common/test-lottieanimation/test-lottieanimation.component';
import { LottieModule } from 'ngx-lottie';
import player from "lottie-web";
import { LottieFileTempletComponent } from './common/templets/lottie-file-templet/lottie-file-templet.component';
import { DefaultBrandClothesComponent } from './yologram-social/default-brand-clothes/default-brand-clothes.component';
import { GarmentNewPanelComponent } from './common/templets/garment-new-panel/garment-new-panel.component';
import { GarmentCacheComponent } from './yologram-social/garment-cache/garment-cache.component';
import { APP_INITIALIZER } from '@angular/core';

import { GlobalStateService } from './services/global-state.service';
import { AvatarPanelComponent } from './common/panel-templete/avatar-panel/avatar-panel.component';
import { BrandPanelComponent } from './common/panel-templete/brand-panel/brand-panel.component';
import { GarmentPanelComponent } from './common/panel-templete/garment-panel/garment-panel.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LazyLoadDirective } from './lazy-load.directive';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DripsV2Component } from './common/drips-v2/drips-v2.component';
import { DripsSampleComponent } from './common/drips-sample/drips-sample.component';
import { DripsGarmentPanelComponent } from './common/panel-templete/drips-garment-panel/drips-garment-panel.component';
import { DripsBrandPanelComponent } from './common/panel-templete/drips-brand-panel/drips-brand-panel.component';
import { StoreFrontConfigComponent } from './yologram-social/store-front-config/store-front-config.component';
import { EditNewsGarmentPanelComponent } from './common/panel-templete/edit-news-garment-panel/edit-news-garment-panel.component';
import { BrandSpacesComponent } from './yologram-social/brand-spaces/brand-spaces.component';
import { TexturePageComponent } from './yologram-social/texture-page/texture-page.component';
import { BrandColorComponent } from './yologram-social/brand-color/brand-color.component';
import { SearchSelectionPanelComponent } from './common/panel-templete/search-selection-panel/search-selection-panel.component';
import { SearchTagPanelComponent } from './common/panel-templete/search-tag-panel/search-tag-panel.component';




export function playerFactory() {
  return player;
}

export function initializeApp(globalStateService: GlobalStateService) {
  return () => {
    // Initialize global resources or state
    return globalStateService.initializeApp();
  };
}

const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'left',
			distance: 12
		},
		vertical: {
			position: 'bottom',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    LoginComponent,
    LazyLoadDirective,
    AppComponent,
    YologramSocialComponent,
    BrandsComponent,
    NewsComponent,
    AvatarUploaderComponent,
     BrandDialogComponent,
    AvatarIndexComponent,
    AvatarUploadComponent,
    AvatarModelViewComponent,
    DropzoneDirective,
    BrandBoardComponent,
    DialogContentExampleDialog,
    GLTFAvatarDialog,
    GLTFAvatarDialog1,
    UnzipBrandPack,
    ViewsFiles,
    StyleComponent,
    PublicComponent,
    PublicNewsComponent,
    ModelViewerDilog,
    SocialMediaShareDialog,
    UserNewsModelDilog,
    MenuPageComponent,
    ChatBoxComponent,
    GroupUserComponent,
    NotifyUserComponent,
    SecurityGroupComponent,
    WebPagesComponent,
    GroupRoleComponent,
    YologramNetworkComponent,
    SecurityGroupChangeComponent,
    UserNewsComponent,
    NewsUserComponent,
    UserFeedComponent,
    ShareComponent,
    NetworkComponent,
    UserViewFilesComponent,
    UserViewFilePopUP,
    BrandStoriesComponent,
    BrandStoriesNewsComponent,
    ModelViewerDilogForstories,
    YologramPublicStyleComponent,
    BrandProfileComponent,
    PublicBrandProfileComponent,
    PublicBrandStoriesComponent,
    PublicBrandStoriesNewsComponent,
    ModelViewerDilogForPublicstories,
    BrandStyleComponent,
    PublicBrandStyleComponent,
    GarmentProfileComponent,
    GarmentStyleComponent,
    PublicGarmentProfileComponent,
    PublicGarmentStyleComponent,
    PopupTwoDComponent,
    GarmentEditComponent,
    EnvironmentProfileComponent,
    PublicEvnProfileComponent,
    StyleStoriesComponent,
    ModelViewerDilogForPublicstoriespopup,
    ZipStyleComponent,
    treedialogContentExampleDialog,
    PopupThreeDComponent,
    PublicGarmentFeedComponent,
    GarmentFeedComponent,
    UserProfileFeedComponent,
    PublicUserFeedComponent,
    NewsCommonComponent,
    GarmentPopupComponent,
    BrandPopupComponent,
    UserPopupComponent,
    SocialMedialStoryComponent,
    UserStoryComponent,PinPublishViewerDilog,
    swipeUpComponent,ModelViewerDilogStyle,
    ToastComponent,TwoDpopupComponent,
    LoginPopupComponent,
    GarmentCollectionComponent,
    SliderTwoDComponent,
    SliderTwoSingleComponent,
    DummyPublicNewsComponent,
    DummyPublicNewsUpdatedComponent,
    CommonNewsFeedComponent,
    NewDummyPageComponent,
    UserEditComponent,
    UserMyProfileComponent,
    CaraosalPopupComponent,
    NewThreeDFileComponent,
    NewsThreeDComponent,
    SocialMediaShareDialogNewsTreeD,
    ModelViewerDilogThreeDs,
    News3DPopupComponent,
    News3DTempleteComponent,
    UsermenuPopupComponent,
    Style3DComponent,
    Style3DStoriesComponent,
    DripsComponent,
    ThreadsComponent,
    ThreadsChildComponent,
    InfiniteScrollerClothComponent,
    ImageSlideComponent,
    DummyAnimationComponent,
    DripsImageTempleteComponent,
    UserPanelComponent,
    EnvironmentPanelComponent,
    GroupBrandsComponent,
    AvatarPageComponent,
    BrandAvatarComponent,
    BrandPortfolioComponent,
    TestLottieanimationComponent,
    LottieFileTempletComponent,
    DefaultBrandClothesComponent,
    GarmentNewPanelComponent,
    GarmentCacheComponent,
    AvatarPanelComponent,
    BrandPanelComponent,
    GarmentPanelComponent,
    DripsV2Component,
    DripsSampleComponent,
    DripsGarmentPanelComponent,
    DripsBrandPanelComponent,
    StoreFrontConfigComponent,
    EditNewsGarmentPanelComponent,
    BrandSpacesComponent,
    TexturePageComponent,
    BrandColorComponent,
    SearchSelectionPanelComponent,
    SearchTagPanelComponent,

  ],
  imports: [
    // RouterModule.forRoot(routes, {useHash: true})
    LottieModule.forRoot({ player: playerFactory }),
    LazyLoadImageModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    CarouselModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ScrollingModule,

    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-bottom',
      preventDuplicates: true,
    }
    ),
    NotifierModule.withConfig(customNotifierOptions)
  ],
  providers: [DatePipe, ApiHttpService, MessageService, DataSharingService, NgbActiveModal, { provide: LocationStrategy, 
    useClass: PathLocationStrategy
   },  GlobalStateService,
   {
     provide: APP_INITIALIZER,
     useFactory: initializeApp,
     deps: [GlobalStateService],
     multi: true
   }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
