import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { Router } from '@angular/router';
import { BrandPopupComponent } from '../brand-popup/brand-popup.component';
import { TwoDpopupComponent } from '../twoDimagePopUp/twoDpopup';
import { MatDialog } from '@angular/material/dialog';
import { PopupThreeDComponent } from '../../popup-three-d/popup-three-d.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-swipe-popup',
  templateUrl: './swipe-story.component.html',
  styleUrls: ['./swipe-story.component.css']
})
export class swipeUpComponent implements OnInit {

  @Input() key: string | undefined;
  @Input() garmentId: string | undefined;
  @Input() fileUrl: string | undefined;
  @Input() garmentFlagDummy: boolean | undefined;

  @ViewChild('swipeUp', { read: ElementRef }) public swipeUp: ElementRef<any>;
  // @Output() scrollValue: string | undefined;
  @Output() scrollValue = new EventEmitter<Number>();
  @Output() brandPanelOpenByCard = new EventEmitter<any>();
  @Output() garmentPanelOpenByCard = new EventEmitter<any>();

  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;


  snapByApi: any = [];
  clothShapShotsListAll: any = [];
  clothDetails: any;
  brandDetails: any;
  counter: number = 2;
  userFiledetails: any = [];
  garmentsCollection: any = [];
  brandsCollection: any = [];
  brandboardCollection: any = [];
  userUploadedFileList: any = [];
  clocthCollectionIdcollection: any = [];
  boards: any;
  clothShapShotsList: any = [];
  brandObject: any = {}
  clothShapShotsListSend: any = [];
  snaploadingSnap: boolean = true
  garmentFlag: boolean = true
  image2DFlag: boolean = true
  dripFlag: boolean = true
  showImages: any;
  filelist: any = {}
  heartFlag: boolean = false
  url: any
  iconselctFlag: boolean = false
  selectedTab: string;
  showMore: boolean = false;
  userFiledetails3d: any = [];
  collectionData: any = [];
  collectionDetails: any = [];
  dummyText: any = `Your brand's apparel is digitised in 3D.\n \n
  On a gamified experience, consumers style avatars & share \n  \n
  animated avatar stories (linked to ecommerce)\n \n
  Defining a new paradigm in immersive storytelling for social media & the metaverse`;
  images = [
    { "image": "./assets/dummy-image/img_2.jpg" },
    { "image": "./assets/dummy-image/img_3.jpg" },
    { "image": "./assets/dummy-image/img_4.jpg" },
    { "image": "./assets/dummy-image/img_5.jpg" },
    { "image": "./assets/dummy-image/img_6.jpg" },
    { "image": "./assets/dummy-image/img_7.jpg" },
    { "image": "./assets/dummy-image/img_8.jpg" },
    { "image": "./assets/dummy-image/img_1.jpg" },
  ]



  selectedIcon: string = 'popup'
  brandlogUrl: any;
  constructor(private dataSharingService: DataSharingService,
    private firestore: AngularFirestore,
    private pinterestAPI: PinterestService,
    private commonService: CommonService,
    public router: Router,
    private modal: NgbModal,
    public dialog: MatDialog,
    private http: HttpClient,

  ) {

    // this.pinterestAPI.getBoardList().subscribe((data: {
    //   name: any; items: any;
    // }) => {
    //   this.boards = data.items
    // })

  }


  async loadBrandData() {this.image2DFlag = true
    this.snaploadingSnap = true
    this.garmentFlag = true
    let data = await this.garmentPanelDataFunction(this.garmentId);
    this.brandObject = data.brand;
    this.clothDetails = data.garment;
    this.brandlogUrl = data.brandLogoUrl;
    this.clothShapShotsListAll = data.snapshots.object.slice(0, 3);
    this.image2DFlag = false
    this.snaploadingSnap = false
    this.garmentFlag = false
    let filelist2d = data.userFiles.filter(
      (uploadlist) =>
        uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = data.userFiles.filter(
      (uploadlist) =>
        uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload3D'
    );
    this.userFiledetails = filelist2d;
    this.userFiledetails3d = filelist3d;

  }
  
  async garmentPanelDataFunction(garmentId){
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId,fileType: this.key }).toPromise();
  }



  ngOnChanges(): void {

    // this.userFiledetails = []
    // this.garmentFlag = true
    // this.garmentsList.subscribe(
    //   garments => {
    //     this.garmentsCollection = garments;
    //     let brandGarments = this.garmentsCollection.filter((b: any) => b.id == this.garmentId);
    //     // this.clothDetails = this.garmentsCollection.find((b: any) => b.id == this.garmentId);
    //   });

    // this.brandsFilesList.subscribe((brands) => {
    //   this.brandsCollection = brands;
    //   // this.brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
    //   this.garmentFlag = false
    // });

    let reqInputObject = {
      fileType: this.key,
      id: this.garmentId,
      pageNumber: 1
    }
    this.snaploadingSnap = true


    // this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
    //   this.snapByApi = response.object
    //   // this.clothShapShotsListAll = this.snapByApi.slice(0, 3)
    //   // this.clothShapShotsListSend = this.snapByApi
    //   this.snaploadingSnap = false
    //   this.garmentFlag = false
    // })

    // this.image2DFlag = true

    this.userFilesList.subscribe((userFile) => {
      // this.userUploadedFileList = userFile
      // let clothId = this.clothDetails.id
      // this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == this.garmentId).slice(0, 2)
      // let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload2D')
      // let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload3D')
      // this.userFiledetails = filelist2d
      // this.userFiledetails3d = filelist3d
      // this.image2DFlag = false

      // Extract and deduplicate links
      // let links = filterlisBy2d.map((uploadlist) => uploadlist.link);
      // let uniqueLinks = [...new Set(links)];

      // // Filter garmentsCollection based on unique links
      // let datOfGarment = this.garmentsCollection.filter((collection) => uniqueLinks.includes(collection.id));
      // console.log("Data Of Garment", datOfGarment);

      // // Get brand names for each garment
      // let garmentDataWithBrands = datOfGarment.map( (garment) => {
      //   let brandName =  this.brandname(garment);
      //   return { BrandName: brandName, GarmentId: garment.id, GarmentName:garment.Name };
      // });
      
      // console.log("Data Of Garment with Brand Names", garmentDataWithBrands);



    })

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })

    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata
    })

  }



  ngOnInit(): void {
    if (this.garmentId) {
      this.loadBrandData();
    }

    // this.dataSharingService.fileUrlObservable.subscribe(fileUrl => {
    //   this.userFiledetails = fileUrl.slice(0, 2)
    // })

  }

  // brandname(brand) {
  //   let BrandName = this.brandsCollection.find((b) => b.id === brand.BrandID);
  //   return BrandName ? BrandName.BrandName : null;
  // }

  getgarmentStyle() {
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    // this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key,'tab':'Menswears' }])

    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'tab': 'Menswears' }])
  }


  getgarmentStylefeed() {
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key }])

    // this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key }])
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getBrandProfileByGarment(brand: any, board: any) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsListSend);

    const newdta = {
      garmentId: this.garmentId, key: this.key

    }
    this.brandPanelOpenByCard.emit(newdta)
    // this.router.navigate(['/brand', { 'brandId': brand.id, 'select-tab': 'story' }])


  }




  // getClothProfile() {
  //   // if(this.router.url == 'public/public-garment-feed') { window.location.reload()}
  //   let snap = this.clothShapShotsListSend
  //   let cloths = this.clothDetails
  //   this.dataSharingService.sendUserSnapShots(snap)
  //   this.dataSharingService.sendClothDetails(this.clothDetails)
  //   this.dataSharingService.sendUserfiles(this.userFiledetails)
  //   this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 
  //   'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key ,'tab':'Menswear'}])
  // }


  // getClothProfile() {
  //   let snap = this.clothShapShotsListSend
  //   let cloths = this.clothDetails
  //   let type

  //   if (this.key == 'HeadwearID') {
  //     type = 'headgear'
  //   } else if (this.key == 'UpperwearID') {
  //     type = 'upperwear'
  //   } else if (this.key == 'BottomwearID') {
  //     type = 'bottomwear'
  //   } else if (this.key == 'FootwearID') {
  //     type = 'footwear'
  //   }
  //   const selectedData = {
  //     garmentId: this.garmentId, key: this.key, type: type
  //   }
  //   this.garmentPanelOpenByCard.emit(selectedData)

  //   this.dataSharingService.sendUserSnapShots(snap)
  //   this.dataSharingService.sendClothDetails(this.clothDetails)
  //   this.dataSharingService.sendUserfiles(this.userFiledetails)
  // }

  getClothProfile() {
    const typeMapping = {
      'HeadwearID': 'headgear',
      'UpperwearID': 'upperwear',
      'BottomwearID': 'bottomwear',
      'FootwearID': 'footwear'
    };

    const type = typeMapping[this.key];
    const selectedData = { garmentId: this.garmentId, key: this.key, type };
// [selectedSnapShot]="selectedGarmentName" [brandUrl]="brandUrl"
//                         [typeWear]="garmentTypeID" [RealType]="realType"
    this.garmentPanelOpenByCard.emit(selectedData);

    this.dataSharingService.sendUserSnapShots(this.clothShapShotsListSend);
    this.dataSharingService.sendClothDetails(this.clothDetails);
    this.dataSharingService.sendUserfiles(this.userFiledetails);
  }

  getgarmenFeed(snapshot) {
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'storyId': snapshot.StoryID, 'tab': 'story' }])

    // this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key,'storyId':snapshot.StoryID,'tab':'story'}])
  }



  openImageBox(fileurl) {
    this.showImages = fileurl
    this.openTwoDImage(fileurl)
  }


  openBrandPoup() {
    const modalRef = this.modal.open(BrandPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.garmentId = this.garmentId;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }



  openTwoDImage(url) {
    const modalRef = this.modal.open(TwoDpopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.boxImage = url;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }
  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    dialogRef.componentInstance.gltfImgSrc = gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  scrolltopSide() {
    // this.scrollValue.emit(this.swipeUp.nativeElement.scrollTop) 
  }
  clickOnHeart() {
    this.heartFlag = true
  }



  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }

  getStory(Menswears) {
    if (Menswears == 'Menswears') {
      this.iconselctFlag = true
      this.selectedTab = "Menswears"
    }
    this.selectedIcon = ''

  }
  getStyle(brandIds) {
    this.selectedIcon = ''
    this.iconselctFlag = false
    this.selectedTab = "Menswear"
  }

  filterCollectionName(id) {
    // let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id )
    // let getCollection =  this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    // let name = getCollection != undefined && this.collectionData != undefined &&  getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    // return name
  }


  getgarmentnameBackground() {
    const background = this.router.url.includes('/avatar') || this.router.url.includes('/drip')
      ? 'rgba(49, 49, 49, 0.3)'
      : '##313131';
    return background
  }

  garmentnameBackground() {
    const background = this.router.url.includes('/avatar') || this.router.url.includes('/drip')
      ? 'rgba(49, 49, 49, 0.3)'
      : '##313131';
    return background
  }
  getgarmentname_title() {
    const background = this.router.url.includes('/avatar') || this.router.url.includes('/drip')
      ? 'rgba(241, 241, 241, 0.3)'
      : '#f1f1f1';
    return background
  }

  clothName_background() {
    const background = this.router.url.includes('/avatar') || this.router.url.includes('/drip')
      ? 'rgba(186, 186, 186, 0.3)'
      : '#bababa';
    return background
  }
}