import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Brand } from 'src/app/models/brand';
@Component({
  selector: 'app-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.css']
})
export class AvatarPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;


  @Input() scrollSide: any;
  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() UserID: any | undefined;
  @Input() userData: any;
  @Input() tempAvatrData: any;

  @Input() rootFlag: string | undefined;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedAvatarName: any;
  @Input() selectedUserName: any;
  @Input() AvatarData: any;
  @Input() ResetPanel: boolean;

  @Input() TabSelection: any;
  @Input() SetBrandId: any;
  @Input() SetGarmentId: any;
  @Input() Index: any;
  @Input() updatdeIndex: any;







  @Input() passTabSelection: boolean;
  @Input() passTabSelectionStyle: boolean;


  






  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() sendStoryId = new EventEmitter<any>();
  @Output() setTabSelection = new EventEmitter<any>();

  @Output() setTabSelectionBrandId = new EventEmitter<any>();
  @Output() setTabSelectionGarment = new EventEmitter<any>();






  @Output() emitSlectedSnapList = new EventEmitter<any>();

  @Output() potrateImage = new EventEmitter<any>();

  @Output() closeAvatarPanel = new EventEmitter<any>();
  @Output() emitSlectedUSER_And_Avatar = new EventEmitter<any>();


















  userAvatarData: any = {};
  allUserSnapDetails: any = []
  tempSnapList: any = []
  snapshotsBrandFilter: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails: any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';
  pageNumber: number = 1
  pagecount: number = 1
  newArray: any = [];

  dropdownOpen: boolean;
  showStoryList: boolean = true;
  brandList: any = [];
  garmentCacheList: any = [];
  snapshotsGarmentFilter: any = [];
  cacheObject: any
  showDetailes = false
  pageFlagRout;


  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss: boolean = true;
  showChild: boolean = true;
  garmentsCollection: any = [];
  brandsCollection: any = [];
  brandObject: any;
  isBrandLoading: any;
  pageiIndex: any;
  callpageNumer: number;
  nextPage: boolean;
  callpageIndexFlag: any;
  indexCache: any;
  avatrObjFlag: boolean;
  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {


    this.checkCurrentRoute()

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    })

    // this.user.subscribe(userdetails => {
    //   this.userDetails = userdetails
    //   let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
    // });


    // this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    //   this.allUserDetails = allUsers.users;
    //   this.AllDetails = allUsers.users;
    // })

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   // this.update()  
    // })
  }

  ngOnInit(): void {
    //    this.getDocumentDetails()

    //   let reqInputObject = { id: this.UserShapShotObject.AvatarID, pageNumber: 1 }
    //   this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
    //     this.filterSnapShotFileDetailsall = response.object
    //     // this.allUserSnapDetails = response.object.slice(0, 3)
    //     this.allUserSnapDetails= this.filterSnapShotFileDetailsall
    //     this.brandsnaploadingSnap = false
    //     this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
    //     });
    //   })

    // this.userFilesList.subscribe((userFile) => {
    //   let userId = this.UserShapShotObject &&
    //   this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
    //   this.userUploadedFileList = userFile
    //   this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    //   let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
    //   this.userFiledetails = filelist
    // });
    // this.setuserDetails()
  }


  // ngOnChanges(changes) {
  //   new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
  //     interval: true // Disable automatic sliding if needed
  //   });    
  // }

  // async getDocumentDetails() {
  //   await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID)
  //     .then((documents) => {
  //       if (documents && documents.length > 0) {
  //         const firstDocument = documents[0]; // Assuming there is only one document
  //         this.userAvatarData = null
  //         this.userAvatarData = firstDocument ;
  //         this.newArray = this.processAvatarUrls()
  //         console.log("this.userAvatarData", this.userAvatarData)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error in component:', error);
  //       throw error; 
  //       // You can choose to re-throw the error or handle it here
  //     });
  // }

  async getDocumentDetails() {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);
      let dummydata = { AvatarID: this.UserShapShotObject.AvatarID }
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        this.userAvatarData = null;
        this.userAvatarData = firstDocument;
        this.newArray = this.processAvatarUrls();
      } else {
        this.userAvatarData = { AvatarID: this.UserShapShotObject.AvatarID };
      }
    } catch (error) {
      console.error('Error in component:', error);
      throw error;
    }
  }

  getAvatarDisplayName(userAvatarData: any): string {
    return userAvatarData?.AvatarName !== undefined && userAvatarData?.AvatarName !== ''
      ? userAvatarData.AvatarName
      : userAvatarData.AvatarID;
  }

  getAvatarImageUrl(userAvatarData: any): string {
    return userAvatarData?.AvatarPreviewPortraitUrl !== undefined && userAvatarData?.AvatarPreviewPortraitUrl !== ''
      ? userAvatarData.AvatarPreviewPortraitUrl
      : './assets/peteravatar.png'; // Replace with the actual path to your dummy image
  }


  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.userAvatarData.AvatarPreviewFullBodyUrl, fileurl: this.userAvatarData.AvatarPreviewFullBodyUr });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({
        fileurlMini: this.userAvatarData.AvatarPreviewPortraitUrl,
        fileurl: this.userAvatarData.AvatarPreviewPortraitUrl
      });
    }
    this.potrateImage.emit(urls)
    return urls;
  }



  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });

      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userDetails.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'userpopup'
        };

        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  // Optionally, you could add this ID to this.userData to keep the local array updated
                  // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });
  }


  // userSnapShots() {
  //   this.closePopUp();
  //   this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
  //   this.dataSharingService.sendUserData(this.userData)
  // }

  async getUserSnapShotDashboard(page, snapshot, index) {
    let id = snapshot.UserID != undefined ? snapshot.UserID : this.UserID
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    let pagetab = page
    let storyId = snapshot.StoryID != undefined ? snapshot.StoryID : "BlanckStoryID"
    // Construct the parameters object
    const params = {
      id: snapshot.UserID || this.UserID,
      pagetab: page,
      storyId: storyId,
      index: index,
      pageNumber: currentPage,
      PageType: 'User',

    };
    this.router.navigate(['/avatar', params]);

  }

  getUserSnapShotByAvatarId(page, snap, index) {
    let sToryId = snap.StoryID != undefined ? snap.StoryID : ''
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
    let count = 12
    let flag = true
    const currentPage = Math.floor(index / count) + 1;
    this.router.navigate(['/avatar', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID), 'Storyid': this.UserShapShotObject.id, 'PageType': 'User' }])

  }

  getUserSnapShotByAvatar(page, snap, index) {
    this.closePopUp();

    let flag = true

    // if(this.pageFlagRout == 'avatar' && !snap.SnapshotFileUrl){
    //   return
    // }

    let tempAVatarID = this.allUserSnapDetails[0].AvatarID || snap.AvatarID
    let tempUserID = this.allUserSnapDetails[0].UserID || snap.UserID

    // if (this.AvatarData) {
    //   tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID
    //   tempUserID = this.AvatarData.snapshotsData.object[0].UserID
    // }

    // Set the number of items per page
    const count = 12;
    const currentPage = Math.floor(index / count) + 1;

    // Extract required values with fallback
    const avatarId = tempAVatarID || this.UserShapShotObject?.AvatarID || this.AvatarID;
    const userId = tempUserID || this.userDetailsObject?.UserID || this.UserID;
    const storyId = snap?.StoryID || 'defaultStoryID';


    // Construct the parameters object
    const params: any = {
      avatarID: avatarId,
      pagetab: page,
      id: userId,
      storyId: storyId,
      index: index,
      pageNumber: currentPage,
      PageType: 'Avatar'
    };

    // Add additional logic if certain tabs are selected
    if (this.secteTab === 'Brands') {
      params['TabSelection'] = this.secteTab;
      params['SetBrandId'] = this.brandObject.id;
    }

    if (this.secteTab === 'Style') {
      params['TabSelection'] = this.secteTab;
      params['SetGarmentId'] = this.cacheObject.id;
    }

    this.router.navigate(['/avatar', params]);
    this.closeAvatarPanel.emit(snap)
    let miss = this.allUserSnapDetails[0]
    let AvatarSwichDetails
    AvatarSwichDetails = AvatarSwichDetails || {};
    AvatarSwichDetails['UserID'] = miss.UserID;
    AvatarSwichDetails['AtarID'] = miss.AvatarID;

    this.emitSlectedUSER_And_Avatar.emit(AvatarSwichDetails)

    if (snap?.StoryID) {
      this.sendStoryId.emit(snap.StoryID);
    } else {
      console.warn('StoryID is undefined for the provided snap object.');
    }

    // Navigate with the constructed parameters


  }


  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.router.navigate(['user', { 'id': this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  selectTab(tab: any) {
    this.secteTab = tab
    if (tab == 'Story') {
      this.showStoryList = true
      this.selectAllAvatars()
    }

    if (tab == 'Brands') {
      this.showStoryList = false
      this.switchBrandTab()
    }

    if (tab == 'Style') {
      this.showStoryList = false
      this.switchStyleTab()
    }

    this.setTabSelection.emit(tab)
    this.setTabSelectionBrandId.emit(this.SetBrandId)
    this.setTabSelectionGarment.emit(this.SetGarmentId)

  }

  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }


  incrementImgCounter() {
    this.pageNumber++
    let avatarId = this.AvatarData.snapshotsData.object[0].AvatarID
    let reqInputObject = { id: avatarId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.allUserSnapDetails.push(...response.object)
    })
  }

  toggleChild() {
    // this.showChild = !this.showChild;
    this.closeAvatarEmitter.emit(false)
  }



  async avatarPanelDataFunction(userId, avatarId) {
    if (!userId || !avatarId) {
      console.warn("userId or avatarId is undefined. Skipping HTTP call.");
      return null; // Or handle this case as required in your application
    }

    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getAvatarPanelDetails";

    try {
      return await this.http.post<any>(functionUrl, { userId: userId, avatarId: avatarId }).toPromise();
    } catch (error) {
      console.error("Error fetching avatar panel data:", error);
      throw error;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Handle UserShapShotObject change

    if (changes['tempAvatrData'] && this.tempAvatrData) {
      this.newArray = []
      this.AvatarData = this.tempAvatrData.AvatarData
      this.selectedUserName = this.AvatarData.userDetails.DisplayName;
      this.selectedAvatarName = this.AvatarData.avatarDetails[0].AvatarName;
      this.userAvatarData = this.AvatarData.avatarDetails[0]
      this.allUserSnapDetails = this.AvatarData.snapshotsData.object
      this.newArray = this.processAvatarUrls()
      console.log("this.TabSelection", this.TabSelection);

      // this.selectTab(this.TabSelection);

      //   if (this.TabSelection == 'Brands') {
      //   this.AvatarData = await this.avatarPanelDataFunction(this.allUserSnapDetails[0]?.UserID, this.allUserSnapDetails[0]?.AvatarID);
      //   this.loadUserData();
      //   if (this.TabSelection) {
      //     this.selectTab(this.TabSelection)
      //   }

      // }

      // this.selectTab(this.TabSelection)
      console.log("tempAvatrData------------------------------------------------------------");
    }



    if (changes['UserShapShotObject'] && this.UserShapShotObject) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject?.UserID, this.UserShapShotObject?.AvatarID);
      this.loadUserData();
      if (changes['TabSelection'] && this.TabSelection) {
        this.selectTab(this.TabSelection)
      }
    }

    // Handle userData change
    if (changes['userData'] && this.userData && this.userData.userData && this.userData.userData.userDetails) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.AvatarData = this.userData.userData;
      this.selectedUserName = this.AvatarData.userDetails.DisplayName;
      this.selectedAvatarName = this.AvatarData.userDetails.DisplayName;
      this.loadUserData();
      if (changes['TabSelection'] && this.TabSelection) {
        this.selectTab(this.TabSelection)
      }

    } else {
      console.warn('User data or userDetails is missing');
    }
    if (this.ResetPanel == true) {
      this.AvatarData = null
    }

    // Initial load when UserShapShotObject is undefined
    if ((!this.UserShapShotObject || changes['UserShapShotObject'] && !this.AvatarData) || this.ResetPanel) {
      this.brandObject = null;
      this.TabSelection = 'Story'

      // Determine the current values for UserID and AvatarID
      const currentUserID = this.UserShapShotObject?.UserID || this.UserID;
      const currentAvatarID = this.UserShapShotObject?.AvatarID || this.AvatarID;

      if (this.ResetPanel) {
        this.AvatarData = null
      }


      // Fetch avatar data only if it's undefined or needs an update
      if (!this.AvatarData || changes['UserShapShotObject']) {
        this.AvatarData = await this.avatarPanelDataFunction(currentUserID, currentAvatarID);
        if (this.AvatarData) {
          this.loadUserData(); // Load user data after fetching
        }
        if (this.ResetPanel) {
          this.selectedUserName = this.AvatarData.userDetails.DisplayName;
          this.selectedAvatarName = this.AvatarData.avatarDetails[0].AvatarName;
          this.userAvatarData = this.AvatarData.avatarDetails[0]
          this.allUserSnapDetails = this.AvatarData.snapshotsData.object
          this.newArray = this.processAvatarUrls()
        }
        if (changes['TabSelection'] && this.TabSelection) {
          this.selectTab(this.TabSelection);
          console.log("Tab selection updated");
        }
      }



      if (changes['passTabSelection'] && this.passTabSelection) {
        this.selectTab('Brands');
        console.log("Tab view..............",this.TabSelection);
      }

      if (changes['passTabSelectionStyle'] && this.passTabSelectionStyle) {
        this.selectTab('Style');
        console.log("Tab view..............",this.TabSelection);
      }

      



      // Handle tab selection if necessary

    }




    if (this.Index) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      this.fetchSnapshotByIndex(this.Index)

    }

    if (changes['updatdeIndex'] && this.updatdeIndex) {
      if (this.pageFlagRout == 'avatar') {
        return
      }
      let numb = this.updatdeIndex
      this.loadStoryByScroll(numb)
    }


    // this.selectTab(this.TabSelection);


  }

  // async loadUserData() {
  //   let data = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID,this.UserShapShotObject.AvatarID)
  //   this.userAvatarData = data.avatarDetails[0]
  //   this.allUserSnapDetails = data.snapshotsData
  //   console.log("data",data)

  // }
  loadStoryByScroll(number) {
    // Only trigger the logic for multiples of 6
    if (number % 5 === 0) {
      this.indexCache = number;
      if (this.indexCache === 5 && this.scrollSide === 'up') {
        this.previousUserbutton();
      } else if (this.indexCache === 5 && this.scrollSide === 'down') {
        this.incrementImgCounter();
      }
    }
  }
  async loadUserData() {
    try {
      if (this.AvatarData.length != 0) {
        this.brandsnaploadingSnap = false;
        if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
          this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
          this.newArray = this.processAvatarUrls()
        } else {
          this.userAvatarData = null; // Handle case where no avatar details are found
        }
        if (Array.isArray(this.AvatarData.snapshotsData.object)) {
          if (this.Index == undefined || this.Index < 12 && !this.avatrObjFlag) {
            this.allUserSnapDetails = this.AvatarData.snapshotsData.object
            this.avatrObjFlag = true
          }

          if (this.Index) {
            this.fetchSnapshotByIndex(this.Index)
          }
          // this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
        } else {
          this.allUserSnapDetails = []; // Handle case where no snapshots data is found
        }
        this.userDetailsObject = this.AvatarData.userDetails; // Assuming user
      }
      // Assuming avatarPanelDataFunction is a method that retrieves data asynchronously
      this.AvatarData = await this.avatarPanelDataFunction(this.UserShapShotObject.UserID, this.UserShapShotObject.AvatarID);


      // Check and assign avatar details
      if (Array.isArray(this.AvatarData.avatarDetails) && this.AvatarData.avatarDetails.length > 0) {
        this.userAvatarData = this.AvatarData.avatarDetails[0]; // Assuming you only need the first avatar detail
        this.newArray = this.processAvatarUrls()

      } else {
        this.userAvatarData = null; // Handle case where no avatar details are found
      }

      // Assign snapshots data
      if (Array.isArray(this.AvatarData.snapshotsData.object)) {

        if (this.Index == undefined || this.Index < 12 && !this.avatrObjFlag) {
          this.allUserSnapDetails = this.AvatarData.snapshotsData.object
          this.avatrObjFlag = true
        }

        if (this.Index) {
          this.fetchSnapshotByIndex(this.Index)
        }
        // this.allUserSnapDetails = this.AvatarData.snapshotsData.object; // Assuming snapshotsData.object is the array you want to assign
      } else {
        this.allUserSnapDetails = []; // Handle case where no snapshots data is found
      }

      // Assign user details
      this.userDetailsObject = this.AvatarData.userDetails; // Assuming userDetails is an object directly from the response
      this.brandsnaploadingSnap = false;
    } catch (error) {
      // console.error("Error loading user data:", error);
      // Handle error gracefully
      console.log("not loaded")
    }

    this.tempSnapList = this.allUserSnapDetails
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
    this.brandsnaploadingSnap = false;


  }

  getGarmentrealtype(garmentId: string): string {
    return this.garmentsCollection.find((b: any) => b.id === garmentId)?.RealType ?? '';
  }

  //showing Brandlogo With Garment Id
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${imgURL}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  //For Finding Brand Logo BrandLogoUrl 
  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  getGarmentName(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    return garment?.Name ?? '';
  }


  selectavatarOprtion() {
    this.dropdownOpen = !this.dropdownOpen
  }


  selectAllAvatars() {
    this.brandObject = null
    this.cacheObject = null
    this.allUserSnapDetails = this.tempSnapList
    this.dropdownOpen = false
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  }


  selectBrand(brand) {
    let tempAVatarID
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID
    }
    this.brandObject = brand
    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;
    this.isBrandLoading = true; // Show loader
    this.commonService.getSnapshotsByAvatarAndBrand(avatarId, this.brandObject.id).subscribe({
      next: (response) => {
        this.snapshotsBrandFilter = response.snapshots;
        this.allUserSnapDetails = this.snapshotsBrandFilter
        this.showStoryList = true
        this.dropdownOpen = false
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)

      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        this.isBrandLoading = false; // Hide loader
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)

      }
    });
  }

  getHeight(length: number): number {
    if (length === 2) {
      return 150;
    } else if (length === 3 || length === 4) {
      return 225;
    } else if (length >= 5) {
      return 300; // Set the "regular height" here
    }
    return 350; // Default height if needed
  }

  async switchBrandTab() {
    let tempAVatarID
    console.log('this.userAvatarData: ', this.allUserSnapDetails[0]
    ); // Logs the entire AvatarData object

    console.log('this.AvatarData: ', this.AvatarData); // Logs the entire AvatarData object
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData ? this.AvatarData?.snapshotsData.object[0].AvatarID : this.allUserSnapDetails[0].AvatarID;
    }

    console.log('tempAVatarID (derived from AvatarData): ', tempAVatarID); // Logs tempAVatarID

    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;
    console.log('this.UserShapShotObject: ', this.UserShapShotObject); // Logs UserShapShotObject
    console.log('this.AvatarID: ', this.AvatarID); // Logs AvatarID
    console.log('Computed avatarId: ', avatarId); // Log
    this.isBrandLoading = true;
    try {
      // Fetch the brand list
      const response = await this.commonService.getUniqueBrandListByAvatar(avatarId).toPromise();
      this.brandList = response.brands;
      // Compress images and update the brand list
      for (const brand of this.brandList) {
        let type = 'b';
        const url = await this.commonService.compressImage(brand.LogoURL, type);
        brand.Brandurl = url.downloadUrl; // Save the compressed image URL back into the brand object
      }

      // Cache the current UserID to prevent redundant loading
      // this.cachedUserId = userId;

    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      // Remove the loader once the process is complete
      if (this.SetBrandId) {
        let setbend = this.brandList.find((b) => b.id == this.SetBrandId)
        this.selectBrand(setbend)
      }
      this.isBrandLoading = false;
    }
  }

  async switchStyleTab() {
    let tempAVatarID
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData ? this.AvatarData?.snapshotsData.object[0].AvatarID : this.allUserSnapDetails[0].AvatarID;
    } const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;
    try {
      // Fetch the brand list
      const response = await this.commonService.getUniqueCacheImageListByAvatar(avatarId).toPromise();
      this.garmentCacheList = response.garments;
      // this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      if (this.SetGarmentId) {
        let setbend = this.garmentCacheList.find((b) => b.id == this.SetGarmentId)
        this.selectCacheImage(setbend)
      }
      this.isBrandLoading = false;
    }


  }

  async selectCacheImage(cache) {
    this.cacheObject = cache;
    let tempAVatarID
    if (this.AvatarData) {
      tempAVatarID = this.AvatarData.snapshotsData.object[0].AvatarID

    }

    const avatarId = this.UserShapShotObject?.AvatarID || this.AvatarID || tempAVatarID || this.allUserSnapDetails[0].AvatarID;


    this.commonService.getSnapshotsByAvatarAndGarment(avatarId, cache.id).subscribe({
      next: (response) => {
        // Assign API response to the correct variable
        this.snapshotsGarmentFilter = response.snapshots;
        // Debugging: Log the snapshotsGarmentFilter value  
        // Assign snapshotsGarmentFilter to allUserSnapDetails
        this.allUserSnapDetails = this.snapshotsGarmentFilter;
        // Update UI state
        this.showStoryList = true;
        this.dropdownOpen = false;
      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        // Emit the selected snapshots list
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);
      }
    });
  }

  async cacheReGenrate(chache) {
    let tempIamge = 'assets/realtype/upperwear.png'
    let data
    await this.commonService.downloadAndProcessPackUserReference(chache).subscribe({
      next: (response) => {
        data = response
      },
      error: (error) => console.error('Error:', error)
    });
    let image = data.publicUrl
    return image
  }



  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png'; // Replace with correct image path
      case 2:
        return 'assets/newsfeed/bottomwear-new.png'; // Replace with correct image path
      case 3:
        return 'assets/realtype/footwear.png'; // Replace with correct image path
      case 4:
        return 'assets/realtype/fullbody.png'; // Replace with correct image path
      case 5:
        return './assets/realtype/fullbody.png'; // Replace with correct image path
      default:
        return ''; // Default or placeholder image
    }
  }




  /////new previous function
  private loadedPagesNumber = new Set<number>();
  private loadedPagesBackWord = new Set<number>();



  fetchSnapshotByIndex(index: number) {
    // this.previousFlage = true;

    const itemsPerPage = 12; // Number of items per page
    const pageNumber = this.calculatePageNumber(index, itemsPerPage);
    // Check if the page is already loaded
    if (this.loadedPagesNumber.has(pageNumber)) {
      return;
    }


    // Mark the page as loading
    this.loadedPagesNumber.add(pageNumber);

    // Update state variables
    this.pageiIndex = pageNumber;
    this.callpageNumer = pageNumber - 1;
    this.pageNumber = pageNumber;

    // Simulate fetching or call the actual fetching function
    this.functNavigate(pageNumber);

  }

  functNavigate(pageNumber) {
    const reqInputObject = { id: this.AvatarID || this.UserShapShotObject.AvatarID, pageNumber: pageNumber };
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count
        this.allUserSnapDetails = response.object
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        if (this.pagecount > this.allUserSnapDetails.length) {
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails
  }


  calculatePageNumber(index: number, itemsPerPage: number = 12): number {
    const calculatedPageNumber = Math.floor(index / itemsPerPage) + 1;
    return calculatedPageNumber;
  }

  previousUserbutton() {
    const count = 12; // Number of items per page
    // Initialize the page number if necessary
    // if (this.callpageIndexFlag) {
    //   this.pageNumber = this.pageiIndex - 1;
    //   this.callpageIndexFlag = false;
    // }

    if (this.callpageNumer > 0) {

      // Calculate indices for the current page
      const cllpagecount = this.callpageNumer * count;
      const startIndex = cllpagecount - count;

      // Check if the page is already loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        this.callpageNumer--;
        return;
      }
      if (this.loadedPagesNumber.has(this.callpageNumer)) {
        return;
      }


      // Prepare request object for API call
      let avatarId = this.AvatarData.snapshotsData.object[0].AvatarID

      let reqInputObject = { id: this.AvatarID || avatarId, pageNumber: this.callpageNumer }
      // Get the currently visible item's index
      const currentIndex = this.updatdeIndex; // Placeholder for visible index logic

      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        const newItems = response.object; // Data for the current page
        this.nextPage = true;

        // Unshift new items into the array
        this.allUserSnapDetails.unshift(...newItems);

        // Mark this page as loaded
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;


        // Adjust the slider to maintain the same visible item
        const adjustedIndex = currentIndex + newItems.length; // Recalculate the visible item's index
        // this.setSliderToIndex(adjustedIndex); // Programmatically set the slider position

        // Emit the updated snapshot list
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);

        // Decrement the page number
        this.callpageNumer--;
      });
    } else {
      console.log('No more pages to load backward.');
    }
  }

  colapsAvatarPanel(event) {
    event.stopPropagation()

    this.showDetailes = !this.showDetailes
  }

  checkCurrentRoute(): void {
    this.activatedRoute.url.subscribe(url => {
      this.pageFlagRout = url[0].path
      if (this.pageFlagRout == 'avatar') {

      }
      // Check if the URL matches your condition
    });
  }


}