import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { generateUUID } from 'three/src/math/MathUtils';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  @Output() closeAvatarEmitter = new EventEmitter<any>();
  @Output() emitSlectedSnapList = new EventEmitter<any>();

  @Output() SelectedStoryID = new EventEmitter<any>();
  @Output() emitSlectedUSER_And_Avatar = new EventEmitter<any>();
  @Output() sendStoryId = new EventEmitter<any>();
  @Output() UserDetails_Data = new EventEmitter<any>();
  @Output() pageid = new EventEmitter<any>();
  @Output() userDetailsObjectemit = new EventEmitter<any>();
  @Output() setTabSelection = new EventEmitter<any>();
  @Output() setTabSelectionBrandId = new EventEmitter<any>();

  @Output() setTabSelectionGarment = new EventEmitter<any>();


  

  














  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() scrollSide: any;


  @Input() Index: number;

  @Input() myProfile: boolean;

  @Input() callDripUserPanel: boolean;
  @Input() callAvatrUserPanel: boolean;






  @Input() UserID: any;
  
  @Input() userData: any;
  @Input() rootFlag: string;
  @Input() userProfile: string;
  @Input() brandStoryScrollerHeight: any;
  @Input() closebuttonFlag: boolean;
  @Input() selectedUserName: any;
  @Input() brandObject: any
  @Input() usrPageAvatarFlag: any;
  @Input() UserData: any;
  @Input() TabSelection: any;
  @Input() SetBrandId: any;
  @Input() SetGarmentId: any;
  @Input() SetAvatarId: any;
  @Input() updatdeIndex: any;











  showChild: boolean = true;
  snapInitCall: boolean;
  dropdownOpen: boolean;
  showStoryList: boolean = true
  loadedPagesBackWord = {};



  allUserSnapDetails: any = []
  snapshotsGarmentFilter: any = []
  garmentCacheList: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  snapshotsBrandFilter: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails: any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';
  pageNumber: number = 1
  pagecount: number = 1
  brandList: any
  cacheObject: any



  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss: boolean = true;
  avatarObject: any;
  newAvatarfilterSnap: any = [];
  newAvatarList: any = [];
  filterSnap: any = [];
  selectedavatarId: any = '';
  avatarMoreFlag: boolean;
  showList: boolean;
  tempSnapList: any = [];
  cachedUserId: any;
  isBrandLoading: boolean;
  uploadFileName: any;
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  loading: boolean;
  delete: boolean;
  previousFlage: boolean;
  callpageIndexFlag: any;
  callpageNumer: number;
  nextPage: boolean;
  pageiIndex: number;
  indexCache: any;
  showpanelView: boolean = true;






  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private storage: AngularFireStorage,
    public datepipe: DatePipe,


  ) {


    this.user.subscribe(userdetails => {
      this.userDetails = userdetails
      let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
      // this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)

    });

    // this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    //   this.allUserDetails = allUsers.users;
    //   this.AllDetails = allUsers.users;
    //   console.log("this.AllDetails", allUsers)
    // })

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   // this.update()  
    // })
  }

  ngOnInit(): void {
    this.filterAvatarDetails()
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['myProfile'] && this.myProfile) {
      this.delete = true
    }



    if (changes['callAvatrUserPanel'] && this.callAvatrUserPanel) {
      this.showpanelView = false
      this.UserData = await this.userPanelDataFunction(this.UserID);
      if (this.UserData) {
        this.userFiledetails = this.UserData.userFiles
        this.userDetailsObject = this.UserData.userDetails
        this.selectedUserName = this.UserData.userDetails.DisplayName;

        // if (this.Index == undefined || this.Index < 12) {
        //   this.allUserSnapDetails = this.UserData.snapshotsData.object
        // }

        this.pagecount = this.UserData.snapshotsData.count
        this.brandsnaploadingSnap = false
        if (this.Index) {
          this.fetchSnapshotByIndex(this.Index)
        }
        console.log("this.UserData--------------- same>")
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        this.showpanelView = true
        this.selectTab(this.TabSelection)

      }
    }



    if (changes['callDripUserPanel'] && this.callDripUserPanel) {
      this.UserData = await this.userPanelDataFunction(this.UserID);
      if (this.UserData) {
        this.userFiledetails = this.UserData.userFiles
        this.userDetailsObject = this.UserData.userDetails
        this.selectedUserName = this.UserData.userDetails.DisplayName;
        // if (this.Index == undefined || this.Index < 12) {
        //   this.allUserSnapDetails = this.UserData.snapshotsData.object
        // }
        this.pagecount = this.UserData.snapshotsData.count
        this.brandsnaploadingSnap = false
        if (this.Index) {
          this.fetchSnapshotByIndex(this.Index)
        }
        console.log("this.UserData--------------->")
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        this.selectTab(this.TabSelection)

      }
    }



    if (changes['UserID'] && this.UserID) {
      this.loadUserData();
      if (changes['TabSelection'] && this.TabSelection) {
        console.log("TabSelection", this.TabSelection)
        this.selectTab(this.TabSelection)
        // SetBrandId
        // SetGarmentId
      }
      console.log("UserID 1", this.UserID)
    }

    console.log("UserID 1", this.UserID)
    console.log("this.userDetailsObject", this.userDetailsObject)


    if (changes['usrPageAvatarFlag'] && this.usrPageAvatarFlag) {
      if (this.usrPageAvatarFlag) {
        this.SetAvatarId = this.usrPageAvatarFlag
        console.log("SetAvatarId", this.SetAvatarId)
      }
    }

    this.brandStoryScrollerHeight = window.innerHeight - 270;

    if (changes['userData'] && this.userData) {
      this.UserDetails_Data.emit(this.userData)

      // Ensure userWholeObject exists
      if (this.userData.userWholeObject) {
        this.UserData = this.userData.userWholeObject;
        // Ensure userDetails exists before accessing it
        if (this.UserData.userDetails) {
          this.selectedUserName = this.UserData.userDetails.DisplayName;
        } else {
          console.warn("userDetails is undefined in UserData");
          this.selectedUserName = ''; // Set a default or handle the case
        }
        this.loadUserData();
      } else {
        console.warn("userWholeObject is undefined in userData");
      }
    }

    if (changes['Index'] && this.Index) {
      let numb = this.Index

    }

    if (changes['updatdeIndex'] && this.updatdeIndex) {
      let numb = this.updatdeIndex
      this.loadStoryByScroll(numb)
    }

    if (this.scrollSide) {
      let numb = this.scrollSide
    }

   
    // if (changes['TabSelection'] && this.TabSelection) {
    //   console.log("TabSelection", this.TabSelection)
    //   this.selectTab(this.TabSelection)
    //   // SetBrandId
    //   // SetGarmentId
    // }



  }

  async loadUserData() {

    if (this.UserID && !this.UserData) {
      this.UserData = await this.userPanelDataFunction(this.UserID);
    }
    if (this.UserData.length != 0 && this.UserData) {
      this.userFiledetails = this.UserData.userFiles
      this.userDetailsObject = this.UserData.userDetails

      this.pagecount = this.UserData.snapshotsData.count
      this.brandsnaploadingSnap = false

      if (this.Index == undefined || this.Index < 12) {
        this.allUserSnapDetails = this.UserData.snapshotsData.object
      }

      if (this.Index) {
        this.fetchSnapshotByIndex(this.Index)
      }



    } else {
      this.UserData = await this.userPanelDataFunction(this.UserID);
      if (this.UserData) {
        this.userFiledetails = this.UserData.userFiles
        this.userDetailsObject = this.UserData.userDetails

        if (this.Index == undefined || this.Index < 12) {
          this.allUserSnapDetails = this.UserData.snapshotsData.object
        }

        this.pagecount = this.UserData.snapshotsData.count
        this.brandsnaploadingSnap = false
        if (this.Index) {
          this.fetchSnapshotByIndex(this.Index)
        }
        console.log("this.UserData--------------->")
      }
    }
    this.tempSnapList = this.allUserSnapDetails
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  }


  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });

      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userDetails.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'userpopup'
        };

        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  // Optionally, you could add this ID to this.userData to keep the local array updated
                  // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });
  }


  userSnapShots() {
    this.closePopUp();
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.dataSharingService.sendUserData(this.userData)
  }

  async getUserSnapShotDashboard(page, snapshot, index) {
    let tabFalg = false;
    let count = 12;
    const currentPage = Math.floor(index / count) + 1;
    let id = snapshot.UserID != undefined ? snapshot.UserID : this.UserID;
    let pagetab = page;
    if (this.secteTab == 'Brands' || this.secteTab == 'Style') {
      tabFalg = true;
    }
    this.pagecount;
    let storyId = snapshot.StoryID != undefined ? snapshot.StoryID : "BlanckStoryID";
    // Construct the parameters object
    const params = {
      id: snapshot.UserID || this.UserID,
      pagetab: page,
      storyId: storyId,
      index: index,
      pageNumber: currentPage,
      PageType: 'User',

    };

    // Add tabFlag logic

    if (tabFalg) {
      params['TabSelection'] = this.secteTab;
      if (this.secteTab == 'Brands') { params['SetBrandId'] = this.brandObject.id; }
      if (this.secteTab == 'Story') { params['SetAvatarId'] = this.avatarObject.AvatarID; }
      if (this.secteTab == 'Style') { params['SetGarmentId'] = this.cacheObject.id; }
    }

    if (this.secteTab == 'Story' || storyId == 'BlanckStoryID') {
      params['TabSelection'] = this.secteTab
    }


    if (page == 'user-story') {
      this.SelectedStoryID.emit(storyId)
      this.sendStoryId.emit(storyId)
    }

    this.pageid.emit(page)
    this.userDetailsObjectemit.emit(this.userDetailsObject)
    

    if (this.myProfile) {
      return
    }

    this.router.navigate(['/avatar', params]);

  }


  getUserSnapShotByAvatarId(page, snap) {
    
    let sToryId = snap.StoryID != undefined ? snap.StoryID : ''
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });

    this.closePopUp();
    this.router.navigate(['/user', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID) }])
  }

  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.router.navigate(['user', { 'id': this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  selectTab(tab: any) {
    this.secteTab = tab
    if (tab == 'Story') {
      this.showStoryList = true
      this.selectAllAvatars()
    }
    if (tab == 'Brands') {
      this.showStoryList = false
      this.switchBrandTab()
      
    }

    if (tab == 'Style') {
      this.showStoryList = false
      this.switchStyleTab()
    }
    
    this.setTabSelection.emit(tab)
    this.setTabSelectionBrandId.emit(this.SetBrandId)
    this.setTabSelectionGarment.emit(this.SetGarmentId)
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)

    console.log('selected tab',tab)
    console.log('selected SetBrandId',this.SetBrandId)
    console.log('selected SetGarmentId',this.SetGarmentId)


  }

  getHeight(length: number): number {
    if (length === 2) {
      return 150;
    } else if (length === 3 || length === 4) {
      return 225;
    } else if (length >= 5) {
      return 300; // Set the "regular height" here
    }
    return 350; // Default height if needed
  }


  async switchStyleTab() {
    const userId = this.UserID || this.userDetailsObject.UserID; // Replace with the actual user ID
    try {
      // Fetch the brand list
      const response = await this.commonService.getUniqueCacheImageList(userId).toPromise();
      this.garmentCacheList = response.garments;
      this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      if (this.SetGarmentId) {
        let setbend = this.garmentCacheList.find((b) => b.id == this.SetGarmentId)
        this.selectCacheImage(setbend)
      }
      this.isBrandLoading = false;
      this.garmentCacheList.forEach(async element => {
        if (element.cacheUrl == null) {
          let image = await this.cacheReGenrate(element)
          element.cacheUrl = image.publicUrl
        }
      });
    }
  }



  async switchBrandTab() {
    const userId = this.UserID || this.userDetailsObject.UserID; // Replace with the actual user ID

    // Check if data for the same UserID is already loaded
    // if (this.cachedUserId === userId) {
    //   console.log('Data already loaded for the same UserID');
    //   return;
    // }

    // Set a loader to indicate the process
    this.isBrandLoading = true;

    try {
      // Fetch the brand list
      const response = await this.commonService.getUniqueBrandListByUserId(userId).toPromise();
      this.brandList = response.brands;

      // Compress images and update the brand list
      for (const brand of this.brandList) {
        let type = 'b';
        const url = await this.commonService.compressImage(brand.LogoURL, type);
        brand.Brandurl = url.downloadUrl; // Save the compressed image URL back into the brand object
      }
      // Cache the current UserID to prevent redundant loading
      this.cachedUserId = userId;
    } catch (error) {
      console.error('Error fetching brand list:', error);
    } finally {
      // Remove the loader once the process is complete
      if (this.SetBrandId) {
        let setbend = this.brandList.find((b) => b.id == this.SetBrandId)
        this.selectBrand(setbend)
      }

      this.isBrandLoading = false;
    }
  }



  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }

  calculatePageNumber(index: number, itemsPerPage: number = 12): number {
    const calculatedPageNumber = Math.floor(index / itemsPerPage) + 1;
    return calculatedPageNumber;
  }


  // Keep track of already fetched pages
  private loadedPagesNumber = new Set<number>();

  fetchSnapshotByIndex(index: number) {
    this.previousFlage = true;

    const itemsPerPage = 12; // Number of items per page
    const pageNumber = this.calculatePageNumber(index, itemsPerPage);

    // Check if the page is already loaded
    if (this.loadedPagesNumber.has(pageNumber)) {
      return;
    }


    // Mark the page as loading
    this.loadedPagesNumber.add(pageNumber);

    // Update state variables
    this.pageiIndex = pageNumber;
    this.callpageNumer = pageNumber - 1;
    this.pageNumber = pageNumber;

    // Simulate fetching or call the actual fetching function
    this.functNavigate(pageNumber);


  }

  // Helper function to calculate the page number

  loadStoryByScroll(number) {

    // Only trigger the logic for multiples of 6
    if (number % 5 === 0) {
      this.indexCache = number;
      if (this.indexCache === 5 && this.scrollSide === 'up') {
        this.previousUserbutton();
      } else if (this.indexCache === 5 && this.scrollSide === 'down') {
        this.incrementImgCounter();
      }
    }
  }


  previousUserbutton() {
    const count = 12; // Number of items per page
    // Initialize the page number if necessary
    if (this.callpageIndexFlag) {
      this.pageNumber = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    if (this.callpageNumer > 0) {

      // Calculate indices for the current page
      const cllpagecount = this.callpageNumer * count;
      const startIndex = cllpagecount - count;

      // Check if the page is already loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        this.callpageNumer--;
        return;
      }
      if (this.loadedPagesNumber.has(this.callpageNumer)) {
        return;
      }
      // Prepare request object for API call
      const reqInputObject = {
        id: this.UserID || this.userDetailsObject.UserID,
        pageNumber: this.callpageNumer
      };

      // Get the currently visible item's index
      const currentIndex = this.updatdeIndex; // Placeholder for visible index logic

      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        const newItems = response.object; // Data for the current page
        this.nextPage = true;

        // Unshift new items into the array
        this.allUserSnapDetails.unshift(...newItems);

        // Mark this page as loaded
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;

        // Adjust the slider to maintain the same visible item
        const adjustedIndex = currentIndex + newItems.length; // Recalculate the visible item's index
        this.setSliderToIndex(adjustedIndex); // Programmatically set the slider position

        // Emit the updated snapshot list
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);

        // Decrement the page number
        this.callpageNumer--;
      });
    } else {
      console.log('No more pages to load backward.');
    }
  }

  setSliderToIndex(adjustedIndex: any) {
    this.allUserSnapDetails
    let snap = this.allUserSnapDetails[adjustedIndex]
    let tab = 'user-story'
    //  this.getUserSnapShotDashboard(tab,snap,adjustedIndex)
  }
  incrementImgCounter() {
    this.pageNumber++;
    const reqInputObject = { id: this.UserID, pageNumber: this.pageNumber };
    if (this.loadedPagesNumber.has(this.pageNumber)) {
      return;
    }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count

        if (this.pagecount > this.allUserSnapDetails.length) {
          this.allUserSnapDetails.push(...response.object);
        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  }

  functNavigate(pageNumber) {
    const reqInputObject = { id: this.UserID, pageNumber: pageNumber };
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(
      async (response: any) => {
        this.pagecount = response.count
        this.allUserSnapDetails = response.object
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
        if (this.pagecount > this.allUserSnapDetails.length) {

        } else {
          console.log('No new snapshots added. Current pagecount:', this.pagecount);
        }
      },
      (error: any) => {
        console.error('Error occurred while fetching snapshots:', error);
      }
    );
    this.tempSnapList = this.allUserSnapDetails
  }

  toggleChild() {
    this.showChild = !this.showChild;
    this.closeAvatarEmitter.emit(this.showChild)
  }


  // previousUserbutton() {
  //   let count = 12;
  //   if (this.callpageIndexFlag) {
  //     this.pageNumber = this.pageiIndex - 1;
  //     this.callpageIndexFlag = false;
  //   }
  //   if (this.callpageNumer > 0) {
  //     console.log('callpageNumer', this.callpageNumer);
  //     let cllpagecount = (this.callpageNumer * count) - 1;
  //     let startIndex = (cllpagecount - 11) - (count - 1);
  //     console.log('cllpagecount', cllpagecount);
  //     console.log('startIndex', startIndex);
  //     console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);
  //     // Check if the page has already been loaded
  //     if (this.loadedPagesBackWord[cllpagecount]) {
  //       console.log(`Page ${this.callpageNumer + 1} is already loaded. Skipping API call.`);
  //       this.callpageNumer--;  // Decrease callpage by 1
  //       return;
  //     }
  //     console.log("this.UserID -",this.UserID )
  //     console.log("userDetailsObject - UserID -",this.userDetailsObject.UserID)
  //     // Prepare the request object with the updated page number
  //     let reqInputObject = { id: this.UserID || this.userDetailsObject.UserID, pageNumber: this.callpageNumer }
  //     this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
  //       const newItems = response.object;
  //       this.nextPage = true;
  //       // Update userSnapShotListGarment with the new items at the correct start index

  //       for (let i = 0; i < newItems.length; i++) {
  //         this.allUserSnapDetails[startIndex + i] = newItems[i];
  //       }

  //       // Mark this page as loaded to prevent future duplicate calls
  //       this.loadedPagesBackWord[cllpagecount] = true;
  //       this.nextPage = false;
  //       console.log("this.userSnapShotListGarment after push", this.allUserSnapDetails);
  //       this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  //       console.log("loadedPages", this.loadedPagesBackWord);
  //       // Decrease callpage by 1 after the API call
  //       this.callpageNumer--;
  //     });
  //   }

  // }

  async userPanelDataFunction(userId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getUserPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId }).toPromise();
  }

  async filterAvatarDetails() {

    let reqInputObject = { userID: this.UserID || this.userDetailsObject.UserID };

    // Wait for the service call to complete
    await this.commonService.yologramPublicByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.newAvatarfilterSnap = response.object;
      this.newAvatarList = this.newAvatarfilterSnap
      // Use Promise.all with forEach for async operations
      await Promise.all(
        this.newAvatarList.map(async (entry) => {
          const avatarName = await this.getAvatarData(entry.UserID, entry.AvatarID);
          if (avatarName) {
            entry.AvatarName = avatarName.AvatarName;
            entry.AvatarPreviewPortraitUrl = avatarName.AvatarPreviewPortraitUrl;
          }
        })
      );

      if (this.SetAvatarId) {
        let avatarStory = this.newAvatarList.find((a) => a.AvatarID == this.SetAvatarId)
        this.selectAvatarId(avatarStory)
      }

      console.log("Updated newAvatarList", this.newAvatarList);
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown() {
    this.dropdownOpen = false;
  }

  async getAvatarData(id, avatar) {
    try {
      const documents = await this.commonService.getDocumentsFromSubcollection(id, avatar);
      if (documents && documents.length > 0) {
        const firstDocument = documents[0]; // Assuming there is only one document
        return firstDocument;
      }
    } catch (error) {
      console.error('Error getting avatar data:', error);
      return null; // Return null if there's an error fetching the data
    }
  }

  selectAvatarId(id) {
    this.avatarMoreFlag = true
    this.avatarObject = id
    this.dropdownOpen = false
    this.selectedavatarId = id.AvatarID
    console.log("id", id)
    let reqInputObject = { id: this.selectedavatarId, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap = response.object;
      this.allUserSnapDetails = this.filterSnap
      this.emitSlectedSnapList.emit(this.allUserSnapDetails)
    })
  }

  selectBrand(brand) {
    this.brandObject = brand
    this.isBrandLoading = true; // Show loader
    this.commonService.getSnapshotsByUserAndBrand(this.UserID || this.userDetailsObject.UserID, this.brandObject.id).subscribe({
      next: (response) => {
        this.snapshotsBrandFilter = response.snapshots;
        this.allUserSnapDetails = this.snapshotsBrandFilter
        this.showStoryList = true
        this.dropdownOpen = false
      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        this.isBrandLoading = false; // Hide loader
        this.emitSlectedSnapList.emit(this.allUserSnapDetails)
      }
    });
  }

  async selectCacheImage(cache) {
    this.cacheObject = cache;

    this.commonService.getSnapshotsByUserAndGarment(this.UserID || this.userDetailsObject.UserID, cache.id).subscribe({
      next: (response) => {

        // Assign API response to the correct variable
        this.snapshotsGarmentFilter = response.snapshots;

        // Debugging: Log the snapshotsGarmentFilter value

        // Assign snapshotsGarmentFilter to allUserSnapDetails
        this.allUserSnapDetails = this.snapshotsGarmentFilter;

        // Update UI state
        this.showStoryList = true;
        this.dropdownOpen = false;
      },
      error: (error) => {
        console.error('Error fetching snapshots:', error);
      },
      complete: () => {
        // Emit the selected snapshots list
        this.emitSlectedSnapList.emit(this.allUserSnapDetails);
      }
    });
  }

  selectAllAvatars() {
    this.brandObject = null
    this.cacheObject = null
    this.avatarObject = null
    this.allUserSnapDetails = this.tempSnapList
    this.dropdownOpen = false
    this.secteTab = 'Story'
    this.emitSlectedSnapList.emit(this.allUserSnapDetails)
  }

  async cacheReGenrate(chache): Promise<any> {
    try {
      const response = await firstValueFrom(this.commonService.downloadAndProcessPackUserReference(chache));
      return response; // Return the response directly
    } catch (error) {
      console.error('Error:', error);
      return null; // Return null if there's an error
    }
  }

  navigateToAvatarPage() {
    let data = { UserID: this.UserID, AtarID: this.selectedavatarId }

    if (this.myProfile) { return }
    const avatarId = this.selectedavatarId ;
    const userId = this.UserID ;
    const storyId =  'defaultStoryID';


    // Construct the parameters object
    const params: any = {
      avatarID: avatarId,
      pagetab:'story',
      id: userId,
      storyId: storyId,
      index: '',
      pageNumber: '',
      PageType: 'Avatar'
    };

    this.router.navigate(['/avatar', params]);
    this.emitSlectedUSER_And_Avatar.emit(data)


    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/avatar'], { queryParams: params });
    // });
    // this.router.navigate(['/avatar', { 'id': this.UserID, 'avatarID': this.selectedavatarId, 'pagetab': "story", 'PageType': 'User' }])

  }

  selectavatarOprtion() {
    this.dropdownOpen = !this.dropdownOpen
  }

  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png'; // Replace with correct image path
      case 2:
        return 'assets/newsfeed/bottomwear-new.png'; // Replace with correct image path
      case 3:
        return 'assets/realtype/footwear.png'; // Replace with correct image path
      case 4:
        return 'assets/realtype/fullbody.png'; // Replace with correct image path
      case 5:
        return './assets/realtype/fullbody.png'; // Replace with correct image path

      default:
        return ''; // Default or placeholder image
    }
  }

  userFileUpload() {
    // document.getElementById("file").click();
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  async start2DFileUpload($event: any) {
    let userID = this.userDetailsObject.UserID || this.UserID
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    const path = `Uploads/${userID}/${this.uploadFileName}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject: any = {
      id: uid,
      filename: this.uploadFileName,
      fileurl: '',
      fileurlMini: '',
      filepath: path,
      date: date,
      fileType: 'UserUploads',
      userId: userID,
      link: '',
    };

    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(async url => {
        uploadObject.fileurl = url;
        // Generate the modified filename
        const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
        const compressedFilePath = `Uploads/${userID}/${modifiedFileName}`;
        let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
        try {
          const response = await this.http.post(functionUrl, {
            imageUrl: url,
            path: compressedFilePath
          }).toPromise();
          uploadObject.fileurlMini = response['fileurlMini'];

          // Ensure fileurlMini is defined before adding to Firestore
          if (uploadObject.fileurlMini) {
            await this.firestore.collection('userfiles').add(uploadObject);
            this.userFiledetails.push(uploadObject)
          } else {
            console.error('File URL Mini is undefined');
          }
        } catch (error) {
          console.error('Error compressing file:', error);
        }
      });
    });
  }

  setuserDetails(userDetails) {
    let userID = this.userDetailsObject.UserID || this.UserID
    this.loading = true;
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      let userDetail = this.allUserDetails.find((user) => user.uid == userID);
      if (!userDetail) {
        // Handle the case where the user does not exist.
        this.loading = false;
        return;
      }
      let displayDetails = userDetail.providerData[0];
      let saveUserDetails = {
        "DisplayName": displayDetails.displayName != undefined ? displayDetails.displayName : '',
        "Email": displayDetails.email != undefined ? displayDetails.email : '',
        "FirstName": '',
        "Identifier": '',
        "LastName": '',
        "MiddleName": '',
        "ProfilePicture": displayDetails.photoURL != undefined ? displayDetails.photoURL : '',
        "UserID": userDetail.uid,
        "PageName": 'myuserprofilefeed'
      };
      // Check if the user-profile already has this user's information to avoid logical duplication
      const userProfileRef = this.firestore.collection('user-profile').doc(userDetails.id);
      userProfileRef.get().subscribe((docSnapshot) => {
        if (docSnapshot.exists) {
          // Update the existing user profile
          userProfileRef.update(saveUserDetails);
        } else {
          // Create a new user profile if it doesn't exist
          console.log("Create a new user profile if it doesn't exist")
        }
        this.loading = false;
      });
    });
  }


}

