

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, NgZone, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, finalize, fromEvent, map, Observable, Subject, Subscription, take, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { DOCUMENT } from '@angular/common';
import * as JSZip from 'jszip/dist/jszip'
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
@Component({
  selector: 'app-threads-child',
  templateUrl: './threads-child.component.html',
  styleUrls: ['./threads-child.component.css']
})
export class ThreadsChildComponent {
  currentGarmentLinks: Record<string, string> = {};
  onChanges = new Subject<SimpleChanges>();
  @Input() brandGarments: any;
  @Input() mode: any;
  @Input() brandsIDdetailsAll: any;
  @Input() selectBrandGender: any;
  @Input() tab: any;
  @Input() brandsDetails: any;
  @Input() onChangeFlag: boolean;
  @Input() loading: boolean;
  @Input() brandId: any;
  @Input() pageNumber: any | 1;
  @Input() clickEventFlag: boolean;
  @Input() stylePageFlag: string | undefined;
  @Input() stylePage: string | undefined;
  @Input() routLogInFlag: boolean | undefined;
  @Input() isShowBrandBand: boolean | undefined;
  @Input() byScroll: boolean | undefined;
  @Input() showBrandFlag: boolean | undefined;
  @Input() pageTrueFlage: boolean | undefined;
  @Input() BrandSelection: boolean | undefined;
  @Input() showSelectBrand: boolean | undefined;
  @Input() setTabFlag: string | undefined;
  @Input() StoryId: string | undefined;
  @Input() pageNumberByDrips: string | undefined;
  @Input() garmentId: string | undefined;
  @Input() panel: string | undefined;
  @Input() Drape: string | undefined;
  @Input() index: any | undefined;
  @Input() pageiIndex: any | undefined;
  @Input() compressUrl: any | undefined;
  isDropdownVisible: boolean = false;

  @Input() isShowStats: boolean | true;



  

  
  








  @Input() portFolioFlag: boolean | undefined;
  @Input() searchFilterData: any = [];
  
  @Output() sendBrandData = new EventEmitter();
  @Output() receveidPanelEven: EventEmitter<any> = new EventEmitter();
  @Output() receveidPanelUser: EventEmitter<any> = new EventEmitter();
  @Output() receveidPanelGarment: EventEmitter<any> = new EventEmitter();
  @Output() receveidSearchData: EventEmitter<any> = new EventEmitter();
  @Output() opentheSearchPanel: EventEmitter<any> = new EventEmitter();


  

  @Input() selectedSnapData: boolean | undefined;
  


  private baseUrl = 'https://us-central1-yolouploader.cloudfunctions.net';

  @ViewChildren('sliderImg2Upper') sliderImages: QueryList<ElementRef>;
  @ViewChild('storyContainer') storyContainer: ElementRef;
  @ViewChild('womenswearScroller') womenswearScroller: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @Output() receiveAvatarPanelDetails: EventEmitter<any> = new EventEmitter();
  @Output() receiveAvatarbrandPanel: EventEmitter<any> = new EventEmitter();


  //ForFemale Container

  @ViewChild('scrollContainerWUpper') scrollContainerWUpper: ElementRef;
  @ViewChild('scrollContainerWBottom') scrollContainerWBottom: ElementRef;
  @ViewChild('scrollContainerWHeadGear') scrollContainerWHeadGear: ElementRef;
  @ViewChild('scrollContainerWFootwear') scrollContainerWFootwear: ElementRef;


  @ViewChild('scrollContainerMUpper') scrollContainerMUpper: ElementRef;
  @ViewChild('scrollContainerMBottom') scrollContainerMBottom: ElementRef;
  @ViewChild('scrollContainerMHeadGear') scrollContainerMHeadGear: ElementRef;
  @ViewChild('scrollContainerMFootwear') scrollContainerMFootwear: ElementRef;


  @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;
  @ViewChild('stylesnapShotFeeds', { read: ElementRef }) public stylesnapShotFeeds: ElementRef<any>;
  @ViewChild('scrollTarget') scrollTarget: ElementRef;
  @ViewChild('setclothBand', { static: false }) setclothBand: ElementRef;

  @Output() selectedBrandID = new EventEmitter<string>();
  @Output() changeSelectedTab = new EventEmitter<string>();
  @Output() changeSelctedCloth = new EventEmitter<string>();
  @Output() changeSelctedState = new EventEmitter<string>();
  @Output() changeCloseBanner = new EventEmitter<any>();
  @Output() outnewStoryIndeX = new EventEmitter<number>();

  // @HostListener('window:scroll', ['$event'])



  brandSpacesList = this.firestore.collection('BrandSpaces').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userBrandAvatar = this.firestore.collection('BrandAvatar').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  defaultGarmentCollection = this.firestore.collection('DefaultBrandGarments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  private isBrandIdLoaded: boolean = false;
  isFirstTabChange = true;
  avatarCollapseFlag: boolean = false
  profileName: any
  debounceTime: number = 300; // 300 milliseconds
  private activeDownloads: Set<string> = new Set();
  selectedImageIndex: number = 0;
  currentlySelected;
  private downloadDebounceTimer: any = null;
  color = 'primary';
  newFlag: boolean
  value = 50;
  autoscrollFlag = false;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  garmentScrollFlag = false;
  offsetPrevious = 0;
  scrolling = 'down';
  scrollingWidth = 50;
  indexMapping = 0;
  scrollPixel = 0;
  snapshotCollection: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  brandsCollection: any = [];
  lastDownloadTime = {};
  storyPinterestPinsList: any = [];
  subscription: Subscription | undefined;
  scrollselected: any = [];
  identifierList: any = {};
  clothDetails: any = {};
  selectedBrand: any = {};
  brandDetails: any;
  brands: any = [];
  boardId: any;
  boards: any;
  pinImg: string = "";
  SnapShotFileDetails: any = []
  userSnapShotListGarmentShotFileDetails: any = []
  userSnapShotListGarmentShotFileDetailsDetalis: any = []
  isLoading = false;
  topScroll: number;
  pageScrollFlag: boolean = false;
  showMore: boolean = false;
  isloadingPanel: boolean = false;

  scrollFlag: boolean = false;
  SnapShotDetails: any = []
  clocthCollectionIdcollection: any = []
  clothShapShotsList: any = []
  profileDetails: any = {}
  userSnapShotListGarmentShotForProfile: any = [];
  userSnapShotListGarmentShotForAvatar: any = [];
  //For Style
  binaryImg: any;
  zipFile: any;
  testVar: any = '-';
  selectedBrandId: any = "";
  errMessage: any = "";
  errMessageForMen: any = "";
  errMessageForWomen: any = "";
  packJsonObject = []
  zipList: any = {};
  isloading: boolean = false
  isLoadingStyle: boolean = false
  garmetBinaryImgDetails: any
  garmentTypeDetails: any
  imageObject: any
  evnFileListFileName: any
  filterEnvironmentSnpShot: any = [];
  clothShapShotsListALL: any = [];
  next: number = 1
  isLoadingStory: boolean = true
  brandFlag: boolean = true
  keyType: any
  selectedStoryId: string;
  pack0 = "%2Fpack0";
  pack1 = "%2Fpack1";
  lastScrolledIndex: number = -1; // New property to track the last scrolled index
  isDownloadPack0Called: boolean = false;
  usedPackIndices = {};
  style: string = 'treads';
  previousGarmentLink: string = null;
  showOverlay: boolean = false;
  brandStoryScrollerHeight: number;
  sideNavHeight: number

  femalUpperwear: any = '';
  femalBottomWear: any = '';
  maleBottomwear: any = ''
  maleUpperwear: any = ''


  feMaleAvatar: any = ''
  maleAvatar: any = ''
  evnDefault: any = '';
  brandSpaces: any = [];
  filteredEvenfileList: any = [];


  arData: any;


  isMaleGenderChecked: boolean
  pindetails: any = [];

  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }

  setIndex: number
  imageList: any = this.pinImg;
  // brandId: any;
  StoryID: any;
  UpperwearId: any;
  pinId: any;
  typeSelected: any;
  boardsName: any;
  filterByUserFlag: boolean = false;
  userId: string;
  snpUrl: any
  storyId: string;
  brandCollectionIdcollection: any = [];
  brandhShapShotsList: any = []
  selectedAvtar: any
  userSnapShotList: any = []
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  userFileList: any = [];

  counter: number = 4;
  showImages: any = {}
  selectedGender: any;
  evnFileList: any = [];
  SnapShotFileEnv: any = [];
  id: any
  allUserDetails: any;
  thresholdHeightPagination: number = 0
  prevRequestObject: any;
  pageScroll: any;
  pagecount: number;
  heartFlag: boolean = true;
  genderFlag: boolean;
  brandObject: any;
  closeDiv: boolean = false;
  avtarUrl: any = '';
  functionFlag: any = '';
  genders: string = '';
  upperwear: any;
  bottomwear: any;
  garmentLink: string;
  headgear: any;
  footwear: any;
  newEntry = {
    AvatarID: "",
    BottomwearID: "",
    DateTime: { _seconds: 0, _nanoseconds: 0 },
    DisplayName: "",
    Environment: "",
    FootwearID: "",
    HeadwearID: "",
    SnapshotFileUrl: "",
    SnapshotFileUrlMicro: "",
    SnapshotFileUrlMini: "",
    StoryID: "",
    StoryType: "",
    UpperwearID: "",
    UserID: "",
    avatarName: "",
    home: "",
    id: ""
  };


  isFeMaleGenderChecked: boolean;
  genderSlected: string = '';
  loadingState: boolean;
  dialogRef: any;
  isLoadedLoading: boolean = true;
  setGarmetType: any;
  linkGarment: string;
  isSelectetedGarment: boolean;
  garmentH: any;
  garmentB: any;
  garmentF: any;
  garmentU: any;
  allSelectedUrl: any = [];
  errorMessage: any;
  zipListPack: any = {};
  packJsonObjectPack: any[];
  fileType: any;
  pack1Flag: boolean = false;
  SelectedIndex: any;
  cacheZipArray: any;
  intiatebrandPack: boolean = true;
  tempMessage: any = '';
  loadzipFlag: boolean = false;
  downloadFlag: boolean;
  processFlag: boolean;
  iconselctFlag: boolean = false;
  private previousScrollLeft = 0;
  side: boolean;
  lastPackIndex: number = 0;
  downloadFlagNewToStop: boolean;
  flagFalse: boolean = true;
  selectBrand: boolean = true;
  storySelection: boolean = false;
  heightStory: number = 350;
  previousScrollTop: any;
  callDownloadcheck: any;
  callDownloadcache: any;
  lastSelectedBrand: any;
  lastSelectedGender: any;
  settimeOutFun: number = 0;
  startTime: number;
  endTime: number;
  timeTaken: number;
  sceneintilizedDone: boolean = false;
  firstCallFlag: boolean = true;
  genderCode: string = '';
  typenumber: number;
  sliderWU: any;
  isScrollingStart: any;
  sliderMU: any;
  uniqueKey: any;
  scrollInitialized: any;
  urlSaved: string;
  flipFlag: boolean = false;
  garmentImgage: any;
  garmentClothType: any;
  garmentClothId: any;
  typeWear: any;
  selectedIcon: any;
  userFiledetails3d: any;
  threadsPageFlag: boolean;
  collectionDetails: any = [];
  collectionData: any;
  dummyText: string;
  userSnapShotListGarment: any = [];
  previousBrandId: any;
  previousTab: any;
  startDownload: boolean = true;
  PositionSide: string;
  swichImageFlage: boolean = true;
  storyImageFlag: boolean = false
  images = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg" },
    { "image": "./assets/garment-upload-dummy/img-2.jpg" },
    { "image": "./assets/garment-upload-dummy/img-3.jpg" },
    { "image": "./assets/garment-upload-dummy/img-4.jpg" },
    { "image": "./assets/garment-upload-dummy/img-5.jpg" },
    { "image": "./assets/garment-upload-dummy/img-6.jpg" },
    { "image": "./assets/garment-upload-dummy/img-7.jpg" },
    { "image": "./assets/garment-upload-dummy/img-8.jpg" },
    { "image": "./assets/garment-upload-dummy/img-9.jpg" },
  ]

  fatnessValue: number = 0; // Set the default value to 0
  receivedData: any = {

  };

  showGrmentStoryCard: boolean = false;
  queryHeadgear: any = "";
  queryUpperwear: any = "";
  queryBottomwear: any = "";
  queryFootwear: any = "";
  garmentLinks_F: any = {};
  garmentLinks_M: any = {};

  garmentQF: any = "";
  garmentQB: any = "";
  garmentQU: any = "";
  garmentQH: any = "";
  callUrlFlag: boolean = true;
  newPageNo: string;
  funcValue: any;

  uFilesString: any = [];
  newZipLists: any = {};
  hFilesFilesString: any = [];
  bFilesFilesString: any = [];
  fFilesFilesString: any = [];
  userBrandAvatarList: any = [];
  defaultAvatar_M: any = {};
  defaultAvatar_F: any = {};
  defaultMaleAvatars: any = [];
  defaultFemaleAvatars: any = [];
  functionflagValue: string = "";
  defaultGarment: any = [];
  newTest_Memory: any[];
  defaultSelectedAvatar: any;
  defaultSelectedAvatarMale: any;
  defaultMaleAvatarsList: any = [];
  defaultFemaleAvatarsList: any = [];
  showSlider: boolean = false;
  oldFunctionCalled: boolean = false;
  executionTime: number;
  selectTempBrand: any;
  defaultSelectedAvatarFeMale: any;
  setBrandAvatarDetails_M: any = {};
  setBrandAvatarDetails_F: any = {};

  onbrandchangeflag: boolean = false;
  private initialized: boolean = false;
  loadTime: number | null = null;
  mainMenuFlag: boolean = true;
  dripsPageAvatarData: any;
  tempCthoth_H: any = '';
  tempCthoth_U: any = '';
  tempCthoth_B: any = '';
  tempCthoth_F: any = '';
  showBrandStoryCard: boolean = false;
  avatarData: any;
  blob: any;
  isCheckedFocus: boolean = false;
  isChecked: boolean = false;
  dripsPageFlage: boolean = false;
  branUrl: null;
  newBrandObject: any;
  source: any;
  garmentData: any = [];
  isloadingBrandPanel: boolean;
  Drap: string;


  nextPage: boolean = false;
  itemsPerPage: number = 12;

  callpageIndexFlag: boolean = true;
  callpageNumer: any;
  callpageIndexFlagNextButton: boolean = true;
  snapInitCall: boolean;
  loadedPages = {};  // Object to track loaded pages
  loadedPagesBackWord = {};
  visbleIndex: any;
  selectedevenUrl: any;
  spaceName: any = '';
  isLoadEnv: boolean;
  showstatsFlag: any;
  newWidth: string;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private dataSharingService: DataSharingService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    public router: Router,
    private storage: AngularFireStorage,
    private commonService: CommonService,
    public sanitizer: DomSanitizer,
    private _httpClient: HttpClient,
    private modal: NgbModal,
    private renderer: Renderer2,
  ) {
    this.subscribeToUserBrandAvatar();
    console.log("portFolioFlag===>", this.portFolioFlag)
    this.isDownloadPack0Called = false
    let title = 'Yologram - threads'
    this.commonService.changeTitle(title);
    this.typeSelected = 'ball-fussion';

    const startTime = new Date().getTime();
    // this.brandBoardList.subscribe(
    //   brandboard => {
    //     this.brandboardCollection = brandboard;
    //     const endTime = new Date().getTime();
    //     const executionTime = endTime - startTime;
    //     console.log(`subscribe piniterst board data execution time: ${executionTime} ms`);
    //   });


    // this.dataSharingService.userIdObservable.subscribe(snapshot => {
    //   this.id = snapshot
    // })

    // this.garmentsList.subscribe(
    //   garments => {
    //     this.garmentsCollection = garments;
    //   });

    // this.brandsFilesList.subscribe((brands) => {
    //   this.brandsCollection = brands;
    // });

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });

    // this.pinterestBoardPinsStatus.subscribe(
    //   response => {
    //     this.storyPinterestPinsList = response
    //   });

    // this.userFilesList.subscribe((userFile) => {
    //   this.userUploadedFileList = userFile
    // })

    // this.userGarmentCollectionItems.subscribe((collectiondata) => {
    //   this.collectionDetails = collectiondata
    // })

    // this.defaultGarmentCollection.subscribe((clothList) => {
    //   this.defaultGarment = clothList
    // })

    // this.userGarmentCollection.subscribe((collectiondata) => {
    //   this.collectionData = collectiondata
    // })

    // this.userBrandAvatar.subscribe((avatarData) => {
    //   this.userBrandAvatarList = avatarData
    //   console.log("this.userBrandAvatarList",this.userBrandAvatarList)
    // })

    this.brandSpacesList.subscribe((brandSpaces) => {
      this.brandSpaces = brandSpaces
      console.log("this.userBrandAvatarList", this.userBrandAvatarList)
    })


    const text = "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";

    const lines = text.split('\n\n');
    this.dummyText = lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines

  }

  async ngOnInit(): Promise<void> {
    this.mainMenuFlag = true

    if (this.garmentId != undefined && this.receivedData.typeWear != undefined) {
      this.garmentSnpshot(this.garmentId, this.receivedData.typeWear)
    }

    await this.subscribeToFirebaseCollections();

    this.onbrandchangeflag = true;
    this.isLoadingStyle = false;
    this.getAllUsers();
    this.openNav()
    if (this.selectBrandGender == 'M') {
      this.isMaleGenderChecked = true;
    } else if (this.selectBrandGender == 'F') {
      this.isFeMaleGenderChecked = true;
    }
    this.checkFor3D();
    console.log("selectedBrandId", this.selectedBrandId);
    console.log("BrandId");
    this.snapInIt();
    this.initialized = true;

  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if(changes['selectedSnapData'] && changes['selectedSnapData'].currentValue !== changes['selectedSnapData'].previousValue) {
      console.log("clearTab", this.selectedSnapData)
    }  

    if(changes['StoryId'] && changes['StoryId'].currentValue !== changes['StoryId'].previousValue) {
      this.selectedStoryId = this.StoryId
      console.log("this.selectedStoryId", this.selectedStoryId)
    }  

    if (changes['searchFilterData'] && changes['searchFilterData'].currentValue !== changes['searchFilterData'].previousValue) {
      console.log("this.searchFilterData", this.searchFilterData)
      if (this.tab == 'womens-wear' || this.tab == 'mens-wear') {
        this.userSnapShotList = this.searchFilterData.snapshot;
        this.selectedSnapData =  false
      }
    }  
    

    
    
    
    if (!this.initialized) {
      return; // Skip ngOnChangess until ngOnInit completes
    }

    this.setBrandStoryScrollerHeight();
    window.addEventListener('resize', () => {
      this.setBrandStoryScrollerHeight();
    });
    this.thresholdHeightPagination = 0;
    this.isLoadingStyle = false;
    this.isLoadingStory = true;
    this.displayProgressSpinner = true;
    let brandChanged = false;
    let tabChanged = false;



    if (changes['isShowStats'] && changes['isShowStats'].currentValue !== changes['isShowStats'].previousValue) {
      console.log("isShowStats",this.isShowStats)
      this.showstatsFlag = this.isShowStats
    }
    
    if (changes['compressUrl'] && changes['compressUrl'].currentValue !== changes['compressUrl'].previousValue) {
      this.callEvnUrl(this.compressUrl)
    }

        


    

    if (changes['brandId'] && changes['brandId'].currentValue !== changes['brandId'].previousValue) {
      brandChanged = true;
      console.log(this.brandId)
      this.getListOfselectedBrandSpaces()
      console.log("this.garmentId", this.garmentId)
      if (!this.isBrandIdLoaded) {
        this.isBrandIdLoaded = true;
        let currentPage = 1
        let reqInputObject = { id: this.brandId, pageNumber: currentPage };
        this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
          if (response && response.count) {
            this.pagecount = response.count;
            this.nextByBrandCall('count');
            console.log("first  pagecount", this.pagecount)
          } else {
            console.error('Invalid response structure', response);
          }

        })
      }

      if (this.garmentId == undefined || this.garmentId == null) {
        this.onChangeBrand();
      } else {
        setTimeout(async () => {
          this.onChangeBrand();
        }, 1000);
      }
    }

    if (changes['tab'] && changes['tab'].currentValue !== changes['tab'].previousValue) {
      tabChanged = true;
      console.log("selectedBrandId", this.selectedBrandId);
      if (this.tab !== 'mens-wear' || this.tab !== 'womens-wear') {
        this.selectBrandStoryCardTab()
      }
      if(this.tab == 'story'){
        this.flipClose()
      } 
       this.firstCallFlag = false;
      if (this.callUrlFlag) {
        this.getClothLink();
        this.callUrlFlag = false;
      }

    }


    if (brandChanged || tabChanged) {
      this.getPinterestBoards();
    }


    if (!(this.prevRequestObject && this.prevRequestObject.id && this.prevRequestObject.id == this.brandId && this.prevRequestObject.pageNumber == 1)) {
      this.pageNumber = 1;
      this.snapInIt();
    }

    this.checkFor3D();
    if (this.sceneintilizedDone && this.setTabFlag && !this.firstCallFlag) {
      // this.setTab(this.tab, this.genderCode);
      // alert('set tab' + this.sceneintilizedDone && this.setTabFlag && !this.firstCallFlag)
    }

    if (changes['pageNumberByDrips'] && (changes['pageNumberByDrips'].currentValue !== changes['pageNumberByDrips'].previousValue || changes['pageNumberByDrips'].currentValue === null)) {
      setTimeout(() => {
        this.selectedImageIndex = this.commonService.getIndex();
        this.callIncrementByPageNumber(this.pageNumberByDrips);
      }, 1200);
    }
    this.callincrementGarmentByPageNo(this.pageNumberByDrips);
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.commonService.setLoadTime();
      this.loadTime = this.commonService.getLoadTime();
      console.log(`Page Load Time: ${this.loadTime} ms`);
    });

  }

  ngOnDestroy() {
    this.onChanges.complete();
  }



  callincrementGarmentByPageNo(pageNumberByDrips) {
    if (this.pageNumberByDrips == null) {
      return
    }
    if (this.pageNumberByDrips == this.newPageNo) {
      return
    }
    this.newPageNo = this.pageNumberByDrips
    for (let i = 0; i < pageNumberByDrips; i++) {
      this.incrementImgGarmentCard();

    } setTimeout(() => {
      this.setindexOfStory(this.selectedImageIndex);
      if (this.StoryId) {
        this.selectGarmentImage(this.selectedImageIndex, this.StoryId);
      }
    }, 1200)
  }

  callIncrementByPageNumber(pageNumberByDrips) {
    // if (this.pageNumberByDrips == null) {
    //   return
    // }
    // for (let i = 0; i < pageNumberByDrips; i++) {
    //   this.incrementImgCounter();
    // } setTimeout(() => {
    //   this.setindexOfStory(this.selectedImageIndex);
    //   if (this.StoryId) {
    //     this.selectImage(this.selectedImageIndex, this.StoryId);
    //   }
    // }, 1200)
  }

  subscribeToUserFilesList(): void {
    const startTime = new Date().getTime();
    this.userFilesList.pipe(
      take(1) // take only the first emission
    ).subscribe(userFile => {
      this.userUploadedFileList = userFile;
      const endTime = new Date().getTime();
      this.executionTime = endTime - startTime;
      console.log(`subscribeToUserFilesList execution time: ${this.executionTime} ms`);
    });
  }

  // async getUserFiles(): Promise<any[]> {
  //   const url = `${this.baseUrl}/getUserFiles`;
  //   return await this._httpClient.get<any[]>(url).toPromise();
  // }

  // async subscribeToUserFilesList(): Promise<void> {
  //   const startTime = performance.now();
  //   try {
  //     const data = await this.getUserFiles();
  //     console.log("getUserFiles", data);
  //       this.userUploadedFileList = data;
  //   } catch (error) {
  //     console.error("Error fetching user files", error);
  //   } finally {
  //     const endTime = performance.now();
  //     console.log(`loadUserFiles execution time: ${(endTime - startTime).toFixed(2)} ms`);
  //   }
  // }

  subscribeToUserGarmentCollectionItems(): void {
    const startTime = new Date().getTime();
    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToUserGarmentCollectionItems execution time: ${executionTime} ms`);
    });
  }

  subscribeToDefaultGarmentCollection(): void {
    const startTime = new Date().getTime();
    this.defaultGarmentCollection.subscribe((clothList) => {
      this.defaultGarment = clothList;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToDefaultGarmentCollection execution time: ${executionTime} ms`);
    });
  }

  subscribeToUserGarmentCollection(): void {
    const startTime = new Date().getTime();
    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToUserGarmentCollection execution time: ${executionTime} ms`);
    });
  }

  subscribeToUserBrandAvatar(): void {
    const startTime = new Date().getTime();
    this.userBrandAvatar.subscribe((avatarData) => {
      this.userBrandAvatarList = avatarData;
      //  this.onChangeBrand()
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToUserBrandAvatar execution time: ${executionTime} ms`);
    });
  }

  subscribeToBrandBoardList(): void {
    const startTime = new Date().getTime();
    this.brandBoardList.subscribe(brandboard => {
      this.brandboardCollection = brandboard;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToBrandBoardList execution time: ${executionTime} ms`);
    });
  }

  subscribeToUserIdObservable(): void {
    const startTime = new Date().getTime();
    this.dataSharingService.userIdObservable.subscribe(snapshot => {
      this.id = snapshot;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToUserIdObservable execution time: ${executionTime} ms`);
    });
  }

  subscribeToGarmentsList(): void {
    const startTime = new Date().getTime();
    this.garmentsList.subscribe(garments => {
      this.garmentsCollection = garments;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToGarmentsList execution time: ${executionTime} ms`);
    });
  }

  subscribeToBrandsFilesList(): void {
    const startTime = new Date().getTime();
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
      const endTime = new Date().getTime();
      const executionTime = endTime - startTime;
      console.log(`subscribeToBrandsFilesList execution time: ${executionTime} ms`);
    });
  }





  async subscribeToFirebaseCollections() {
    // this.subscribeToUserFilesList();

    // await this.subscribeToUserBrandAvatar();
    await this.subscribeToUserGarmentCollectionItems();
    await this.subscribeToDefaultGarmentCollection();
    await this.subscribeToUserGarmentCollection();
    await this.subscribeToBrandBoardList();
    await this.subscribeToUserIdObservable();
    await this.subscribeToGarmentsList();
    await this.subscribeToBrandsFilesList();
  }

  functionloading() {

  }

  determineGenderCode(tabValue: string): string {
    switch (tabValue) {
      case 'womens-wear': return 'F';
      case 'mens-wear': return 'M';
      default: return 'Unknown';
    }
  }

  snapInIt() {
    this.pageNumber = 1
    if (this.panel && this.brandId != undefined) {
      let reqInputObject = { id: this.brandId, pageNumber: this.pageNumber }
      this.prevRequestObject = reqInputObject

      this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
        this.pagecount = response.count
        console.log("this.brandId", this.brandId)
        console.log(this.index, "this.index")
        console.log(response, "tresponse")
        if (this.index) {
          // this.nextByBrandCall('count')
          // this.selectedStoryId = this.storyId
          return
        }
        this.userSnapShotList = response.object;
        console.log("userSnapShotList", this.userSnapShotList)
        this.pagecount = response.count
      })
    }



    //  let findSnap = this.userSnapShotList[0]
    // this.selectImage(,findSnap)
  }



  clickOnHeart() {
    this.heartFlag = true
  }



  scrollToPosition() {
    if ((this.selectedImageIndex % 12 === 0 || this.userSnapShotList.length >= 12) && this.selectedImageIndex !== this.lastScrolledIndex) {
      this.storyContainer.nativeElement.scrollTop = this.heightStory;
      this.lastScrolledIndex = this.selectedImageIndex; // Update the last scrolled index
      this.heightStory = +  this.storyContainer.nativeElement.scrollTop - 255
    } else {
    }
  }


  onSelectImageIndexChange(newIndex: number) {
    let index = 12
    this.selectedImageIndex = newIndex;
    if (newIndex % 12 === 0 || newIndex >= index) {
      this.scrollToPosition();
      index + 12
    }
  }

  checkFor3D() {
    if (this.selectBrandGender == 'F') {
      this.avtarUrl = this.feMaleAvatar;
    } else if (this.selectBrandGender == 'M') {
      this.avtarUrl = this.maleAvatar;
    }
  }

  // setTab(tab, gender) {
  //   this.previousScrollLeft = null
  //   this.activeDownloads.clear();
  //   this.flipFlag = false
  //   this.selectBrandGender = gender
  //   this.tab = tab;
  //   if (this.lastSelectedBrand === this.brandId && this.lastSelectedGender === gender) {
  //     if (this.tab === 'mens-wear' || this.tab === 'womens-wear' || this.tab === 'story') {
  //       this.changeSelectedTab.emit(this.tab);
  //       this.genderFlag = this.tab === 'mens-wear';
  //       if (this.tab === 'mens-wear' || this.tab === 'womens-wear') {
  //         this.downloadFlag = true;
  //         this.genderCode = this.tab === 'womens-wear' ? 'F' : this.tab === 'mens-wear' ? 'M' : 'Unknown';
  //         this.functionFlag = this.genderCode === 'M' ? 'ShowMale' : 'ShowFemale';
  //         this.checkFor3D();
  //         this.showBrandPackDetails()
  //       }
  //     }
  //     return;
  //   }
  //   if (this.tab === 'mens-wear' || this.tab === 'womens-wear') {
  //     this.changeSelectedTab.emit(this.tab);
  //     this.genderFlag = this.tab === 'mens-wear';
  //     this.downloadFlag = true;
  //     this.genderCode = this.tab === 'womens-wear' ? 'F' : this.tab === 'mens-wear' ? 'M' : 'Unknown';
  //     this.functionFlag = this.genderCode === 'M' ? 'ShowMale' : 'ShowFemale';
  //     this.checkFor3D();
  //     this.showBrandPackDetails()
  //   } else if (this.tab === 'story') {
  //     this.changeSelectedTab.emit(this.tab);
  //   }

  //   this.lastSelectedBrand = this.brandId;
  //   this.lastSelectedGender = gender;

  // }



  async setTab(tab, gender) {
    this.closeSearched()

    if (tab == 'story') {
      this.tab = tab;
      this.loadBrandPanelData(this.brandId)
      this.flipClose()
    }
    this.previousScrollLeft = null;
    this.activeDownloads.clear();
    this.flipFlag = false;
    this.selectBrandGender = gender;
    // this.showBrandStoryCard = false
    this.tab = tab;

    if (this.lastSelectedBrand === this.brandId && this.lastSelectedGender === gender) {
      if (this.tab === 'mens-wear' || this.tab === 'womens-wear' || this.tab === 'story') {
        this.changeSelectedTab.emit(this.tab);
        this.genderFlag = this.tab === 'mens-wear';
        if (this.tab === 'mens-wear' || this.tab === 'womens-wear') {
          this.downloadFlag = true;
          this.genderCode = this.tab === 'womens-wear' ? 'F' : this.tab === 'mens-wear' ? 'M' : 'Unknown';
          this.showAvatar(this.genderCode)
          // this.functionFlag = this.genderCode === 'M' ? 'ShowMale' : 'ShowFemale';
          this.checkFor3D();
          this.showBrandPackDetails();
        }
      }
      return;
    }
    if (this.tab === 'mens-wear' || this.tab === 'womens-wear') {
      this.changeSelectedTab.emit(this.tab);
      this.genderFlag = this.tab === 'mens-wear';
      this.downloadFlag = true;
      this.genderCode = this.tab === 'womens-wear' ? 'F' : this.tab === 'mens-wear' ? 'M' : 'Unknown';
      this.showAvatar(this.genderCode)
      // this.functionFlag = this.genderCode === 'M' ? 'ShowMale' : 'ShowFemale';
      this.checkFor3D();
      this.showBrandPackDetails();
      // Check if this is the first time tab change
    } else if (this.tab === 'story') {
      this.changeSelectedTab.emit(this.tab);
    }

    this.lastSelectedBrand = this.brandId;
    this.lastSelectedGender = gender;
    this.showBrandStoryCard = null
    this.showGrmentStoryCard = null
    if (this.tab === 'mens-wear' || this.tab === 'womens-wear') {
      this.showBrandStoryCard = false
    }

    if (this.tab === 'story') {
      this.showGrmentStoryCard = false
    }

    if (this.tab !== 'mens-wear' || this.tab !== 'womens-wear') {
      this.selectBrandStoryCardTab()
    }

  }


  getAllUsers() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    })
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }
  setSelectedUrl(urlValue) {
    this.allSelectedUrl = urlValue
  }
  setEroorMessage(message) {
    this.errorMessage = message
  }
  setPageloading(pageload) {
    this.loadingState = pageload
    this.changeSelctedState.emit(pageload);
    if (!this.loadingState) {
      this.close2DModelPopUp()
    }
  }
  handleError(error: any) {
    console.log(error)
  }

  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.SnapShotDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      this.clothShapShotsListALL = this.SnapShotDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
    }
  }


  // setBrandDetails(garmentId: string) {
  //   if (this.garmentsCollection.length > 0) {
  //     let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
  //     if (grandmentObject && grandmentObject['Name'] != undefined) {
  //       let brandId = grandmentObject["BrandID"];
  //       this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
  //       this.brandDetails['boardName'] = 'Not Link to Board';
  //       let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
  //       if (brandBoardObject != undefined) {
  //         let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
  //         let boardDetails = this.boards.find((b: any) => b.id == boardId);
  //         if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
  //       }
  //     }
  //     let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
  //     this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
  //     this.brandhShapShotsList = this.SnapShotDetails.filter((snapshot) => {
  //       return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
  //     }).slice(0, 9);
  //   }
  // }


  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  openNav() {
    const sidebarWidth = window.innerWidth > 768 ? "249px" : "181px"; // 768px is a common breakpoint for desktop sizes
    const sidebar = document.getElementById("mySidenav");
    if (sidebar) {
      sidebar.style.width = sidebarWidth;
    }
    this.closeDiv = true;
    this.newWidth ='0px'
    this.showOverlay = true

  }

  closeNav() {
    // document.getElementById("mySidenav").style.width = "20px";
    this.newWidth =  document.getElementById("mySidenav").style.width = "50px";
    this.closeDiv = false
    this.showBrandFlag = false
    this.byScroll = false
    this.showOverlay = false;
    this.swichImageFlage = true
    let flag = false
    this.opentheSearchPanel.emit(flag)
  }


showTempSearchPanel(){
  let flag = true
  this.opentheSearchPanel.emit(flag)
}

  getPinterestBoards() {
    // this.pinterestAPI.getBoardList().subscribe((data: {
    //   name: any; items: any;
    // }) => {
    //   this.boards = data.items
    // });
  }

  selectImage(index: number, StoryID) {
    this.selectedImageIndex = index;
    this.selectedStoryId = StoryID
    this.nextPage = true;
    console.log("this.selectedStoryId", this.selectedStoryId)
    setTimeout(() => {
      this.showBrandStoryCard = true
      this.showGrmentStoryCard = false
      this.selectedStoryId = StoryID
    }, 1000);
    this.nextPage = false;

  }

  selectGarmentImage(index: number, StoryID) {
    this.selectedImageIndex = index;
    this.showGrmentStoryCard = true
    setTimeout(() => {

      this.selectedStoryId = StoryID

    }, 1000);
    // this.scrollToStoryId(snapshot);
  }
  closecard() {
    this.selectedImageIndex = -1;
    this.showGrmentStoryCard = false
  }

  closeBrandcard() {
    this.selectedImageIndex = -1;
    this.showBrandStoryCard = false
  }
  setSelectedGarmetCloth(event) {
    if (event == 'Cloth') {
      this.setCloth()
    }
  }
  autoScroll() {
    const scrollableDiv = document.getElementById('scrollableDiv')
    if (scrollableDiv) {
      scrollableDiv.scrollTop = 10;
      this.garmentScrollFlag = false
    }
  }
  scrollToElement() {
    const element = this.storyContainer.nativeElement;
    const yOffset = element.scrollHeight;
    this.renderer.setProperty(document.documentElement, 'scrollTop', yOffset);
  }
  setindexOfStory(index) {
    console.log("index=====>", index)
    if (index) {
      this.selectedImageIndex = index;
      this.onSelectImageIndexChange(this.selectedImageIndex);
      setTimeout(() => {
        const storyContainerElement = document.getElementById('mySidenav'); // Update the ID here if needed
        const selectedImageElements = document.querySelectorAll('.columnImg');
        if (storyContainerElement && selectedImageElements) {
          const containerHeight = storyContainerElement.clientHeight;
          const selectedImageElement = selectedImageElements[index] as HTMLElement;
          if (selectedImageElement) {
            const scrollTo = selectedImageElement.offsetTop;
            storyContainerElement.scrollTo({
              top: scrollTo,
              behavior: 'smooth'
            });

          } else {
            console.log("Selected image element not found.");
          }
        } else {
          console.log("Story container or selected image elements not found.");
        }
      }, 1500);
    }
  }
  scrollToStoryId(snapshot) {
    this.storySelection = true
    this.selectedStoryId = snapshot.StoryID
    // this.tab = "news-stories"
    // this.changeSelectedTab.emit('news-stories');
  }
  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }
  snapDetails() {
    this.brandCollectionIdcollection = this.brandGarments;
    let SnapshotWithbrandGarment = this.SnapShotDetails.filter((snapshot) => {
      if (this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0) {
      }
    })
  }
  handelerror(error) {
    console.log('error', error)
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = 'Pack0 is not found for  this selection !!!' + error.message
    this.isLoading = false;
    this.isLoadingStyle = false;
  }
  async downloadPack1(garment, i, type) {
    let genders = this.tab == 'mens-wear' ? 'M' : 'W';
    console.log('this.activeDownloads', this.activeDownloads.size)
    console.log('...startDownload', this.startDownload)
    let pack = "%2Fpack";
    let brandId = this.selectedBrandId;
    let garmentType = '%2F' + garment;
    let zipfilename = garmentType + pack + i;
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let newZipName = '/' + garment + '/pack' + i;
    let newPackLoction = "AvatarBlenderClothes/" + brandId + "_" + gender + newZipName;
    let brandEncodedurl = packlocation;
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    this.tempMessage = '';
    console.log("Next Pack Url", url)
    const startTime = performance.now();
    let uniqueKey = packlocation;

    if (this.activeDownloads.has(uniqueKey)) {
      this.startDownload = false

      return;
    }
    this.activeDownloads.add(uniqueKey);
    this.startDownload = true

    try {
      let nextPackData = await this.downloadprocessNextPackZip(newPackLoction)
      this.extractNextFileData(nextPackData)

    } catch (error) {
      this.handleErrors(error);
    } finally {
      const endTime = performance.now();
      const responseTime = endTime - startTime;
      console.log(`Download time for ${url}: ${responseTime}ms`);
    }
    this.setupInfiniteScroll(type)
  }

  async downloadprocessNextPackZip(url: string) {
    try {
      let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadNextPackZip";
      const response = await this._httpClient.get<any>(functionUrl, { params: { packlocation: url } }).toPromise();
      console.log('next Zip response:', response);
      return response;
    } catch (error) {
      console.error('Error processing cloth zip:', error);
      throw error;
    }
  }
  async downloadprocessPackZip(url: string) {
    try {
      let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadPack0";
      const response = await this._httpClient.get<any>(functionUrl, { params: { packlocation: url } }).toPromise();
      console.log('Zip response:', response);
      return response;
    } catch (error) {
      console.error('Error processing cloth zip:', error);
      throw error;
    }
  }
  async downloadPack0() {
    console.log('pack0 loading intilze...')
    this.isloading = true;
    this.isLoadingStyle = true;
    this.testVar = 'Change';
    let brandId = this.selectedBrandId;
    let zipfilename = this.pack0;  // Assuming this is the zip file name
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let packlocationTemp = "AvatarBlenderClothes/" + brandId + "_" + gender + '/pack0';
    console.log("packlocation", packlocation)
    let brandEncodedurl = packlocation;
    this.callDownloadcheck = brandId + gender
    let uniqueKey = packlocation;
    if (this.activeDownloads.has(uniqueKey)) {
      return;
    }
    this.activeDownloads.add(uniqueKey);
    console.log('this.activeDownloads.size', this.activeDownloads.size)
    this.callDownloadcache = this.callDownloadcheck
    if (!brandId || !gender || !zipfilename) {
      this.isloading = false;
      this.isLoadingStyle = false;
      this.downloadFlag = false;  // Reset the flag
      return;
    }
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    try {
      // const res = await this._httpClient.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
      // await this.processZipFile(res.body, this.processResponse.bind(this));
      let newPacketData = await this.downloadprocessPackZip(packlocationTemp)
      this.extractFileData(newPacketData)

    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.isloading = false;
      this.isLoadingStyle = false;
      this.downloadFlag = false;  // Reset the flag after download is complete or fails
    }
    console.log('pack0 loaded........')
  }
  handleErrors(error) {
    console.log('error', error)
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack0 is not found for this selection !!!"
    this.isLoading = false;
    this.isLoadingStyle = false;
  }

  showBrandPackDetails() {
    const newGender = this.tab == 'mens-wear' ? 'M' : this.tab == 'womens-wear' ? 'F' : "";
    this.zipList = {};
    this.selectedBrandId = this.brandId;
    this.selectedGender = newGender;
    this.errMessage = "";
    this.isloading = true;
    this.isLoadingStyle = true;
    this.downloadPack0();
  }

  flipClose() {
    this.flipFlag = false
    this.garmentScrollFlag = false
    this.swichImageFlage = true
    this.userFileList = []
    this.userFiledetails3d = []
    this.userSnapShotListGarment = []
    this.garmentData = []
    this.clothDetails = {}
  }
  DeatailsPopup(garmetBinaryImg, garmentType: any, object, garmentTypeForPopup, brandflag) {
    console.log("garmetBinaryImg", garmetBinaryImg)
    this.garmetBinaryImgDetails = garmetBinaryImg
    this.garmentTypeDetails = garmentType
    this.imageObject = object
    this.keyType = garmentTypeForPopup
    let typeWear = this.keyType
    let garmentId = this.imageObject
    let gBinaryImg = this.garmetBinaryImgDetails
    let gType = this.garmentTypeDetails
    this.changeSelctedCloth.emit(object);
    this.genders = this.tab === 'mens-wear' ? 'M' : 'F';
    let packlocation = "AvatarBlenderClothes%2F" + this.brandObject.id + "_" + this.genders + "%2F" + garmentType + "%2F" + object;
    this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    this.openNav()

    if (garmentType == 'headgear') {
      this.headgear = this.garmentLink
      this.garmentH = garmentType

    } else if (garmentType == 'upperwear') {
      this.upperwear = this.garmentLink
      this.garmentU = garmentType

    } else if (garmentType == 'bottomwear') {
      this.bottomwear = this.garmentLink
      this.garmentB = garmentType

    } else if (garmentType == 'footwear') {
      this.footwear = this.garmentLink
      this.garmentF = garmentType
    }

    if (!this.allSelectedUrl.includes(this.garmentLink)) {
      this.open2DModelPopUp(gBinaryImg, gType, garmentId, typeWear, brandflag);
    } else {
    }
  }

  infoPopup() {
    let infoObject = this.imageObject
    this.brandId = this.imageObject
    this.setClothDetails(infoObject)
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    this.openGarmentPopup(this.keyType, infoObject)
  }

  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }


  incrementImgCounter() {
    this.pageNumber++;
    let reqInputObject = { id: this.brandId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      // this.userSnapShotList.push(...response.object)
      // this.userSnapShotList.forEach(async (snap: any, index) => {
      //   let userObjet = await this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      //   snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      //   userObjet.profileName = snap.profileName;
      //   snap.userData = userObjet
      //   snap.firstName = snap.profileName.split(" ", 1);
      //   snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      // })
    })
    console.log("this.userSnapShotList.length", this.userSnapShotList)
  }


  incrementImgGarmentCard() {
    this.isLoadingStory = true
    this.pageNumber++;
    let reqInputObject = { fileType: this.typeWear, id: this.garmentClothId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotListGarment.push(...response.object)
      this.userSnapShotListGarment.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        // snap.pageNumber = reqInputObject.pageNumber
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })
  }

  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.stylesnapShotFeeds.nativeElement.scrollTop
  }
  threeDPopup() {
    let typeWear = this.keyType
    let garmentId = this.imageObject
    let gBinaryImg = this.garmetBinaryImgDetails
    let gType = this.garmentTypeDetails
    this.open2DModelPopUp(gBinaryImg, gType, garmentId, typeWear, +-this.brandFlag)
  }
  open2DModelPopUp(garmetBinaryImg, garmentType: any, garmentId, typeWear, brandFlag) {
    this.isLoadedLoading = true
    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    this.isLoading = false;
    this.dialogRef = this.dialog.open(PopupTwoDComponent, {
      panelClass: 'dailog-transparent-css-loading',
      disableClose: true // This will disable closing the dialog when clicking outside
    });
    this.dialogRef.componentInstance.garmentId = garmentId;
    this.dialogRef.componentInstance.typeWear = typeWear;
    this.dialogRef.componentInstance.garmentImg = garmentImg;
    this.dialogRef.componentInstance.garmentType = garmentType;
    this.dialogRef.componentInstance.routLogInFlag = this.routLogInFlag;
    this.dialogRef.componentInstance.pageType = "style";
    this.dialogRef.componentInstance.brandFlag = brandFlag;
    this.dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }
  close2DModelPopUp() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.isLoadedLoading = false
    }
  }
  handleContainerClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.classList.contains('yologram-btn-close')) {
      event.preventDefault();
      event.stopPropagation();

    }
  }
  closeButtonForBrand() {
    this.selectBrand = false
    this.byScroll = false
    this.showBrandFlag = false;
    console.log("this.selectedBrandID==>", this.selectedBrandId)
    this.changeCloseBanner.emit(this.byScroll);
  }
  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }

  async open3Dflag(garmetBinaryImg, garmentType: any, garmentId, typeWear) {
    await this.subscribeToUserFilesList()
    this.threadsPageFlag = true
    this.flipFlag = true

    // this.garmentSnpshot(garmentId, typeWear)
    console.log("garmentId==>", garmentId)
    this.garmentImgage = garmetBinaryImg
    this.garmentClothType = garmentType
    this.garmentClothId = garmentId
    this.typeWear = typeWear
    this.clothDetails = this.garmentsCollection.find((b: any) => b.id == garmentId);
    this.selectIcon('popup')
    this.loadGarmentData(garmentId)
    // let clothId = this.garmentClothId
    // let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload2D')
    // let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload3D')
    // this.userFileList = filelist2d
    // this.userFiledetails3d = filelist3d
    // console.log('this.userFileList', this.garmentClothType)
    // console.log('garmentImgage', this.garmentImgage)
    // console.log('userUploadedFileList', this.userUploadedFileList)
  }

  garmentSnpshot(id, typeWear) {
    this.pageNumber = 1;
    let reqInputObject = { fileType: typeWear, id: id, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      console.log()
      this.pagecount = response.totalCount
      console.log('this.pagecount', this.pagecount)
      if (this.index != undefined) {
        if (this.pagecount) {
          this.nextByCall('count')
        }
        return
      }

      this.userSnapShotListGarment = response.object
      this.userSnapShotListGarment.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })
  }
  filterCollectionName(id) {
    let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id)
    let getCollection = this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    let name = getCollection != undefined && getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    return name
  }
  setCloth() {
    let brandflag = true
    this.DeatailsPopup(this.garmentImgage, this.garmentClothType, this.garmentClothId, this.typeWear, brandflag)
  }


  getClothLink() {
    if (this.Drape == 'N') {
      return
    }
    if ((this.garmentId == undefined || this.garmentId == null)) {
      return
    }

    let clothDetails = this.garmentsCollection.find((b: any) => b.id == this.garmentId);
    let garmentType = clothDetails.GarmentType
    this.genders = this.tab === 'mens-wear' ? 'M' : 'F';
    let packlocation = "AvatarBlenderClothes%2F" + clothDetails.BrandID + "_" + this.genders + "%2F" + clothDetails.GarmentType + "%2F" + this.garmentId;
    let newGarmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    if (newGarmentLink == this.previousGarmentLink) {
      return
    }
    // Check if new link is different from the previous one
    if (newGarmentLink !== this.previousGarmentLink) {
      this.garmentLink = newGarmentLink;
      console.log("garmentLink", this.garmentLink);
      this.previousGarmentLink = newGarmentLink;
    } else {
      console.warn("Garment link is the same as before:", this.garmentLink);
    }

    if (garmentType == 'headgear') {
      this.queryHeadgear = this.garmentLink
      this.garmentQH = garmentType
      this.tempCthoth_H = this.garmentLink

    } else if (garmentType == 'upperwear') {
      this.queryUpperwear = this.garmentLink
      this.garmentQU = garmentType
      this.tempCthoth_U = this.garmentLink

    } else if (garmentType == 'bottomwear') {
      this.queryBottomwear = this.garmentLink
      this.garmentQB = garmentType
      this.tempCthoth_B = this.garmentLink

    } else if (garmentType == 'footwear') {
      this.queryFootwear = this.garmentLink
      this.garmentQF = garmentType
      this.tempCthoth_F = this.garmentLink
    }

  }



  async processZipFileCopy(data) {

    try {
      const zipContent = await JSZip.loadAsync(data);
      const imageUrls = await this.extractImagesFromZip(zipContent);
      this.updateZipList(imageUrls);
    } catch (error) {
      console.error('Error processing zip file:', error);
    }
  }
  async extractImagesFromZip(zip) {
    const imageFileRegex = /(.jpg|.png|.gif|.ps|.jpeg)$/;
    const imagePromises = Object.keys(zip.files)
      .filter(fileName => imageFileRegex.test(fileName.toLowerCase()))
      .map(fileName => {
        const file = zip.files[fileName];
        return file.async('blob').then(blob => [
          fileName.substring(0, fileName.lastIndexOf('.')),
          URL.createObjectURL(blob)
        ]);
      });

    const images = await Promise.all(imagePromises);
    return images.reduce((accumulator, [fileName, url]) => {
      accumulator[fileName] = url;
      return accumulator;
    }, {});
  }
  updateZipList(imageUrls) {
    if (!this.zipList) {
      this.zipList = {}; // Initialize as an object if not already initialized
    }
    // Assuming this.zipList is available in the scope
    Object.assign(this.zipList, imageUrls); // Assign imageUrls to zipList object
    for (let type = 0; type <= 3; type++) {
      this.flagFalse = true;
      this.setupInfiniteScroll(type);
      this.flagFalse = false;
    }
  }
  setSceneFlag(flag) {
    this.sceneintilizedDone = flag
    console.log('flag sceneintilizedDone ......................', flag)
    if (this.sceneintilizedDone) {
      // Clear the existing timeout to reset the debounce timer
      //  let tab = this.tab === 'womens-wear'  this.tab === 'mens-wear
      // let this.genderCode = this.tab === 'womens-wear' ? 'F' : this.tab === 'mens-wear' ? 'M' : 'Unknown';
      // this.setTab(this.tab, this.genderCode);
      // console.log('flag this.genderCode ......................', this.genderCode)
      // if (this.tab === 'womens-wear') {
      //   this.functionFlag = 'ShowFemale'
      // } else if (this.tab === 'mens-wear') {
      //   this.functionFlag = 'ShowMale'
      // }
    }
  }
  getImageDimensions(src, callback) {
    const img = new Image();
    img.onload = () => {
      callback({ width: img.width, height: img.height });
    };
    img.src = src;
  }

  scrollAndUnZipFileIntilize(containerType) {
    let garmentType;
    let typeNo;
    let scrollContainer: ElementRef;

    if (this.tab === 'womens-wear') {
      switch (containerType) {
        case 'headgear':
          scrollContainer = this.scrollContainerWHeadGear;
          garmentType = containerType;
          typeNo = 0;
          break;
        case 'upperwear':
          scrollContainer = this.scrollContainerWUpper;
          garmentType = containerType;
          typeNo = 1;
          break;
        case 'bottomwear':
          scrollContainer = this.scrollContainerWBottom;
          garmentType = containerType;
          typeNo = 2;
          break;
        case 'footwear':
          scrollContainer = this.scrollContainerWFootwear;
          garmentType = containerType;
          typeNo = 3;
          break;
      }
    } else {
      switch (containerType) {
        case 'headgear':
          scrollContainer = this.scrollContainerMHeadGear;
          garmentType = containerType;
          typeNo = 0;
          break;
        case 'upperwear':
          scrollContainer = this.scrollContainerMUpper;
          garmentType = containerType;
          typeNo = 1;
          break;
        case 'bottomwear':
          scrollContainer = this.scrollContainerMBottom;
          garmentType = containerType;
          typeNo = 2;
          break;
        case 'footwear':
          scrollContainer = this.scrollContainerMFootwear;
          garmentType = containerType;
          typeNo = 3;
          break;
      }
    }
    let count = this.packJsonObject[typeNo].value['count'];
    window.clearTimeout(0);
    if (count >= 25) {
      // Only start scrolling if the count is greater than or equal to 25
      setTimeout(() => {
        this.scrollingContainer(scrollContainer, garmentType, typeNo, this.tab);
      }, 100);
    }
    setTimeout(() => {
      this.setupInfiniteScroll(typeNo);
    }, 100);
  }

  scrollingContainer(scrollContainer, garmentType, typeNo, objectType) {
    if (scrollContainer) {
      const scrollLeft = scrollContainer.nativeElement.scrollLeft;
      const scrollWidth = scrollContainer.nativeElement.scrollWidth;
      const clientWidth = scrollContainer.nativeElement.clientWidth;
      const currentScrollPosition = scrollLeft + clientWidth;
      this.findScrollSide(scrollLeft)
      let files = this.packJsonObject[typeNo].value['files'];
      let count = this.packJsonObject[typeNo].value['count'];
      let formula = ((files.length - 25) / 40) + 1;
      let countformula = ((count - 25) / 40) + 1;
      let roundedFormula = this.customRound(formula);
      let currentIndex = -1; // Initialize currentIndex to -1
      let triggeredIndices = []; // Array to keep track of triggered indices
      console.log("files", files.length)
      files.forEach((file, index) => {
        // console.log('indexfile', index)
        const element = document.getElementById('element' + objectType + garmentType + index);
        // console.log(element)
        if (element) {
          const elementLeft = element.offsetLeft;
          const elementRight = elementLeft + element.clientWidth;
          if (elementLeft < currentScrollPosition && elementRight > scrollLeft) {
            console.log('index', index)
            const callPositionIndex = index  // 12 is hardCoded but it is current pack capacity divided by 2 (eg.25/2)
            let scrollSide = this.side
            console.log(scrollSide, callPositionIndex)
            this.checkScroll(callPositionIndex, scrollSide, typeNo, garmentType)
          }
        }
      });
    }
  }
  checkScroll(callPositionIndex, scrollSide, typeNo, garmentType) {
    let files = this.packJsonObject[typeNo].value.files;
    let count = this.packJsonObject[typeNo].value.count;
    console.log("count", count)
    let packCapacity = 25
    let totalIndex = callPositionIndex - 1
    let totalCount = files.length
    let trigger = []
    if (totalCount == callPositionIndex - packCapacity) {
      return;
    }
    if (totalIndex >= count.length) {
      return
    }
    if (totalIndex == packCapacity) {
      return
    }
    if (this.downloadDebounceTimer) {
      clearTimeout(this.downloadDebounceTimer);
    }

    let packValue = ((callPositionIndex - 25) / 40)
    let packIndex = this.customRound(packValue)
    let startFrom = packCapacity + (packIndex * 40)
    // Record this packIndex as used
    if (!scrollSide) {
      let packForward = (startFrom - 25) / 40;
      let packIndex = this.customRound(packForward)
      console.log('packIndex', packIndex)
      if (packIndex > 0 && packIndex < 3) {
        this.downloadPack1(garmentType, packIndex, typeNo);
        // this.setupInfiniteScroll(typeNo)
      }
    } else {
      let packForward = (startFrom - 25) / 40 + 1;
      let packIndex = this.customRound(packForward)
      if (packIndex > 0 && packIndex < 3) {
        console.log('packIndex', packIndex)
        this.downloadPack1(garmentType, packIndex, typeNo);
      }

    }

  }
  findScrollSide(currentScrollLeft) {
    const isScrollingRight = currentScrollLeft > this.previousScrollLeft;
    const isScrollingLeft = currentScrollLeft < this.previousScrollLeft;
    if (isScrollingRight) {
      this.side = true
      this.PositionSide = 'Right'
      console.log('this.PositionSide', this.PositionSide)
    } else if (isScrollingLeft) {
      this.side = false
      this.PositionSide = 'Left'
      console.log('this.PositionSide', this.PositionSide)
    }
    this.previousScrollLeft = currentScrollLeft; // U
    console.log('this.previousScrollLeft', this.previousScrollLeft)
  }
  customRound(value) {
    if (value - Math.floor(value) >= 0.85) {
      return Math.ceil(value);
    } else {
      return Math.round(value);
    }
  }
  //infinite scrolling
  private setupInfiniteScroll(type: number): void {
    let category;
    switch (this.tab) {
      case 'mens-wear':
        category = 'male';
        break;
      case 'womens-wear':
        category = 'female';
        break;
      default:
        console.error(`Invalid tab: ${this.tab}`);
        return;
    }

    const scrollContainer = this.getScrollContainer(type, this.tab);
    console.log('category', category)

    // if (!scrollContainer) {
    //   console.error(`Scroll container not found for tab: ${this.tab} and type: ${type}`);
    //   return;
    // }

    const slider = scrollContainer.nativeElement;


    // if (!slider.children || slider.children.length <= 1) {
    //   console.warn('Not enough elements for infinite scroll:', slider.children.length);
    //   return;
    // }

    const firstChild = slider.firstElementChild as HTMLElement | null;
    const lastChild = slider.lastElementChild as HTMLElement | null;

    if (!firstChild || !lastChild) {
      console.error('Critical elements for scrolling are missing.');
      return;
    }

    this.handleScroll(slider, firstChild, lastChild);
  }

  private handleScroll(slider: HTMLElement, firstChild: HTMLElement, lastChild: HTMLElement): void {
    const buffer = 0.5; // Buffer value for triggering scroll adjustments
    const childWidth = firstChild.offsetWidth;
    // Implementing the infinite scroll logic
    if (slider.scrollLeft + slider.offsetWidth + buffer >= slider.scrollWidth) {
      slider.appendChild(firstChild); // Move the first child to the end
      slider.scrollLeft -= childWidth; // Adjust scroll position accordingly
      setTimeout(() => {
        slider.style.transition = '';
      }, 0);
    } else if (slider.scrollLeft <= buffer) {
      slider.insertBefore(lastChild, slider.firstChild); // Move the last child to the beginning
      slider.scrollLeft += childWidth; // Adjust scroll position accordingly
      setTimeout(() => {
        slider.style.transition = '';
      }, 0);
    }
  }
  private getScrollContainer(type: number, tab: string): ElementRef | undefined {
    switch (tab) {
      case 'mens-wear':
        switch (type) {
          case 0: return this.scrollContainerMHeadGear;
          case 1: return this.scrollContainerMUpper;
          case 2: return this.scrollContainerMBottom;
          case 3: return this.scrollContainerMFootwear;
          default: return undefined;
        }
      case 'womens-wear':
        switch (type) {
          case 0: return this.scrollContainerWHeadGear;
          case 1: return this.scrollContainerWUpper;
          case 2: return this.scrollContainerWBottom;
          case 3: return this.scrollContainerWFootwear;
          default: return undefined;
        }
      default:
        console.error(`Invalid tab: ${tab}`);
        return undefined;
    }
  }
  switch3DImage() {
    this.swichImageFlage = !this.swichImageFlage

    // this.autoscrollFlag=true

    // if (this.autoscrollFlag) {
    //    this.autoScroll();
    // }
  }
  onFatnessChange() {

    // You can perform any other actions here based on the value change
  }
  onGarmentScroll() {
    if (this.setclothBand.nativeElement.scrollTop >= 200) {
      this.garmentScrollFlag = true
    } else {
      this.garmentScrollFlag = false
    }
  }

  incrementStorybYDrips() {
    this.pageNumber++;
    //= nextbutton + 1;
    //this.outPageNumber.emit(this.pageNumber);
    let reqInputObject = { id: this.brandId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {


      // this.userSnapShotList.push(...response.object)
      // this.userSnapShotList.forEach(async (snap: any, index) => {
      //   let userObjet = await this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      //   snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      //   userObjet.profileName = snap.profileName;
      //   snap.userData = userObjet
      //   snap.firstName = snap.profileName.split(" ", 1);
      //   snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      // })

    })
  }

  async functionLoaded(event) {
    let funValue = event;
    this.funcValue = event
    if (funValue == "loaded" && (this.garmentId != null || this.garmentId != undefined)) {

      if (this.dripsPageFlage) {
        this.showGrmentStoryCard = false
      }

      this.receivedData = await this.commonService.getSharedObject();
      this.callGarmentCloth();
    }
  }
  async callGarmentCloth() {
    this.open3Dflag(this.receivedData.zipSource, this.receivedData.type, this.receivedData.garmentId, this.receivedData.typeWear);
    console.log("this.receivedData.garmentId", this.receivedData)

    this.garmentSnpshot(this.receivedData.garmentId, this.receivedData.typeWear);
    this.garmentImgage = this.receivedData.zipSource;
    this.garmentClothType = this.receivedData.type;
    console.log("zipSourse", this.receivedData);
  }

  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight - 222;
    this.brandStoryScrollerHeight = window.innerHeight - 270;
    console.log("this.brandStoryScrollerHeight", this.brandStoryScrollerHeight)
  }

  async extractFileData(result) {
    const packJsonObject = result.packJsonObject;
    let data = result.zipList;
    this.newZipLists = data;

    for (const key in this.newZipLists) {
      if (this.newZipLists.hasOwnProperty(key)) {
        const base64String = this.newZipLists[key];
        const blobUrl = await this.base64ToBlobUrl(base64String);
        this.newZipLists[key] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
      }
    }
    this.zipList = this.newZipLists;
    console.log("Updated newZipLists:", this.zipList);

    // Refactor using loop for categories
    ['hFiles', 'uFiles', 'bFiles', 'fFiles'].forEach((category, index) => {
      this.extractFiles(packJsonObject, category, index);
    });
  }
  extractFiles(packJsonObject: any, category: string, index: number) {
    const filesArray: string[] = packJsonObject.brandSaves[category];
    const nonEmptyFilesArray: string[] = filesArray.filter((file: string) => file !== '');
    let tcount = packJsonObject.brandSaves['totalCount']
    let newPackJsonObject: any = {
      value: {
        files: nonEmptyFilesArray,
        count: tcount
      }
    };
    this.packJsonObject[index] = newPackJsonObject; // Assign to the appropriate index
    console.log(`Updated files for ${category}`, this.packJsonObject);
  }
  async base64ToBlobUrl(base64String: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      const blobUrl = URL.createObjectURL(blob);
      resolve(blobUrl);
    });
  }
  async extractNextFileData(result) {
    const packJsonObject = result;
    let nextPackList = packJsonObject.imageUrls;
    let newListObject = {};

    for (const key in nextPackList) {
      if (nextPackList.hasOwnProperty(key)) {
        const base64String = nextPackList[key];
        const blobUrl = await this.base64ToBlobUrl(base64String);
        // Remove file extension from key
        const keyWithoutExtension = key.replace(/\.[^.]+$/, '');
        newListObject[keyWithoutExtension] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
      }
    }

    this.updateZipList(newListObject); // Update the zip list with the modified object
    console.log("packJsonObject Next Data", newListObject);
  }


  //For Default Avatar Tagging in the threads page start

  // async oldFunctionbrandDefultAvatarFilter() {
  //   if (this.tab == "mens-wear") {
  //     console.log("selectedBrandId", this.selectedBrandId);
  //     let slectedBrandAvatarId_M = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.selectedBrandId && avatar.Gender === 'MALE');
  //     console.log("slectedBrandAvatarId_M", slectedBrandAvatarId_M);
  //     if (slectedBrandAvatarId_M) {

  //       this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandId && avatar.Gender === 'MALE');
  //     } else {
  //       this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'MALE');
  //     }
  //     const firstDefaultMaleAvatar = this.defaultMaleAvatars.length > 0 ? this.defaultMaleAvatars[0] : null;
  //     if (firstDefaultMaleAvatar !== this.defaultSelectedAvatarMale) {
  //       this.defaultMaleAvatarsList = this.defaultMaleAvatars;
  //       this.defaultSelectedAvatarMale = firstDefaultMaleAvatar;
  //       this.defaultAvatar_M = {
  //         savedHairTex: firstDefaultMaleAvatar?.SavedHairTexUrl,
  //         savedMetalRoughTex: firstDefaultMaleAvatar?.SavedMetalRoughTexUrl,
  //         savedNormalTex: firstDefaultMaleAvatar?.SavedNormalTexUrl,
  //         savedTex: firstDefaultMaleAvatar?.SavedTexUrl,
  //         savefile: firstDefaultMaleAvatar?.SavefileUrl
  //       };
  //     }
  //   }
  //   if (this.tab == "womens-wear") {
  //     let slectedBrandAvatarId_F = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.selectedBrandId && avatar.Gender === 'FEMALE');
  //     console.log("slectedBrandAvatarId_F", slectedBrandAvatarId_F);

  //     if (slectedBrandAvatarId_F) {
  //       this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandId && avatar.Gender === 'FEMALE');
  //     } else {
  //       this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'FEMALE');
  //     }

  //     const firstDefaultFemaleAvatar = this.defaultFemaleAvatars.length > 0 ? this.defaultFemaleAvatars[0] : null;
  //     if (firstDefaultFemaleAvatar !== this.defaultSelectedAvatar) {
  //       this.defaultFemaleAvatarsList = this.defaultFemaleAvatars;
  //       this.defaultSelectedAvatar = firstDefaultFemaleAvatar;
  //       this.defaultAvatar_F = {
  //         savedHairTex: firstDefaultFemaleAvatar?.SavedHairTexUrl,
  //         savedMetalRoughTex: firstDefaultFemaleAvatar?.SavedMetalRoughTexUrl,
  //         savedNormalTex: firstDefaultFemaleAvatar?.SavedNormalTexUrl,
  //         savedTex: firstDefaultFemaleAvatar?.SavedTexUrl,
  //         savefile: firstDefaultFemaleAvatar?.SavefileUrl
  //       };
  //     }

  //   }
  // }
  //end......................
  // defaultCloth_ByDefaultGarmentPage() {
  //   let defaultMaleCloth
  //   let defaultFeMaleCloth
  //   if (this.tab == "mens-wear") {
  //     let slectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
  //     console.log("slectedBrand_M", slectedBrand_M)
  //     if (slectedBrand_M) {
  //       defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
  //     } else {
  //       defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === 'Default' && dBrand.Gender === 'M');
  //     }
  //     const garmentLinks = {};
  //     // Iterate over each garment type
  //     const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
  //     for (const garmentType of garmentTypes) {
  //       // Find the garment with the specified garment type
  //       const garment = defaultMaleCloth.find(b => b.GarmentType === garmentType);
  //       if (garment) {
  //         // If garment is found, construct the link
  //         const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
  //         const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
  //         const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
  //         // Store the link in the garmentLinks object
  //         garmentLinks[garmentType] = garmentLink;
  //       }
  //     }

  //     console.log("Garment Links:", garmentLinks);
  //     this.queryHeadgear = garmentLinks['headgear'];
  //     this.queryUpperwear = garmentLinks['upperwear'];
  //     this.queryBottomwear = garmentLinks['bottomwear'];
  //     this.queryFootwear = garmentLinks['footwear'];

  //     // this.headgear = garmentLinks['headgear'];
  //     // this.upperwear = garmentLinks['upperwear'];
  //     // this.bottomwear = garmentLinks['bottomwear'];
  //     // this.footwear = garmentLinks['footwear'];
  //   }
  //   else if (this.tab == "womens-wear") {
  //     // const defaultFemaleCloth = this.defaultGarment.filter(avatar => avatar.BrandId === 'Default' && avatar.Gender === 'F');
  //     let slectedBrand_F = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');
  //     console.log("slectedBrand_F", slectedBrand_F)
  //     if (slectedBrand_F) {
  //       defaultFeMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');
  //     } else {
  //       defaultFeMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === 'Default' && dBrand.Gender === 'F');
  //     }
  //     const garmentLinks_F = {};
  //     // Iterate over each garment type
  //     const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
  //     for (const garmentType of garmentTypes) {
  //       // Find the garment with the specified garment type
  //       const garment = defaultFeMaleCloth.find(b => b.GarmentType === garmentType);
  //       if (garment) {
  //         // If garment is found, construct the link
  //         const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
  //         const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
  //         const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
  //         // Store the link in the garmentLinks object
  //         garmentLinks_F[garmentType] = garmentLink;
  //       }
  //     }
  //     console.log("Garment Links:", garmentLinks_F);
  //     this.queryHeadgear = garmentLinks_F['headgear'];
  //     this.queryUpperwear = garmentLinks_F['upperwear'];
  //     this.queryBottomwear = garmentLinks_F['bottomwear'];
  //     this.queryFootwear = garmentLinks_F['footwear'];

  //     // this.headgear = garmentLinks_F['headgear'];
  //     // this.upperwear = garmentLinks_F['upperwear'];
  //     // this.bottomwear = garmentLinks_F['bottomwear'];
  //     // this.footwear = garmentLinks_F['footwear'];
  //   }
  // }
  //   defaultCloth_ByDefaultGarmentPage() {
  //     let defaultMaleCloth;
  //     let defaultFeMaleCloth;
  //     if (this.tab == "mens-wear") {
  //         let slectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
  //         console.log("slectedBrand_M", slectedBrand_M);
  //         if (slectedBrand_M) {
  //             defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
  //         } else {
  //             defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === 'Default' && dBrand.Gender === 'M');
  //         }
  //         const garmentLinks = this.constructGarmentLinks(defaultMaleCloth);
  //         console.log("Garment Links:", garmentLinks);
  //         this.updateGarmentLinks(garmentLinks);
  //     } else if (this.tab == "womens-wear") {
  //         let slectedBrand_F = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');
  //         console.log("slectedBrand_F", slectedBrand_F);
  //         if (slectedBrand_F) {
  //             defaultFeMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');
  //         } else {
  //             defaultFeMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === 'Default' && dBrand.Gender === 'F');
  //         }
  //         const garmentLinks_F = this.constructGarmentLinks(defaultFeMaleCloth);
  //         console.log("Garment Links:", garmentLinks_F);
  //         this.updateGarmentLinks(garmentLinks_F);
  //     }
  // }

  // constructGarmentLinks(garmentList) {
  //     const garmentLinks = {};
  //     const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
  //     for (const garmentType of garmentTypes) {
  //         const garment = garmentList.find(b => b.GarmentType === garmentType);
  //         if (garment) {
  //             const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
  //             const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
  //             const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
  //             garmentLinks[garmentType] = garmentLink;
  //         }
  //     }
  //     return garmentLinks;
  // }

  // updateGarmentLinks(garmentLinks) {
  //     if ( this.currentGarmentLinks != garmentLinks) {
  //         this.queryHeadgear = garmentLinks['headgear'];
  //         this.queryUpperwear = garmentLinks['upperwear'];
  //         this.queryBottomwear = garmentLinks['bottomwear'];
  //         this.queryFootwear = garmentLinks['footwear'];
  //         this.headgear = garmentLinks['headgear'];
  //         this.upperwear = garmentLinks['upperwear'];
  //         this.bottomwear = garmentLinks['bottomwear'];
  //         this.footwear = garmentLinks['footwear'];
  //         this.currentGarmentLinks = garmentLinks;
  //     }
  // }

  showAvatarSlider() {
    this.showSlider = !this.showSlider
  }

  selectMaleAvatar(avatars) {
    this.showSlider = false
    const selecteddefaultMaleAvatars = this.defaultMaleAvatarsList.find((avatar) => avatar.AvatarId === avatars.AvatarId);
    this.defaultSelectedAvatarMale = selecteddefaultMaleAvatars
    console.log("selecteddefaultMaleAvatars", selecteddefaultMaleAvatars);
    if (selecteddefaultMaleAvatars) {
      this.defaultAvatar_M = {
        savedHairTex: selecteddefaultMaleAvatars.SavedHairTexUrl,
        savedMetalRoughTex: selecteddefaultMaleAvatars.SavedMetalRoughTexUrl,
        savedNormalTex: selecteddefaultMaleAvatars.SavedNormalTexUrl,
        savedTex: selecteddefaultMaleAvatars.SavedTexUrl,
        savefile: selecteddefaultMaleAvatars.SavefileUrl
      }

      console.log("this.garmentLinks_M:", this.garmentLinks_M);
      this.queryHeadgear = null
      this.queryUpperwear = null
      this.queryBottomwear = null
      this.queryFootwear = null
      this.functionFlag = null;
      setTimeout(() => {
        this.queryHeadgear = this.garmentLinks_M['headgear'];
        this.queryUpperwear = this.garmentLinks_M['upperwear'];
        this.queryBottomwear = this.garmentLinks_M['bottomwear'];
        this.queryFootwear = this.garmentLinks_M['footwear'];
        this.functionFlag = "LoadDefaultMaleAvatar";
      }, 100);

    }
    else {
      console.log("Avatar not found:", avatars);
    }
  }

  selectFemaleAvatar(avatars) {
    this.showSlider = false
    const selecteddefaultFemleAvatars = this.defaultFemaleAvatarsList.find((avatar) => avatar.AvatarId === avatars.AvatarId);
    this.defaultSelectedAvatar = selecteddefaultFemleAvatars
    this.defaultAvatar_F = {
      savedHairTex: selecteddefaultFemleAvatars?.SavedHairTexUrl,
      savedMetalRoughTex: selecteddefaultFemleAvatars?.SavedMetalRoughTexUrl,
      savedNormalTex: selecteddefaultFemleAvatars?.SavedNormalTexUrl,
      savedTex: selecteddefaultFemleAvatars?.SavedTexUrl,
      savefile: selecteddefaultFemleAvatars?.SavefileUrl
    };

    console.log("this.garmentLinks_F", this.garmentLinks_F);
    this.queryHeadgear = null
    this.queryUpperwear = null
    this.queryBottomwear = null
    this.queryFootwear = null




    this.functionFlag = null;
    setTimeout(() => {
      this.queryHeadgear = this.garmentLinks_F['headgear'];
      this.queryUpperwear = this.garmentLinks_F['upperwear'];
      this.queryBottomwear = this.garmentLinks_F['bottomwear'];
      this.queryFootwear = this.garmentLinks_F['footwear'];
      this.functionFlag = 'LoadDefaultFaleAvatar'
    }, 100);
  }

  // async onChangeBrand() {
  //   console.log('Brand changed. New brand ID:', this.brandId);
  //   await this.userBrandAvatar.subscribe((avatarData) => {
  //     this.userBrandAvatarList = avatarData
  //     console.log("this.userBrandAvatarList",this.userBrandAvatarList)
  //   })
  //   // Check if brandId is defined
  //   if (this.onbrandchangeflag && this.userBrandAvatarList) {
  //     this.selectedBrandId = this.brandId;
  //     this.genderCode = this.determineGenderCode(this.tab);
  //     this.brandObject = this.brandsCollection.find((b: any) => b.id === this.brandsIDdetailsAll);
  //     this.selectedImageIndex = null;
  //     // this.funcValue = "";
  //     try {

  //       await this.setBrandAvatar_Details();
  //       await this.setCloth_Details();
  //       await this.setTab(this.tab, this.genderCode);
  //     } catch (error) {
  //       console.error('Error in onChangeBrand:', error);
  //     }// Exit the method early if brandId is not defined
  //   }



  // }
  async onChangeBrand() {
    console.log('Brand changed. New brand ID:', this.brandId);

    // Check if brandId is defined
    if (!this.brandId) {
      console.error('Brand ID is not defined.');
      return;
    }

    try {
      // Await the subscription to get avatar data
      this.userBrandAvatarList = await new Promise((resolve, reject) => {
        this.userBrandAvatar.subscribe((avatarData) => {
          resolve(avatarData);
        }, reject);
      });
      console.log("this.userBrandAvatarList", this.userBrandAvatarList);

      if (this.onbrandchangeflag && this.userBrandAvatarList) {
        this.selectedBrandId = this.brandId;
        this.genderCode = this.determineGenderCode(this.tab);
        this.brandObject = this.brandsCollection.find((b: any) => b.id === this.brandsIDdetailsAll);
        this.selectedImageIndex = null;
        // this.funcValue = "";

        await this.setBrandAvatar_Details();
        await this.setCloth_Details();
        await this.setTab(this.tab, this.genderCode);
      }
    } catch (error) {
      console.error('Error in onChangeBrand:', error);
    }
  }


  async setBrandAvatar_Details() {
    const startTime = performance.now(); // Capture the start time
    // this.functionFlag = null
    // Ensure defaults are set only if they are not already defined
    if (!this.defaultSelectedAvatarMale) {
      await this.defaultAvatar_Male();
    }
    if (!this.defaultSelectedAvatarFeMale) {
      await this.defaultAvatar_FeMale();
    }

    // Ensure the correct method is called
    await this.currentBrandAvatar_FeMale();
    await this.currentBrandAvatar_Male();


    // Additional console logs for debugging
    // console.log('Male avatar details:', maleAvatar);
    // console.log('Female avatar details:', femaleAvatar);

    // this.setBrandAvatarDetails_M = maleAvatar;
    // this.setBrandAvatarDetails_F = femaleAvatar;

    // Confirm that the properties have been set correctly
    console.log('Set brand avatar details:', this.setBrandAvatarDetails_M, this.setBrandAvatarDetails_F);

    const endTime = performance.now(); // Capture the end time
    const executionTime = endTime - startTime; // Calculate the duration
    console.log(`setBrandAvatar_Details execution time: ${executionTime}ms`); // Log the execution time
  }
  async setCloth_Details() {
    await this.currentdefaultCloth_Male()
    await this.currentdefaultCloth_FeMale()
  }

  defaultAvatar_Male() {
    if (!this.defaultSelectedAvatarMale) { // Only set default if not already set
      this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'MALE');
      const firstDefaultMaleAvatar = this.defaultMaleAvatars.length > 0 ? this.defaultMaleAvatars[0] : null;
      this.defaultSelectedAvatarMale = firstDefaultMaleAvatar;
      console.log("this.userBrandAvatarList ", this.userBrandAvatarList)

      console.log("this.defaultSelectedAvatarMale ", this.defaultSelectedAvatarMale)
    }
  }
  defaultAvatar_FeMale() {
    if (!this.defaultSelectedAvatarFeMale) { // Only set default if not already set
      this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'FEMALE');
      const firstDefaultFemaleAvatar = this.defaultFemaleAvatars.length > 0 ? this.defaultFemaleAvatars[0] : null;
      this.defaultSelectedAvatarFeMale = firstDefaultFemaleAvatar;
    }
  }

  currentBrandAvatar_Male() {
    let slectedBrandAvatarId_M = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.brandId && avatar.Gender === 'MALE');
    if (slectedBrandAvatarId_M) {
      this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.brandId && avatar.Gender === 'MALE');
    } else {
      this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'MALE');
    }
    this.defaultMaleAvatarsList = this.defaultMaleAvatars;
    const firstDefaultMaleAvatar = this.defaultMaleAvatars.length > 0 ? this.defaultMaleAvatars[0] : null;
    this.defaultSelectedAvatarMale = firstDefaultMaleAvatar;
    this.setBrandAvatarDetails_M = slectedBrandAvatarId_M;
  }

  currentBrandAvatar_FeMale() {
    let slectedBrandAvatarId_F = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.brandId && avatar.Gender === 'FEMALE');
    if (slectedBrandAvatarId_F) {
      this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.brandId && avatar.Gender === 'FEMALE');
    } else {
      this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'FEMALE');
    }
    const firstDefaultFemaleAvatar = this.defaultFemaleAvatars.length > 0 ? this.defaultFemaleAvatars[0] : null;
    this.defaultSelectedAvatarFeMale = firstDefaultFemaleAvatar;

    this.defaultFemaleAvatarsList = this.defaultFemaleAvatars;
    this.setBrandAvatarDetails_F = slectedBrandAvatarId_F;
    console.log('slectedBrandAvatarId_F', slectedBrandAvatarId_F)
  }

  // currentdefaultCloth_Male() {

  //   // Check if defaultGarment is defined

  //   let selectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');

  //   let selectedBrand = selectedBrand_M && selectedBrand_M.BrandId !== undefined ? selectedBrand_M.BrandId : "Default";

  //   let defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'M');

  //   const garmentLinks_M = {};
  //   const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];

  //   for (const garmentType of garmentTypes) {

  //     const garment = defaultMaleCloth.find(b => b.GarmentType === garmentType);

  //     if (garment) {
  //       const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);

  //       if (selectedDefaultCloth) {
  //         const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
  //         const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
  //         garmentLinks_M[garmentType] = garmentLink;
  //       } else {
  //       }
  //     }
  //   }
  //   this.garmentLinks_M = garmentLinks_M;
  //   console.log('garmentLinks_M:', this.garmentLinks_M);
  // }

  currentdefaultCloth_Male() {
    // Check if defaultGarment is defined
    let selectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
    let selectedBrand = selectedBrand_M && selectedBrand_M.BrandId !== undefined ? selectedBrand_M.BrandId : "Default";
    let defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'M');

    const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];

    const promises = garmentTypes.map(async garmentType => {
      const garment = defaultMaleCloth.find(b => b.GarmentType === garmentType);
      if (garment) {
        const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
        if (selectedDefaultCloth) {
          const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
          const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
          return { [garmentType]: garmentLink };
        }
      }
      return null;
    });

    Promise.all(promises).then(results => {
      const garmentLinks_M = {};
      results.forEach(result => {
        if (result) {
          Object.assign(garmentLinks_M, result);
        }
      });
      this.garmentLinks_M = garmentLinks_M;
      console.log('garmentLinks_M:', this.garmentLinks_M);
    }).catch(error => {
      console.error('Error fetching garment links:', error);
    });
  }

  currentdefaultCloth_FeMale() {
    // Check if defaultGarment is defined


    let selectedBrand_F = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');

    let selectedBrand = selectedBrand_F && selectedBrand_F.BrandId !== undefined ? selectedBrand_F.BrandId : "Default";

    let defaultFemaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'F');

    const garmentLinks_F = {};
    const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];

    for (const garmentType of garmentTypes) {

      const garment = defaultFemaleCloth.find(b => b.GarmentType === garmentType);

      if (garment) {
        const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);

        if (selectedDefaultCloth) {
          const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
          const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
          garmentLinks_F[garmentType] = garmentLink;
        } else {
        }
      }
    }
    this.garmentLinks_F = garmentLinks_F;
  }


  // showAvatar(genderCode) {
  //   // this.funcValue = "";
  //   if (genderCode == 'F' && this.defaultSelectedAvatarFeMale) {
  //     let female = this.setBrandAvatarDetails_F !== undefined ? this.setBrandAvatarDetails_F : this.defaultSelectedAvatarFeMale;
  //     console.log('female.......................................................................................................................', female)
  //     this.defaultAvatar_F = {
  //       savedHairTex: female?.SavedHairTexUrl,
  //       savedMetalRoughTex: female?.SavedMetalRoughTexUrl,
  //       savedNormalTex: female?.SavedNormalTexUrl,
  //       savedTex: female?.SavedTexUrl,
  //       savefile: female?.SavefileUrl
  //     };
  //     console.log(' this.defaultAvatar_F', this.defaultAvatar_F);
  //     if(this.garmentLink != undefined) {
  //       this.queryHeadgear =  this.tempCthoth_H  != undefined ? this.tempCthoth_H :  this.garmentLinks_F['headgear'];
  //       this.queryUpperwear =this.tempCthoth_U  != undefined ? this.tempCthoth_U : this.garmentLinks_F['upperwear'];
  //       this.queryBottomwear =  this.tempCthoth_B  != undefined ? this.tempCthoth_B : this.garmentLinks_F['bottomwear'];
  //       this.queryFootwear =  this.tempCthoth_F  != undefined ? this.tempCthoth_F : this.garmentLinks_F['footwear'];
  //     }else{
  //       this.queryHeadgear =  this.garmentLinks_F['headgear'];
  //       this.queryUpperwear =  this.garmentLinks_F['upperwear'];
  //       this.queryBottomwear = this.garmentLinks_F['bottomwear'];
  //       this.queryFootwear = this.garmentLinks_F['footwear'];
  //     }
  //     this.functionFlag = null
  //     setTimeout(() => {
  //       this.functionFlag = 'ShowFemale';
  //       // this.functionFlag = 'LoadDefaultFaleAvatar'
  //     }, 100);

  //   } else if (genderCode == 'M' && this.defaultSelectedAvatarMale) {
  //     let male = this.setBrandAvatarDetails_M !== undefined ? this.setBrandAvatarDetails_M : this.defaultSelectedAvatarMale;
  //     this.defaultAvatar_M = {
  //       savedHairTex: male?.SavedHairTexUrl,
  //       savedMetalRoughTex: male?.SavedMetalRoughTexUrl,
  //       savedNormalTex: male?.SavedNormalTexUrl,
  //       savedTex: male?.SavedTexUrl,
  //       savefile: male?.SavefileUrl
  //     };
  //     if(this.garmentLink != undefined) {
  //       this.queryHeadgear =  this.tempCthoth_H  != undefined ? this.tempCthoth_H :  this.garmentLinks_M['headgear'];
  //       this.queryUpperwear =this.tempCthoth_U  != undefined ? this.tempCthoth_U : this.garmentLinks_M['upperwear'];
  //       this.queryBottomwear =  this.tempCthoth_B  != undefined ? this.tempCthoth_B : this.garmentLinks_M['bottomwear'];
  //       this.queryFootwear =  this.tempCthoth_F  != undefined ? this.tempCthoth_F : this.garmentLinks_M['footwear'];
  //     }else {
  //       this.queryHeadgear =  this.garmentLinks_M['headgear'];
  //       this.queryUpperwear =  this.garmentLinks_M['upperwear'];
  //       this.queryBottomwear = this.garmentLinks_M['bottomwear'];
  //       this.queryFootwear = this.garmentLinks_M['footwear'];
  //     }

  //     this.functionFlag = null
  //     setTimeout(() => {
  //       this.functionFlag = 'ShowMale';
  //       // this.functionFlag = 'LoadDefaultMaleAvatar'
  //     }, 100);
  //   }
  // }
  showAvatar(genderCode) {
    // this.funcValue = "";
    if (genderCode == 'F' && this.defaultSelectedAvatarFeMale) {
      let female = this.setBrandAvatarDetails_F !== undefined ? this.setBrandAvatarDetails_F : this.defaultSelectedAvatarFeMale;
      console.log('female.......................................................................................................................', female)
      this.defaultAvatar_F = {
        savedHairTex: female?.SavedHairTexUrl,
        savedMetalRoughTex: female?.SavedMetalRoughTexUrl,
        savedNormalTex: female?.SavedNormalTexUrl,
        savedTex: female?.SavedTexUrl,
        savefile: female?.SavefileUrl
      };
      console.log(' this.defaultAvatar_F', this.defaultAvatar_F);

      if (this.garmentLink != undefined) {
        this.queryHeadgear = this.tempCthoth_H != undefined ? this.tempCthoth_H : this.garmentLinks_F['headgear'];
        this.queryUpperwear = this.tempCthoth_U != undefined ? this.tempCthoth_U : this.garmentLinks_F['upperwear'];
        this.queryBottomwear = this.tempCthoth_B != undefined ? this.tempCthoth_B : this.garmentLinks_F['bottomwear'];
        this.queryFootwear = this.tempCthoth_F != undefined ? this.tempCthoth_F : this.garmentLinks_F['footwear'];
      } else {
        this.queryHeadgear = this.garmentLinks_F['headgear'];
        this.queryUpperwear = this.garmentLinks_F['upperwear'];
        this.queryBottomwear = this.garmentLinks_F['bottomwear'];
        this.queryFootwear = this.garmentLinks_F['footwear'];
      }
      console.log("this.garmentLinks_F['upperwear']", this.garmentLinks_F['upperwear'])
      console.log("this.garmentLinks_F['bottomwear']", this.garmentLinks_F['bottomwear'])
      this.functionFlag = null;
      setTimeout(() => {
        this.functionFlag = 'ShowFemale';
        // this.functionFlag = 'LoadDefaultFaleAvatar'
      }, 100);

    } else if (genderCode == 'M' && this.defaultSelectedAvatarMale) {
      let male = this.setBrandAvatarDetails_M !== undefined ? this.setBrandAvatarDetails_M : this.defaultSelectedAvatarMale;
      this.defaultAvatar_M = {
        savedHairTex: male?.SavedHairTexUrl,
        savedMetalRoughTex: male?.SavedMetalRoughTexUrl,
        savedNormalTex: male?.SavedNormalTexUrl,
        savedTex: male?.SavedTexUrl,
        savefile: male?.SavefileUrl
      };
      if (this.garmentLink != undefined) {
        this.queryHeadgear = this.tempCthoth_H != undefined ? this.tempCthoth_H : this.garmentLinks_M['headgear'];
        this.queryUpperwear = this.tempCthoth_U != undefined ? this.tempCthoth_U : this.garmentLinks_M['upperwear'];
        this.queryBottomwear = this.tempCthoth_B != undefined ? this.tempCthoth_B : this.garmentLinks_M['bottomwear'];
        this.queryFootwear = this.tempCthoth_F != undefined ? this.tempCthoth_F : this.garmentLinks_M['footwear'];
      } else {
        this.queryHeadgear = this.garmentLinks_M['headgear'];
        this.queryUpperwear = this.garmentLinks_M['upperwear'];
        this.queryBottomwear = this.garmentLinks_M['bottomwear'];
        this.queryFootwear = this.garmentLinks_M['footwear'];
      }

      this.functionFlag = null;
      setTimeout(() => {
        this.functionFlag = 'ShowMale';
        // this.functionFlag = 'LoadDefaultMaleAvatar'
      }, 100);
    }
  }


  handleData(data: any) {
    console.log("drips Avatar Data", data);
    this.avatarCollapseFlag =
      this.dripsPageAvatarData = data
    console.log("drips Avatar Data", this.dripsPageAvatarData.snpObject);
    console.log("drips Avatar Data", this.dripsPageAvatarData.garmentPanelFlag);
    this.receiveAvatarPanelDetails.emit(data);


    // Handle the emitted data from the child component here
  }


  handlbrand(data: any) {
    console.log("drips Avatar Data", data);
    this.receiveAvatarbrandPanel.emit(data)
  }

  handlevnData(data: any) {
    console.log("drips Avatar Data", data);
    this.receveidPanelEven.emit(data)
  }

  handlUserData(data: any) {
    console.log("drips User Data", data);
    this.receveidPanelUser.emit(data)
  }

  handlUsGarment(data) {
    console.log("drips User Data", data);
    this.receveidPanelGarment.emit(data)
  }

  handleStorysarch(data) {
    this.receveidSearchData.emit(data)
    console.log("Search",data)
  }


  async selectBrandStoryCardTab() {
    this.receivedData = await this.commonService.getSharedObject();
    console.log("this.receivedData",)
    if (this.receivedData.brandTab == 'news-stories') {
      this.tab = this.receivedData.brandTab
      this.showBrandStoryCard = true
    }
    console.log("this.receivedData ", this.receivedData)
  }

  storyIndex(data) {
    let storyIdex = data
    this.selectedImageIndex = storyIdex
    this.outnewStoryIndeX.emit(storyIdex)
  }

  handleAvatarData(data: any) {
    // this.avatarData = data;
    // console.log('Received avatar data from child:', data);

    // // Update related variables with the received data
    // this.evnDefault = data.envUrl;
    // this.avtarUrl = data.avatraUrls;
    // this.headgear = data.headgear;
    // this.footwear = data.footwear;
    // this.selectBrandGender = data.genders;
    // this.upperwear = data.garmenturl;
    // this.bottomwear = data.garmentbottom;
    // this.fatnessValue = data.InputFatness; // assuming InputFatness is part of emitted data
    // this.brandId = data.BrandIdName; // assuming BrandIdName is part of emitted data
    // this.functionFlag = data.functionflagValue;
    // this.queryHeadgear = data.queryHeadgear; // assuming queryHeadgear is part of emitted data
    // this.queryUpperwear = data.queryUpperwear; // assuming queryUpperwear is part of emitted data
    // this.queryBottomwear = data.queryBottomwear; // assuming queryBottomwear is part of emitted data
    // this.queryFootwear = data.queryFootwear; // assuming queryFootwear is part of emitted data
    // this.defaultAvatar_M = data.DefaultAvatar_M; // assuming DefaultAvatar_M is part of emitted data
    // this.defaultAvatar_F = data.DefaultAvatar_F; // assuming DefaultAvatar_F is part of emitted data
  }


  handleAvataData(data: any) {
    this.dripsPageFlage = true
    this.functionFlag = null
    this.funcValue = null
    this.avatarData = data;
    console.log('Received avatar data from child:', data);
    this.evnDefault = data.envUrl;
    this.avtarUrl = data.avatraUrls;
    this.selectBrandGender = data.genders;
    this.queryHeadgear = data.headgear;
    this.queryUpperwear = data.garmenturl;
    this.queryBottomwear = data.garmentbottom;
    this.queryFootwear = data.footwear;
    this.blob = data.dataBlobUrl
    this.storyId = data.storyID
    this.functionFlag = 'LoadAvatar' + '_' + this.avtarUrl + '_StoryID' + data.storyID;
    let temp_tab = this.selectBrandGender == "M" ? ' mens-wear' : "womens-wear"
    this.closecard()
    // this.setTab(temp_tab,this.selectBrandGender)
  }

  BrandhandleAvataData(data: any) {
    this.dripsPageFlage = true
    this.functionFlag = null
    this.funcValue = null
    this.tab = null
    this.avatarData = data;
    console.log('Received avatar data from child:', data);
    this.evnDefault = data.envUrl;
    this.avtarUrl = data.avatraUrls;
    this.selectBrandGender = data.genders;
    this.queryHeadgear = data.headgear;
    this.queryUpperwear = data.garmenturl;
    this.queryBottomwear = data.garmentbottom;
    this.queryFootwear = data.footwear;
    this.blob = data.dataBlobUrl
    this.storyId = data.storyID
    this.functionFlag = 'LoadAvatar' + '_' + this.avtarUrl + '_StoryID' + data.storyID;
    let temp_tab = data.genders == "M" ? "mens-wear" : (data.genders == "F" ? "womens-wear" : "")
    this.tab = temp_tab
    console.log("Brand Tab", this.tab)
    this.setTabByStory(this.tab, this.selectBrandGender)
    this.closeBrandcard()




    // this.setTab(temp_tab,this.selectBrandGender)
  }

  newVrmAvatar(data: any) {
    this.dripsPageFlage = true
    this.functionFlag = null
    this.avatarData = data;
    console.log('Received avatar data from child:', data);
    this.evnDefault = data.envUrl;
    this.avtarUrl = data.avatraUrls;
    this.selectBrandGender = data.genders;
    this.queryHeadgear = data.headgear;
    this.queryUpperwear = data.garmenturl;
    this.queryBottomwear = data.garmentbottom;
    this.queryFootwear = data.footwear;
    this.blob = data.dataBlobUrl
    this.storyId = data.storyID
    this.functionFlag = data.functionflagValue;
  }




  async setTabByStory(tab, gender) {
    this.previousScrollLeft = null;
    this.activeDownloads.clear();
    this.flipFlag = false;
    this.selectBrandGender = gender;
    // this.showBrandStoryCard = false
    this.tab = tab;
    if (this.lastSelectedBrand === this.brandId && this.lastSelectedGender === gender) {
      if (this.tab === 'mens-wear' || this.tab === 'womens-wear' || this.tab === 'story') {
        this.changeSelectedTab.emit(this.tab);
        this.infoPopup()
        this.showBrandPackDetails();
      }
      return;
    }
  }

  switchFlag() {
    if (this.isChecked) {
      this.functionFlag = 'TogglePostCardOn';
    } else {
      this.functionFlag = 'TogglePostCardOff';
    }
  }

  switchFlagforFocus() {
    this.isCheckedFocus = !this.isCheckedFocus;
    if (this.isCheckedFocus) {
      this.functionFlag = 'FocusOnAvatar';
    } else {
      this.functionFlag = 'FocusOnStory';
    }
  }

  toggleSwitch() {
    this.isChecked = !this.isChecked;
    console.log("isChecked", this.isChecked)
    this.switchFlag();
  }

  async brandPanelDataFunction(brandId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/brandPanelDetailswithLogoUrl";
    return this._httpClient.post<any>(functionUrl, { brandId: brandId }).toPromise();
  }


  async loadBrandPanelData(brand) {
    this.dripsPageFlage = true
    this.brandObject = undefined
    this.newBrandObject = null
    this.branUrl = null
    this.userSnapShotList = null
    // this.isloadingBrandPanel = true
    this.tab = 'story';
    let data = await this.brandPanelDataFunction(brand);
    if (this.index == undefined) {
      this.userSnapShotList = data.object;
    }    // this.isloadingBrandPanel = false
    this.newBrandObject = data.brand
    this.branUrl = data.brandLogoUrl
    console.log("data", data)
    setTimeout(() => {
      if (this.panel == 'brandPanel' && this.StoryId != null) {
        this.showBrandStoryCard = true
        this.showGrmentStoryCard = false
        if (this.index == undefined) {
          this.userSnapShotList = data.object;
        }
        // this.tab = 'news-stories';
        this.closeBrandcard
        // this.selectImage(0, this.StoryId)
      }
    }, 500);

    if (this.tab == 'story' && this.StoryId != null || this.StoryId != undefined) {
      this.showBrandStoryCard = true
    }

  }


  async loadGarmentData(garmentId) {

    this.isloadingPanel = true
    this.garmentData = []
    this.userFileList = []
    this.userFiledetails3d = []
    this.userSnapShotListGarment = []
    const startTime = performance.now();
    console.log(`Data fetching started at: ${startTime} ms`);
    this.garmentData = await this.garmentPanelDataFunction(garmentId);
    this.isloadingPanel = false
    this.clothDetails = this.garmentData.garment;
    const endTime = performance.now();
    console.log(`Data fetching ended at: ${endTime} ms`);
    const duration = endTime - startTime;
    console.log(`Data fetching duration MicroUrl: ${duration} ms`);
    let filePathNewTemp = this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
    this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
    this.brandObject = this.garmentData.brand;
    this.branUrl = this.garmentData.brandLogoUrl;

    if (this.index == undefined) {
      this.userSnapShotListGarment = this.garmentData.snapshots.object;
    }

    const storageRef = this.storage.ref(filePathNewTemp.filepath);
    const url = await storageRef.getDownloadURL().pipe(finalize(() => { })).toPromise();

    console.log('Download URL:', url);
    this.source = url
    console.log("this.source", this.source);
    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
    let filelist2d = this.garmentData.userFiles.filter(
      (uploadlist) =>
        uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = this.garmentData.userFiles.filter(
      (uploadlist) =>
        uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload3D'
    );

    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;
    setTimeout(() => {
      if (this.StoryId != null || this.StoryId != undefined) {
        this.showGrmentStoryCard = true
        this.selectGarmentImage(this.selectedImageIndex, this.StoryId);
      }
    }, 500);

  }

  async garmentPanelDataFunction(garmentId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this._httpClient.post<any>(functionUrl, { garmentId: garmentId, fileType: this.typeWear }).toPromise();
  }


  handlebrandSnapShot(event) {
    console.log("data", event)
  }

  handleVisibleIndex(event) {
    let data = event
    this.selectedImageIndex = data
    console.log("Index", event)
    let flage = true
    // if(this.selectedImageIndex  == 11 && flage){
    //   this.nextButton('c')
    //   this.pageNumber = 2
    //   flage = false
    // }
  }


  handleBrandVisibleIndex(event) {
    // let data = event
    // this.selectedImageIndex = data
    // console.log("Index", event)
    let flage = true
    // if(this.selectedImageIndex  == 11 && flage){
    //   this.nextButton('c')
    //   this.pageNumber = 2
    //   flage = false
    // }
  }



  //new pagination start  for the  story card - garment
  handleStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let count = 1
      this.nextButton(count)
    }
  }

  nextByCall(counts) {
    // this.userSnapShotListGarment = []
    const count = 12;
    for (let i = 0; i < this.pagecount; i++) {
      this.userSnapShotListGarment.push(this.newEntry);
    }
    this.showBrandStoryCard = false;
    this.showGrmentStoryCard = false;
    const totalPages = Math.ceil(this.pagecount / count);
    const currentPage = Math.floor(this.index / count) + 1;
    const startIndex = (currentPage - 1) * count;
    console.log("totalPages", totalPages)
    console.log("this.pagecount", this.pagecount)
    console.log("currentPage", currentPage)
    // this.counter = count;
    let reqInputObject = { fileType: this.typeWear, id: this.garmentClothId, pageNumber: currentPage }
    console.log('reqInputObject', reqInputObject)
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      console.log("newItems", newItems)

      for (let i = 0; i < newItems.length; i++) {
        this.userSnapShotListGarment[startIndex + i] = newItems[i];
      }
      console.log("newItems", this.userSnapShotListGarment)
      console.log("this.storyId", this.StoryId)
      this.selectedStoryId = this.StoryId
      this.snapInitCall = true
      // this.showGrmentStoryCard = true;
    })
  }

  nextButton(counts) {
    let cou = 12;
    let cllpagecount = (this.callpageNumer * cou) - 1;
    console.log('cllpagecount', cllpagecount);
    console.log('visbleIndex', this.visbleIndex);
    if (this.callpageIndexFlagNextButton) {
      this.pageNumber = this.pageiIndex != undefined ? this.pageiIndex : 1;
      this.callpageIndexFlagNextButton = false
    }
    this.pageNumber++;
    const count = 12; // Number of items per page
    const totalPages = Math.ceil(this.pagecount / count);
    // Ensure the current page number does not exceed total pages
    if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }
    // Calculate the current page and start index
    const currentPage = this.pageNumber;
    const startIndex = (currentPage - 1) * count;

    console.log("pageNumber---------------------------------------->", this.pageNumber);

    this.counter = this.pageNumber;

    // Check if this page has already been loaded
    if (this.loadedPages[this.pageNumber]) {
      console.log(`Page ${this.pageNumber} is already loaded. Skipping API call.`);
      return;
    }

    // Prepare the request object with the updated page number
    let reqInputObject = { fileType: this.typeWear, id: this.garmentClothId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      console.log("this.userSnapShotListGarment before push", this.userSnapShotListGarment);

      const newItems = response.object;
      this.nextPage = true;

      // Update userSnapShotListGarment with the new items at the correct start index
      for (let i = 0; i < newItems.length; i++) {
        this.userSnapShotListGarment[startIndex + i] = newItems[i];
      }

      // Mark this page as loaded
      this.loadedPages[this.pageNumber] = true;

      this.nextPage = false;
      console.log("this.userSnapShotListGarment after push", this.userSnapShotListGarment);
    });
  }

  previousbutton() {
    let count = 12;

    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    if (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);

      console.log('cllpagecount', cllpagecount);
      console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

      // Check if the page has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${this.callpageNumer + 1} is already loaded. Skipping API call.`);
        this.callpageNumer--;  // Decrease callpage by 1
        return;
      }

      // Prepare the request object with the updated page number
      let reqInputObject = { fileType: this.typeWear, id: this.garmentClothId, pageNumber: this.callpageNumer }
      this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
        console.log("this.userSnapShotListGarment before push", this.userSnapShotListGarment);
        const newItems = response.object;
        this.nextPage = true;

        // Update userSnapShotListGarment with the new items at the correct start index
        for (let i = 0; i < newItems.length; i++) {
          this.userSnapShotListGarment[startIndex + i] = newItems[i];
        }

        // Mark this page as loaded to prevent future duplicate calls
        this.loadedPagesBackWord[cllpagecount] = true;

        this.nextPage = false;
        console.log("this.userSnapShotListGarment after push", this.userSnapShotListGarment);
        console.log("loadedPages", this.loadedPagesBackWord);

        // Decrease callpage by 1 after the API call
        this.callpageNumer--;
      });
    }
  }
  async handlePreviousPage(data) {
    console.log("index number :-", data);
    this.visbleIndex = data
    let count = 12;
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }
    while (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
      console.log('cllpagecount', cllpagecount);

      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${cllpagecount} is already loaded. Skipping API call.`);
        return;
      }

      if (cllpagecount === data) {
        console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

        try {
          let reqInputObject = { fileType: this.typeWear, id: this.garmentClothId, pageNumber: this.callpageNumer }
          this.callpageNumer--;  // Decrease callpage by 1

          const response = await this.commonService.yologramSnapshotDetailsByType(reqInputObject).toPromise();
          const newItems = response['object'];
          this.nextPage = true;
          // Update userSnapShotListGarment with the new items at the correct start index
          newItems.forEach((item, index) => {
            this.userSnapShotListGarment[startIndex + index] = item;
          });
          this.loadedPagesBackWord[cllpagecount] = true;
          console.log("loadedPages", this.loadedPagesBackWord);
        } catch (error) {
          console.error("Error fetching page data:", error);
        }
      } else {
        break;  // Exit loop if no match is found
      }
    }
  }
  //end garment pagination garment



  //start brand pagination part
  handleBrandStoryCall(data) {
    this.nextPage = data
    if (this.nextPage == true) {
      let newcount = this.pageiIndex * 12
      console.log("newcount", newcount)
      console.log("selectedImageIndex", this.selectedImageIndex)
      let count = 1
      this.nextBrandButton(count)
    }
  }

  nextBrandButton(counts) {
    let cou = 12;
    let cllpagecount = (this.callpageNumer * cou) - 1;
    console.log('cllpagecount', cllpagecount);
    console.log('visbleIndex', this.visbleIndex);
    if (this.callpageIndexFlagNextButton) {
      this.pageNumber = this.pageiIndex != undefined ? this.pageiIndex : 1;
      this.callpageIndexFlagNextButton = false
    }

    this.pageNumber++;
    const count = 12; // Number of items per page
    const totalPages = Math.ceil(this.pagecount / count);

    // Ensure the current page number does not exceed total pages
    if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }
    // Calculate the current page and start index
    const currentPage = this.pageNumber;
    const startIndex = (currentPage - 1) * count;

    console.log("pageNumber---------------------------------------->", this.pageNumber);

    this.counter = this.pageNumber;

    // Check if this page has already been loaded
    if (this.loadedPages[this.pageNumber]) {
      console.log(`Page ${this.pageNumber} is already loaded. Skipping API call.`);
      return;
    }

    // Prepare the request object with the updated page number
    let reqInputObject = { id: this.brandId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      console.log("this.userSnapShotListGarment before push", this.userSnapShotList);

      const newItems = response.object;
      this.nextPage = true;

      // Update userSnapShotListGarment with the new items at the correct start index
      for (let i = 0; i < newItems.length; i++) {
        this.userSnapShotList[startIndex + i] = newItems[i];
      }

      // Mark this page as loaded
      this.loadedPages[this.pageNumber] = true;
      this.nextPage = false;
      console.log("this.userSnapShotListGarment after push", this.userSnapShotList);
    });
  }

  previousBrandbutton() {
    let count = 12;
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }
    if (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);

      console.log('cllpagecount', cllpagecount);
      console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

      // Check if the page has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${this.callpageNumer + 1} is already loaded. Skipping API call.`);
        this.callpageNumer--;  // Decrease callpage by 1
        return;
      }

      // Prepare the request object with the updated page number
      let reqInputObject = { id: this.brandId, pageNumber: this.callpageNumer }
      this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
        console.log("this.userSnapShotListGarment before push", this.userSnapShotListGarment);
        const newItems = response.object;
        this.nextPage = true;
        // Update userSnapShotListGarment with the new items at the correct start index
        for (let i = 0; i < newItems.length; i++) {
          this.userSnapShotList[startIndex + i] = newItems[i];
        }
        // Mark this page as loaded to prevent future duplicate calls
        this.loadedPagesBackWord[cllpagecount] = true;
        this.nextPage = false;
        console.log("this.userSnapShotListGarment after push", this.userSnapShotList);
        console.log("loadedPages", this.loadedPagesBackWord);
        // Decrease callpage by 1 after the API call
        this.callpageNumer--;
      });
    }
  }

  async handleBrandPreviousPage(data) {
    // Check if data is 0 or less than 10, and exit early if so
    if (data === 0 || data < 10) {
      return;  // Exit the function if data is 0 or less than 10
    }

    console.log("index number :-", data);
    this.visbleIndex = data;
    let count = 12;

    // Initialize page number if flag is set
    if (this.callpageIndexFlag) {
      this.callpageNumer = this.pageiIndex - 1;
      this.callpageIndexFlag = false;
    }

    // Continue while the page number is valid
    while (this.callpageNumer > 0) {
      let cllpagecount = (this.callpageNumer * count) - 1;
      let startIndex = cllpagecount - (count - 1);
      console.log('cllpagecount', cllpagecount);
      console.log('startIndex', startIndex);

      // Check if the page data has already been loaded
      if (this.loadedPagesBackWord[cllpagecount]) {
        console.log(`Page ${cllpagecount} is already loaded. Skipping API call.`);
        return;  // Exit the loop once the page is loaded
      }

      // If the current page number corresponds to the desired index
      if (cllpagecount === data) {
        console.log(`Alert for index ${cllpagecount} on page ${this.callpageNumer + 1}!`);

        try {
          let reqInputObject = { id: this.brandId, pageNumber: this.callpageNumer };
          this.callpageNumer--;  // Decrease callpage by 1

          // Fetch the data for the previous page
          const response = await this.commonService.yologramSnapshotByBrands(reqInputObject).toPromise();
          const newItems = response['object'];
          this.nextPage = true;

          // Update userSnapShotList with the new items at the correct start index
          newItems.forEach((item, index) => {
            this.userSnapShotList[startIndex + index] = item;
          });

          // Mark the page as loaded
          this.loadedPagesBackWord[cllpagecount] = true;
          console.log("loadedPages", this.loadedPagesBackWord);

        } catch (error) {
          console.error("Error fetching page data:", error);
          break;  // Exit if there is an error
        }
      } else {
        break;  // Exit the loop if no match is found
      }
    }
  }




  nextByBrandCall(counts) {
    const count = 12;
    this.showBrandStoryCard = false;
    this.showGrmentStoryCard = false;

    console.log("this.userSnapShotList ", this.userSnapShotList);
    // Ensure that userSnapShotList is initialized
    if (!this.userSnapShotList) {
      this.userSnapShotList = [];
    }

    let newEntry = {
      AvatarID: "",
      BottomwearID: "",
      DateTime: { _seconds: 0, _nanoseconds: 0 },
      DisplayName: "",
      Environment: "",
      FootwearID: "",
      HeadwearID: "",
      SnapshotFileUrl: "",
      SnapshotFileUrlMicro: "",
      SnapshotFileUrlMini: "",
      StoryID: "",
      StoryType: "",
      UpperwearID: "",
      UserID: "",
      avatarName: "",
      home: "",
      id: ""
    };
    for (let i = 0; i < this.pagecount; i++) {
      this.userSnapShotList.push(newEntry); // Push will now work because userSnapShotList is initialized
    }

    const totalPages = Math.ceil(this.pagecount / count);
    const currentPage = Math.floor(this.index / count) + 1;
    const startIndex = (currentPage - 1) * count;

    console.log("totalPages", totalPages);
    console.log("this.pagecount", this.pagecount);
    console.log("currentPage", currentPage);

    let reqInputObject = { id: this.brandId, pageNumber: currentPage };

    console.log("reqInputObject", reqInputObject);

    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      const newItems = response.object;
      console.log("newItems", newItems);
      console.log("empty userSnapShotList", this.userSnapShotList);

      // Make sure to check if newItems exists and has elements
      if (newItems && newItems.length > 0) {
        for (let i = 0; i < newItems.length; i++) {
          this.userSnapShotList[startIndex + i] = newItems[i];
        }
      }
      console.log("Updated userSnapShotList", this.userSnapShotList);
      console.log("this.storyId", this.StoryId);
      this.selectedStoryId = this.StoryId;
      // this.showBrandStoryCard = true;
    });
  }


  callEvnUrl(url) {
    this.functionFlag = null;

    // Set a delay before executing the rest of the code
    setTimeout(() => {
      this.evnDefault = url;
      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.evnDefault);

      // Show an alert indicating that the environment has changed
      alert('The environment has been changed.');
    }, 100); // 100 ms delay, adjust this value as needed
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  //end brand pagination part


  getListOfselectedBrandSpaces() {
    let brand = this.brandId;
    this.isDropdownVisible = false
    this.spaceName = ''
    // Step 1: Filter the brandSpaces for the selected brand
    let bSpace = this.brandSpaces.filter((b) => b.BrandId == brand);
    // Step 2: Get the list of EnvironmentId from the filtered brand spaces
    let EnvironmentIds = bSpace.map((b) => b.EnvironmentId);
    // Step 3: Filter the evenfileList using the EnvironmentId values from bSpace
    let filteredEvenfileList = this.evnFileList.filter((e) => EnvironmentIds.includes(e.id));
    // Step 4: Log the results
    // Optionally, save this new array for future use
    this.filteredEvenfileList = filteredEvenfileList;
    console.log('this.filteredEvenfileList ', this.filteredEvenfileList)
    this.functionFlag = null
    setTimeout(() => {

      this.evnDefault = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78'
      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.selectedevenUrl);
      this.isDropdownVisible = false
    }, 100); // Adjust the delay (in milliseconds) as needed
  }

  async selectEven(even) {
    this.isLoadEnv = false

    this.functionFlag = null
    if (even == 'Spaces') {
      setTimeout(() => {
        this.spaceName = 'Spaces';  // Set spaceName to hardcoded "Spaces"
        this.evnDefault = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78'
        this.functionFlag = 'skybox';
        console.log('Delayed execution, evntempFile set:', this.selectedevenUrl);
        this.isDropdownVisible = false
        return
      }, 100); // Adjust the delay (in milliseconds) as needed
    }
    let type = 'e'
    let evnpath = even.storageURL
    this.spaceName = even.displayName
    this.functionFlag = null
    let compressUrl = await this.commonService.compressImage(evnpath, type);
    this.isDropdownVisible = false

    setTimeout(() => {
      this.evnDefault = compressUrl.downloadUrl
      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.selectedevenUrl);
    }, 0); // Adjust the delay (in milliseconds) as needed
  }

  handleSkaybox(data){
    this.isLoadEnv = false
    if(data){
      setTimeout(() => {
      this.isLoadEnv = data
    }, 1500);
    }

}


closeSearched() {
  this.selectedSnapData = false;
  setTimeout(() => {
    this.selectedSnapData = false;
  }, 400);
}

ToggleAR(){
  this.arData = "Test 123";
  this.functionFlag = "arData";
  // Use setTimeout to clear the values after 1 second (1000ms)
  setTimeout(() => {
    this.functionFlag = null;
    console.log("After clearing: ", this.arData, this.functionFlag);
  }, 500); // 1000ms = 1 second
}

}