<button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close"
  (click)="toggleChild()" style="color: white;"></button>
<span *ngIf="skyBoXall == undefined">
  <mat-dialog-content class="mat-typography">
    <h4 class="text-center"></h4>
    <model-viewer camera-controls ar auto-rotate camera-orbit="45deg 55deg 2.5m" [src]="gltfImgSrc"
      alt="A 3D avatar-model-view" data-js-focus-visible="" quick-look ar-status="not-presenting"
      skybox-image={{newCompressImages?.downloadUrl}}>
    </model-viewer>
  </mat-dialog-content>

  <a class="band" *ngIf="fileDeletFlag">
    <i class="bi bi-trash-fill class-delete" (click)=" deleteSnapshotFile(fileObject)"></i>
    <div class="justify-content-center text-center div-s-n-s p-2">
      <div class="d-flex col-12 " *ngIf="nameFlag">
        <div class="col-10 col-sm-8">
          <h6 class="p-1 text-start">{{fileObject.filename}}</h6>
        </div>
        <div class="col-2 col-sm-4 text-end">
          <i class="bi bi-pencil" (click)="selectName()"></i>
        </div>
      </div>
      <div class="d-flex justify-content-center text-center col-12" *ngIf="!nameFlag">
        <div class="col-10 col-sm-8">
          <input type="text" class="form-control text-start transparent-input text-black" placeholder="Enter File name"
            (change)="editFileName($event)" [value]="fileObject.filename">
        </div>
        <div class="col-2 col-sm-4 text-end">
          <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"
            (click)="updateFileName(fileObject)">Save</button>
        </div>
      </div>
    </div>
  </a>
  <mat-dialog-actions align="end">
    <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
  </mat-dialog-actions>
</span>


<div *ngIf="!userSnapShotListSlice" class="text-center">
  <div class="spinner-border m-5" role="status">
    <span class="sr-only"></span>
  </div>
</div>
<div *ngIf="skyBoXall != undefined && userSnapShotListSlice">
  <div *ngIf="snaploader" class="text-center">
    <div class="spinner-border m-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- <div class="col-12 justify-content-center">
    <img *ngIf="hideFlag != undefined"  (click)="getEnvironmentProfile()" class="shadow-1-strong rounded mb-1 image-popup"
      [src]="newCompressImages.downloadUrl != undefined ? newCompressImages.downloadUrl :  this.skyBoXall">
    <h2 class="evn-name"(click)="getEnvironmentProfile()" data-bs-dismiss="modal">
      {{this.evnData?.displayName ? this.evnData?.displayName :  evsdiplaynameALL}}</h2>
  </div> -->

  <div class="col-12 justify-content-center position-relative">
    <!-- 
  <div *ngIf="this.pageFlagRout == 'spaces'" class="toggle-container" (click)="toggleQuality($event)">
    <span class="toggle-option">{{ selectedQuality }}</span>
  </div> -->

    <div class="col-12 brand-tag">
      <h6 style="margin: 5px;" class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <img style="width: 25px; height: 25px;" src="./assets/search/spaces.png" alt="Image"
            (click)="getEnvironmentProfile()">
          <span (click)="getEnvironmentProfile()"
            class="m-1">{{ this.evnData?.displayName ? this.evnData?.displayName : evsdiplaynameALL }}</span>
        </div>
        <img *ngIf="showCollapsImage" (click)="collapsImageAndViewer($event)" style="width: 25px; height: 25px;"
          src="./assets/up-down-button/up.png" alt="Image">
        <img *ngIf="!showCollapsImage" (click)="collapsImageAndViewer($event)" style="width: 25px; height: 25px;"
          src="./assets/up-down-button/down.png" alt="Image">
      </h6>
    </div>


    <div class="cropped-image-container" *ngIf="showCollapsImage">
      <img (click)="sliderSLIDEbutton($event)"
        [src]="newCompressImages?.downloadUrl != undefined ? newCompressImages?.downloadUrl : downloadedUrl || this.skyBoXall"
        alt="Cropped Image" class="cropped-image">
    </div>

    <!-- <img 
    *ngIf="hideFlag != undefined"  
    (click)="getEnvironmentProfile()" 
    class="shadow-1-strong rounded mb-1 image-popup"
    [src]="newCompressImages?.downloadUrl != undefined ? newCompressImages?.downloadUrl :  this.skyBoXall"> -->

    <h2 class="evn-name" *ngIf="showCollapsImage" (click)="getEnvironmentProfile()" data-bs-dismiss="modal">
      {{ this.evnData?.displayName ? this.evnData?.displayName : evsdiplaynameALL }}
    </h2>
    <!-- <i 
      *ngIf="isIconRight && this.pageFlagRout == 'spaces'" (click)="sliderSLIDEbutton($event)"
      class="bi bi-chevron-right chevron-icon">
    </i>
    <i 
      *ngIf="!isIconRight && this.pageFlagRout == 'spaces'" (click)="sliderSLIDEbutton($event)"
      class="bi bi-chevron-left chevron-icon">
    </i> -->
  </div>




  <div *ngIf="this.snaploaderSnap" class="text-center">
    <div class="spinner-border m-4 p-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- <model-viewer [ngClass]="{'d-none': showNonSelectedBg }" *ngIf="!snaploader2D && showCollapsImage" id="poster"
    camera-controls touch-action="pan-y" data-js-focus-visible="false"
    [attr.skybox-image]="newCompressImages?.downloadUrl  || downloadedUrl || this.skyBoXall"
    alt="A 3D model of a sphere reflecting a sunrise" [attr.src]="gltfImgSrc" (environmentchange)="onSkyboxLoad()">
  </model-viewer> -->

  <div *ngIf="snaploader" class="text-center">
    <div class="spinner-border m-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="static-band">
    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
      <div class="brandbtn" (click)="selectTab('Story')">
        <h6 class="m-1" [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
          <a>
            <img *ngIf="secteTab  == 'Story'" class="story-icon" src="  ./assets/storystyle/story-white.png">
            <img *ngIf="secteTab  != 'Story'" style="width:15px !important" src=" ./assets/storystyle/storyblack.png">
          </a>

        </h6>
        <h6 *ngIf="secteTab  == 'Story'" class="ms-2 font-class">Story</h6>
      </div>
      <div class="brandbtn" (click)="selectTab('Brands')">
        <h6 class="m-1 p-0" [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
          <a>
            <img *ngIf="secteTab  == 'Brands'" style="width:15px !important" src="./assets/brand_white.png">
            <img *ngIf="secteTab  != 'Brands'" style="width:15px !important" src="./assets/brand_black.png">

          </a>
        </h6>
        <h6 *ngIf="secteTab  == 'Brands'" class="font-class">Brands </h6>
      </div>
      <div class="brandbtn" (click)="selectTab('Style')">
        <h6 class="m-1 p-0 " [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
          <a>
            <img *ngIf="secteTab  == 'Style'" style="width:15px !important" src="./assets/search/avatarwhite.png">
            <img *ngIf="secteTab  != 'Style'" style="width:15px !important" src="./assets/search/avatar.png">
          </a>
        </h6>
        <h6 *ngIf="secteTab  == 'Style'" class="font-class">Avatar</h6>
      </div>
    </div>
  </div>

  <!-- <button *ngIf="secteTab  == 'Brands' ||  secteTab  == 'Style'" class="transparent-button rounded-plus-button"
    style="margin-right: -5px !important;" (click)="handlePlusClick()">
    view all 
  </button> -->

  <div *ngIf="!showBrandslider &&  !selectedBrand && secteTab  == 'Brands'" class="brand-tag ">
    <h6 style="margin: 5px;" class="d-flex text-center justify-content-center "  (click)="handlePlusClick()">
      <!-- <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image"> -->
      <img  style="width:25px !important" src="./assets/brand_black.png">
      <h6 style="margin: 3px;" > All Brands </h6>
    </h6>
    <button class="brand-remove" (click)="handlePlusClick()">
      X
    </button>
  </div>

  <div *ngIf="!showBrandAvatarslider && !selectednewAvatar && secteTab == 'Style'" class="brand-tag ">
    <h6 style="margin: 5px;" class="d-flex text-center justify-content-center "  (click)="handleAvatrSliderClick()">
      <img  style="width:25px !important" src="./assets/search/avatar.png">

      <!-- <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image"> -->
      <h6 style="margin: 3px;" > All Avatar </h6>
    </h6>
    <button class="brand-remove" (click)="handleAvatrSliderClick()">
      X
    </button>
  </div>

  <div *ngIf="!showBrandslider &&  selectedBrand && secteTab  == 'Brands'" class="brand-tag ">
    <h6 style="margin: 5px;" class="d-flex">
      <img style="width: 25px; height: 25px;" [src]="getBrandImageByLogo(selectedBrand?.LogoURL) " alt="Image">
      <h6 style="margin: 3px;">{{ selectedBrand.BrandName }}</h6>
    </h6>
    <button class="brand-remove" (click)="handlePlusClick()">
      X
    </button>
  </div>


  <!-- <button *ngIf="secteTab  == 'Style'" class="transparent-button rounded-plus-button"
    style="margin-right: -5px !important;">
    +
  </button> -->
  <div *ngIf="!showBrandAvatarslider && selectednewAvatar && selectednewAvatar?.details && secteTab  == 'Style'" class="brand-tag ">
    <h6 style="margin: 5px;" class="d-flex">
      <img style="width: 25px; height: 25px;" [src]="selectednewAvatar?.details" alt="Image">
      <h6 style="margin: 3px;">{{ selectednewAvatar?.avatarName }}</h6>
    </h6>
    <button class="brand-remove" (click)="handleAvatrSliderClick()">
      X
    </button>
  </div>

  <div class="div-center class-desktop" *ngIf="!snaploader && hideFlag != undefined">
    <h6 *ngIf="pageiIndex" (click)="previousbutton($event)"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
    <div class="row w-100 image-margin" [ngClass]="{
      'class1': showCollapsImage,
      'class2': !showCollapsImage && userSnapShotListSlice?.length > 8,
      'class3': showCollapsImage && userSnapShotListSlice?.length > 8 && SpacePanelDefault
    }">

      <!-- [ngStyle]="{ 'height.px': showCollapsImage ? 280 : (!showCollapsImage && userSnapShotListSlice?.length > 8   ? 540 : 280) 

    }" -->

      <!-- Change "col-4" to "col-6" to make two images per row -->
      <div class="col-6 p-0" *ngFor="let snapshot of userSnapShotListSlice; let i=index;"
        [ngClass]="{'d-none': !snapshot?.SnapshotFileUrl || snapshot?.SnapshotFileUrl == ''}">
        <div class="d-flex align-items-center justify-content-center">
          <img class="shadow-1-strong rounded m-1 story-img" (click)="getEnvironmentProfileFeed(snapshot,i)"
            [src]="snapshot?.SnapshotFileUrlMini || snapshot?.SnapshotFileUrl">
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="col-12 text-center text-black justify-content-center">
    <h6 (click)="incrementImgCounter($event)">
      <b>More</b>
    </h6>
  </div>
</div>