import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { PopupThreeDComponent } from '../../popup-three-d/popup-three-d.component';
import { HttpClient } from '@angular/common/http';
import { TextureLoader } from 'three';

@Component({
  selector: 'app-environment-panel',
  templateUrl: './environment-panel.component.html',
  styleUrls: ['./environment-panel.component.css']
})

export class EnvironmentPanelComponent {
  // @Input() gltfImgSrc: string | undefined;
  @Input() skyBoXall: string | undefined;
  @Input() twoDprivew: string | undefined;
  @Input() evsname: string | undefined;
  @Input() evsdiplaynameALL: string | undefined;
  @Input() environmentFileName: string | undefined;
  @Input() hideFlag: boolean | undefined;
  @Input() fileDeletFlag: boolean | undefined;
  @Input() fileObject: any = {}
  @Input() routeFlageEven: string | undefined;
  @Input() brandaStoryScrollerHeight: any;
  @Input() environmentData: any;
  @Input() evndata: any;
  @Input() pageiIndex: any;
  @Input() callpageNumer: any;
  @Input() brandobject: any;

  @Input() selectedBrand: any;
  @Input() spaceName_Page: any;
  @Input() selectednewAvatar: any;

  @Input() selectedEnv: any;


  






  @Input() SpacePanelDefault: boolean | undefined;
  @Input() showNonSelectedBg: boolean | undefined;

  @Input() showBrandslider: boolean | undefined;

  @Input() showBrandAvatarslider: boolean | undefined;










  @Input() evnProfilePageFlag: boolean | undefined;
  isIconRight: boolean = false; // Initially set to show the "right" icon.


  @Output() SnapStoryId: EventEmitter<any> = new EventEmitter();
  @Output() evnCompressUrl: EventEmitter<any> = new EventEmitter();
  @Output() emitSlectedSnapList: EventEmitter<any> = new EventEmitter();
  @Output() emitSliderArrow: EventEmitter<any> = new EventEmitter();
  @Output() emitHDtoggle: EventEmitter<any> = new EventEmitter();

  @Output() modelViewerData: EventEmitter<any> = new EventEmitter();
  @Output() setTabEmit: EventEmitter<any> = new EventEmitter();
  @Output() emitBrandList: EventEmitter<any> = new EventEmitter();
  @Output() showbrandsliderEmit: EventEmitter<any> = new EventEmitter();
  @Output() emitAvatarNmaeList: EventEmitter<any> = new EventEmitter();

  @Output() showAvatarsliderEmit: EventEmitter<any> = new EventEmitter();
  @Output() setEslectedEvn: EventEmitter<any> = new EventEmitter();


  




















  @Output() closeEvenEmitter = new EventEmitter<any>();
  @Output() previousEvenEmitter = new EventEmitter<any>();



  gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';






  srcfile = './assets/scene.gltf'
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  SnapShotFileDetails: any = []
  evnFileListFileName: any = []
  evnFileList: any = []
  filterEnvironmentSnpShot: any = []
  snapshot: any;
  userSnapShotList: any = []
  userSnapShotListSlice: any = []
  snaploader2D: boolean = false
  snaploader: boolean = false
  nameFlag: boolean = true;
  fileName: any;
  pageNumber: number = 1;
  newCompressImages: any = {};
  pagecount: number = 1;
  showChild: boolean;
  @Input() closebuttonFlag: boolean;

  @Input() _notSame_Environment: boolean;



  evnData: any;
  snaploaderSnap: boolean = false;
  evnUrl: any;
  downloadedUrl: string;
  selectedQuality: string = 'SD'; // Initial value
  hdImageUrl: string = 'path-to-hd-image.jpg';
  sdImageUrl: string = 'path-to-sd-image.jpg';
  pageFlagRout: any;
  spaceLoaderInsideModelviewer: boolean = true;
  showCollapsImage: boolean = false;
  previousUrl: any
  private isSnapShotSaved: boolean = false; // Add a flag to track if the value is already saved
  cachedownloadedUrl: string;
  secteTab: any = 'Story';
  snapshots: any;
  garmentClothList: any[];
  brandList: Brand[];
  cacheSnap: any = [];
  cacheFlage: boolean = true;
  loadsnap: boolean = true;


  // gltfImgSrc  :any = "https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945"

  constructor(public dialog: MatDialog,

    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    private firestore: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    private modal: NgbModal,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private scrollService: CommonService, private activatedRoute: ActivatedRoute) {
    this.checkCurrentRoute()

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
        let envFileName = this.evsname
        let evenListName = "Environments/"
        this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + envFileName)
      });

    this.garmentsList.subscribe(
      (garment) => {
        this.garmentClothList = garment
      });
    this.brandsFilesList.subscribe(
      (brand) => {
        this.brandList = brand
      });

    this.environmentCollection.subscribe(evenList => {
      this.evnFileList = evenList
      this.evnFileList.forEach(async (even) => {
        let type = 'e';
        let url = await this.commonService.compressImage(even.storageURL, type); // Assuming each brand has 'imagePath' property
        even.evnCompressUrl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
    });


  }






  ngOnInit(): void {
    this.snaploaderSnap = true;
    this.printInputsToConsole()


    if (this.pageFlagRout == 'spaces') {
      this.showCollapsImage = false
    }

    // setInterval(() => {
    //   this.snaploader2D = false;
    // }, 2000);




    setTimeout(() => {
      // this.snaploader = false;
      this.snaploaderSnap = false

    }, 3000);

    // let reqInputObject = { id: this.evsname, pageNumber: 1 };
    // console.log("reqInputObject", reqInputObject)
    // this.commonService
    //   .yologramSnapshotEnvironment(reqInputObject)
    //   .subscribe(async (evn: any) => {
    //     this.userSnapShotListSlice = evn.object;
    //     console.log("userSnapShotListSlice", this.userSnapShotListSlice)
    //     this.pagecount = evn.count
    //   });
    // this.getCompressImage()
  }
  scrollToId(id: string) {
    if (id != '') this.scrollService.scrollToElementById(id);
  }

  async environmentPanelDataFunction(storageUrl) {
    let functionUrl = " https://us-central1-yolomoves-fb435.cloudfunctions.net/observeEnvironmentsByStorageURL";
    return this.http.post<any>(functionUrl, { storageURL: storageUrl }).toPromise();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.brandaStoryScrollerHeight = innerHeight - 300

    if (changes['spaceName_Page'] && this.spaceName_Page) {
      this.loadFromSlider(this.spaceName_Page)
      console.log("load 3")

    }




    console.log("this._notSame_Environment", this._notSame_Environment)








    if (changes['selectednewAvatar'] && this.selectednewAvatar) {
      const AvatrID = this.selectednewAvatar.AvatarID; // Example BrandID
      const snapshotsForAvatar = this.getSnapshotsByAvatarID(AvatrID);
      this.userSnapShotListSlice = snapshotsForAvatar
      console.log("snapshotsForAvatar", snapshotsForAvatar)
      this.selectTab(this.secteTab)
    }

    if (changes['brandobject'] && this.brandobject) {
      console.log("brandobject", this.brandobject)
      const brandID = this.brandobject.id; // Example BrandID
      const snapshotsForBrand = this.getSnapshotsByBrandID(brandID);
      this.userSnapShotListSlice = snapshotsForBrand
      console.log('Snapshots for BrandID:', brandID, snapshotsForBrand);
      this.selectTab(this.secteTab)
    }




    if (changes['showNonSelectedBg']) {
      this.showCollapsImage = this.showNonSelectedBg
      console.log("load 250")
    }

    if (changes['environmentData']?.currentValue !== undefined && this.environmentData) {
      if (this.SpacePanelDefault) {
        // console.log("load 1", this.environmentData);
        // if (this.isSnapShotSaved) {
        //   console.log("Snapshot already saved, skipping further assignment.");
        //   return;
        // }
        // this.userSnapShotListSlice = this.environmentData.snapData?.object || [];
        // console.log(this.environmentData);

        // // Mark as saved to prevent further updates
        // this.isSnapShotSaved = true;
        return;
      }

      this.loadData();
      console.log("load 1");
    }


    if (changes['evndata']?.currentValue?.evenWholeData !== undefined && this.evndata) {
      this.environmentData = this.evndata.evenWholeData;
      this.loadData();
      console.log("load 2")
    }

    if (changes['pageiIndex'] && this.pageiIndex) {
      console.log("load pageiIndex", this.pageiIndex)
    }


    if (changes['selectedEnv'] && this.selectedEnv) {
      console.log("selectedEnv", this.selectedEnv)
      console.log("fileObject", this.evnData)
      let storageURL = this.evnData?.displayName
      storageURL = storageURL.replace("Environments/", "");
      if(storageURL != this.selectedEnv){
        setTimeout(() => {
          this.selectTab('Story')
        }, 500);
      }
    }

    
  }

  loadFirstTime() {
    let num = this.pageiIndex
    this.pageNumber = this.pageiIndex
    let reqInputObject = { id: this.evsname, pageNumber: num };
    console.log("reqInputObject", reqInputObject)
    this.commonService
      .yologramSnapshotEnvironment(reqInputObject)
      .subscribe(async (evn: any) => {
        this.userSnapShotListSlice = evn.object;
        console.log("userSnapShotListSlice", this.userSnapShotListSlice)
        this.pagecount = evn.count
      });
    //  this.emitSlectedSnapList.emit(this.userSnapShotListSlice || []);
  }

  // async loadBrandData() {
  //   let storageUrl = this.evsname
  //   let data = await this.environmentPanelDataFunction(storageUrl);
  //   console.log(data,storageUrl)
  //   // let image = await this.commonService.compressImage(this.evnData.skybox);
  //   this.newCompressImages = this.evnData.skybox;
  //   // this.getCompressImage()

  // if(this.newCompressImages){
  //   this.evnData = data.documents[0]
  //   this.userSnapShotListSlice = data.snapData.object
  //   this.snaploader2D = false;
  //   this.snaploader = false;
  // }
  // let image = await this.commonService.compressImage(this.evnData.skybox);
  // console.log("image", image);
  // }



  async loadData() {
    try {
      if (this.environmentData && this.environmentData.documents?.length > 0) {
        this.evnData = this.environmentData.documents[0]; // First document
        this.userSnapShotListSlice = this.environmentData.snapData?.object || [];

        if (this.userSnapShotListSlice.length > 0) {
          let evnFileName = this.userSnapShotListSlice[0]?.Environment;
          if (evnFileName) {
            let evnpath = 'Environment/' + evnFileName;
            let type = 'e';
            this.newCompressImages = await this.commonService.compressImage(evnpath, type);
          }
        }
        this.snaploaderSnap = false;
      } else {
        this.snaploaderSnap = true;
        let storageUrl = this.evsname == "AdobeStock_209580496.jpeg" ? "default" : this.evsname
        let data = await this.environmentPanelDataFunction(storageUrl);

        if (data.documents?.length > 0) {
          this.evnData = data.documents[0];
          this.userSnapShotListSlice = data.snapData?.object || [];
          if (this.userSnapShotListSlice.length > 0) {
            let evnFileName = this.userSnapShotListSlice[0]?.Environment;
            if (evnFileName) {
              let evnpath = 'Environment/' + evnFileName;
              let type = 'e';
              this.newCompressImages = await this.commonService.compressImage(evnpath, type);
            }
          }
          this.snaploaderSnap = false;
        } else {
          console.warn('No documents found.');
        }
      }
    } catch (error) {
      console.error('Error loading brand data:', error);

      // Handle error gracefully
      let evnFileName = this.environmentData?.snapData?.object?.[0]?.Environment;
      if (evnFileName) {
        let evnpath = 'Environment/' + evnFileName;
        let type = 'e';
        this.downloadedUrl = await this.commonService.compressImage(evnpath, type);
      }
      this.snaploaderSnap = false;
    }

    // Ensure loader is hidden after timeout
    setTimeout(() => {
      this.snaploaderSnap = false;
    }, 500);

    // Emit selected snapshot list
    this.emitSlectedSnapList.emit(this.userSnapShotListSlice || []);
  }



  async getCompressImage() {
    // this.snaploader2D = true;
    // this.snaploader = true;
    let evnFileName = this.environmentData.snapData.object[0].Environment
    let evnpath = 'Environment/' + evnFileName
    let type = 'e'

    let image = await this.commonService.compressImage(evnpath, type);
    this.newCompressImages = image;
    this.snaploader2D = false;
    this.snaploader = false;


  }

  getEnvironmentProfile() {
    this.evnUrl = '';

    if (this.evnProfilePageFlag) {
      this.evnUrl = this.newCompressImages.downloadUrl;
      this.evnCompressUrl.emit(this.evnUrl);
      return;
    }

    this.previousUrl = this.router.url;

    // Check if the current URL contains '/drip'

    let envFileName = this.evsname;
    let evenListName = "Environments/";
    let obj = this.evnData['storageURL'];

    // Remove the "Environments/" part
    let fileName = obj.replace("Environments/", "");
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL === this.evnData['storageURL']);
    let url = this.newCompressImages?.downloadUrl || this.downloadedUrl || this.skyBoXall;
    this.evnCompressUrl.emit(url);
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    console.log("previousUrl", this.previousUrl)

    // if (this.previousUrl.includes('/drip')) {
    //   // this.router.navigate(['/drip']);
    //   return;
    // }

    // Navigate to '/drip' with parameters
    // this.router.navigate(['/drip', {
    //   'evnFileName': fileName,
    //   'evnFile': this.evnFileListFileName.displayName,
    //   // 'evnFileImage': '', 
    //   // 'envName': fileName, 
    //   'storyFeed': 'story'
    // }]);

    const queryParams = {
      storyId: undefined,
      evnFileName: fileName,
      envName: fileName,
      evnFile: this.evnFileListFileName?.displayName,
      evnFileImage: '',
      storyFeed: 'story',
    };

    if (this.previousUrl.includes('/drip')) {
      // Update the query string without reloading the route
      this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
      return;
    }

    // Navigate to /drip if not already there
    this.router.navigate(['/drip'], { queryParams });
  }


  // getEnvironmentProfileFeed(snapShot, index) {
  //   let selectedStoryId = snapShot.StoryID
  //   this.SnapStoryId.emit(selectedStoryId)
  //   // if(this.evnProfilePageFlag){
  //     this.previousUrl = this.router.url;

  //   let evnName = snapShot.Environment
  //   let envFileName = this.evsname
  //   let obj = this.evnData['storageURL'];
  //   let storyId = snapShot.StoryID
  //   let count = 12
  //   const currentPage = Math.floor(index / count) + 1;
  //   let evenListName = "Environments/"
  //   let fileName = obj.replace("Environments/", "");

  //   this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == obj)
  //   let reqInputObject = { id: envFileName, pageNumber: 1 }
  //   this.dataSharingService.sendUserSnapShots(this.userSnapShotList)

  //   if(!this.SpacePanelDefault){
  //     return
  //   }
  //   if (this.previousUrl.includes('/drip')) {
  //     this.router.navigate(['/drip']);
  //     return;
  //   }

  //   this.router.navigate(['/drip', { 'storyId': snapShot.StoryID, 'evnFileName': fileName, 'envName': fileName, 'evnFile': this.evnFileListFileName?.displayName, 'evnFileImage': '', 'storyFeed': 'storyFeed', 'index': index, 'pageNumber': currentPage }])

  // }
  getEnvironmentProfileFeed(snapShot, index) {
    let selectedStoryId = snapShot.StoryID;
    this.SnapStoryId.emit(selectedStoryId);

    this.previousUrl = this.router.url;

    let evnName = snapShot.Environment;
    let envFileName = this.evsname;
    let obj = this.evnData['storageURL'];
    let storyId = snapShot.StoryID;
    let count = 12;
    const currentPage = Math.floor(index / count) + 1;
    let evenListName = "Environments/";
    let fileName = obj.replace("Environments/", "");

    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL === obj);
    let reqInputObject = { id: envFileName, pageNumber: 1 };
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);

    if (!this.SpacePanelDefault) {
      return;
    }

    const queryParams = {
      storyId: snapShot.StoryID,
      evnFileName: fileName,
      envName: fileName,
      evnFile: this.evnFileListFileName?.displayName,
      evnFileImage: '',
      storyFeed: 'storyFeed',
      index: index,
      pageNumber: currentPage,
    };

    if (this.previousUrl.includes('/drip')) {
      // Update the query string without reloading the route
      this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
      return;
    }

    // Navigate to /drip if not already there
    this.router.navigate(['/drip'], { queryParams });
  }




  editFileName($event) {
    this.fileName = $event.target.value
  }
  updateFileName(data) {
    this.nameFlag = true
    let object = {
      'filename': this.fileName
    }
    this.firestore.collection('userfiles').doc(data["id"]).update(object);
  }

  selectName() {
    this.nameFlag = false
  }

  deleteSnapshotFile(data: any) {
    if (this.fileDeletFlag) {
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = data.filepath
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    } else {
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = data.filepath
        // let filepath = "Uploads/" + data.userId +"/"+ data.filename
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    }
  }

  incrementImgCounter(event) {
    event.stopPropagation()
    this.pageNumber++
    let findspace = this.userSnapShotListSlice[0]
    let evnNameFileName = this.environmentData.snapData.object[0].Environment
    let reqInputObject = { id: findspace.Environment || evnNameFileName, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotListSlice.push(...response.object)
      this.emitSlectedSnapList.emit(this.userSnapShotListSlice || []);
    })
  }

  toggleChild() {
    this.showChild = !this.showChild;
    this.closeEvenEmitter.emit(this.showChild)
  }

  previousbutton(event) {
    event.stopPropagation()
    this.previousEvenEmitter.emit(true)
  }

  printInputsToConsole(): void {

  }

  toggleQuality(event): void {
    event.stopPropagation();
    this.selectedQuality = this.selectedQuality === 'SD' ? 'HD' : 'SD';
    this.emitHDtoggle.emit(this.selectedQuality)

  }

  sliderSLIDEbutton(event): void {
    event.stopPropagation();
    this.isIconRight = !this.isIconRight; // Toggle the flag between true and false.
    console.log(`Icon toggled: ${this.isIconRight ? 'Right' : 'Left'}`);
    this.emitSliderArrow.emit(this.isIconRight)
    if (this.pageFlagRout == 'drips') {
      this.getEnvironmentProfile()
    }
    const data = {
      showNonSelectedBg: this.showNonSelectedBg,
      snaploader2D: this.snaploader2D,
      showCollapsImage: this.showCollapsImage,
      skyboxImage: this.newCompressImages?.downloadUrl || this.downloadedUrl || this.skyBoXall,
      gltfImgSrc: this.gltfImgSrc,
    };

    this.modelViewerData.emit(data);
  }

  async loadFromSlider(url: string) {
    // Check if the URL is repeated
    if (this.cachedownloadedUrl === url) {
      console.log("Duplicate URL. No action taken.");
      return; // Exit the function early if the URL is repeated
    }
    this.cachedownloadedUrl = url
    // Reset state variables
    this.userSnapShotListSlice = [];
    this.newCompressImages = null;
    this.downloadedUrl = '';
    this.skyBoXall = '';
    this.showCollapsImage = false;
    this.snaploaderSnap = true;

    let storageUrl = url; // Assuming this.evsname is correctly set
    let data = await this.environmentPanelDataFunction(storageUrl);

    console.log("storageUrl", storageUrl);

    if (data.documents.length > 0) {
      this.evnData = data.documents[0]; // Assuming you want the first document
      let evnpath = 'Environment/' + storageUrl;
      let type = 'e';
      this.userSnapShotListSlice = data.snapData.object;

      this.snaploaderSnap = false;

      this.newCompressImages = await this.commonService.compressImage(evnpath, type);
      if (storageUrl === 'default') {
        let ekey = 'AdobeStock_209580496.jpeg';
        const evnEncodedurl = encodeURI("Environments/" + ekey).replace('/', '%2F');
        this.downloadedUrl = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + evnEncodedurl + '?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf';
      }

      this.emitSlectedSnapList.emit(this.userSnapShotListSlice);
    } else {
      console.warn('No documents found.');
    }
  }

  checkCurrentRoute(): void {
    this.activatedRoute.url.subscribe(url => {
      this.pageFlagRout = url[0].path

      // Check if the URL matches your condition
    });




  }

  onSkyboxLoad() {
    this.snaploader2D = false
    this.spaceLoaderInsideModelviewer = false; // Stop loader once the image is loaded
    console.log("this.spaceLoaderInsideModelviewer", this.spaceLoaderInsideModelviewer)
  }

  collapsImageAndViewer(event) {
    event.stopPropagation()
    this.showCollapsImage = !this.showCollapsImage
  }

  selectTab(tab) {

    this.loadsnap = false
    this.secteTab = tab
    let findsnap = this.userSnapShotListSlice[0]
    let findenv

    if (tab == 'Story') {
      this.cacheSnap = this.userSnapShotListSlice
      this.cacheFlage = false
      console.log('this.cacheSnap', this.cacheSnap)
    }

    if (tab == 'Story') {
      this.loadData();
      this.loadsnap = true
    }

    console.log('userSnapShotListSlice', this.userSnapShotListSlice)


    // this.userSnapShotListSlice = []
    this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
      if (snapshots?.object && snapshots?.object.length > 0) {
        this.snapshots = snapshots?.object
        findenv = this.snapshots.filter((snap) => snap.Environment == findsnap.Environment)
        console.log('findenv', findenv)
        const wearIDs = findenv.map((snap) => ({
          BottomwearID: snap.BottomwearID,
          FootwearID: snap.FootwearID,
          HeadwearID: snap.HeadwearID,
          UpperwearID: snap.UpperwearID,
        }));

        console.log('Extracted Wear IDs:', wearIDs);
        const matchingBrandIDs = wearIDs.map((wearID) => {
          const brandMatches = [];

          for (const [key, value] of Object.entries(wearID)) {
            if (value) {
              // Find matching garment in garmentClothList
              const matchingGarment = this.garmentClothList.find(
                (garment) => garment.id === value
              );
              if (matchingGarment) {
                brandMatches[key] = matchingGarment.BrandID;
              }
            }
          }
          return brandMatches;
        });

        const brandIDs = [
          ...new Set(
            matchingBrandIDs.flatMap((match) =>
              Object.values(match).filter((id) => id) // Flatten all BrandIDs and remove undefined/null values
            )
          )
        ];

        // Filter the brandList based on unique BrandIDs
        const brandfilterList = this.brandList.filter((b) => brandIDs.includes(b.id));
        this.emitBrandList.emit(brandfilterList)


        const processedAvatarIDs = new Set();

        const avatarDetailsList = await Promise.all(
          findenv.map(async (snapshot) => {
            if (!processedAvatarIDs.has(snapshot.AvatarID)) {
              // Add the AvatarID to the Set
              processedAvatarIDs.add(snapshot.AvatarID);

              // Fetch avatar details
              await this.getAvatarDetails(snapshot);
            }
            return snapshot; // Return the snapshot, even if already processed
          })
        );

        console.log('Avatar Details List:', avatarDetailsList);
        console.log('Unique Brand IDs:', brandIDs);
        console.log('Filtered Brand List:', brandfilterList);

        this.emitAvatarNmaeList.emit(avatarDetailsList)

        // let storageUrl = this.evsname == "AdobeStock_209580496.jpeg" ? "default" : this.evsname
        // let data = await this.environmentPanelDataFunction(storageUrl);



        if (tab == 'Brands') {
          const brandID = this.brandobject.id; // Example BrandID
          const snapshotsForBrand = await this.getSnapshotsByBrandID(brandID);
          this.userSnapShotListSlice = snapshotsForBrand
          this.loadsnap = true
          console.log("snapshotsForBrand", snapshotsForBrand)
        }


        if (tab == 'Style') {
          const AvatarID = this.selectednewAvatar.AvatarID; // Example BrandID
          const snapshotsForStyle = await this.getSnapshotsByAvatarID(AvatarID);
          this.userSnapShotListSlice = snapshotsForStyle
          this.loadsnap = true
          console.log("snapshotsForStyle", snapshotsForStyle)

        }

      }
    })

    this.setEslectedEvn.emit(this.userSnapShotListSlice[0].Environment)
    console.log(this.userSnapShotListSlice[0].Environment)

    this.setTabEmit.emit(tab)
  }


  handlePlusClick() {
    let data = true
    this.showbrandsliderEmit.emit(data)
  }

  handleAvatrSliderClick() {
    let data = true
    this.showAvatarsliderEmit.emit(data)
  }

  getBrandImageByLogo(logourl: string): string {
    const imgURL = encodeURI(logourl).replace('/', '%2F');
    // const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed');
    const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${newPath}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  async getAvatarDetails(snapShot) {
    try {
      const documents = await this.commonService.getUserAvatarDetails(snapShot.UserID, snapShot.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        // Update the properties of snapShot directly
        snapShot.avatarName = firstDocument.AvatarName || snapShot.AvatarID; // Use firstDocument.avatarName or fallback
        snapShot.details = firstDocument.AvatarPreviewPortraitUrl || null; // Add other fields as needed

      } else {
        snapShot.avatarName = snapShot.AvatarID; // Fallback to AvatarID if no document is found
      }
    } catch (error) {
      console.error('Error in getAvatarDetails:', error);
      throw error; // Re-throw error for further handling
    }
  }

  getSnapshotsByBrandID(brandID: string): any[] {
    let findsnap = this.userSnapShotListSlice[0]
    let findenv = this.snapshots.filter((snap) => snap.Environment == findsnap.Environment)
    const matchingSnapshots = findenv.filter((snapshot) => {
      // Extract wear IDs from the snapshot
      const wearIDs = {
        BottomwearID: snapshot.BottomwearID,
        FootwearID: snapshot.FootwearID,
        HeadwearID: snapshot.HeadwearID,
        UpperwearID: snapshot.UpperwearID,
      };
      // Check if any wear ID matches the BrandID in garmentClothList
      return Object.values(wearIDs).some((wearID) => {
        if (!wearID) return false; // Skip undefined or empty wearIDs
        const matchingGarment = this.garmentClothList.find(
          (garment) => garment.id === wearID && garment.BrandID === brandID
        );
        return !!matchingGarment; // Return true if a matching garment is found
      });
    });
    return matchingSnapshots;
  }

  getSnapshotsByAvatarID(avatarID: string): any[] {
    // Filter snapshots based on the given AvatarID
    const findsnap = this.userSnapShotListSlice[0];
    const findenv = this.snapshots.filter(
      (snap) => snap.Environment === findsnap.Environment
    );

    const matchingSnapshots = findenv.filter((snapshot) => {
      return snapshot.AvatarID === avatarID;
    });

    return matchingSnapshots;
  }


}

