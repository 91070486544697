

<div *ngIf="garmentFlagDummy == undefined">
    <div class="bg-white square-bracate">
    </div>
    <div #swipeUp class="row text-center justify-content-center garmentSawap" (scroll)="scrolltopSide()">
        <div class="garment-card">
            <div class="row text-center m-1 p-1 justify-content-center">
                <div class="col-12 details">
                    <div *ngIf="!garmentFlag" class=" text-center w-100  d-flex garmentname"  [ngStyle]="{ background: getgarmentnameBackground()}">
                        <div class="d-flex  col-2">
                            <img *ngIf="brandObject?.LogoURL != undefined" 
                                class="rounded profileImg-brand"
                                (click)="getBrandProfileByGarment(brandObject,brandObject)"
                                [src]="getBrandImage(brandObject.LogoURL)"
                                style="width:35px !important; height:35px !important;">
                        </div>
                        <div class="col-8 brand-font-size text-white">
                            <b (click)="getBrandProfileByGarment(brandObject,brandObject)"
                               >{{brandObject?.BrandName}}</b>
                            <b>
                                <h6 class="color-gender text-white">
                                    {{clothDetails.Gender == 'F' ? 'Womenswear' :
                                    (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}
                                </h6>
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <span><img *ngIf="heartFlag == true" class="heart"
                                    style="width:18px !important; color:black"
                                    src="./assets/storystyle/newStoryIcon/heart.png"></span>
                            <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                    style="width:18px !important; color:black"
                                    src="./assets/storystyle/newStoryIcon/heartGray.png">
                            </span>
                        </div>
                    </div>
                    <div *ngIf="garmentFlag" class="text-center">
                        <div class="spinner-border m-5 " role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div class="mb-2 text-black clothName  color-class-band brand-font-size"  [ngStyle]="{ background: clothName_background()}" >
                        <div class="d-flex garment-title" [ngStyle]="{ background: getgarmentname_title()}">
                            <div class="text-center d-inline-flex garmen-name-in-band">
                                <div class="" (click)="getClothProfile()">
                                    <span *ngIf="clothDetails?.RealType == '0'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/headgear.png"></span>
                                    <span *ngIf="clothDetails?.RealType == '1'" class="brandbtn py-1 "><img style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6;
                                                margin-right: 4px;" src="./assets/realtype/upperwear.png"></span><span
                                        *ngIf="clothDetails?.RealType == '2'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                            src="./assets/realtype/bottomwear.png"></span><span
                                        *ngIf="clothDetails?.RealType == '3'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/footwear.png"></span><span
                                        *ngIf="clothDetails?.RealType === '4'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/heeledfootwear.png"></span><span
                                        *ngIf="clothDetails?.RealType == '5'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                            src="./assets/realtype/fullbody.png"></span>
                                </div>
                                <h6 class="brand-font-size class-band-name fo-nt-class" (click)="getClothProfile()" style="overflow-wrap: break-word;">
                                    {{(clothDetails?.Name)}}
                                </h6>
                            </div>
                            <div>
                                <div class="text-center band-3d-icon d-flex col-sm-1">
                                    <h6 (click)="getClothProfile()" class=" text-primary  color-class"><img style="width: 25px !important;
                                            color: black;
                                            margin-top: -5px;
                                            opacity: 0.6;
                                            margin-left: 5px;" src="./assets/realtype/3d-paranoma.png">
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <h6 class="text-primary brandbtn m-1 d-block class" (click)="getClothProfile()">
                                <div *ngIf="selectedIcon == 'popup'"
                                    [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card'  : 'story-menu-icon'">
                                    <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                                </div>
                                <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                    style="color:black ;margin-top: 0px; width: 15px;"
                                    src="./assets/storystyle/storyblack.png">

                                <h6 *ngIf="selectedIcon == 'popup'" class="selected-name text-black m-1">Story</h6>
                            </h6>
                            <h6 (click)="selectIcon('detail')" *ngIf="clothDetails?.ThumbCenterURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'detail'"
                                    [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails.ThumbCenterURL}}" target="_blank">
                                        <img style="width: 15px;" src="./assets/details.png"></a>
                                </div>
                                <a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'detail'"
                                        [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                        style="color:black ;" src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

                                <h6 *ngIf="selectedIcon == 'detail'" class="selected-name m-1">Details</h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                        style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'gallary'"
                                    [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails.ThumbLeftURL}}" target="_blank">
                                        <img style="width: 15px;" src="./assets/gallary.png"></a>
                                </div>
                                <a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'gallary'"
                                        [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                        style="color:black;" src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name m-1">Gallary</h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                        style="width:15px !important ;"
                                        src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'cart'"
                                    [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{clothDetails.ThumbRightURL}}" target="_blank">
                                        <img style="width: 15px;" src="./assets/shop.png"></a>
                                </div>
                                <a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'cart'"
                                        [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                        style="color:black; width: 15px;"
                                        src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                            </h6>
                            <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                        style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="twoDImage d-flex mb-2" >
                    <div *ngIf="image2DFlag" class="text-center">
                        <div class="spinner-border m-5" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div class="" (click)="getClothProfile()" *ngIf="userFiledetails3d.length != 0  && userFiledetails.length != 0">
                        <app-slider-two-single [images]="userFiledetails"></app-slider-two-single>
                    </div>
                    <div class="text-center d-flex upload-image-slider-css-both twoDImage-both"
                        *ngIf="userFiledetails3d.length != 0  && userFiledetails.length != 0">
                        <div (click)="getClothProfile()" *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                style="height: 110px; width: 150px; float: none;">
                            </model-viewer>
                        </div>
                    </div>
                    <div class="slide"  (click)="getClothProfile()"*ngIf="userFiledetails3d.length == 0  && userFiledetails.length != 0 ">
                        <app-slider-two-d [images]="userFiledetails"></app-slider-two-d>
                    </div>
                    <div class="text-center d-flex upload-image-slider-css twoDImage"
                        *ngIf="userFiledetails3d.length != 0  && userFiledetails.length == 0">
                        <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                style="height: 110px; width: 150px; float: none;">
                            </model-viewer>
                        </div>
                    </div>
                    <div class="slide" *ngIf="userFiledetails.length == 0 && !image2DFlag ">
                        <app-slider-two-d [images]="images"></app-slider-two-d>
                    </div>
                </div>
             <div class="text-white"
                    [ngClass]="userFiledetails3d.length == 0    ? 'details-collection-2'  : 'details-collection'"
                    (click)="getClothProfile()">

                    <h6 class=" headingcollection "
                        *ngIf="clothDetails.CollectionName != undefined && clothDetails?.CollectionName != ''">
                        Collection:
                        {{filterCollectionName(clothDetails.CollectionName)}}</h6>
                    <h6 class=" headingcollection "
                        *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">Collection:
                        None
                    </h6>

                    <h5 class="headingcollection"
                        *ngIf="clothDetails != undefined && clothDetails?.Details != undefined">
                        {{(showMore) ? clothDetails?.Details : clothDetails?.Details | slice:0:110}} <span
                            *ngIf="!showMore && clothDetails?.Details.length > 110">...</span>

                    </h5>
                    <h5 class="headingcollection"
                        *ngIf="clothDetails != undefined && clothDetails?.Details == undefined">
                        {{(showMore) ? dummyText: dummyText | slice:0:110}} <span *ngIf="!showMore   ">...</span>

                    </h5>
                    <h6 class="headingcollection">
                        <span class="headingName p-1"> SKU:</span>{{(clothDetails?.SKU)}}
                    </h6>
                </div>
                <div *ngIf="!snaploadingSnap" class="row w-100 ">
                    <div class="column d-block m-1" *ngFor="let snapshot of clothShapShotsListAll  ; let i=index;">
                        <img class="w-100 shadow-1-strong rounded " (click)="getClothProfile()"
                            [src]="snapshot.SnapshotFileUrl">
                    </div>
                    <div class="columnImg d-block m-1" *ngIf="clothShapShotsListAll.length > 2">
                        <img (click)="getClothProfile()" class="w-80 shadow-1-strong rounded image-pop"
                            src="./assets/plus-8-512.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="small-div"></div>
        <div class="small-div"></div>
        <div class="small-div"></div>
        <div class="small-div"></div>
        <div *ngIf="snaploadingSnap" class="text-center">
            <div class="spinner-border m-5 " role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
    <i class="bi bi-chevron-right right"></i>
    <i class="bi bi-chevron-left left"></i>
</div>



<div #swipeUp class="row text-center justify-content-center" (scroll)="scrolltopSide()" *ngIf="garmentFlagDummy == true">
    <div class="row text-center m-1 p-1 justify-content-center">
        <div class="col-12 details">
            <div *ngIf="!garmentFlag" class=" text-center w-100  d-flex garmentname" [ngStyle]="{ background: getgarmentnameBackground()}">
                <div class="d-flex  col-2">
                    <img *ngIf="brandObject?.LogoURL != undefined" data-bs-dismiss="modal"
                        class="rounded profileImg-brand" (click)="getBrandProfileByGarment(brandObject,brandObject)"
                        [src]="getBrandImage(brandObject.LogoURL)"
                        style="width:35px !important; height:35px !important;">
                </div>
                <div class="col-8 brand-font-size text-white  fo-nt-class">
                    <b (click)="getBrandProfileByGarment(brandObject,brandObject)"
                        data-bs-dismiss="modal">{{brandObject?.BrandName}}</b>
                    <b>
                        <h6 class="color-gender text-white  fo-nt-class">
                            {{clothDetails?.Gender == 'F' ? 'Womenswear' :
                            (clothDetails?.Gender == 'M') ? 'Menswear' : 'N/A'}}
                        </h6>
                    </b>
                </div>
                <div class="col-2 text-end">
                    <span><img *ngIf="heartFlag == true" class="heart" style="width:18px !important; color:black"
                            src="./assets/storystyle/newStoryIcon/heart.png"></span>
                    <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                            style="width:18px !important; color:black"
                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                    </span>
                </div>
            </div>
            <div *ngIf="garmentFlag" class="text-center">
                <div class="spinner-border m-5 " role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="mb-2 text-black clothName  color-class-band brand-font-size" [ngStyle]="{ background: clothName_background()}" >
                <div class="d-flex garment-title"  [ngStyle]="{ background: getgarmentname_title()}">
                    <div class="text-center d-inline-flex garmen-name-in-band">
                        <div class="fo-nt-class" (click)="getClothProfile()">
                            <span *ngIf="clothDetails?.RealType == '0'" class="brandbtn py-1"><img
                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                    src="./assets/realtype/headgear.png"></span>
                            <span *ngIf="clothDetails?.RealType == '1'" class="brandbtn py-1 "><img style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6;
                                                margin-right: 4px;" src="./assets/realtype/upperwear.png"></span><span
                                *ngIf="clothDetails?.RealType == '2'" class="brandbtn py-1"><img
                                    style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                    src="./assets/realtype/bottomwear.png"></span><span
                                *ngIf="clothDetails?.RealType == '3'" class="brandbtn py-1"><img
                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                    src="./assets/realtype/footwear.png"></span><span
                                *ngIf="clothDetails?.RealType === '4'" class="brandbtn py-1"><img
                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                    src="./assets/realtype/heeledfootwear.png"></span><span
                                *ngIf="clothDetails?.RealType == '5'" class="brandbtn py-1"><img
                                    style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                    src="./assets/realtype/fullbody.png"></span>
                        </div>
                        <h6 class="brand-font-size class-band-name" (click)="getClothProfile()" style="overflow-wrap: break-word;">
                            {{(clothDetails?.Name)}}
                        </h6>
                    </div>
                    <div>
                        <div class="text-center band-3d-icon d-flex col-sm-1">
                            <h6  (click)="getClothProfile()" class=" text-primary  color-class"><img style="width: 25px !important;
                                            color: black;
                                            margin-top: -5px;
                                            opacity: 0.6;
                                            margin-left: 5px;" src="./assets/realtype/3d-paranoma.png">
                            </h6>
                        </div>
                    </div>
                </div>


                <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                    <h6 class="text-primary brandbtn m-1 d-block class" (click)="getClothProfile()">
                        <div *ngIf="selectedIcon == 'popup'"
                            [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card'  : 'story-menu-icon'">
                            <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                        </div>
                        <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                            style="color:black ;margin-top: 0px; width: 15px;" src="./assets/storystyle/storyblack.png">

                        <h6 *ngIf="selectedIcon == 'popup'" class="selected-name text-black m-1">Story</h6>
                    </h6>
                    <h6 (click)="selectIcon('detail')" *ngIf="clothDetails?.ThumbCenterURL  != undefined"
                        class="m-1 brandbtn">
                        <div *ngIf="selectedIcon == 'detail'"
                            [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                            <a href="{{clothDetails.ThumbCenterURL}}" target="_blank">
                                <img style="width: 15px;" src="./assets/details.png"></a>
                        </div>
                        <a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img *ngIf="selectedIcon != 'detail'"
                                [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''" style="color:black ;"
                                src="./assets/storystyle/newStoryIcon/detailGray.png"></a>
                        <h6 *ngIf="selectedIcon == 'detail'" class="selected-name m-1">Details</h6>
                    </h6>
                    <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important; color:black"
                                src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                    </h6>
                    <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined"
                        class="m-1 brandbtn">
                        <div *ngIf="selectedIcon == 'gallary'"
                            [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                            <a href="{{clothDetails.ThumbLeftURL}}" target="_blank">
                                <img style="width: 15px;" src="./assets/gallary.png"></a>
                        </div>
                        <a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img *ngIf="selectedIcon != 'gallary'"
                                [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''" style="color:black;"
                                src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                        <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name m-1">Gallary</h6>
                    </h6>
                    <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important ;"
                                src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                    </h6>
                    <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined"
                        class="m-1 brandbtn">
                        <div *ngIf="selectedIcon == 'cart'"
                            [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                            <a href="{{clothDetails.ThumbRightURL}}" target="_blank">
                                <img style="width: 15px;" src="./assets/shop.png"></a>
                        </div>
                        <a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img *ngIf="selectedIcon != 'cart'"
                                [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                style="color:black; width: 15px;"
                                src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                        <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                    </h6>
                    <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important; color:black"
                                src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                    </h6>
                </div>
            </div>
        </div>
        <div class="twoDImage d-flex mb-2">
            <div *ngIf="image2DFlag" class="text-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="" *ngIf="userFiledetails3d.length != 0  && userFiledetails.length != 0">
                <app-slider-two-single [images]="userFiledetails"></app-slider-two-single>

            </div>
            <div class="text-center d-flex upload-image-slider-css-both twoDImage-both"
                *ngIf="userFiledetails3d.length != 0  && userFiledetails.length != 0">
                <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                    <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                        camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                        alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                        style="height: 110px; width: 150px; float: none;">
                    </model-viewer>
                </div>
            </div>

            <div class="slide" *ngIf="userFiledetails3d.length == 0  && userFiledetails.length != 0 ">
                <app-slider-two-d [images]="userFiledetails" [dripFlag]="dripFlag"></app-slider-two-d>
            </div>
            <div class="text-center d-flex upload-image-slider-css twoDImage"
                *ngIf="userFiledetails3d.length != 0  && userFiledetails.length == 0">
                <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                    <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                        camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                        alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                        style="height: 110px; width: 150px; float: none;">
                    </model-viewer>
                </div>
            </div>
            <div class="slide" *ngIf="userFiledetails.length == 0">
                <app-slider-two-d [images]="images" [dripFlag]="dripFlag"></app-slider-two-d>
            </div>
        </div>
        <div class="text-white  fo-nt-class"
            [ngClass]="userFiledetails3d.length == 0    ? 'details-collection-2'  : 'details-collection'"
            (click)="getClothProfile()">

            <h6 class=" headingcollection "
                *ngIf="clothDetails?.CollectionName != undefined && clothDetails?.CollectionName != ''">Collection:
                {{filterCollectionName(clothDetails.CollectionName)}}</h6>
            <h6 class=" headingcollection "
                *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">Collection: None
            </h6>

            <h5 class="headingcollection" *ngIf="clothDetails != undefined && clothDetails.Details != undefined">
                {{(showMore) ? clothDetails.Details : clothDetails.Details | slice:0:110}} <span
                    *ngIf="!showMore && clothDetails.Details.length > 110">...</span>

            </h5>
            <h5 class="headingcollection" *ngIf="clothDetails != undefined && clothDetails?.Details == undefined">
                {{(showMore) ? dummyText: dummyText | slice:0:110}} <span *ngIf="!showMore   ">...</span>

            </h5>
            <h6 class="headingcollection">
                <span class="headingName p-1"> SKU:</span>{{(clothDetails?.SKU)}}
            </h6>
        </div>
        <div *ngIf="!snaploadingSnap" class="row w-100 story-mar">
            <div class="column d-block m-1" *ngFor="let snapshot of clothShapShotsListAll  ; let i=index;">
                <img class="w-100 shadow-1-strong rounded " (click)="getClothProfile()"    defaultImage="{{ snapshot?.SnapshotFileUrlMicro || '/assets/no thumbnail.jpg' }}"
                    [appLazyLoad]="snapshot.SnapshotFileUrlMicro != undefined ? snapshot.SnapshotFileUrlMicro : '/assets/no thumbnail.jpg' ">
            </div>
            <div class="columnImg d-block m-1" *ngIf="clothShapShotsListAll.length > 2">
                <img (click)="getClothProfile()" class="w-80 shadow-1-strong rounded image-pop"
                    src="./assets/plus-8-512.png">
            </div>
        </div>
    </div>
</div>
<div *ngIf="snaploadingSnap" class="text-center">
    <div class="spinner-border m-5 " role="status">
        <span class="sr-only"></span>
    </div>
</div>