import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { HttpClient } from '@angular/common/http';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
import { BVHImport } from '../news3-d-templete/BVHImport.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import * as JSZip from 'jszip/dist/jszip'
import { DownloadZipService } from 'src/app/services/download-zip.service';
import { Observable, firstValueFrom } from 'rxjs';
import { Console, debug } from 'console';
import { stringify } from 'querystring';
import { SimpleChanges } from '@angular/core';
import Stats from 'stats.js';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ARButton } from "three/examples/jsm/webxr/ARButton.js";



@Component({
  selector: 'app-news3-d-popup',
  templateUrl: './news3-d-popup.component.html',
  styleUrls: ['./news3-d-popup.component.css']
})

export class News3DPopupComponent {
  @Output() lodingStateFlag = new EventEmitter();
  @Output() triggedUrl = new EventEmitter();
  @Output() errorMessage = new EventEmitter();
  @Output() sceneDone = new EventEmitter();
  @Output() calledFunction = new EventEmitter();
  @Output() skyboxLoaded = new EventEmitter();
  @Output() arStateChange = new EventEmitter<boolean>();

  @Input() EnvUrl: any | undefined;
  @Input() AvatarUrl: any;

  @Input() upperwearURL: any;
  @Input() headgearURL: any;
  @Input() bottomwearURL: any;
  @Input() footwearURL: any;

  @Input() GarmentsGender: any;
  @Input() Loading: boolean;
  @Input() BrandIdName: any;
  @Input() FunctionFlag: any;
  @Input() InputFatness: any = 0;

  @Input() queryHeadgear: any;
  @Input() queryUpperwear: any;
  @Input() queryBottomwear: any;
  @Input() queryFootwear: any;
  @Input() DefaultAvatar_M: any;
  @Input() DefaultAvatar_F: any;
  @Input() StoryID: any;
  @Input() BlobUrl: any;
  @Input() isShowStats: boolean;
  @Input() ARdata: any;
  

  hitTestSourceRequested = false;
  isModelplaced = false;
  planeFound = false;
  hitTestSource = null;
  reticle;
  controller;
  avatarCanPlace = false;
  customARButton: HTMLButtonElement;
  originalPosition;
  originalQuaternion;
  originalScale;
  skyBoxTextureCached;
  animationListShow = false
  xrSession = null;
  animationFrameId = null;
  isARsessionActive = false;
  



  

  @ViewChild('threeDModelContainerMale', { static: true }) threeDModelContainerMale: ElementRef;
  @ViewChild('threeDModelContainerFemale', { static: true }) threeDModelContainerFemale: ElementRef;

  private downloadAndExtractClothZipData: string = environment.functionBaseUrl + "downloadAndExtractClothZipData";

  //Praful's Variables
  newLink: any = [];

  //stats
  private stats: Stats;
  //Scene
  scene: any;
  controls: any;
  camera: any;
  renderer: any;
  private _sceneInitialized: Promise<void>;
  private _resolveSceneInitialized: () => void;
  postcard: THREE.Mesh;
  avatarLoadCallCount: number = 0;
  public clothData: any[] = [];


  //Background
  dbAmbientLight: THREE.AmbientLight;
  dbDirectionalLight1: THREE.DirectionalLight;
  dbDirectionalLight2: THREE.DirectionalLight;
  dbFloor: THREE.Mesh;
  dbDefaultBackgroundColor: THREE.Color = new THREE.Color(0xdddddd);
  dbHiddenBackgroundColor: THREE.Color = new THREE.Color(0x000000);
  loadingStartTime: number;
  threeJsLoadingStartTime: number;
  threeJsLoadingEndTime: number;

  //Avatar
  femaleAvatarParent = {
    BodyObject: null,
    HairObject: null,
    upperwear: null,
    bottomwear: null,
    footwear: null,
    headgear: null,
  }

  maleAvatarParent = {
    BodyObject: null,
    HairObject: null,
    upperwear: null,
    bottomwear: null,
    footwear: null,
    headgear: null,
  }

  femaleAvatarData = {
    Fatness: null,
    Headbone: null,
    HeightScale: null,
    OriginalPoses: []
  }

  maleAvatarData = {
    Fatness: null,
    Headbone: null,
    HeightScale: null,
    OriginalPoses: []
  }

  fatness: any;
  private isFemaleAvatarVisible: boolean = true;
  private isMaleAvatarVisible: boolean = true;

  triangleData: any = [];

  //Debugging
  showDisplayText: any = '';
  cachedStoryID: any;
  isChecked: boolean;
  isloadingState: boolean;
  isloadingForChange: boolean;

  dUnzipeedAvatar_M = {
    savedHairTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776%2FsavedHairTex.png?alt=media&token=5e3426d5-047b-48e8-b435-4b0bb991e1fe',
    savedMetalRoughTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776%2FsavedMetalRoughTex.jpg?alt=media&token=be188b82-8f20-40a2-a1b3-5f29efc3ac48',
    savedNormalTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776%2FsavedNormalTex.jpg?alt=media&token=36cbfd85-47c8-4e27-b11e-d04d3147a20a',
    savedTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776%2FsavedTex.jpg?alt=media&token=1761b934-6ad1-4952-a9bc-34d232e3b2ce',
    savefile: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776%2Fsavefile.json?alt=media&token=49ad8307-2e25-4882-8357-437fc24279f9'
  }


  dUnzipeedAvatar_F = {
    savedHairTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20231227190149926%2FsavedHairTex.png?alt=media&token=50a7a082-e9cf-4cd6-aa65-cf51deef9ca8',
    savedMetalRoughTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20231227190149926%2FsavedMetalRoughTex.jpg?alt=media&token=64a5c213-0953-4d0e-acb2-4f07d20432b9',
    savedNormalTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20231227190149926%2FsavedNormalTex.jpg?alt=media&token=93e21b58-9749-4029-94d7-80b16ad4de7a',
    savedTex: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20231227190149926%2FsavedTex.jpg?alt=media&token=fbaa9b0e-1e67-435f-baa9-631ad0bcc1c2',
    savefile: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20231227190149926%2Fsavefile.json?alt=media&token=6d9b1416-6725-4f7d-98d6-53fd5ac87469'
  }
  //Default URL
  defaultFemaleAvatar: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2F39LJ7Q9UGEQwGn0u9wR7ckyW9iv1%2F20231227190149926?alt=media&token=20b1dc1e-681b-4d96-a342-7a4407fab4e0&_gl=1*10jumsf*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NzUyMjU5NC4xNzAuMS4xNjk3NTIyNjE1LjM5LjAuMA..'
  defaultMaleAvatar: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2F20240402230732776?alt=media&token=98a0bcdb-7600-4367-897c-3c5cd3570cf1'

  // defaultFemaleUpperWear: any = ' https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2FIKsMd2LU5Fru8yCJgpzs_F%2Fupperwear%2Fcloth_20230601144503044?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
  // defaultFemaleBottomWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F3w6BFBneADzMMzGiWHFZ_F%2Fbottomwear%2Fcloth_20220515232251166?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

  defaultFemaleUpperWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F2nKPNi8rYWdrO1JTywVK_F%2Fupperwear%2Fcloth_20210827005556119?alt=media&token=ae1c78cc-10ee-4c33-ad8f-d24d2ec898c5';
  defaultFemaleBottomWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F2nKPNi8rYWdrO1JTywVK_F%2Fbottomwear%2Fcloth_20210830001343830?alt=media&token=eb692b4a-5474-40c7-ad46-50c79d5c8eb7';

  defaultFemaleFootWear: string = null;
  defaultFemaleHeadGear: string = null;

  // defaultMaleBottomWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F0nZV7f1kKuUBujMlKPgc_M%2Fbottomwear%2Fcloth_20210901184520806?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
  // defaultMaleUpperWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2FaxK6zfRiMiTuiBCW5VK7_M%2Fupperwear%2Fcloth_20231128193533489?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'

  defaultMaleBottomWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F0nZV7f1kKuUBujMlKPgc_M%2Fbottomwear%2Fcloth_20220110105756669?alt=media&token=fa732d87-3d2d-4fd3-9c2f-91f6a14b646a'
  defaultMaleUpperWear: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F0nZV7f1kKuUBujMlKPgc_M%2Fupperwear%2Fcloth_20220110105643281?alt=media&token=a6d9f018-33df-49ca-a825-e89f8e882458'


  defaultMaleFootWear: string = null;
  defaultMaleHeadGear: string = null;

  defaultEnvironment: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78';

  //Default Animations
  defaultAnimationFemale: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestMixamo%2FIdle%20(3).fbx?alt=media&token=1b457671-4811-4e16-ab83-190c225256ff'
  defaultAnimationMale: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestMixamo%2FIdle%20(3).fbx?alt=media&token=1b457671-4811-4e16-ab83-190c225256ff'

  setflagSceneInitialized: boolean = false;
  newHeight: number;
  newWidth: number;

  //VRM
  clock = new THREE.Clock();
  mixer;
  skeletonHelper;
  currentVRM = null;
  helperRoot = new THREE.Group();
  currentAction;

  boneMappingFBX = {
    hips_JNT: 'Pelvis',
    spine_JNT: 'Spine1',
    spine1_JNT: 'Spine2',
    spine2_JNT: 'Spine3',
    neck_JNT: 'Head',
    head_JNT: 'Neck',
    l_shoulder_JNT: 'L_Collar',
    l_arm_JNT: 'L_Shoulder',
    l_forearm_JNT: 'L_Elbow',
    l_hand_JNT: 'L_Wrist',
    l_handThumb1_JNT: 'lthumb0',
    l_handThumb2_JNT: 'lthumb1',
    l_handThumb3_JNT: 'lthumb2',
    l_handIndex1_JNT: 'lindex0',
    l_handIndex2_JNT: 'lindex1',
    l_handIndex3_JNT: 'lindex2',
    l_handMiddle1_JNT: 'lmiddle0',
    l_handMiddle2_JNT: 'lmiddle1',
    l_handMiddle3_JNT: 'lmiddle2',
    l_handRing1_JNT: 'lring0',
    l_handRing2_JNT: 'lring1',
    l_handRing3_JNT: 'lring2',
    l_handPinky1_JNT: 'lpinky0',
    l_handPinky2_JNT: 'lpinky1',
    l_handPinky3_JNT: 'lpinky2',
    r_shoulder_JNT: 'R_Collar',
    r_arm_JNT: 'R_Shoulder',
    r_forearm_JNT: 'R_Elbow',
    r_hand_JNT: 'R_Wrist', // No specific hand bone in FBX, using wrist
    r_handThumb1_JNT: 'rthumb0',
    r_handThumb2_JNT: 'rthumb1',
    r_handThumb3_JNT: 'rthumb2',
    r_handIndex1_JNT: 'rindex0',
    r_handIndex2_JNT: 'rindex1',
    r_handIndex3_JNT: 'rindex2',
    r_handMiddle1_JNT: 'rmiddle0',
    r_handMiddle2_JNT: 'rmiddle1',
    r_handMiddle3_JNT: 'rmiddle2',
    r_handRing1_JNT: 'rring0',
    r_handRing2_JNT: 'rring1',
    r_handRing3_JNT: 'rring2',
    r_handPinky1_JNT: 'rpinky0',
    r_handPinky2_JNT: 'rpinky1',
    r_handPinky3_JNT: 'rpinky2',
    l_upleg_JNT: 'L_Hip',
    l_leg_JNT: 'L_Knee',
    l_foot_JNT: 'L_Ankle',
    l_toebase_JNT: 'L_Foot',
    r_upleg_JNT: 'R_Hip',
    r_leg_JNT: 'R_Knee',
    r_foot_JNT: 'R_Ankle',
    r_toebase_JNT: 'R_Foot',
  };

  mixamoRigMapFBX = {
    mixamorigHips: 'Pelvis',
    mixamorigSpine: 'Spine1',
    mixamorigSpine1: 'Spine2',
    mixamorigSpine2: 'Spine3',
    mixamorigNeck: 'Neck',
    mixamorigHead: 'Head',
    mixamorigLeftShoulder: 'L_Collar',
    mixamorigLeftArm: 'L_Shoulder',
    mixamorigLeftForeArm: 'L_Elbow',
    mixamorigLeftHand: 'L_Wrist',
    mixamorigLeftHandThumb1: 'lthumb0',
    mixamorigLeftHandThumb2: 'lthumb1',
    mixamorigLeftHandThumb3: 'lthumb2',
    mixamorigLeftHandIndex1: 'lindex0',
    mixamorigLeftHandIndex2: 'lindex1',
    mixamorigLeftHandIndex3: 'lindex2',
    mixamorigLeftHandMiddle1: 'lmiddle0',
    mixamorigLeftHandMiddle2: 'lmiddle1',
    mixamorigLeftHandMiddle3: 'lmiddle2',
    mixamorigLeftHandRing1: 'lring0',
    mixamorigLeftHandRing2: 'lring1',
    mixamorigLeftHandRing3: 'lring2',
    mixamorigLeftHandPinky1: 'lpinky0',
    mixamorigLeftHandPinky2: 'lpinky1',
    mixamorigLeftHandPinky3: 'lpinky2',
    mixamorigRightShoulder: 'R_Collar',
    mixamorigRightArm: 'R_Shoulder',
    mixamorigRightForeArm: 'R_Elbow',
    mixamorigRightHand: 'R_Wrist',
    mixamorigRightHandThumb1: 'rthumb0',
    mixamorigRightHandThumb2: 'rthumb1',
    mixamorigRightHandThumb3: 'rthumb2',
    mixamorigRightHandIndex1: 'rindex0',
    mixamorigRightHandIndex2: 'rindex1',
    mixamorigRightHandIndex3: 'rindex2',
    mixamorigRightHandMiddle1: 'rmiddle0',
    mixamorigRightHandMiddle2: 'rmiddle1',
    mixamorigRightHandMiddle3: 'rmiddle2',
    mixamorigRightHandRing1: 'rring0',
    mixamorigRightHandRing2: 'rring1',
    mixamorigRightHandRing3: 'rring2',
    mixamorigRightHandPinky1: 'rpinky0',
    mixamorigRightHandPinky2: 'rpinky1',
    mixamorigRightHandPinky3: 'rpinky2',
    mixamorigLeftUpLeg: 'L_Hip',
    mixamorigLeftLeg: 'L_Knee',
    mixamorigLeftFoot: 'L_Ankle',
    mixamorigLeftToeBase: 'L_Foot',
    mixamorigRightUpLeg: 'R_Hip',
    mixamorigRightLeg: 'R_Knee',
    mixamorigRightFoot: 'R_Ankle',
    mixamorigRightToeBase: 'R_Foot',
  };

  boneMappingVRM = {
    hips_JNT: 'hips',
    spine_JNT: 'spine',
    spine1_JNT: 'chest',
    spine2_JNT: 'upperChest',
    neck_JNT: 'neck',
    head_JNT: 'head',
    l_shoulder_JNT: 'leftShoulder',
    l_arm_JNT: 'leftUpperArm',
    l_forearm_JNT: 'leftLowerArm',
    l_hand_JNT: 'leftHand',
    l_handThumb1_JNT: 'leftThumbMetacarpal',
    l_handThumb2_JNT: 'leftThumbProximal',
    l_handThumb3_JNT: 'leftThumbDistal',
    l_handIndex1_JNT: 'leftIndexProximal',
    l_handIndex2_JNT: 'leftIndexIntermediate',
    l_handIndex3_JNT: 'leftIndexDistal',
    l_handMiddle1_JNT: 'leftMiddleProximal',
    l_handMiddle2_JNT: 'leftMiddleIntermediate',
    l_handMiddle3_JNT: 'leftMiddleDistal',
    l_handRing1_JNT: 'leftRingProximal',
    l_handRing2_JNT: 'leftRingIntermediate',
    l_handRing3_JNT: 'leftRingDistal',
    l_handPinky1_JNT: 'leftLittleProximal',
    l_handPinky2_JNT: 'leftLittleIntermediate',
    l_handPinky3_JNT: 'leftLittleDistal',
    r_shoulder_JNT: 'rightShoulder',
    r_arm_JNT: 'rightUpperArm',
    r_forearm_JNT: 'rightLowerArm',
    r_hand_JNT: 'rightHand',
    r_handThumb1_JNT: 'rightThumbMetacarpal',
    r_handThumb2_JNT: 'rightThumbProximal',
    r_handThumb3_JNT: 'rightThumbDistal',
    r_handIndex1_JNT: 'rightIndexProximal',
    r_handIndex2_JNT: 'rightIndexIntermediate',
    r_handIndex3_JNT: 'rightIndexDistal',
    r_handMiddle1_JNT: 'rightMiddleProximal',
    r_handMiddle2_JNT: 'rightMiddleIntermediate',
    r_handMiddle3_JNT: 'rightMiddleDistal',
    r_handRing1_JNT: 'rightRingProximal',
    r_handRing2_JNT: 'rightRingIntermediate',
    r_handRing3_JNT: 'rightRingDistal',
    r_handPinky1_JNT: 'rightLittleProximal',
    r_handPinky2_JNT: 'rightLittleIntermediate',
    r_handPinky3_JNT: 'rightLittleDistal',
    l_upleg_JNT: 'leftUpperLeg',
    l_leg_JNT: 'leftLowerLeg',
    l_foot_JNT: 'leftFoot',
    l_toebase_JNT: 'leftToes',
    r_upleg_JNT: 'rightUpperLeg',
    r_leg_JNT: 'rightLowerLeg',
    r_foot_JNT: 'rightFoot',
    r_toebase_JNT: 'rightToes',
  };

  mixamoRigMapVRM = {
    mixamorigHips: 'hips',
    mixamorigSpine: 'spine',
    mixamorigSpine1: 'chest',
    mixamorigSpine2: 'upperChest',
    mixamorigNeck: 'neck',
    mixamorigHead: 'head',
    mixamorigLeftShoulder: 'leftShoulder',
    mixamorigLeftArm: 'leftUpperArm',
    mixamorigLeftForeArm: 'leftLowerArm',
    mixamorigLeftHand: 'leftHand',
    mixamorigLeftHandThumb1: 'leftThumbMetacarpal',
    mixamorigLeftHandThumb2: 'leftThumbProximal',
    mixamorigLeftHandThumb3: 'leftThumbDistal',
    mixamorigLeftHandIndex1: 'leftIndexProximal',
    mixamorigLeftHandIndex2: 'leftIndexIntermediate',
    mixamorigLeftHandIndex3: 'leftIndexDistal',
    mixamorigLeftHandMiddle1: 'leftMiddleProximal',
    mixamorigLeftHandMiddle2: 'leftMiddleIntermediate',
    mixamorigLeftHandMiddle3: 'leftMiddleDistal',
    mixamorigLeftHandRing1: 'leftRingProximal',
    mixamorigLeftHandRing2: 'leftRingIntermediate',
    mixamorigLeftHandRing3: 'leftRingDistal',
    mixamorigLeftHandPinky1: 'leftLittleProximal',
    mixamorigLeftHandPinky2: 'leftLittleIntermediate',
    mixamorigLeftHandPinky3: 'leftLittleDistal',
    mixamorigRightShoulder: 'rightShoulder',
    mixamorigRightArm: 'rightUpperArm',
    mixamorigRightForeArm: 'rightLowerArm',
    mixamorigRightHand: 'rightHand',
    mixamorigRightHandPinky1: 'rightLittleProximal',
    mixamorigRightHandPinky2: 'rightLittleIntermediate',
    mixamorigRightHandPinky3: 'rightLittleDistal',
    mixamorigRightHandRing1: 'rightRingProximal',
    mixamorigRightHandRing2: 'rightRingIntermediate',
    mixamorigRightHandRing3: 'rightRingDistal',
    mixamorigRightHandMiddle1: 'rightMiddleProximal',
    mixamorigRightHandMiddle2: 'rightMiddleIntermediate',
    mixamorigRightHandMiddle3: 'rightMiddleDistal',
    mixamorigRightHandIndex1: 'rightIndexProximal',
    mixamorigRightHandIndex2: 'rightIndexIntermediate',
    mixamorigRightHandIndex3: 'rightIndexDistal',
    mixamorigRightHandThumb1: 'rightThumbMetacarpal',
    mixamorigRightHandThumb2: 'rightThumbProximal',
    mixamorigRightHandThumb3: 'rightThumbDistal',
    mixamorigLeftUpLeg: 'leftUpperLeg',
    mixamorigLeftLeg: 'leftLowerLeg',
    mixamorigLeftFoot: 'leftFoot',
    mixamorigLeftToeBase: 'leftToes',
    mixamorigRightUpLeg: 'rightUpperLeg',
    mixamorigRightLeg: 'rightLowerLeg',
    mixamorigRightFoot: 'rightFoot',
    mixamorigRightToeBase: 'rightToes',
  };

  functionIntilized: string;
  avatarFiles: any = {};
  loadTime: number;
  private isStatsVisible = false;



  constructor(private elementRef: ElementRef,private http: HttpClient, private _httpClient: HttpClient, public sanitizer: DomSanitizer, private downloadZipService: DownloadZipService, private commonService: CommonService,) {
    // this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.stats = new Stats();
    this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom

    document.body.appendChild(this.stats.dom);

    // Adjust the CSS style to move the panels to the right side
    this.stats.dom.style.right = '25px';
    this.stats.dom.style.top = '15px';
    this.stats.dom.style.left = 'auto';
    this.stats.dom.style.transform = 'scale(1.5)';

    this.CallFunctionFlag('InitializeScene');
    



  }



  toggleStats() {
    this.isStatsVisible =  !this.isStatsVisible
    const displayStyle = this.isStatsVisible ? 'block' : 'none';
    this.stats.dom.style.display = displayStyle; // Start hidden
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log(`EnvUrl: ${this.EnvUrl}`);
    console.log(`AvatarUrl: ${this.AvatarUrl}`); // Note: This seems to be a duplicate
    console.log(`upperwearURL: ${this.upperwearURL}`); // Note: This seems to be a duplicate
    console.log(`headgearURL: ${this.headgearURL}`);
    console.log(`bottomwearURL: ${this.bottomwearURL}`);
    console.log(`footwearURL: ${this.footwearURL}`);
    console.log(`GarmentsGender: ${this.GarmentsGender}`);
    console.log(`BlobUrl: ${this.BlobUrl}`);

    console.log(`FunctionFlag: ${this.FunctionFlag}`);

    this.CallFunctionFlag(this.FunctionFlag);
    if (changes.hasOwnProperty('InputFatness')) {
      console.log("InputFatness : ", changes['InputFatness'].currentValue);
      this.SettingFatness(this.GarmentsGender, changes['InputFatness'].currentValue);
    }


    if (changes['isShowStats'] && changes['isShowStats'].currentValue !== changes['isShowStats'].previousValue) {
      this.toggleStats()
    }
    

    if (changes.hasOwnProperty('upperwearURL')) {
      console.log("upperwearURL : ", changes['upperwearURL'].currentValue);
      if (changes['upperwearURL'].currentValue !== '' && changes['upperwearURL'].currentValue !== undefined) {
        this.DownloadAndLoadClothesByType(changes['upperwearURL'].currentValue, "upperwear", this.GarmentsGender, true);
        console.log("upperwearURL : ", changes['upperwearURL'].currentValue);
      }
    }

    if (changes.hasOwnProperty('bottomwearURL')) {
      console.log("bottomwearURL : ", changes['bottomwearURL'].currentValue);
      if (changes['bottomwearURL'].currentValue !== '' && changes['bottomwearURL'].currentValue !== undefined) {
        this.DownloadAndLoadClothesByType(changes['bottomwearURL'].currentValue, "bottomwear", this.GarmentsGender, true);
        console.log("bottomwearURL : ", changes['bottomwearURL'].currentValue);
      }
    }

    if (changes.hasOwnProperty('headgearURL')) {
      console.log("headgearURL : ", changes['headgearURL'].currentValue);
      if (changes['headgearURL'].currentValue !== '' && changes['headgearURL'].currentValue !== undefined) {
        this.DownloadAndLoadClothesByType(changes['headgearURL'].currentValue, "headgear", this.GarmentsGender, true);
        console.log("headgearURL : ", changes['headgearURL'].currentValue);
      }
    }

    if (changes.hasOwnProperty('footwearURL')) {
      console.log("footwearURL : ", changes['footwearURL'].currentValue);
      if (changes['footwearURL'].currentValue !== '' && changes['footwearURL'].currentValue !== undefined) {
        this.DownloadAndLoadClothesByType(changes['footwearURL'].currentValue, "footwearr", this.GarmentsGender, true);
        console.log("footwearURL : ", changes['footwearURL'].currentValue);
      }
    }

    this.setWindowDimensions();
    this.updateRendererSize();

    console.log("queryHeadgear : ", this.queryHeadgear);
    console.log("queryUpperwear : ", this.queryUpperwear);
    console.log("queryBottomwear : ", this.queryBottomwear);
    console.log("queryFootwear : ", this.queryFootwear);

    // if(changes.hasOwnProperty('BrandIdName')) {
    //   console.log("BrandIdName : ", changes['BrandIdName'].currentValue);
    //   if(changes['BrandIdName'].currentValue !== '') {
    //    this.LoadDefaulClothes();
    //   }
    // }
    // const  newLink = this.StyleGarmentUrl || this.Headgear || this.StyleGarmentBottomWear || this.Footwear;

    if (this.upperwearURL && !this.newLink.includes(this.upperwearURL)) {
      this.newLink.push(this.upperwearURL);
    }

    if (this.headgearURL && !this.newLink.includes(this.headgearURL)) {
      this.newLink.push(this.headgearURL);
    }

    if (this.bottomwearURL && !this.newLink.includes(this.bottomwearURL)) {
      this.newLink.push(this.bottomwearURL);
    }

    if (this.footwearURL && !this.newLink.includes(this.footwearURL)) {
      this.newLink.push(this.footwearURL);
    }

    this.triggedUrl.emit(this.newLink);
    
    if (changes['ARdata']) {
      console.log("calling triggerARButton()");
      this.triggerARButton();
      this.ARdata = null;
      console.log("called: onShowARButtonClick()");
    }

  }

  ngOnInit(): void {
    this.loadingStartTime = performance.now();

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.isloadingState = true
    this.lodingStateFlag.emit(this.isloadingState);
    this.isloadingForChange = true
  }

  private handleResize = () => {
    this.setWindowDimensions();
    this.updateRendererSize();
  };

  private handleBeforeUnload = () => {
    this.disposeOfScene(this.scene, this.renderer);
  };

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    if (this.stats && this.stats.dom) {
      document.body.removeChild(this.stats.dom);
    }
  }
  calculateLoadingTime(): void {
    const loadingEndTime = performance.now();
    const totalLoadingTime = loadingEndTime - this.loadingStartTime;
    // console.log(`threejs total Loading Time: ${totalLoadingTime} ms`);
  }

  disposeOfScene(scene, renderer) {
    window.removeEventListener('resize', this.handleResize);

    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();
        if (Array.isArray(child.material)) {
          child.material.forEach((material) => {
            material.dispose();
          });
        } else {
          child.material.dispose();
        }
      } else if (child instanceof THREE.SkinnedMesh) {
        child.geometry.dispose();
        child.material.dispose();
        child.skeleton.dispose();
      }
      if (child instanceof THREE.Texture) {
        child.dispose();
      }
    });

    // Dispose of any additional objects in the scene
    scene.remove(...scene.children);

    // Dispose of the renderer
    renderer.dispose();
  }


  setWindowDimensions() {
    this.newWidth = window.innerWidth;
    this.newHeight = window.innerHeight;
  }


  ngAfterViewInit() {
    if (this.renderer && this.renderer.domElement && this.threeDModelContainerFemale && this.threeDModelContainerFemale.nativeElement) {
      this.threeDModelContainerFemale.nativeElement.appendChild(this.renderer.domElement);
    } else {
      console.error('Renderer or threeDModelContainer is not initialized');
    }
  }
  calculateThreeJsLoadingTime(): void {
    this.threeJsLoadingEndTime = performance.now();
    const totalLoadingTime = this.threeJsLoadingEndTime - this.threeJsLoadingStartTime;
    console.log(`Total Three.js Data Loading Time: ${totalLoadingTime} ms`);
  }

  /**
 * Calls the specified function.
 * Based on the values of the parameters, it calls the corresponding function.
 *
 * @param FunctionToBecalled - The gender of the avatar to hide. Can be either 'male' or 'female'.
 */
  CallFunctionFlag(FunctionToBecalled?: 'TogglePostCardOn' | 'TogglePostCardOff' | 'LoadDefaultMaleAvatar' | 'LoadDefaultFaleAvatar' | 'ShowMale' | 'ShowFemale' | 'AjustFatnessMale' | 'AjustFatnessFemale' | 'InitializeScene' | 'FocusOnStory' | 'FocusOnAvatar' | 'disposeAvatar'| 'hideBothAvatar' | 'updateWindowSize' | 'skybox' | string) {
    this.FunctionFlag = null;

    switch (FunctionToBecalled) {
      case 'TogglePostCardOn':
        this.togglePostcard();
        break;

      case 'TogglePostCardOff':
        this.togglePostcard();
        break;

      case 'LoadDefaultMaleAvatar':
        this.LoadDefaultAvatar('M');
        break;

      case 'LoadDefaultFaleAvatar':
        this.LoadDefaultAvatar('F');
        break;

      case 'ShowMale':
        this.showAvatars("male");
        this.hideAvatars("female");
        break;

      case 'ShowFemale':
        this.showAvatars("female");
        this.hideAvatars("male");
        break;

      case 'FocusOnStory':
        this.FocusOnStory();
        break;

      case 'FocusOnAvatar':
        this.FocusOnAvatar();
        break;

      case 'AjustFatnessMale':
        this.adjustFatness('M', this.InputFatness);
        break;

      case 'AjustFatnessFemale':
        this.adjustFatness('F', this.InputFatness);
        break;

      case 'disposeAvatar':
        this.disposeAvatar()
        break;

        case 'updateWindowSize':
          this.updateSizeForStore()
          break;
          
        case 'skybox':
          this.loadSkybox(this.EnvUrl);
          break;
        

      case 'hideBothAvatar':
        this.hideAvatars("male");
        this.hideAvatars("female");
        break;

      case 'InitializeScene':
        this.manageInitialization();
        break;

      default:
        if (FunctionToBecalled == null || FunctionToBecalled == undefined || FunctionToBecalled == '')
          break;

        if (FunctionToBecalled.includes('bvhAnimationUrl')) {
          const url = FunctionToBecalled.split('bvhAnimationUrl_')[1];
          if (this.currentVRM) {
            console.log("loading bvh true", url);
            this.DownloadBvhFile(url, true);
          }
          else {
            console.log("loading bvh false", url);
            this.DownloadBvhFile(url, false);
          }
        }
        else
          if (FunctionToBecalled.includes('LoadAvatar')) {
            let url;
            const match = FunctionToBecalled.match(/LoadAvatar_(.*?)_StoryID/);
            if (match) {
              url = match[1];
              console.log(url); // prints "someText"
            } else {
              console.log("No match found");
            }

            if (this.cachedStoryID == this.StoryID) {
              return;
            }

            if (url != null && url != undefined && url != '') {
              console.log("loading 3d", url);
              this.Load3DSnapshot(url, this.EnvUrl, this.upperwearURL, this.bottomwearURL, this.headgearURL, this.footwearURL, this.GarmentsGender, this.BlobUrl);
              this.AvatarUrl = null;
            }
            else {
              this.callErrorMesssage("VRM Avatar URL is not valid");
            }
          }
          else
            if (FunctionToBecalled.includes('LoadVRMAvatar')) {
              let url;
              const match = FunctionToBecalled.match(/LoadVRMAvatar_(.*?)_StoryID/);
              if (match) {
                url = match[1];
                console.log(url); // prints "someText"
              } else {
                console.log("No match found");
              }

              if (this.cachedStoryID == this.StoryID) {
                return;
              }

              if (url != null && url != undefined && url != '') {
                console.log("loading vrm", url);
                this.LoadVRMSnapshot(url, this.EnvUrl, this.GarmentsGender, this.BlobUrl);
              }
              else {
                this.callErrorMesssage("VRM Avatar URL is not valid");
              }
            }
            else
              if (FunctionToBecalled.includes('LoadOnlyAvatar')) {
                let url;
                const match = FunctionToBecalled.split("LoadOnlyAvatar_");
                console.log(match);
                if (match) {
                  url = match[1];
                  console.log(url); // prints "someText"
                } else {
                  console.log("No match found");
                }

                if (url != null && url != undefined && url != '') {
                  console.log("loading vrm", url);
                  this.Load3DSnapshot(url, this.EnvUrl, this.upperwearURL, this.bottomwearURL, this.headgearURL, this.footwearURL, this.GarmentsGender, this.BlobUrl);
                  this.AvatarUrl = null;
                }
                else {
                  this.callErrorMesssage("VRM Avatar URL is not valid");
                }
              }
              else {
                console.log('Invalid function name');
              }
    }
    this.cachedStoryID = this.StoryID
  }

  async Load3DSnapshot(_avatarURL, _envURL, _upperwearURL, _bottomwearURL, _headgearURL, _footwearURL, _gender, _snapshotImageURL) {
    try {
      console.log("LoadDefaultAvatar intilize");
      this.isloadingState = true
      this.lodingStateFlag.emit(this.isloadingState);
      this.isloadingForChange = true


      //-------remove previous avatar---------//
      if (this.currentVRM) {
        this.scene.remove(this.currentVRM.scene);
        VRMUtils.deepDispose(this.currentVRM.scene);
        this.hideDefaultVRMLighting();
      }

      this.hideAvatars("female");
      this.hideAvatars("male");
      this.RemoveLoadedClothesMesh(_gender);
      //-----end removing previous avatar-----//

      this.callLogMesssage("waiting for Avatar");
      console.log("waiting for Avatar");
      await this._sceneInitialized;

      if (! await this.checkAndLoadPrefabAvatar(_gender)) {
        this.callErrorMesssage("Avatar loading failed");
        throw new Error("Avatar loading failed");
      }

      if (_envURL != null && _envURL != undefined && _envURL != '') {
        this.hideDefaultBackground();
        await this.loadSkybox(_envURL);
      }

      if (_snapshotImageURL != null && _snapshotImageURL != undefined && _snapshotImageURL != '') {
        this.loadPostcard(_snapshotImageURL);
        // this.postcard.visible = !this.postcard.visible;
      }

      this.callLogMesssage("Starting Data Download");
      this.stopAnimationAndResetPose(_gender);

      if (_gender == 'M') {
        await this.DownloadAndLoadAvatar(_avatarURL, 'M', true);
        this.manageQuerryClothID(_gender);
        await this.DownloadAndLoadClothes(this.queryUpperwear, this.queryBottomwear, this.queryHeadgear, this.queryFootwear, 'M');
        this.showAvatars("male");
      }
      else {
        await this.DownloadAndLoadAvatar(_avatarURL, 'F', true);
        this.manageQuerryClothID(_gender);
        await this.DownloadAndLoadClothes(this.queryUpperwear, this.queryBottomwear, this.queryHeadgear, this.queryFootwear, 'F');
        this.showAvatars("female");
      }

      this.SettingFatness(_gender, this.InputFatness);
      this.callTempLogMessage("Avatar loaded", 5)

      this.functionIntilized = "loaded"
      this.calledFunction.emit(this.functionIntilized);
      console.log(this.functionIntilized);

      await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, _gender);

      this.queryBottomwear = null;
      this.queryFootwear = null;
      this.queryHeadgear = null;
      this.queryUpperwear = null;

      this.showDisplayText = '';
      this.isloadingState = false
      this.isloadingForChange = false
      this.lodingStateFlag.emit(this.isloadingState);
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }

  async LoadVRMSnapshot(_vrmAvatarURL, _envURL, _gender, _snapshotImageURL) {

    console.log("LoadDefaultAvatar intilize");
    this.isloadingState = true
    this.lodingStateFlag.emit(this.isloadingState);
    this.isloadingForChange = true

    //-------remove previous avatar---------//
    this.hideAvatars("female");
    this.hideAvatars("male");
    this.RemoveLoadedClothesMesh(_gender);

    if (this.currentVRM) {
      this.scene.remove(this.currentVRM.scene);
      VRMUtils.deepDispose(this.currentVRM.scene);
    }

    console.log("waiting for scene initialize");
    await this._sceneInitialized;


    this.callLogMesssage("Starting VRM Download");

    //this.showDefaultVRMLighting();

    await Promise.all([
      this.loadvrmtest(_vrmAvatarURL),
      this.loadSkybox(_envURL),
      this.loadPostcard(_snapshotImageURL)
    ]);

    if (_envURL != null) {
      this.showDefaultVRMLighting();
    }
    else {
      this.hideDefaultVRMLighting();
    }

    console.log("loaded vrm, skybox and postcard");
    this.queryBottomwear = null;
    this.queryFootwear = null;
    this.queryHeadgear = null;
    this.queryUpperwear = null;

    this.showDisplayText = '';
    this.isloadingState = false
    this.isloadingForChange = false
    this.lodingStateFlag.emit(this.isloadingState);
  }

  /**
    * Hides the avatars based on the specified gender.
    * If no gender is specified, hides both male and female avatars.
    *
    * @param gender - The gender of the avatar to hide. Can be either 'male' or 'female'.
    */
  async LoadDefaultAvatar(gender: 'M' | 'F') {
    try {
      console.log("LoadDefaultAvatar intilize");
      this.isloadingState = true
      this.lodingStateFlag.emit(this.isloadingState);
      this.isloadingForChange = true

      //-------remove previous avatar---------//
      this.hideAvatars("female");
      this.hideAvatars("male");
      this.RemoveLoadedClothesMesh(gender);

      if (this.postcard != null && this.postcard != undefined)
        this.postcard.visible = false;

      if (this.currentVRM) {
        this.scene.remove(this.currentVRM.scene);
        VRMUtils.deepDispose(this.currentVRM.scene);
        this.hideDefaultVRMLighting();
      }
      //-----end removing previous avatar-----//

      this.callLogMesssage("waiting for Avatar");
      console.log("waiting for Avatar");
      await this._sceneInitialized;

      if (! await this.checkAndLoadPrefabAvatar(gender)) {
        this.callErrorMesssage("Avatar loading failed");
        throw new Error("Avatar loading failed");
      }

      this.callLogMesssage("Starting Data Download");
      this.stopAnimationAndResetPose(gender);

      if (gender == 'M') {
        if (this.DefaultAvatar_M?.savedHairTex != undefined) {
          console.log("Male brand avatar called", this.DefaultAvatar_M);
          await this.DownloadAndLoadAvatar(this.DefaultAvatar_M, 'M');
        } else {
          console.log("male default avatar called", this.dUnzipeedAvatar_M);
          await this.DownloadAndLoadAvatar(this.dUnzipeedAvatar_M, 'M');
        }
        this.manageQuerryClothID(gender);
        await this.DownloadAndLoadClothes(this.queryUpperwear, this.queryBottomwear, this.queryHeadgear, this.queryFootwear, 'M');
        this.showAvatars("male");
      }
      else {
        if (this.DefaultAvatar_F?.savedHairTex != undefined) {
          console.log("Male brand avatar called", this.DefaultAvatar_F);
          await this.DownloadAndLoadAvatar(this.DefaultAvatar_F, 'F');
        } else {
          console.log("male default avatar called", this.dUnzipeedAvatar_F);
          await this.DownloadAndLoadAvatar(this.dUnzipeedAvatar_F, 'F');
        } this.manageQuerryClothID(gender);
        await this.DownloadAndLoadClothes(this.queryUpperwear, this.queryBottomwear, this.queryHeadgear, this.queryFootwear, 'F');
        this.showAvatars("female");
      }

      this.SettingFatness(gender, this.InputFatness);
      this.callTempLogMessage("Avatar loaded", 5)

      this.functionIntilized = "loaded"
      this.calledFunction.emit(this.functionIntilized);
      console.log(this.functionIntilized);

      await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, gender);

      this.queryBottomwear = null;
      this.queryFootwear = null;
      this.queryHeadgear = null;
      this.queryUpperwear = null;

      this.showDisplayText = '';
      this.isloadingState = false
      this.isloadingForChange = false
      this.lodingStateFlag.emit(this.isloadingState);
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }

  manageQuerryClothID(gender: 'M' | 'F') {
    // Define default mappings based on gender
    const defaults = {
      M: {
        bottomwear: this.defaultMaleBottomWear,
        upperwear: this.defaultMaleUpperWear,
        footwear: this.defaultMaleFootWear,
        headgear: this.defaultMaleHeadGear
      },
      F: {
        bottomwear: this.defaultFemaleBottomWear,
        upperwear: this.defaultFemaleUpperWear,
        footwear: this.defaultFemaleFootWear,
        headgear: this.defaultFemaleHeadGear
      }
    };

    // Get the appropriate defaults based on the gender
    const genderDefaults = defaults[gender];

    // Use a loop to assign defaults if necessary
    this.queryBottomwear = this.queryBottomwear || genderDefaults.bottomwear;
    this.queryUpperwear = this.queryUpperwear || genderDefaults.upperwear;
    this.queryFootwear = this.queryFootwear || genderDefaults.footwear;
    this.queryHeadgear = this.queryHeadgear || genderDefaults.headgear;
  }



  async LoadDefaulClothes() {
    this.isloadingState = true
    this.lodingStateFlag.emit(this.isloadingState);
    this.isloadingForChange = true
    await Promise.all([
      this.DownloadAndLoadClothes(this.defaultMaleUpperWear, this.defaultMaleBottomWear, null, null, 'M'),
      this.DownloadAndLoadClothes(this.defaultFemaleUpperWear, this.defaultFemaleBottomWear, null, null, 'F')
    ]);

    this.showDisplayText = '';
    this.isloadingState = false
    this.isloadingForChange = false
    this.lodingStateFlag.emit(this.isloadingState);
  }

  // #region Initialization
  private _isInitialized = false;
  manageInitialization() {
    if (this._isInitialized) {
      return;
    }

    this._isInitialized = true;

    this._sceneInitialized = new Promise(resolve => {
      this._resolveSceneInitialized = resolve;
    });

    this.initializeScene();
    this.InputFatness = 0;
  }

  // async initializeScene() {
  //   this.scene = null
  //   this.renderer = null
  //   this.stats.begin();
  //   const width = window.innerWidth;
  //   const height = window.innerHeight;
  //   console.log(height, width);
  //   this.callLogMesssage("Initializing 3D Scene");
  //   //for avatar 
  //   this.triangleData = await this.loadJsonFile();
  //   // Three.js setup
  //   this.scene = new THREE.Scene();
  //   this.camera = new THREE.PerspectiveCamera(45, width / height, 0.5, 100); // Set the width and height as needed
  //   this.renderer = new THREE.WebGLRenderer({ antialias: true });
  //   this.renderer.shadowMap.enabled = false;
  //   this.renderer.gammaOutput = true;
  //   this.renderer.gammaFactor = 2;

  //   // Set the renderer size to match the ppup dimensions
  //   // this.renderer.setSize(400, 750);

  //   this.renderer.outputEncoding = THREE.sRGBEncoding;
  //   this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
  //   this.renderer.toneMappingExposure = 0.4;
  //   this.renderer.physicallyCorrectLights = true;

  //   // Key Light - Bright, directional light
  //   const keyLight = new THREE.DirectionalLight(0xffffff, 0.8);
  //   keyLight.position.set(10, 10, 10);
  //   this.scene.add(keyLight);

  //   // Fill Light - Less intense, opposite side of the key light
  //   const fillLight = new THREE.DirectionalLight(0xffffff, 0.4);
  //   fillLight.position.set(-10, 5, 10);
  //   this.scene.add(fillLight);
  //   this.updateRendererSize();

  //   // Back Light - Positioned behind the subject to create a rim of light
  //   const backLight = new THREE.DirectionalLight(0xffffff, 0.6);
  //   backLight.position.set(0, 10, -10);
  //   backLight.castShadow = true; // Enable shadow for back light if needed
  //   this.scene.add(backLight);

  //   // Optionally, add an AmbientLight for a very soft and non-directional light

  //   const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
  //   this.scene.add(ambientLight);

  //   //add by praful
  //   this.threeDModelContainerMale.nativeElement.appendChild(this.renderer.domElement);
  //   // this.threeDModelContainerFemale.nativeElement.appendChild(this.renderer.domElement);


  //   this.controls = new OrbitControls(this.camera, this.renderer.domElement);

  //   //camera position
  //   this.camera.position.set(0, 1.6, 2.6);

  //   this.controls.target.set(-0.1, 0.9, 0);
  //   this.controls.update();


  //   this.loadDefaultBackground();

  //   // Animation loop
  //   const animate = () => {
  //     this.stats.begin();

  //     this.stats.end();
  //     this.calculateLoadingTime();

  //     requestAnimationFrame(animate);


  //     var delta = this.clock.getDelta();

  //     if (this.mixer) this.mixer.update(delta);

  //     // if (skeletonHelper) skeletonHelper.update(); // Remove or comment out this line

  //     if (this.controls) this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true


  //     this.renderer.render(this.scene, this.camera);
  //   };

  //   animate();

  //   await new Promise(resolve => setTimeout(resolve, 30));
  //   //await this.avatarPrefabLoader();
  //   //window.addEventListener('keydown', this.handleKeyDown.bind(this));

  //   this.hideAvatars("male");
  //   this.hideAvatars("female");

  //   await Promise.all([
  //     // this.LoadDefaultAvatar('M'),
  //     // this.LoadDefaultAvatar('F'),
  //     this.loadSkybox(this.defaultEnvironment)
  //   ]);

  //   console.log("Done loading default avatar and skybox");

  //   this._resolveSceneInitialized();
  //   this.SceneInitializationDone();
  //   this.showDisplayText = '';
  //   this.isloadingState = false
  //   this.isloadingForChange = false
  //   this.lodingStateFlag.emit(this.isloadingState);

  // }

  async initializeScene() {
    this.scene = null;
    this.renderer = null;
    this.stats.begin();
    const width = window.innerWidth;
    const height = window.innerHeight;
    console.log(height, width);
    this.callLogMesssage("Initializing 3D Scene");
    this.triangleData = await this.loadJsonFile();
  
    // Three.js setup
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 100);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.shadowMap.enabled = false;
    this.renderer.gammaOutput = true;
    this.renderer.gammaFactor = 2;
  
    // Dynamically set the renderer size based on the window
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.4;
    this.renderer.physicallyCorrectLights = true;
    this.renderer.xr.enabled = true;
  
    // Key Light - Bright, directional light
    const keyLight = new THREE.DirectionalLight(0xffffff, 0.8);
    keyLight.position.set(10, 10, 10);
    this.scene.add(keyLight);
  
    // Fill Light - Less intense, opposite side of the key light
    const fillLight = new THREE.DirectionalLight(0xffffff, 0.4);
    fillLight.position.set(-10, 5, 10);
    this.scene.add(fillLight);
    this.updateRendererSize();
  
    // Back Light - Positioned behind the subject to create a rim of light
    const backLight = new THREE.DirectionalLight(0xffffff, 0.6);
    backLight.position.set(0, 10, -10);
    backLight.castShadow = true;
    this.scene.add(backLight);
  
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    this.scene.add(ambientLight);
  
    this.threeDModelContainerMale.nativeElement.appendChild(this.renderer.domElement);
  
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.camera.position.set(0, 1.6, 2.6);
    this.controls.target.set(-0.1, 0.9, 0);
    this.controls.update();
  
    this.loadDefaultBackground();

    this.reticle = new THREE.Mesh(
      new THREE.RingGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2),
      new THREE.MeshBasicMaterial()
    );
    if (this.reticle)
      console.log("assigning ring geometry to reticle");
    this.reticle.matrixAutoUpdate = false;
    this.reticle.visible = false;
    this.scene.add(this.reticle);
    this.createARbutton()

    const directionalLight = new THREE.DirectionalLight(0xffffff, 5); // White light, intensity 1
    directionalLight.position.set(10, 10, 10); // Set position of the light
    this.scene.add(directionalLight); // Add the light to the scene

    console.log("AR Testing before animate call");

    // Animation loop
    const animate = (timestamp, frame) => {

      // console.log("animate function before return");
      if(this.isARsessionActive != true)
        return;

      // console.log("animate function after return");
      
      this.stats.begin();

      this.stats.end();
      var delta = this.clock.getDelta();

      if (this.mixer) this.mixer.update(delta);

      if (this.currentVRM) {
        this.currentVRM.update(delta);
      }

      if (this.controls) this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

      this.planeDetection(frame);
      this.renderer.alpha = true;
      console.log("Setting alpha to true");
      this.renderer.render(this.scene, this.camera);
    };

    console.log("AR Testing setAnimationLoop");

    // animate()/;
    this.renderer.setAnimationLoop(animate);

    const animateAnimation = ()=>{
      this.animationFrameId = requestAnimationFrame(animateAnimation);

      // console.log("running animateAnimation loop: ");
      this.stats.begin();

      this.stats.end();
      var delta = this.clock.getDelta();

      if (this.mixer) this.mixer.update(delta);

      if (this.currentVRM) {
        this.currentVRM.update(delta);
      }

      if (this.controls) this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
      // console.log("AR Testing: calling planeDetection");
      if(this.isARsessionActive == true)
        return;
      this.renderer.render(this.scene, this.camera);

    }

    animateAnimation();
    console.log("AR Testing after animate call");
  
    // const animate = () => {
    //   this.stats.begin();
    //   this.stats.end();
    //   this.calculateLoadingTime();
    //   requestAnimationFrame(animate);
  
    //   var delta = this.clock.getDelta();
  
    //   if (this.mixer) this.mixer.update(delta);
    //   if (this.controls) this.controls.update();
  
    //   this.renderer.render(this.scene, this.camera);
    // };
  
    // animate();
  
    await new Promise(resolve => setTimeout(resolve, 30));
  
    this.hideAvatars("male");
    this.hideAvatars("female");
  
    await Promise.all([
      this.loadSkybox(this.defaultEnvironment)
    ]);
  
    console.log("Done loading default avatar and skybox");
  
    this._resolveSceneInitialized();
    this.SceneInitializationDone();
    this.showDisplayText = '';
    this.isloadingState = false
    this.isloadingForChange = false
    this.lodingStateFlag.emit(this.isloadingState);
  
    // No need to call updateSizeForStore here, as the scene is initialized with normal size
  }
  
  updateRendererSize() {
    // Apply the new size to the renderer and camera
    this.renderer.setSize(this.newWidth, this.newHeight);
    this.camera.aspect = this.newWidth / this.newHeight;
    this.camera.updateProjectionMatrix();
  }

updateSizeForStore() {
  // setTimeout(() => {
  //   // Apply the new size to the renderer and camera after the timeout
  //   const newWidth = 230;
  //   const newHeight = 350;
  //   this.renderer.setSize(newWidth, newHeight);
  //   this.camera.aspect = newWidth / newHeight;
  //   this.camera.updateProjectionMatrix();
  // }, 100); // Timeout set to 1000 milliseconds (1 second)
  this.renderer.setSize(window.innerWidth, window.innerHeight);
    if (window.innerWidth <= 768) {
    } else {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    this.camera.aspect = this.renderer.domElement.width / this.renderer.domElement.height;
    this.camera.updateProjectionMatrix();
}



  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'm') {
      console.log("dsd", this.InputFatness);
      this.InputFatness += 1;
      console.log(this.InputFatness);
      this.adjustFatness('M', this.InputFatness);
    }
  }


  async loadJsonFile() {
    return this.http.get<any>('assets/male_triangle/Male_mesh_TriangleData.json').toPromise();
  }

  async avatarPrefabLoader() {
    try {
      console.log("avatarPrefabLoader initialize");

      this.callLogMesssage("Setting Up Avatar System");
      console.log("loadFemaleFBX avatarPrefabLoader initialize");

      await new Promise(resolve => setTimeout(resolve, 30));
      await this.loadFemaleFBX();

      console.log("loadFemaleFBX avatarPrefabLoader initialized...");

      await new Promise(resolve => setTimeout(resolve, 30));
      await this.loadMaleFBX();

      console.log("loadMaleFBX avatarPrefabLoader initialize");


      console.log("loadMaleFBX avatarPrefabLoader initialized...");
      await new Promise(resolve => setTimeout(resolve, 30));

      this.callLogMesssage("Done Setting Up Avatar System");
      this.callTempLogMessage('', 10);
      console.log("avatarPrefabLoader initialized......");

    } catch (error) {
      alert("Error occurred in avatarPrefabLoader: " + error);
      this.callErrorMesssage(error);
    }
  }

  async checkAndLoadPrefabAvatar(gender: 'M' | 'F') {
    if (gender === 'M') {
      let avatarParent = this.maleAvatarParent;

      if (avatarParent.BodyObject == null) {
        console.log("M prefab not loaded yet, waiting for 1 sec");
        try {
          await new Promise(resolve => setTimeout(resolve, 500));
          await this.loadMaleFBX();
          await new Promise(resolve => setTimeout(resolve, 30));
          return true;
        } catch (error) {
          console.log("Error in loading male avatar");
          return false;
        }
      }
      return true;
    } else {
      let avatarParent = this.femaleAvatarParent;

      if (avatarParent.BodyObject == null) {
        console.log("F prefab not loaded yet, waiting for 1 sec");
        try {
          await new Promise(resolve => setTimeout(resolve, 500));
          await this.loadFemaleFBX();
          await new Promise(resolve => setTimeout(resolve, 30));
          return true;
        } catch (error) {
          console.log("Error in loading female avatar");
          return false;
        }
      }
      return true;
    }
  }

  async loadFemaleFBX(): Promise<void> {
    return new Promise((resolve, reject) => {
      const avatarFBXPath = 'assets/avatarFBX/avatarBlendedFemale.fbx'; // Path relative to the assets folder
      var loader = new FBXLoader;
      loader.load(avatarFBXPath, async (object) => {
        this.femaleAvatarParent.BodyObject = object;
        this.femaleAvatarParent.BodyObject.visible = false;
        this.scene.add(this.femaleAvatarParent.BodyObject);
        await new Promise(resolve => setTimeout(resolve, 30));
        await this.CacheOriginalPoses('F');
        console.log("calling Check And Load");
        resolve();
      }, function (xhr) {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      }, (error) => {
        alert("Error occurred while loading the Female FBX file: " + error);
        this.callErrorMesssage('An error occurred while loading the FBX file: ' + error)
        reject(error);
      });
    });
  }

  async loadMaleFBX(): Promise<void> {
    return new Promise((resolve, reject) => {
      var loader = new FBXLoader;
      const avatarFBXPath = 'assets/avatarFBX/avatarBlendedMale.fbx'; // Path relative to the assets folder
      loader.load(avatarFBXPath, async (object) => {
        this.maleAvatarParent.BodyObject = object;
        this.maleAvatarParent.BodyObject.visible = false;
        this.scene.add(this.maleAvatarParent.BodyObject);
        await new Promise(resolve => setTimeout(resolve, 30));
        await this.CacheOriginalPoses('M');
        console.log("calling Check And Load");
        resolve();
      }, function (xhr) {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      }, (error) => {
        alert("Error occurred while loading the Male FBX file: " + error);
        this.callErrorMesssage('An error occurred while loading the FBX file: ' + error)
        reject(error);
      });
    });
  }

  // #endregion

  // #region Background and skyboxes
  loadDefaultBackground() {
    this.scene.background = this.dbDefaultBackgroundColor;

    this.dbAmbientLight = new THREE.AmbientLight(0x333333);
    this.scene.add(this.dbAmbientLight);

    this.dbDirectionalLight1 = new THREE.DirectionalLight(0xFFFFFF, 2);
    this.dbDirectionalLight1.position.set(0, 50, 20);
    this.scene.add(this.dbDirectionalLight1);

    this.dbDirectionalLight2 = new THREE.DirectionalLight(0xFFFFFF, 2);
    this.dbDirectionalLight2.position.set(0, 50, -20);
    this.dbDirectionalLight2.castShadow = true;
    this.scene.add(this.dbDirectionalLight2);

    const floorGeometry = new THREE.PlaneGeometry(100, 100);
    const floorMaterial = new THREE.MeshStandardMaterial({ color: 0xdddddd, side: THREE.DoubleSide });
    this.dbFloor = new THREE.Mesh(floorGeometry, floorMaterial);
    this.dbFloor.rotation.x = -Math.PI / 2;
    this.dbFloor.receiveShadow = true;
    this.scene.add(this.dbFloor);
  }

  hideDefaultBackground() {
    this.dbAmbientLight.visible = false;
    this.dbDirectionalLight1.visible = false;
    this.dbDirectionalLight2.visible = false;
    this.dbFloor.visible = false;
    this.scene.background = this.dbHiddenBackgroundColor;
  }

  showDefaultBackground() {
    this.dbAmbientLight.visible = true;
    this.dbDirectionalLight1.visible = true;
    this.dbDirectionalLight2.visible = true;
    this.dbFloor.visible = true;
    this.scene.background = this.dbDefaultBackgroundColor;
  }

  showDefaultVRMLighting() {
    if (!this.dbAmbientLight) {
      this.dbAmbientLight = new THREE.AmbientLight(0x333333);
      this.scene.add(this.dbAmbientLight);
    }
    else {
      this.dbAmbientLight.visible = true;
    }

    if (!this.dbDirectionalLight1) {
      this.dbDirectionalLight1 = new THREE.DirectionalLight(0xFFFFFF, 2);
      this.dbDirectionalLight1.position.set(0, 10, 20);
      this.scene.add(this.dbDirectionalLight1);
    }
    else {
      this.dbDirectionalLight1.visible = true;
    }

    if (!this.dbDirectionalLight2) {
      this.dbDirectionalLight2 = new THREE.DirectionalLight(0xFFFFFF, 2);
      this.dbDirectionalLight2.position.set(0, 10, -20);
      this.dbDirectionalLight2.castShadow = true;
      this.scene.add(this.dbDirectionalLight2);
    }
    else {
      this.dbDirectionalLight2.visible = true;
    }
    //modify light intensity
    console.log("loading default lighting");
    this.dbDirectionalLight1.intensity = 16;
    this.dbDirectionalLight2.intensity = 16;
  }

  hideDefaultVRMLighting() {
    //modify light intensity
    this.dbDirectionalLight1.intensity = 2;
    this.dbDirectionalLight2.intensity = 2;
  }

  async loadSkybox(envURL) {
    const self = this;

    console.log("environment url :", envURL);
    if (envURL == null || envURL == undefined || envURL == '' || envURL == 'default') {
      this.showDefaultBackground();
      return;
    }


    let compressedURL = await this.compressImage(envURL);
    if (!compressedURL || !compressedURL['downloadUrl']) {
      this.callErrorMesssage('Environment file not found it might be deleted');
      this.showDefaultBackground();
      return;
    }

    const loader = new THREE.TextureLoader();
    loader.load(
      compressedURL['downloadUrl'],
      function (texture) {
        self.hideDefaultBackground();
        texture.mapping = THREE.EquirectangularReflectionMapping;
        texture.generateMipmaps = true; // Enable mipmapping
        texture.minFilter = THREE.LinearMipmapLinearFilter; // Set minFilter for mipmap
        console.log("MiniMapping");
        self.scene.background = texture;
        self.scene.environment = texture;
        console.log("calling Check And Load");
      }
    );
    this.skyboxLoaded.emit(true)
  }

  async compressImage(_envURL: string) {
    const imageUrlParts = _envURL.split("o/");
    const filenamePart = imageUrlParts[1].split("?alt=media");
    const filePath = decodeURIComponent(filenamePart[0]);
    const filePathParts = filePath.split("/");
    const filename = filePathParts[filePathParts.length - 1];
    const path = 'Environment/'+filename
    const functionUrl = 'https://us-central1-yolomoves-fb435.cloudfunctions.net/compressImage';
    try {
      const response = await this.http.post(functionUrl, {path:path, type:'e' ,}).toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  async loadPostcard(_snapshotImageURL) {

    if (this.postcard) {
      this.scene.remove(this.postcard);
      this.postcard = null;
    }

    const imageLoader = new THREE.ImageLoader();
    imageLoader.load(_snapshotImageURL, image => {
      // Get the dimensions of the image
      const width = image.width;
      const height = image.height;

      // Decide on a conversion factor
      const conversionFactor = 0.002;

      // Create a texture from the image
      const texture = new THREE.Texture(image);
      texture.needsUpdate = true;

      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide // Make the material double-sided
      });

      // Create a geometry that matches the dimensions of the image
      const geometry = new THREE.PlaneGeometry(width * conversionFactor, height * 0.003);

      this.postcard = new THREE.Mesh(geometry, material);

      this.postcard.position.y = 1; // Position the postcard 1 unit above the ground
      this.postcard.position.x = -1; // Move the postcard -1 unit in the x direction
      this.postcard.rotation.y = Math.PI / 2; // Rotate the postcard 90 degrees

      this.scene.add(this.postcard);
    });
  }

  togglePostcard() {
    if (this.postcard) {
      this.postcard.visible = !this.postcard.visible;
    }
  }

  FocusOnStory() {
    //     console.log("Adjustments - Position:", this.cameraAdjustment, "Rotation:", this.orbitControlTargetAdjustment);
    //     // this.cameraAdjustment = new THREE.Vector3(0, 1.5, 2.5);
    //     // this.orbitControlTargetAdjustment = new THREE.Vector3(1.3877787807814457e-17, 0.8999999999999998, 0);
    //     // Apply position adjustments
    //     this.camera.position.set(
    //       this.cameraAdjustment.x,
    //       this.cameraAdjustment.y,
    //       this.cameraAdjustment.z
    //     );

    //     // Apply rotation adjustments
    // // Apply rotation adjustments, converting degrees to radians
    //     this.controls.target = this.orbitControlTargetAdjustment;

    // Apply position directly
    this.camera.position.set(0.5, 1, 2.5);

    // Apply rotation directly
    this.controls.target = new THREE.Vector3(-2.9999999999999982, 1.0000000000000007, 0);

    console.log("Camera Position:", this.camera.position);
    console.log("Camera Rotation:", this.camera.rotation);

    // Update the controls if necessary
    if (this.controls) {
      this.controls.update();
    }
  }

  FocusOnAvatar() {
    //     console.log("Adjustments - Position:", this.cameraAdjustment, "Rotation:", this.orbitControlTargetAdjustment);
    //     this.cameraAdjustment = new THREE.Vector3(0, 1.5, 2.5);
    //     this.orbitControlTargetAdjustment = new THREE.Vector3(1.3877787807814457e-17, 0.8999999999999998, 0);

    //     console.log("Adjustments - Position:", this.cameraAdjustment, "Rotation:", this.orbitControlTargetAdjustment);
    //     // Apply position adjustments
    //     this.camera.position.set(
    //       this.cameraAdjustment.x,
    //       this.cameraAdjustment.y,
    //       this.cameraAdjustment.z
    //     );

    //     // Apply rotation adjustments
    // // Apply rotation adjustments, converting degrees to radians
    //     this.controls.target = this.orbitControlTargetAdjustment;

    // Apply position directly
    this.camera.position.set(0, 1.5, 2.5);

    // Apply rotation directly
    this.controls.target = new THREE.Vector3(1.3877787807814457e-17, 0.8999999999999998, 0);

    console.log("Camera Position:", this.camera.position);
    console.log("Camera Rotation:", this.camera.rotation);

    // Update the controls if necessary
    if (this.controls) {
      this.controls.update();
    }
  }
  // #endregion

  // #region Logging and Error Handeling

  //Error Calls
  callErrorMesssage(Messagestring) {
    console.log(Messagestring);
    this.showDisplayText = Messagestring;
    //Error Handling in future
    //give option to reload
  }

  //Logging updates
  callLogMesssage(Messagestring) {
    console.log(Messagestring);
    //this.showDisplayText = Messagestring;
  }

  //For set Time
  callTempLogMessage(Messagestring, time) {
    console.log(Messagestring);
    this.showDisplayText = Messagestring;
    setTimeout(() => {
      this.showDisplayText = '';
    }, time);
  }

  async SceneInitializationDone() {
    await this._sceneInitialized;

    // Scene initialized
    console.log("******************Scene Initialized******************");
    this.setflagSceneInitialized = true
    this.sceneDone.emit(this.setflagSceneInitialized);

  }

  // #endregion

  // #region Avatar

  /**
   * Hides the avatars based on the specified gender.
   * If no gender is specified, hides both male and female avatars.
   *
   * @param gender - The gender of the avatar to hide. Can be either 'male' or 'female'.
   */
  async hideAvatars(gender?: 'male' | 'female') {
    // If no gender is specified or if gender is 'female', hide all parts of the female avatar
    if (!gender || gender === 'female') {
      for (let key in this.femaleAvatarParent) {
        if (this.femaleAvatarParent[key]) {
          this.femaleAvatarParent[key].visible = false;
        }
      }
    }

    // If no gender is specified or if gender is 'male', hide all parts of the male avatar
    if (!gender || gender === 'male') {
      for (let key in this.maleAvatarParent) {
        if (this.maleAvatarParent[key]) {
          this.maleAvatarParent[key].visible = false;
        }
      }
    }
  }

  /**
   * Shows the avatars based on the specified gender.
   * If no gender is specified, shows both male and female avatars.
   *
   * @param gender - The gender of the avatar to show. Can be either 'male' or 'female'.
   */
  async showAvatars(gender?: 'male' | 'female') {
    console.log("show waiting for scene initialize");
    await this._sceneInitialized;

    const avatarParent = gender === 'male' ? this.maleAvatarParent : this.femaleAvatarParent;

    if (avatarParent.BodyObject == null) {
      if (gender == 'male') {
        this.LoadDefaultAvatar('M');
      }
      else {
        this.LoadDefaultAvatar('F');
      }
      return;
    }
    // If no gender is specified or if gender is 'female', show all parts of the female avatar
    if (!gender || gender === 'female') {
      for (let key in this.femaleAvatarParent) {
        if (this.femaleAvatarParent[key]) {
          this.femaleAvatarParent[key].visible = true;
        }
      }

      if (this.femaleAvatarParent.BodyObject) {
        await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, 'F');
      }

      this.hideAvatars('male');
    }

    // If no gender is specified or if gender is 'male', show all parts of the male avatar
    if (!gender || gender === 'male') {
      for (let key in this.maleAvatarParent) {
        if (this.maleAvatarParent[key]) {
          this.maleAvatarParent[key].visible = true;
        }
      }

      if (this.maleAvatarParent.BodyObject) {
        await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, 'M');
      }
      this.hideAvatars('female');
    }


  }

  async DownloadAvatarDataURL(avatarURL) {
    const response = await fetch(avatarURL);
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return arrayBuffer;
  }

  async DownloadAllAvatarData(avatarURLs) {
    const keys = Object.keys(avatarURLs);
    //Order of fileNames must match order of avatarURLs
    const fileNames = ['savedHairTex.png', 'savedMetalRoughTex.jpg', 'savedNormalTex.jpg', 'savedTex.jpg', 'savefile.json'];

    const data = {};

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const url = avatarURLs[key];
      data[fileNames[i]] = await this.DownloadAvatarDataURL(url);
    }
    return data;
  }

  async DownloadAvatarFirebaseFun(url: string) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/processAvatarDataZipForThreads";
    const response = await this._httpClient.post<any>(functionUrl, { url: url }).toPromise();
    const responseSize = new Blob([JSON.stringify(response)]).size;
    console.log(`Size of the response: ${responseSize} bytes`);

    let result = {};
    for (let key in response) {
      if (response[key] !== 'Not found') {
        result[key] = this.convertBase64ToArrayBuffer(response[key]);

      } else {
        result[key] = 'Not found';
      }
    }

    return result;
  }

  async DownloadAndLoadAvatar(avatarURL, gender, canExtract = false) {

    let avatarDataReceived;
    try {

      // if (! await this.checkAndLoadPrefabAvatar(gender)) {
      //   this.callErrorMesssage("Avatar loading failed");
      //   throw new Error("Avatar loading failed");
      // }

      // avatarDataReceived = await this.downloadZipService.downloadAndProcessAvatarDataZip(avatarURL);
      if (canExtract == false) {
        avatarDataReceived = await this.DownloadAllAvatarData(avatarURL);
      }
      else {
        avatarDataReceived = await this.DownloadAvatarFirebaseFun(avatarURL);
      }

      // 
      console.log("Avatar Data Received", avatarDataReceived);
      if (avatarDataReceived == null) {
        this.callErrorMesssage("Avatar data not found");
      }

      let { material, hairmaterial, clothSaveFileJSON } = await this.LoadAvatarMeshData(avatarDataReceived);

      avatarDataReceived = null;

      let avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
      let avatarData = gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;
      avatarData.Fatness = clothSaveFileJSON.fatBlendshapeValue;
      avatarData.HeightScale = clothSaveFileJSON.heightScale;

      avatarParent.BodyObject.visible = true;

      this.callLogMesssage("Loading Avatar");
      console.log("Loading Avatar");

      await this.updateAvatarMeshWithData(material, hairmaterial, clothSaveFileJSON, avatarParent, avatarData);
      material.dispose();
      hairmaterial.dispose();
      clothSaveFileJSON = null;
      avatarParent = null;
      avatarData = null;

    } catch (error) {
      // Handle specific error
      if (error.status === 404) {
        this.callErrorMesssage("Avatar data not found : " + error.status);
      } else {
        // Handle other errors
        this.callErrorMesssage("Avatar data not found : " + error);
      }

      throw error;
    }
  }



  // async DownloadAndLoadAvatar(avatarURL, gender) {
  //   try {
  //     if (!await this.checkAndLoadPrefabAvatar(gender)) {
  //       this.callErrorMesssage("Avatar loading failed");
  //       throw new Error("Avatar loading failed");
  //     }

  //     // Convert the Observable to a Promise
  //     const data = await firstValueFrom(await this.downloadZipService.downloadAndProcessAvatarDataZip(avatarURL));
  // console.log("data",data)
  //     if (!data) {
  //       this.callErrorMesssage("Avatar data not found");
  //       return;
  //     }

  //     if (!this.avatarFiles) {
  //       this.avatarFiles = {};
  //     }

  //     // Process data
  //     Object.keys(data).forEach(key => {
  //       console.log(`Converting ${key}`);
  //       if (data[key]) { // Ensure there's data to convert
  //         this.avatarFiles[key] = this.convertBase64ToArrayBuffer(data[key].replace(/^data:.+;base64,/, ''));
  //         console.log(`Converted ${key}:`, this.avatarFiles[key]);
  //       } else {
  //         console.log(`No data for ${key}`);
  //       }
  //     });

  //     console.log('avatarFiles:', this.avatarFiles);

  //     // Load and use the avatar data
  //     const { material, hairmaterial, clothSaveFileJSON } = await this.LoadAvatarMeshData(this.avatarFiles);
  //     const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
  //     const avatarData = gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;
  //     avatarData.Fatness = clothSaveFileJSON.fatBlendshapeValue;
  //     avatarData.HeightScale = clothSaveFileJSON.heightScale;

  //     avatarParent.BodyObject.visible = true;

  //     this.callLogMesssage("Loading Avatar");
  //     console.log("Loading Avatar");

  //     await this.updateAvatarMeshWithData(material, hairmaterial, clothSaveFileJSON, avatarParent, avatarData);
  //   } catch (error) {
  //     console.error("Error in DownloadAndLoadAvatar:", error);
  //     this.callErrorMesssage(`Avatar data not found: ${error.message || error}`);
  //     throw error;
  //   }
  // }


  async updateAvatarMeshWithData(material, hairmaterial, clothSaveFileJSON, avatarParent, avatarData) {
    try {
      let headbone;
      avatarParent.BodyObject.traverse(async child => {
        if (child instanceof THREE.SkinnedMesh) {

          let triangles = [];
          for (let i = 0; i < this.triangleData.Triangles.length; i += 3) {
            triangles.push(this.triangleData.Triangles[i]);
            triangles.push(this.triangleData.Triangles[i + 2]);
            triangles.push(this.triangleData.Triangles[i + 1]);
          }

          let vertices = clothSaveFileJSON.vertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));
          let expandedVertices = [];
          for (let i = 0; i < triangles.length; i++) {
            const vertexIndex = triangles[i];
            const vertex = vertices[vertexIndex];
            expandedVertices.push(vertex.x, vertex.y, vertex.z);
          }

          vertices = null;
          for (let i = 0; i < expandedVertices.length; i++) {
            child.geometry.attributes.position.array[i] = expandedVertices[i];
          }
          child.geometry.attributes.position.needsUpdate = true;
          expandedVertices = null;

          let normals = [];
          normals = clothSaveFileJSON.normals.map(n => new THREE.Vector3(n.x, n.y, -n.z));

          let expandedNormals = [];
          for (let i = 0; i < triangles.length; i++) {
            const normalIndex = triangles[i];
            const normal = normals[normalIndex];
            expandedNormals.push(normal.x, normal.y, normal.z);
          }

          normals = null;
          // Update the normals array
          for (let i = 0; i < expandedNormals.length; i++) {
            child.geometry.attributes.normal.array[i] = expandedNormals[i];
          }
          child.geometry.attributes.normal.needsUpdate = true;
          expandedNormals = null;

          let uvCoordinates = clothSaveFileJSON.uv.map(uv => new THREE.Vector2(uv.x, uv.y));


          let expandedUVs = [];
          for (let i = 0; i < triangles.length; i++) {
            const uvIndex = triangles[i];
            const uv = uvCoordinates[uvIndex];
            expandedUVs.push(uv.x, uv.y);
          }
          uvCoordinates = null;

          for (let i = 0; i < expandedUVs.length; i++) {
            child.geometry.attributes.uv.array[i] = expandedUVs[i];
          }
          child.geometry.attributes.uv.needsUpdate = true;
          expandedUVs = null;

          await new Promise(resolve => setTimeout(resolve, 100));
          // If your geometry uses morph targets or skinning, you may also need to update the bounding box
          child.geometry.computeBoundingBox();

          await new Promise(resolve => setTimeout(resolve, 100));
          child.geometry.computeBoundingSphere();

          await new Promise(resolve => setTimeout(resolve, 100));
          var rotationMatrix = new THREE.Matrix4().makeRotationY(Math.PI);
          child.geometry.applyMatrix4(rotationMatrix);
          rotationMatrix = null;

          child.material = material;
          material.dispose();
        }
        if (child instanceof THREE.Bone) {
          if (child.name == "Head") {
            headbone = child;
            console.log("Headbone found", headbone);
          }
        }
      });


      avatarParent.BodyObject.scale.set(1, 1, 1);
      console.log("bodyscale");

      this.removeHair(avatarParent, avatarData);
      console.log("Hair removed");
      await new Promise(resolve => setTimeout(resolve, 30));

      console.log("creating new hair");
      avatarParent.HairObject = await (await this.LoadHairData(clothSaveFileJSON, hairmaterial, headbone, avatarData)).hairMesh;
      console.log("new Hair created", avatarParent);

      // avatarParent.BodyObject.scale.set(avatarData.HeightScale, avatarData.HeightScale, avatarData.HeightScale);
    } catch (error) {
      console.error("Error in updateAvatarMeshWithData:", error);
      throw error;
    }
  }

  async LoadAvatarMeshData(data) {

    const albedoTexture = await this.arrayBufferToTexture(data['savedTex.jpg']);
    const normalTexture = await this.arrayBufferToTexture(data['savedNormalTex.jpg']);
    const hairTexture = await this.arrayBufferToTexture(data['savedHairTex.png']);
    const metalTexture = data['savedMetalRoughTex.jpg'] ? await this.arrayBufferToTexture(data['savedMetalRoughTex.jpg']) : null;

    albedoTexture.wrapS = albedoTexture.wrapT = THREE.RepeatWrapping;
    normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    hairTexture.wrapS = hairTexture.wrapT = THREE.RepeatWrapping;

    if (metalTexture) {
      const metal = metalTexture.wrapT = THREE.RepeatWrapping;
      metalTexture.wrapS = await metal;
    }

    const material = new THREE.MeshStandardMaterial({
      map: albedoTexture,
      normalMap: normalTexture,
      metalness: metalTexture ? undefined : 0.3, // If there is no metalness texture, set a default metalness
      envMap: this.scene.environment, // Set the environment map
      side: THREE.DoubleSide,
    });

    albedoTexture.dispose();
    normalTexture.dispose();
    metalTexture?.dispose();

    const hairmaterial = new THREE.MeshStandardMaterial({
      map: hairTexture,
      transparent: true,
      alphaTest: 0.5
    });

    hairTexture.dispose();

    const clothSaveFileJSON = await JSON.parse(new TextDecoder().decode(data['savefile.json']));
    data = null;

    this.fatness = clothSaveFileJSON.fatBlendshapeValue;

    return {
      material: material,
      hairmaterial: hairmaterial,
      clothSaveFileJSON: clothSaveFileJSON,
    };
  }

  removeHair(avatarParent, avatarData) {

    if (avatarParent.HairObject) {

      console.log("Remove Hair Function Called", avatarParent, avatarData);
      avatarData.Headbone.remove(avatarParent.HairObject);
      this.scene.remove(avatarParent.HairObject);
      avatarParent.HairObject = null;
      avatarData.Headbone = null;
    }
  }

  async LoadHairData(clothSaveFileJSON, hairmaterial, headbone, avatarData) {

    console.log("Called LoadHairData");
    let hairVertices = clothSaveFileJSON.hairVertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));

    let hairTriangles = [];
    for (let i = 0; i < clothSaveFileJSON.hairTriangles.length; i += 3) {
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i]);
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i + 2]);
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i + 1]);
    }
    let flattenedhairUVs = clothSaveFileJSON.hairUv.map(uv => [uv.x, uv.y]).flat();

    console.log("modified data");

    let hairGeometry = new THREE.BufferGeometry();
    hairGeometry.setFromPoints(hairVertices);
    hairGeometry.setIndex(hairTriangles);
    hairGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(flattenedhairUVs.flat(), 2));
    hairGeometry.computeVertexNormals();


    hairVertices = null;
    hairTriangles = null;
    flattenedhairUVs = null;

    hairGeometry.scale(1 / avatarData.HeightScale, 1 / avatarData.HeightScale, 1 / avatarData.HeightScale);
    console.log("Creating hairgeometry");

    const hairMesh = new THREE.Mesh(hairGeometry, hairmaterial);

    hairGeometry = null;

    hairMesh.position.set(0, 0, 0);
    hairMesh.rotation.y = Math.PI;
    this.scene.add(hairMesh);

    const globalPosition = new THREE.Vector3();
    headbone.getWorldPosition(globalPosition);

    let pivot = new THREE.Object3D();
    pivot.position.copy(globalPosition);

    hairMesh.position.sub(globalPosition);

    pivot.add(hairMesh);
    console.log("hair mesh positioning");
    // Now, pivotMesh's position is at the headbone's global position, and hairMesh is correctly positioned relative to pivotMesh
    this.scene.add(pivot);
    headbone.add(pivot);
    pivot.position.set(0, 0, 0);
    avatarData.Headbone = headbone;
    console.log("done hair mesh positioning");
    return {
      hairMesh: pivot
    };
  }

  // #endregion

  // #region VRM
  async loadvrmtest(vrmURL): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.crossOrigin = 'anonymous';

      // Install GLTFLoader plugin
      loader.register((parser) => {
        return new VRMLoaderPlugin(parser);
      });

      loader.load(
        vrmURL,
        (gltf) => {
          const vrm = (gltf.userData as any)['vrm'];
          if (this.currentVRM) {
            this.scene.remove(this.currentVRM.scene);
            VRMUtils.deepDispose(this.currentVRM.scene);
          }
          this.currentVRM = vrm; // Update the current VRM model
          this.scene.add(vrm.scene);

          vrm.scene.traverse((obj) => {
            obj.frustumCulled = false;
          });

          this.mixer = new THREE.AnimationMixer(this.currentVRM.scene);

          // Load animation
          this.loadMixamoAnimationVRM(this.defaultAnimationFemale, this.currentVRM).then((clip) => {

            // Apply the loaded animation to mixer and play
            this.mixer.clipAction(clip).play();


          });
          // rotate if the VRM is VRM0.0
          VRMUtils.rotateVRM0(vrm);

          console.log(vrm);

          resolve(); // Resolve the promise when the VRM model is loaded
        },
        (progress) => {
          const percentage = Math.round(100.0 * (progress.loaded / progress.total));
          // loadingPercentage.innerText = `${percentage}%`;
        },
        (error) => {
          console.error(error);
          //loadingScreen.style.display = 'none';
          reject(error); // Reject the promise if there's an error
        }
      );
    });
  }

  async loadMixamoAnimationVRM(url, vrm) {

    const loader = new FBXLoader(); // A loader which loads FBX
    return loader.loadAsync(url).then((asset) => {

      const clip = THREE.AnimationClip.findByName(asset.animations, 'mixamo.com'); // extract the AnimationClip

      const tracks = []; // KeyframeTracks compatible with VRM will be added here

      const restRotationInverse = new THREE.Quaternion();
      const parentRestWorldRotation = new THREE.Quaternion();
      const _quatA = new THREE.Quaternion();
      const _vec3 = new THREE.Vector3();

      // Adjust with reference to hips height.
      const motionHipsHeight = asset.getObjectByName('mixamorigHips').position.y;
      const vrmHipsY = vrm.humanoid?.getNormalizedBoneNode('hips').getWorldPosition(_vec3).y;
      const vrmRootY = vrm.scene.getWorldPosition(_vec3).y;
      const vrmHipsHeight = Math.abs(vrmHipsY - vrmRootY);
      const hipsPositionScale = vrmHipsHeight / motionHipsHeight;

      clip.tracks.forEach((track) => {

        // Convert each tracks for VRM use, and push to `tracks`
        const trackSplitted = track.name.split('.');
        const mixamoRigName = trackSplitted[0];
        const vrmBoneName = this.mixamoRigMapVRM[mixamoRigName];

        const vrmNodeName = vrm.humanoid?.getNormalizedBoneNode(vrmBoneName)?.name;

        const mixamoRigNode = asset.getObjectByName(mixamoRigName);

        if (vrmNodeName != null) {

          const propertyName = trackSplitted[1];

          // Store rotations of rest-pose.
          mixamoRigNode.getWorldQuaternion(restRotationInverse).invert();
          mixamoRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

          if (track instanceof THREE.QuaternionKeyframeTrack) {

            // Retarget rotation of mixamoRig to NormalizedBone.
            for (let i = 0; i < track.values.length; i += 4) {

              const flatQuaternion = track.values.slice(i, i + 4);

              _quatA.fromArray(flatQuaternion);

              // 親のレスト時ワールド回転 * トラックの回転 * レスト時ワールド回転の逆
              _quatA
                .premultiply(parentRestWorldRotation)
                .multiply(restRotationInverse);

              _quatA.toArray(flatQuaternion);

              flatQuaternion.forEach((v, index) => {

                track.values[index + i] = v;

              });

            }

            tracks.push(
              new THREE.QuaternionKeyframeTrack(
                `${vrmNodeName}.${propertyName}`,
                track.times,
                track.values.map((v, i) => (vrm.meta?.metaVersion === '0' && i % 2 === 0 ? - v : v)),
              ),
            );

          } else if (track instanceof THREE.VectorKeyframeTrack) {

            const value = track.values.map((v, i) => (vrm.meta?.metaVersion === '0' && i % 3 !== 1 ? - v : v) * hipsPositionScale);
            tracks.push(new THREE.VectorKeyframeTrack(`${vrmNodeName}.${propertyName}`, track.times, value));

          }

        }

      });
      return new THREE.AnimationClip('vrmAnimation', clip.duration, tracks);
    });

  }
  // #endregion

  // #region Clothes

  async processClothZip(url: string) {

    let functionUrl = this.downloadAndExtractClothZipData;
    const response = await this._httpClient.post<any>(functionUrl, { url: url }).toPromise();
    const responseSize = new Blob([JSON.stringify(response)]).size;
    console.log(`Size of the response: ${responseSize} bytes`);

    let result = {};
    for (let key in response) {
      if (response[key] !== 'Not found') {
        result[key] = this.convertBase64ToArrayBuffer(response[key]);
      } else {
        result[key] = 'Not found';
      }
    }
    return result;
  }

  convertBase64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64); // Decode base64 string
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  UpperwearUlr_M = {
    Albedo: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2Fcloth_20230211165145810%2FAlbedo.jpg?alt=media&token=0b0bdef4-6376-4ffb-bd7c-22476a5b1b7c',
    Normal: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2Fcloth_20230211165145810%2FNormal.png?alt=media&token=8d26704d-db5f-4fec-b8b0-035fcddb149a',
    Metal: '',
    clothSaveFile: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestAvatars%2Fcloth_20230211165145810%2FclothSaveFile.json?alt=media&token=35a54613-d8ab-40ac-9032-1f1e6415a399'
  }

  async DownloadAllClothsData(ClothURLs) {

    const keys = Object.keys(ClothURLs);

    //Order of fileNames must match order of avatarURLs
    const fileNames = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];

    const data = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const url = ClothURLs[key];
      if (url && url.trim() !== '') {
        data[fileNames[i]] = await this.DownloadClothsDataURL(url);
      } else {
        data[fileNames[i]] = "Not Found";
      }
    }
    return data;
  }

  async DownloadClothsDataURL(avatarURL) {
    const response = await fetch(avatarURL);
    const blob = await response.blob();
    console.log(`Size of the response: ${blob.size} bytes`);
    const arrayBuffer = await blob.arrayBuffer();
    return arrayBuffer;
  }

  async DownloadAndLoadClothesByType(_clothURL, _type, _gender, isLoadbyType) {

    let ClothDataRecieved;
    const avatarParent = _gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
    const avatarData = _gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;

    if (isLoadbyType) {
      this.isloadingState = true
      this.lodingStateFlag.emit(this.isloadingState);
      this.isloadingForChange = true
      this.stopAnimationAndResetPose(_gender);
      this.callLogMesssage("Downloading clothes");
      this.RemoveLoadedClothesMeshType(_type, _gender);

      //Adjustment for blendshapes management
      avatarParent.BodyObject.scale.set(1, 1, 1);

      if (avatarParent.HairObject) {
        console.log("Removing Hair");
        avatarData.Headbone.remove(avatarParent.HairObject);
        this.scene.remove(avatarParent.HairObject);
      }
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    try {
      ClothDataRecieved = await this.processClothZip(_clothURL);

      if (ClothDataRecieved == null) {
        this.callErrorMesssage("Cloth data not found");
        throw new Error("Cloth data not found");
      }

      this.callLogMesssage("Clothes downloaded...Creating Mesh");

      await this.UpdateClothMeshData(ClothDataRecieved, _gender, avatarParent, avatarData);
      console.log("no issue in loading cloth  DownloadAndLoadClothesByType");

      //Adjustment for blendshapes management

      if (isLoadbyType) {
        await new Promise(resolve => setTimeout(resolve, 100));
        avatarParent.BodyObject.scale.set(avatarData.HeightScale, avatarData.HeightScale, avatarData.HeightScale);
        await new Promise(resolve => setTimeout(resolve, 100));

        if (avatarParent.HairObject) {
          console.log("Adding Hair", avatarParent);
          console.log("Headbone", avatarData);
          avatarData.Headbone.add(avatarParent.HairObject);
        }
        await new Promise(resolve => setTimeout(resolve, 100));

        if (_gender == 'M') {
          this.maleAvatarParent.BodyObject.visible = true;
        }
        else {
          this.femaleAvatarParent.BodyObject.visible = true;
        }

        this.SettingFatness(_gender, this.InputFatness);


        await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, _gender);
        this.callTempLogMessage("loadedclothes", 1);
        this.showDisplayText = '';
        this.isloadingState = false
        this.isloadingForChange = false
        this.lodingStateFlag.emit(this.isloadingState);
      }
    }
    catch (error) {
      this.callErrorMesssage('Error processing cloth data ZIPs: ' + error);
      throw error;
    }
  }

  async DownloadAndLoadClothes(_upperwearURL, _bottomwearURL, _headgearURL, _footwearURL, _gender) {

    let ClothDataRecieved;
    const avatarParent = _gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
    const avatarData = _gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;

    this.isloadingState = true
    this.lodingStateFlag.emit(this.isloadingState);
    this.isloadingForChange = true
    this.stopAnimationAndResetPose(_gender);
    this.callLogMesssage("Downloading clothes");

    //Adjustment for blendshapes management
    avatarParent.BodyObject.scale.set(1, 1, 1);

    if (avatarParent.HairObject) {
      console.log("Removing Hair");
      avatarData.Headbone.remove(avatarParent.HairObject);
      this.scene.remove(avatarParent.HairObject);
    }
    await new Promise(resolve => setTimeout(resolve, 100));


    if (_upperwearURL) {
      await this.DownloadAndLoadClothesByType(_upperwearURL, 'upperwear', _gender, false);
    }

    console.log("CLothLoaded1");
    if (_bottomwearURL) {
      await this.DownloadAndLoadClothesByType(_bottomwearURL, 'bottomwear', _gender, false);
    }

    console.log("CLothLoaded2");
    if (_headgearURL) {
      await this.DownloadAndLoadClothesByType(_headgearURL, 'headgear', _gender, false);
    }

    if (_footwearURL) {
      await this.DownloadAndLoadClothesByType(_footwearURL, 'footwear', _gender, false);
    }

    console.log("Scalling fix trying");
    avatarParent.BodyObject.scale.set(avatarData.HeightScale, avatarData.HeightScale, avatarData.HeightScale);
    await new Promise(resolve => setTimeout(resolve, 100));
    console.log("Adding hair back to where it belongs");
    if (avatarParent.HairObject) {
      console.log("Adding Hair", avatarParent);
      console.log("Headbone", avatarData);
      avatarData.Headbone.add(avatarParent.HairObject);
    }
    await new Promise(resolve => setTimeout(resolve, 100));

    if (_gender == 'M') {
      this.maleAvatarParent.BodyObject.visible = true;
    }
    else {
      this.femaleAvatarParent.BodyObject.visible = true;
    }
    console.log("setting fatness and visibility ");
    this.SettingFatness(_gender, this.InputFatness);


    //await this.LoadMixamoAnimationFBX(this.defaultAnimationMale, _gender);
    console.log("managing logs");
    this.callTempLogMessage("loadedclothes", 1);
    this.showDisplayText = '';
    this.isloadingState = false
    this.isloadingForChange = false
    this.lodingStateFlag.emit(this.isloadingState);
    /*
    let ClothDataRecieved;
    const avatarParent = _gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
    const avatarData = _gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;

    
    // Add URLs to the array after checking if they are not undefined or empty
    let allUrls = [];
   
    if (_bottomwearURL) allUrls.push(_bottomwearURL);
    if (_headgearURL) allUrls.push(_headgearURL);
    if (_footwearURL) allUrls.push(_footwearURL);
    if (_upperwearURL) allUrls.push(_upperwearURL);

    console.log("allUrls",allUrls)


    
    try {
      
      ClothDataRecieved = await this.downloadZipService.downloadAndProcessClothDataZips(allUrls);
      
      if (ClothDataRecieved == null || ClothDataRecieved.length != allUrls.length) {
        this.callErrorMesssage("Cloth data not found");
        throw new Error("Cloth data not found");
      }

      this.callLogMesssage("Clothes downloaded...Creating Mesh");
      console.log('All cloth data received', ClothDataRecieved);

      //Adjustment for blendshapes management
       if (avatarParent.HairObject) {
         console.log("Removing Hair");
         avatarData.Headbone.remove(avatarParent.HairObject);
         this.scene.remove(avatarParent.HairObject);
      }
      
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // await Promise.all(ClothDataRecieved.map(cloth => this.UpdateClothMeshData(cloth, _gender, avatarParent, avatarData)));
      if (ClothDataRecieved && ClothDataRecieved.length > 0) {
        for (let i = 0; i < ClothDataRecieved.length; i++) {
          await new Promise(resolve => setTimeout(resolve, 30));
          await this.UpdateClothMeshData(ClothDataRecieved[i], _gender, avatarParent, avatarData);
          ClothDataRecieved[i] = null;
          await new Promise(resolve => setTimeout(resolve, 30));
        }
      } else {
        console.log("ClothDataRecieved is null or empty.");
      }


      console.log("no issue in loading cloth : No DownloadAndLoadClothesByType");

      await new Promise(resolve => setTimeout(resolve, 100));

      if (avatarParent.HairObject) {
        console.log("Adding Hair", avatarParent);
        console.log("Headbone", avatarData);
        avatarData.Headbone.add(avatarParent.HairObject);
      }

      await new Promise(resolve => setTimeout(resolve, 100));
      avatarParent.BodyObject.scale.set(avatarData.HeightScale, avatarData.HeightScale, avatarData.HeightScale);

      if (_gender == 'M') {
        this.maleAvatarParent.BodyObject.visible = true;
      }
      else {
        this.femaleAvatarParent.BodyObject.visible = true;
      }
    }
    catch (error) {
      this.callErrorMesssage('Error processing cloth data ZIPs: ' + error);
      throw error;
    }
    */
  }

  async UpdateClothMeshData(downloadedData, gender, avatarParent, avatarData) {
    const clothSaveFileJSON = JSON.parse(new TextDecoder().decode(downloadedData['clothSaveFile.json']));
    let type = clothSaveFileJSON.clothType;
    let clothingType;
    console.log(type);
    switch (type) {
      case 0:
        clothingType = 'headgear';
        break;
      case 1:
        clothingType = 'upperwear';
        break;
      case 2:
        clothingType = 'bottomwear';
        break;
      case 3:
        clothingType = 'footwear';
        break;
      case 5:
        clothingType = 'upperwear';
        this.RemoveLoadedClothesMeshType('bottomwear', gender);
        break;
      default:
        console.error('Invalid clothType value!');
    }

    const albedoTexture = await this.arrayBufferToTextureNonAsync(downloadedData['Albedo.jpg']);
    const normalTexture = await this.arrayBufferToTextureNonAsync(downloadedData['Normal.png']);
    const metalTexture = downloadedData['Metal.png'] ? await this.arrayBufferToTextureNonAsync(downloadedData['Metal.png']) : null;
    console.log("downloadedData['Metal.png']", downloadedData['Metal.png']);

    downloadedData = null;

    albedoTexture.wrapS = albedoTexture.wrapT = THREE.RepeatWrapping;
    normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    if (metalTexture) {
      metalTexture.wrapS = metalTexture.wrapT = THREE.RepeatWrapping;
    }

    const material = new THREE.MeshStandardMaterial({
      map: albedoTexture,
      normalMap: normalTexture,
      metalnessMap: metalTexture,
      side: THREE.DoubleSide,
    });

    albedoTexture.dispose();
    normalTexture.dispose();
    if (metalTexture) {
      metalTexture.dispose();
    }

    // Extracting mesh data
    const { vertices, normals, flattenedUVs, triangles, blendShapes, boneWeights } = await this.extractClothesMeshData(clothSaveFileJSON);

    const skeleton = await this.getSkeletonFromFBX(avatarParent.BodyObject);

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints(vertices);
    const flattenedNormals = normals.flatMap(v => [v.x, v.y, v.z]);
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(flattenedNormals, 3));
    geometry.setIndex(triangles);
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(flattenedUVs, 2));

    const skinIndices = [];
    const skinWeights = [];
    for (let i = 0; i < boneWeights.length; i++) {
      const bw = boneWeights[i];
      if (bw === undefined) {
        console.log('Undefined bw at index:', i);
      } else {
        skinIndices.push(bw.m_BoneIndex0, bw.m_BoneIndex1, bw.m_BoneIndex2, bw.m_BoneIndex3);
        skinWeights.push(bw.m_Weight0, bw.m_Weight1, bw.m_Weight2, bw.m_Weight3);
      }
    }
    geometry.setAttribute('skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4));
    geometry.setAttribute('skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4));

    // Apply blend shape data to the geometry
    for (let blendShape of blendShapes) {
      if (!geometry.morphAttributes['position']) {
        geometry.morphAttributes['position'] = [];
      }
      const attribute = new THREE.Float32BufferAttribute(blendShape.vertices, 3);
      attribute.name = blendShape.name;
      geometry.morphAttributes['position'].push(attribute);
    }



    var rotationMatrix = new THREE.Matrix4().makeRotationY(Math.PI);
    geometry.applyMatrix4(rotationMatrix);

    if (geometry.morphAttributes['position']) {
      for (let attribute of geometry.morphAttributes['position']) {
        for (let i = 0; i < attribute.count; i++) {
          let vertex = new THREE.Vector3();
          vertex.fromBufferAttribute(attribute, i);
          vertex.applyMatrix4(rotationMatrix);
          attribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
        }
      }
    }

    if (avatarParent[clothingType]) {
      const existingMesh = avatarParent[clothingType];
      avatarParent.BodyObject.remove(existingMesh);
      this.scene.remove(existingMesh);
      console.log("Remove existing clothes mesh", existingMesh);
      existingMesh.geometry.dispose();
      existingMesh.material.dispose();
      existingMesh.skeleton.dispose();
    }

    const newMesh = new THREE.SkinnedMesh(geometry, material);
    newMesh.castShadow = true;
    avatarParent.BodyObject.add(newMesh);

    console.log("skeleton", skeleton);
    console.log("avatarparent.bodyobject", avatarParent.BodyObject);

    newMesh.add(skeleton.bones[0].clone());
    newMesh.bind(skeleton);

    avatarParent[clothingType] = newMesh;
    avatarParent[clothingType].visible = true;
    console.log("cloth loading complete..");
    return;
  }

  setMorphIntensity(mesh, morphName, intensity) {
    const geometry = mesh.geometry;
    const morphAttributes = geometry.morphAttributes.position; // Assuming position attributes

    if (!morphAttributes) {
      console.error("No morph attributes found on the geometry!");
      return;
    }
    // Find the index based on userData
    const morphIndex = morphAttributes.findIndex(attr => attr.name === morphName);

    if (morphIndex === -1) {
      console.error(`Morph target ${morphName} not found!`);
      return;
    }
    mesh.morphTargetInfluences[morphIndex] = intensity;
  }

  setAllMorphIntensity(gender: 'M' | 'F', fatness) {
    console.log("setAllMorphIntensity");
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

    if (avatarParent) {
      ['upperwear', 'bottomwear', 'footwear', 'headgear'].forEach(clothingType => {
        const clothingMesh = avatarParent[clothingType];
        if (clothingMesh) {
          this.setMorphIntensity(clothingMesh, "_fat", fatness / 100);
        }
      });
    }
  }

  SettingFatness(gender: 'M' | 'F', fatness) {
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
    const avatarData = gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;

    if (avatarParent.BodyObject == null) {
      return;
    }
    const minFatness = 0;
    const maxFatness = 100 - avatarData.Fatness;

    console.log("fatness: ", fatness, "minFatness: ", minFatness, "maxFatness: ", maxFatness, "avatarData.Fatness: ", avatarData.Fatness)

    avatarParent.BodyObject.traverse((child) => {
      if (child.isSkinnedMesh && child.name === 'mesh') {
        console.log("fatness on body", this.mapToRange(fatness, minFatness, maxFatness));
        this.setMorphIntensity(child, "_fat", this.mapToRange(fatness, minFatness, maxFatness) / 100);
      }
    });

    console.log("fatness on clothes", this.mapToRange(fatness, avatarData.Fatness, 100));
    this.setAllMorphIntensity(gender, (this.mapToRange(fatness, avatarData.Fatness, 100)))
  }


  /**
 * Maps a value from an arbitrary range to the range 0 to 100.
 *
 * @param {number} value The value to map.
 * @param {number} min The minimum value of the input range.
 * @param {number} max The maximum value of the input range.
 * @returns {number} The value mapped to the range 0 to 100.
 */
  mapToRange(value, min, max) {
    if (min === max) {
      console.error("Invalid range: min and max cannot be the same.");
      return 0; // Or handle error as appropriate
    }

    // First, normalize value to a 0-1 range relative to min and max
    const normalized = ((value / 100) * (max - min)) + min;

    // Then scale to 0-100 range and return
    return normalized;
  }

  extractClothesMeshData(clothSaveFileJSON) {
    const vertices = clothSaveFileJSON.vertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));
    const normals = clothSaveFileJSON.normals.map(n => new THREE.Vector3(n.x, n.y, -n.z));
    const flattenedUVs = clothSaveFileJSON.uv.map(uv => [uv.x, uv.y]).flat();

    const triangles = [];
    for (let i = 0; i < clothSaveFileJSON.triangles.length; i += 3) {
      triangles.push(clothSaveFileJSON.triangles[i]);
      triangles.push(clothSaveFileJSON.triangles[i + 2]);
      triangles.push(clothSaveFileJSON.triangles[i + 1]);
    }

    // Extract blend shape data
    const blendShapes = [];
    const sVertCount = clothSaveFileJSON.vertices.length;
    for (let i = 0; i < clothSaveFileJSON.blendShapeCount; i++) {
      const morphVertices = [];
      for (let j = 0; j < sVertCount; j++) {
        const baseVertex = vertices[j];
        const v = clothSaveFileJSON.deltaVertices[j + (i * sVertCount)];
        const finalVertex = baseVertex.clone().add(new THREE.Vector3(v.x, v.y, -v.z));
        morphVertices.push(finalVertex.x, finalVertex.y, finalVertex.z);
      }
      blendShapes.push({
        name: clothSaveFileJSON.blendShapeNames[i],
        vertices: morphVertices
      });
    }
    const reorderedBoneWeights = clothSaveFileJSON.boneWeights;


    return {
      vertices: vertices,
      normals: normals,
      flattenedUVs: flattenedUVs,
      triangles: triangles,
      blendShapes: blendShapes,
      boneWeights: reorderedBoneWeights
    };
  }

  /**
   * Hides the avatars based on the specified gender.
   * If no gender is specified, hides both male and female avatars.
   *
   * @param gender - The gender of the avatar to hide. Can be either 'male' or 'female'.
   */
  RemoveLoadedClothesMesh(gender: 'M' | 'F') {
    // Determine the avatar parent based on the gender
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

    // Remove the clothes from the avatar parent
    if (avatarParent) {
      ['upperwear', 'bottomwear', 'footwear', 'headgear'].forEach(clothingType => {
        const clothingMesh = avatarParent[clothingType];
        if (clothingMesh) {
          avatarParent.BodyObject.remove(clothingMesh);
          clothingMesh.geometry.dispose();
          clothingMesh.material.dispose();
          avatarParent[clothingType] = null;
        }
      });
    }
  }

  RemoveLoadedClothesMeshType(type, gender: 'M' | 'F') {
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

    const clothMesh = avatarParent[type];
    console.log(clothMesh);
    if (clothMesh) {
      avatarParent.BodyObject.remove(clothMesh);
      this.scene.remove(clothMesh);
      clothMesh.geometry.dispose();
      clothMesh.material.dispose();
      clothMesh.skeleton.dispose();
      console.log("Remove existing clothes mesh", clothMesh);
      avatarParent[type] = null;
    }
  }

  // #endregion

  // #region Helper Methods

  arrayBufferToTexture(buffer): Promise<THREE.Texture> {
    //add by praful
    return new Promise((resolve, reject) => {
      const img = new Image();
      const blob = new Blob([buffer], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      img.onload = () => {
        const texture = new THREE.Texture(img);
        texture.needsUpdate = true;
        URL.revokeObjectURL(url); // Clean up the URL object
        resolve(texture);
      };
      img.onerror = () => {
        URL.revokeObjectURL(url); // Clean up the URL object
        reject(new Error('Error loading texture.'));
      };
      img.src = url;
    });
  }

  arrayBufferToTextureNonAsync(buffer) {
    const img = new Image();
    const blob = new Blob([buffer], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    img.src = url;
    const texture = new THREE.Texture(img);
    img.onload = () => {
      texture.needsUpdate = true;
    };
    return texture;
  }

  adjustFatness(gender: 'M' | 'F', fatness) {
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

    // Remove the clothes from the avatar parent
    if (avatarParent) {
      ['upperwear', 'bottomwear', 'footwear', 'headgear'].forEach(clothingType => {
        const clothingMesh = avatarParent[clothingType];
        if (clothingMesh) {
          this.setMorphIntensity(clothingMesh, "_fat", fatness / 100);
        }
      });
    }

    // if(avatarParent.BodyObject)
    // {
    //   avatarParent.BodyObject.traverse(child => {
    //     if (child instanceof THREE.SkinnedMesh) {
    //       this.setMorphIntensity(child, "_fat", fatness / 100);
    //     }
    //   });
    // }
  }

  getSkeletonFromFBX(fbxModel) {
    let skeleton = null;

    // A recursive function to search through all descendants of the model
    fbxModel.traverse((child) => {
      if (child.isSkinnedMesh) {
        skeleton = child.skeleton;
      }
    });

    return skeleton;
  }

  // #endregion

  // #region Animation

  async DownloadBvhFile(url, isVRM) {
    //check if no VRM avatar is loaded
    if (isVRM) {
      return;
    }
    else {
      const avatarParent = this.GarmentsGender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
      if (avatarParent.BodyObject == null || avatarParent.BodyObject == undefined) {
        return;
      }
    }

    //check if no FBX avatar is loaded

    try {
      const response = await fetch(url);
      const text = await response.text();

      this.processBVH(text, isVRM);
    } catch (error) {
      console.error("Error fetching BVH file:", error);
    }
  }

  async processBVH(bvhContent, isVRM) {

    // Process the BVH content
    var lines = bvhContent.split(/[\r\n]+/g);
    var root = BVHImport.readBvh(lines);

    var animation = BVHImport.toTHREE(root);
    console.log("animation", animation);

    var geometry = new THREE.BufferGeometry();

    var material = new THREE.MeshPhongMaterial({ skinning: true } as THREE.MeshPhongMaterialParameters);
    var mesh = new THREE.SkinnedMesh(geometry, material);

    mesh.add(animation.skeleton.bones[0]);
    mesh.bind(animation.skeleton);

    this.skeletonHelper = new THREE.SkeletonHelper(mesh);
    this.skeletonHelper.material.linewidth = 0;

    this.scene.add(this.skeletonHelper);
    this.scene.add(mesh);

    this.mixer = new THREE.AnimationMixer(mesh);
    this.mixer.clipAction(animation.clip).setEffectiveWeight(1.0).play();

    console.log("processed animation");
    if (isVRM) {
      // await this.loadBvhAnimationVRM(animation, this.currentVRM).then((clip) => {
      //   this.mixer = new THREE.AnimationMixer(this.currentVRM.scene);
      //   this.mixer.clipAction(clip).play();
      // });
    }
    else {
      const avatarParent = this.GarmentsGender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

      await this.loadBvhAnimationFBX(animation, avatarParent.BodyObject).then((clip) => {
        this.mixer = new THREE.AnimationMixer(avatarParent.BodyObject);
        console.log("clip received", clip);
        if (this.currentAction) {
          this.currentAction.stop();
        }
        this.currentAction = this.mixer.clipAction(clip);
        this.currentAction.play();

      });
    }

    //remove skeleton helper and mesh
    this.scene.remove(this.skeletonHelper);
    this.scene.remove(mesh);
  }

  async loadBvhAnimationFBX(url, fbx) {
    console.log("calling load bvh");

    const clip = url.clip; // extract the AnimationClip
    const tracks = []; // KeyframeTracks compatible with VRM will be added here

    const restRotationInverse = new THREE.Quaternion();
    const parentRestWorldRotation = new THREE.Quaternion();
    const _quatA = new THREE.Quaternion();
    const _vec3 = new THREE.Vector3();

    // Adjust with reference to hips height.
    const bones = url.skeleton.bones; // Get the array of bones from the skeleton
    const motionHipsHeightbone = bones.find(bone => bone.name === 'hips_JNT');

    // Introduce a delay
    await new Promise(resolve => setTimeout(resolve, 100));

    const motionHipsHeight = motionHipsHeightbone.position.y;

    let pelvisBone = fbx.children[0].children[0];

    const fbxHipsY = pelvisBone.getWorldPosition(_vec3).y;

    const fbxRootY = fbx.getWorldPosition(_vec3).y;

    const fbxHipsHeight = Math.abs(fbxHipsY - fbxRootY);

    const hipsPositionScale = fbxHipsHeight / motionHipsHeight;

    clip.tracks.forEach((track) => {

      // Convert each tracks for VRM use, and push to `tracks`
      const trackSplitted = track.name.split('.');
      const splitStr = trackSplitted[1].split('[')[1];
      const bvhRigName = splitStr.slice(0, -1);

      const fbxBoneName = this.boneMappingFBX[bvhRigName];
      const fbxNodeName = fbxBoneName;
      const bvhRigNode = bones.find(bone => bone.name === bvhRigName);

      if (fbxNodeName == null) {
        //Error log
        console.log("fbxNodeName is null");
      }

      if (fbxNodeName != null) {
        const propertyName = trackSplitted[2];
        // Store rotations of rest-pose.
        bvhRigNode.getWorldQuaternion(restRotationInverse).invert();
        bvhRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

        if (track instanceof THREE.QuaternionKeyframeTrack) {
          tracks.push(
            new THREE.QuaternionKeyframeTrack(
              `${fbxNodeName}.${propertyName}`,
              track.times,
              track.values.map((v) => (-v)),
            ),
          );

        } else if (track instanceof THREE.VectorKeyframeTrack) {
          if (fbxNodeName == "Pelvis") {
            const value = track.values.map((v) => (v) * hipsPositionScale);
            tracks.push(new THREE.VectorKeyframeTrack(`${fbxNodeName}.${propertyName}`, track.times, value));
          }
        }
      }
    });

    return new THREE.AnimationClip('vrmAnimation', clip.duration, tracks);
  }

  async LoadMixamoAnimationFBX(animationUrl, gender) {

    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;

    this.mixer = new THREE.AnimationMixer(avatarParent.BodyObject);

    await this.ProcessMixamoAnimationFBX(animationUrl, avatarParent.BodyObject).then((clip) => {

      if (this.currentAction) {
        this.currentAction.stop();
      }
      this.currentAction = this.mixer.clipAction(clip);
      this.currentAction.play();
    });

    console.log("loaded mixamo animation");
  }

  async ProcessMixamoAnimationFBX(url, fbx) {

    const loader = new FBXLoader(); // A loader which loads FBX
    return loader.loadAsync(url).then((asset) => {

      const clip = THREE.AnimationClip.findByName(asset.animations, 'mixamo.com'); // extract the AnimationClip
      const tracks = []; // KeyframeTracks compatible with VRM will be added here

      const restRotationInverse = new THREE.Quaternion();
      const parentRestWorldRotation = new THREE.Quaternion();
      const _quatA = new THREE.Quaternion();
      const _vec3 = new THREE.Vector3();

      // Adjust with reference to hips height.
      const motionHipsHeight = asset.getObjectByName('mixamorigHips').position.y;
      let pelvisBone = fbx.children[0].children[0];
      const fbxHipsY = pelvisBone.getWorldPosition(_vec3).y;
      const fbxRootY = fbx.getWorldPosition(_vec3).y;
      const fbxHipsHeight = Math.abs(fbxHipsY - fbxRootY);
      const hipsPositionScale = fbxHipsHeight / motionHipsHeight;

      clip.tracks.forEach((track) => {
        // Convert each tracks for VRM use, and push to `tracks`
        const trackSplitted = track.name.split('.');
        const mixamoRigName = trackSplitted[0];
        const fbxBoneName = this.mixamoRigMapFBX[mixamoRigName];
        const fbxNodeName = fbxBoneName;

        const mixamoRigNode = asset.getObjectByName(mixamoRigName);

        if (fbxNodeName != null) {

          const propertyName = trackSplitted[1];

          // Store rotations of rest-pose.
          mixamoRigNode.getWorldQuaternion(restRotationInverse).invert();
          mixamoRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

          if (track instanceof THREE.QuaternionKeyframeTrack) {

            // Retarget rotation of mixamoRig to NormalizedBone.
            for (let i = 0; i < track.values.length; i += 4) {

              const flatQuaternion = track.values.slice(i, i + 4);

              _quatA.fromArray(flatQuaternion);

              // 親のレスト時ワールド回転 * トラックの回転 * レスト時ワールド回転の逆
              _quatA
                .premultiply(parentRestWorldRotation)
                .multiply(restRotationInverse);

              _quatA.toArray(flatQuaternion);

              flatQuaternion.forEach((v, index) => {

                track.values[index + i] = v;

              });

            }

            tracks.push(
              new THREE.QuaternionKeyframeTrack(
                `${fbxNodeName}.${propertyName}`,
                track.times,
                track.values.map((v, i) => (fbx.meta?.metaVersion === '0' && i % 2 === 0 ? - v : v)),
              ),
            );

          } else if (track instanceof THREE.VectorKeyframeTrack) {

            const value = track.values.map((v, i) => (fbx.meta?.metaVersion === '0' && i % 3 !== 1 ? - v : v) * hipsPositionScale);
            tracks.push(new THREE.VectorKeyframeTrack(`${fbxNodeName}.${propertyName}`, track.times, value));

          }

        }

      });

      return new THREE.AnimationClip('fbxAnimation', clip.duration, tracks);

    });

  }

  async CacheOriginalPoses(gender) {

    const avatarData = gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;
    const avatarParent = gender === 'M' ? this.maleAvatarParent : this.femaleAvatarParent;
    avatarData.OriginalPoses = [];

    avatarParent.BodyObject.traverse((child) => {
      if (child.isBone) {
        // Store position, rotation (quaternion), and scale
        const pose = {
          bone: child,
          position: child.position.clone(),
          quaternion: child.quaternion.clone(),
          scale: child.scale.clone()
        };
        avatarData.OriginalPoses.push(pose);
      }

    });
  }

  stopAnimationAndResetPose(gender) {
    if (this.currentAction) {
      this.currentAction.stop();
    }

    const avatarData = gender === 'M' ? this.maleAvatarData : this.femaleAvatarData;

    // Reset each bone to its original pose
    avatarData.OriginalPoses.forEach((pose) => {
      pose.bone.position.copy(pose.position);
      pose.bone.quaternion.copy(pose.quaternion);
      pose.bone.scale.copy(pose.scale);
    });
  }

  disposeAvatar() {
    this.disposeOfScene(this.scene, this.renderer);
  }

  //#region  AR Code
  createARbutton(): void {
    // this.renderer.xr.addEventListener("sessionstart", this.sessionStart);
    this.renderer.xr.addEventListener('sessionstart', () => {
      this.arStateChange.emit(true);
      this.sessionStart()
    });

    this.customARButton = ARButton.createButton(this.renderer, {
      requiredFeatures: ['local', 'hit-test', 'dom-overlay'],
      domOverlay: { root: this.threeDModelContainerMale.nativeElement },
    }) as HTMLButtonElement;

    // Remove any default text or placeholder
    this.customARButton.textContent = 'Enter Button'; // Clear any text
    this.customARButton.innerHTML = 'No button';  // Ensure no HTML content is inside the button

    // Apply a class for consistent styling
    this.customARButton.classList.add('custom-ar-button');

    // // Append AR button to the DOM
    // document.body.appendChild(this.customARButton);
    // console.log('AR Button created and added to the DOM!');

    // Add event listener to disable the skybox when the AR button is clicked
    this.customARButton.addEventListener('click', () => {
      console.log('Skybox disabled.');
        this.hideAvatars("female");
        this.hideAvatars("male");
        this.hideDefaultBackground();
        this.scene.environment = null;
        this.scene.background = null; // Disable the skybox

        this.renderer.setClearColor(0x000000, 0); // Fully transparent background
    });

    this.controller = this.renderer.xr.getController(0);
    this.controller.addEventListener("select", this.onSelect.bind(this));
    this.scene.add(this.controller);
  }

  triggerARButton(){
    if(this.customARButton){
      console.log("Button Calling..")

      this.StoreOriginalPosition();

      this.customARButton.click()
      this.isARsessionActive = true;

      // if (this.animationFrameId) {
      //   cancelAnimationFrame(this.animationFrameId);
      //   this.animationFrameId = null;
      // }
      this.showOverlay();
      console.log("Button Called..")
    }
  }

  StoreOriginalPosition(){
    console.log("storing avatar original position");
    this.originalPosition = new THREE.Vector3(0, 0, 0); // Default position
    this.originalQuaternion = new THREE.Quaternion();   // Default quaternion
    this.originalScale = new THREE.Vector3(1, 1, 1);    // Default scale

    if (this.GarmentsGender === "M" && this.maleAvatarParent["BodyObject"]) {
      this.originalPosition = this.maleAvatarParent["BodyObject"].position.clone();
      this.originalQuaternion = this.maleAvatarParent["BodyObject"].quaternion.clone();
      this.originalScale = this.maleAvatarParent["BodyObject"].scale.clone();
    }
    else
    if (this.GarmentsGender === "F" && this.femaleAvatarParent["BodyObject"]){
      this.originalPosition = this.femaleAvatarParent["BodyObject"].position.clone();
      this.originalQuaternion = this.femaleAvatarParent["BodyObject"].quaternion.clone();
      this.originalScale = this.femaleAvatarParent["BodyObject"].scale.clone();
    }
  }

  planeDetection(frame) {
    // console.log("AR Testing planeDetection start");
    if (frame && !this.isModelplaced) {
      // console.log("AR Testing frame: ", frame);
      const referenceSpace = this.renderer.xr.getReferenceSpace();
      const session = this.renderer.xr.getSession();

      console.log("AR Debugging 1");
      if (this.hitTestSourceRequested === false) {
        session.requestReferenceSpace("viewer").then((viewerReferenceSpace) => {
          session.requestHitTestSource({ space: viewerReferenceSpace }).then((source) => {
            console.log("AR Debugging 1.1");
            this.hitTestSource = source; // Use arrow function to preserve `this` context
            // console.log("AR Debugging 1.2");
          });
        });

        session.addEventListener("end", () => {
          // console.log("AR Debugging 1.3");
          this.hitTestSourceRequested = false;
          // console.log("AR Debugging 1.4");
          this.hitTestSource = null; // Use arrow function to preserve `this` context
        });

        this.hitTestSourceRequested = true;
      }

      console.log("AR Debugging 2: ", this.hitTestSource);
      if (this.hitTestSource) {
        // console.log("AR Testing hitTestSource: ", this.hitTestSource);
        // console.log("AR Debugging 2.1");
        // console.log("AR Debugging 2.2: ")
        frame.getHitTestResults(this.hitTestSource);
        const hitTestResults = frame.getHitTestResults(this.hitTestSource);
        console.log("AR Debugging 2.3");

        // console.log("AR Debugging 3");
        if (hitTestResults.length) {
          if (!this.planeFound) {
            this.planeFound = true;
            //hide #tracking-prompt
            // document.getElementById("tracking-prompt").style.display = "none";
            // document.getElementById("instructions").style.display = "flex";
          }
          // console.log("AR Debugging 3.1");
          const hit = hitTestResults[0];

          // console.log("AR Debugging 3.2");
          if (this.reticle) {
            // console.log("reticle is visible true");
            // console.log("reticle is visible with value: ", this.reticle);
          }
          this.reticle.visible = true;
          // console.log("AR Debugging 3.3");
          this.avatarCanPlace = true;
          console.log("AR Debugging 3.4");
          this.reticle.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);
        } else {
          this.reticle.visible = false;
        }
      }
    }
  }

  onSelect() {
    console.log("In onSelect function: Build v0.2");
    console.log("this.avatarCanPlace: ", this.avatarCanPlace);
    console.log("Scene: ", this.scene);
    console.log("Renderer: ", this.renderer);
    // if(this.reticle.visible){
    //   console.log("reticle is true");
    //   console.log("reticle data: ", this.reticle);
    // }
    // else
    // console.log("reticle is false: ", this.reticle);

    if (this.avatarCanPlace)
      console.log("avatar can place");
    
    if (this.maleAvatarParent["BodyObject"])
      console.log("maleAvatarParent true");

    if (!this.isModelplaced)
      console.log("isModelplaced false");

    if (this.avatarCanPlace && !this.isModelplaced) {
      console.log("onSelect: Start");
      // const flower = model;
      // const mesh = flower.clone();

      if (this.GarmentsGender === "M" && this.maleAvatarParent["BodyObject"]){

        console.log("male avatar");
        // Store the current position, quaternion, and scale
        


        this.reticle.matrix.decompose(this.maleAvatarParent["BodyObject"].position, this.maleAvatarParent["BodyObject"].quaternion, this.maleAvatarParent["BodyObject"].scale);

        const scale = Math.random() * 0.4 + 0.25;
        this.maleAvatarParent["BodyObject"].scale.set(scale, scale, scale);
        //random rotation
        this.maleAvatarParent["BodyObject"].rotateY(Math.random() * Math.PI * 2);
        this.showAvatars('male');
        this.maleAvatarParent["BodyObject"].lookAt(this.camera.position);

        const cameraPosition = this.camera.position.clone(); // Clone camera position
        cameraPosition.y = this.maleAvatarParent["BodyObject"].position.y; // Match the object's Y position to ignore vertical tilt
        this.maleAvatarParent["BodyObject"].lookAt(cameraPosition);
      }
      else
      if (this.GarmentsGender === "F" && this.femaleAvatarParent["BodyObject"]){

        console.log("female avatar");
        // Store the current position, quaternion, and scale
        


        this.reticle.matrix.decompose(this.femaleAvatarParent["BodyObject"].position, this.femaleAvatarParent["BodyObject"].quaternion, this.femaleAvatarParent["BodyObject"].scale);

        const scale = Math.random() * 0.4 + 0.25;
        this.femaleAvatarParent["BodyObject"].scale.set(scale, scale, scale);
        //random rotation
        this.femaleAvatarParent["BodyObject"].rotateY(Math.random() * Math.PI * 2);
        this.showAvatars('female');

        const cameraPosition = this.camera.position.clone(); // Clone camera position
        cameraPosition.y = this.femaleAvatarParent["BodyObject"].position.y; // Match the object's Y position to ignore vertical tilt
        this.femaleAvatarParent["BodyObject"].lookAt(cameraPosition);

      }


      console.log("onSelect: decompose");

      
      // this.scene.add(model);
      this.isModelplaced = true;
      this.reticle.visible = false;
      this.avatarCanPlace = false;

      console.log("Avatar enabled");

      // animate growing via hacky setInterval then destroy it when fully grown
      // const interval = setInterval(() => {
      //   this.maleAvatarParent["BodyObject"].scale.multiplyScalar(1.01);

      //   this.maleAvatarParent["BodyObject"].rotateY(0.03);
      // }, 16);
      // setTimeout(() => {
      //   clearInterval(interval);
      // }, 500);
    }
  }

  sessionStart() {
    this.planeFound = false;
    this.xrSession = this.renderer.xr.getSession();
  }
  
  // Function to show the overlay (if needed dynamically)
  showOverlay() {
    const overlay = document.getElementById('ar-overlay');
    const dropdrown_overlay = document.getElementById('drop-down-overlay');
    const dropdrownList_overlay = document.getElementById('drop-down-list');
    
    dropdrownList_overlay.classList.remove('hidden');
    dropdrown_overlay.classList.remove('hidden');
    overlay.classList.remove('hidden');
  }

  // Function to disable (hide) the overlay
  disableOverlay() {
    const overlay = document.getElementById('ar-overlay');
    if (overlay) {
        overlay.classList.add('hidden');
        console.log("Overlay disabled");
    } else {
        console.log("Overlay element not found");
    }
  }



  // #endregion
}