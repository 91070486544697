<div #sliderRefAll class="slider-container">
    <div class="slider-item" *ngFor="let image of images">
        <img (click)="seletctOption(image)" [src]="image.img" alt="Image" class="image"
            [ngClass]="{'selected-image': selectedImage === image}">
    </div>
</div>


<div class="btn-resize-down m-1 d-flex justify-content-end" *ngIf="this.selectedImage?.value === 'Garment'">
    <div class="story-model-switch">
        <div class="story-model-button" [ngClass]="{ 'active': isCheckedFocus === true }"
            (click)="switchgarmentSearch()">
            Search
        </div>
        <div class="story-model-button" [ngClass]="{ 'active': isCheckedFocus === false }"
            (click)="switchgarmentSearch()">
            Browse
        </div>
        <div class="active-indicator"
            [ngClass]="{ 'search-active': isCheckedFocus === true, 'browse-active': isCheckedFocus === false }"></div>
    </div>
</div>


<div class="search-container">
    <input type="text" placeholder="Search" [(ngModel)]="searchTerm" class="search-input" (input)="suggest($event)">
    <i class="bi bi-x clear-icon" *ngIf="searchTerm" (click)="clearSearch()"></i>
    <i class="bi bi-search search-icon" (click)="clickSearch()"></i>
</div>



<div class="user-container" *ngIf="this.selectedImage?.value === 'User' && showSearchContainer">
    <div class="slider-container-main">
        <div class="d-flex p-1 m-1" *ngFor="let image of filterUser" (click)="seletctUserFromList(image)">
            <img [src]="image.ProfilePicture" alt="Image" class="image rounded-circle m-1">
            <h6 class="m-1">{{image?.DisplayName}}</h6>
        </div>
    </div>
    <div *ngIf="filterUser?.length === 0" class="text-center">
        <p>No selection found.</p>
    </div>

    <div *ngIf="filterUser?.length > 0" class="text-center">
        <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        <div *ngIf="this.searchTerm != '' " class="col-12  user-tag" (click)="selectCondition()" style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="selectedImage.img" alt="Image"> 
                Like: {{ searchTerm.length > 15 ? (searchTerm | slice:0:15) + '...' : searchTerm }}       
                <img class="search-small-icon" src="./assets/brand-space/search-button.png" alt="Search Icon">
            </h6>
            <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        </div>
    </div>

    <div *ngIf="this.selectedUserFromList" class=" col-lg-12 band" style="margin-top: 50px;">
        <div class="static-band" style="margin-left: -5px  !important;">
            <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                <img class="img-thumbnail rounded-circle" style="
                          width: 40px !important;
                          height: 40px !important;
                          margin-top: -36px;" [src]="selectedUserFromList?.ProfilePicture">
                <div class="w-100 col-12 d-flex ">
                    <div class="col-9 text-start">
                        <h5 class="m-1 text-start text-black profile-name">{{selectedUserFromList?.DisplayName}}</h5>
                    </div>
                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                            class="btn btn-outline-info follow-btn">Follow</button>
                    </div>
                </div>
            </div>
            <div>

                <div *ngIf="userFiledetails?.length == 0 ">
                    <app-image-slide [images]="imagesDummy"></app-image-slide>
                </div>
                <div *ngIf="userFiledetails?.length != 0">
                    <app-image-slide [images]="userFiledetails" [UserId]="this.UserID"
                        [UserProfile]="userFiledetails"></app-image-slide>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.selectedUserFromList" class="text-center">
        <button (click)="selectUser()" class="transparent-button search-button">Select</button>
    </div>
</div>
<div class="space-container" *ngIf="this.selectedImage?.value === 'Avatar'&& showSearchContainer">
    <div class="slider-container-main">
        <div class="d-flex p-1 m-1" *ngFor="let image of filterAvatar" (click)="seletctAvatarFromList(image)">
            <img [src]="image.AvatarPreviewPortraitUrl" alt="Image" class="image rounded-circle m-1">
            <h6 class="m-1">{{image?.AvatarName}}</h6>
        </div>
    </div>
    <div *ngIf="this.avatarlistFlag" class="text-center">
        <div class="ner-border spinm-1 p-1" role="status">
            <span class="sr-only"></span>
        </div>
    </div>

    <div *ngIf="filterAvatar?.length === 0 " class="text-center">
        <p>No selection found.</p>
    </div>
    
    <div *ngIf="filterAvatar?.length > 0" class="text-center">
        <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        <div *ngIf="this.searchTerm != '' " class="col-12  user-tag" (click)="selectCondition()" style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="selectedImage.img" alt="Image"> 
                Like: {{ searchTerm.length > 15 ? (searchTerm | slice:0:15) + '...' : searchTerm }}       
                <img class="search-small-icon" src="./assets/brand-space/search-button.png" alt="Search Icon">   </h6>
            <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        </div>
    </div>

    <div *ngIf="this.selectedAvatarFromList" class="space-div col-lg-12 band" style="margin-top: 50px;">
        <div class="static-band" style="margin-left: -5px  !important;">
            <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                <img class="img-thumbnail rounded-circle" style="
                          width: 40px !important;
                          height: 40px !important;
                          margin-top: -36px;" [src]="AvatrUserDeatils?.ProfilePicture">
                <div class="w-100 col-12 d-flex ">
                    <div class="col-9 text-start">
                        <h5 class="m-1 text-start text-black profile-name">{{AvatrUserDeatils?.DisplayName}}</h5>
                    </div>
                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                            class="btn btn-outline-info follow-btn">Follow</button>
                    </div>
                </div>
            </div>
            <div *ngIf="newArray.length != 0" class="text-start capsule-container" style="height: 35px; width: 100%; ">
                <div class="d-flex align-items-center justify-content-center" *ngIf="newArray.length != 0">
                    <img class="avatar-image" style="width: 25px; height: 25px;"
                        [src]="selectedAvatarFromList?.AvatarPreviewPortraitUrl">
                    <span class="avatar-name"><span
                            class="avatar-details">{{selectedAvatarFromList?.AvatarName}}</span></span>
                </div>
            </div>
            <div>
                <div *ngIf="newArray?.length == 0 ">
                    <app-image-slide [images]="imagesDummy"></app-image-slide>
                </div>
                <div *ngIf="newArray?.length != 0">
                    <app-image-slide [images]="newArray"></app-image-slide>
                </div>
            </div>
        </div>
        <div *ngIf="this.selectedAvatarFromList" class="text-center">
            <button (click)="selectAvatar()" class="transparent-button search-button">Select</button>
        </div>
    </div>
</div>
<div class="garment-container" *ngIf="this.selectedImage?.value === 'Space' && showSearchContainer">
    <div class="slider-container-Spaces">
        <div class="d-flex p-1 m-1" *ngFor="let image of filterSpaces" (click)="seletctSpaceFromList(image)">
            <img style="width: 25px; height: 25px;" src="./assets/search/spaces.png" alt="Image"
                class="image rounded-circle m-1">
            <h6 class="m-1">{{image?.displayName}}</h6>
        </div>
    </div>
    <div *ngIf="filterSpaces?.length === 0" class="text-center">
        <p>No selection found.</p>
    </div>
    
    <div *ngIf="filterSpaces?.length > 0" class="text-center">
        <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        <div *ngIf="this.searchTerm != '' " class="col-12  user-tag" (click)="selectCondition()" style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="selectedImage.img" alt="Image"> 
                Like: {{ searchTerm.length > 15 ? (searchTerm | slice:0:15) + '...' : searchTerm }}       
                <img class="search-small-icon" src="./assets/brand-space/search-button.png" alt="Search Icon">   </h6>
            <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="spaceLoadFlag">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
    <div class="d-block space-div" *ngIf="!spaceLoadFlag && selectedSpaceFromList">
        <div class="col-12 justify-content-center">
            <img class="shadow-1-strong rounded mb-1 image-popup"
                [src]="newCompressImages?.downloadUrl != undefined ? newCompressImages?.downloadUrl :  ''">
            <h6 class="evn-name" data-bs-dismiss="modal">
                {{this.selectedSpaceFromList?.displayName ? this.selectedSpaceFromList?.displayName :  'Space'}}
            </h6>
        </div>
        <model-viewer id="poster" camera-controls touch-action="pan-y" data-js-focus-visible="false"
            [attr.skybox-image]="newCompressImages?.downloadUrl " alt="A 3D model of a sphere reflecting a sunrise"
            [attr.src]="gltfImgSrc">
        </model-viewer>
        <div *ngIf="this.selectedSpaceFromList" class="text-center">
            <button (click)="selectSpace()" class="transparent-button search-button">Select</button>
        </div>
    </div>
</div>
<div class="avatar-container" *ngIf="this.selectedImage?.value === 'Brand' && showSearchContainer">
    <div #brandStoriyScrollA [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css" (scroll)="selectByScroll()">
        <div class="" *ngFor="let brand of brandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
            <div *ngIf="selectedBrand == brand  ">
                <div [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (!byScroll ? 'd-none' :'')">
                    <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                    </div>
                </div>
            </div>
            <img *ngIf="brand.Brandurl != undefined" [id]="brand.id" class="m-1"
                style="height:50px !important; width:60px !important;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll && !scrolnewlFlag? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>

    </div>
    <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
        <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
            <img [id]="brand.id" class="m-1" style="height:50px !important; width:60px !important; background: white;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>
    </div>
    <div *ngIf="searchTerm" class="text-center">
        <div *ngIf="this.searchTerm != '' " class="col-12  user-tag" (click)="selectCondition()" style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="selectedImage.img" alt="Image"> 
                Like: {{ searchTerm.length > 15 ? (searchTerm | slice:0:15) + '...' : searchTerm }}       
                <img class="search-small-icon" src="./assets/brand-space/search-button.png" alt="Search Icon">          </h6>
            <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        </div>
      
        <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
    </div>
    <div *ngIf="selectedBrand" class="brand-container d-flex justify-content-center align-items-center">
        <div class="brand-content text-center">
            <img [src]="selectedBrand?.Brandurl" class="brand-image" />
            <div class="brand-details">
                <h6 class="brand-name">{{selectedBrand?.BrandName}}</h6>
                <p class="brand-type">{{selectedBrand?.BrandType}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="this.selectedBrand" class="text-center">
        <button (click)="selecteBrand()" class="transparent-button search-button">Select</button>
    </div>
</div>
<div class="garment-container" *ngIf="this.selectedImage?.value === 'Garment' && showSearchContainer">
    <div *ngIf="!isCheckedFocus">
        <div #brandStoriyScrollGarment *ngIf="showGrmentBrandslier" [ngClass]="scrollFlag ? 'd-none' : ''"
            class="slider-css" (scroll)="selectByScroll()">
            <div class="" *ngFor="let brand of brandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
                <div *ngIf="selectedBrand == brand  ">
                    <div [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (!byScroll ? 'd-none' :'')">
                        <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                        </div>
                    </div>
                </div>
                <img *ngIf="brand.Brandurl != undefined" [id]="brand.id" class="m-1"
                    style="height:50px !important; width:60px !important;"
                    [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll && !scrolnewlFlag? 'blug-css' :'')"
                    (click)="selectBrand(brand)" [src]="brand.Brandurl" />
            </div>

        </div>
        <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
            <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                <img [id]="brand.id" class="m-1"
                    style="height:50px !important; width:60px !important; background: white;"
                    [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                    (click)="selectBrand(brand)" [src]="brand.Brandurl" />
            </div>
        </div>
        <!-- <div class="tab-container">
            <div class="tab" [ngClass]="{'active': selectedTab === 'male'}" (click)="switchTab('male')">
                Male
            </div>
            <div class="tab" [ngClass]="{'active': selectedTab === 'female'}" (click)="switchTab('female')">
                Female
            </div>
        </div> -->

        <div class="tab-switch-container" *ngIf="selectedBrand">
            <div class="tab-switch">
                <div class="tab-switch-button" [ngClass]="{'active': selectedTab === 'male'}"
                    (click)="switchTab('male')">
                    Male
                </div>
                <div class="tab-switch-button" [ngClass]="{'active': selectedTab === 'female'}"
                    (click)="switchTab('female')">
                    Female
                </div>
                <div class="active-indicator"
                    [ngClass]="{'male-active': selectedTab === 'male', 'female-active': selectedTab === 'female'}">
                </div>
            </div>
        </div>

        <div *ngIf="filpFlag" class="p-3">
            <div *ngIf="selectedTab =='male' " class="d-flex my">
                <div class="file womenswear_scroller ">
                    <!-- <h6 class="text-white">{{tempMessage}}</h6> -->
                    <div class="file">
                        <div class="alert-danger" *ngIf="errMessage != '' || errMessage != undefined">{{errMessage}}
                        </div>
                        <div class="row form d-block " *ngIf="packJsonObject != undefined">
                            <div #scrollContainerMHeadGear class="slider-css-head">
                                <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                    id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="head-div">
                                        <img (click)="seletctGarment(object)" class="img-responsive-foot"
                                            *ngIf="zipList[object]"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>
                                </div>
                            </div>


                            <div #scrollContainerMUpper class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                    id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                    <div class="upper-div">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <img (click)="seletctGarment(object)" *ngIf="zipList[object]"
                                            class="img-responsive-upper"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />

                                    </div>
                                </div>
                            </div>
                            <div #scrollContainerMBottom class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                    id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="bottom-div">
                                        <img (click)="seletctGarment(object)" class="img-responsive-bottom"
                                            *ngIf="zipList[object]"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>

                                </div>
                            </div>
                            <div #scrollContainerMFootwear class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                    id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="foot-div">
                                        <img (click)="seletctGarment(object)" class="img-responsive-foot"
                                            *ngIf="zipList[object]"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>

                                </div>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="isloading">
                                <div class="spinner-border" role="status">
                                    <span class=""></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex my" *ngIf="selectedTab =='female'">
                <div class=" file womenswear_scroller ">
                    <div class="file">
                        <div class="alert-danger" *ngIf="errMessage != '' || errMessage != undefined">{{errMessage}}
                        </div>
                        <div class="row form d-block " *ngIf="packJsonObject != undefined ">
                            <div #scrollContainerMHeadGear class="slider-css-head">
                                <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                    id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="head-div">
                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                            (click)="seletctGarment(object)"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>

                                </div>
                            </div>


                            <div #scrollContainerMUpper class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                    id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                    <div class="upper-div">
                                        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                            <div class="spinner-border spn-load text-white" role="status">
                                            </div>
                                        </div>
                                        <img *ngIf="zipList[object]" class="img-responsive-upper"
                                            (click)="seletctGarment(object)"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />

                                    </div>
                                </div>
                            </div>
                            <div #scrollContainerMBottom class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                    id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="bottom-div">
                                        <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                            (click)="seletctGarment(object)"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>

                                </div>
                            </div>
                            <div #scrollContainerMFootwear class="slider-css2-upper">
                                <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                    id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                        <div class="spinner-border spn-load text-white" role="status">
                                        </div>
                                    </div>
                                    <div class="foot-div">
                                        <img class="img-responsive-foot" *ngIf="zipList[object]"
                                            (click)="seletctGarment(object)"
                                            [src]="zipList[object] ? returnUrl(zipList[object]) : ' '" />
                                    </div>

                                </div>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="isloading">
                                <div class="spinner-border" role="status">
                                    <span class=""></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedGarmentFromList && !isCheckedFocus && !filpFlag">
            <div class="" id="target" *ngIf="!isloading" style="margin-left: -5px;     margin-top: 50px;">
                <div class="text-end text-black" (click)="flip()">
                    <h5>X</h5>
                </div>

                <app-twod-image [garmentImg]="this.source" [garmentType]="selectedGarmentFromList.GarmentType"
                    [pageType]="style"></app-twod-image>
            </div>
            <div class="d-flex justify-content-center" *ngIf="isloading">
                <div class="spinner-border" role="status">
                    <span class=""></span>
                </div>
            </div>
            <div class="garment-badge d-flex align-items-center">
                <img class="garment-logo" [src]="getGarmentTypeLogo(selectedGarmentFromList?.RealType)" />
                <h6 class="garment-type">{{selectedGarmentFromList?.Name}}</h6>
            </div>
            <div *ngIf="this.selectedGarmentFromList" class="text-center">
                <button (click)="selectGarment()" class="transparent-button search-button">Select</button>
            </div>
        </div>
    </div>
    <div *ngIf="isCheckedFocus" class="slider-container-main">
        <div class="d-flex p-1 m-1" *ngFor="let image of filterGarment" (click)="seletctGarmentFromList(image)">
            <img style="width: 25px; height: 25px;" [src]=" getGarmentTypeLogo(image.RealType) " alt="Image"
                class="image rounded-circle m-1">
            <h6 class="m-1" style="font-size:18px">{{image?.Name}}</h6>
        </div>
    </div>
    <div *ngIf="filterGarment?.length === 0" class="text-center">
        <p>No selection found.</p>
    </div>
    
    <div *ngIf="filterGarment?.length > 0" class="text-center">
        <div *ngIf="this.searchTerm != '' " class="col-12  user-tag" (click)="selectCondition()" style="background-color:transparent !important">
            <h6 style="margin: 5px;" class="d-flex">
                <img style="width: 25px; height: 25px;" [src]="selectedImage.img" alt="Image"> 
    Like: {{ searchTerm.length > 15 ? (searchTerm | slice:0:15) + '...' : searchTerm }}       
    <img class="search-small-icon" src="./assets/brand-space/search-button.png" alt="Search Icon">            </h6>
            <!-- <button (click)="selectCondition()" class="transparent-button search-button">Search Condition</button> -->
        </div>
    
    <div *ngIf="selectedGarmentFromList && isCheckedFocus">
        <div class="" id="target" *ngIf="!isloading" style="margin-left: -5px;">
            <app-twod-image [garmentImg]="this.source" [garmentType]="selectedGarmentFromList.GarmentType"
                [pageType]="style"></app-twod-image>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isloading">
            <div class="spinner-border" role="status">
                <span class=""></span>
            </div>
        </div>
        <div class="garment-badge d-flex align-items-center">
            <img class="garment-logo" [src]="getGarmentTypeLogo(selectedGarmentFromList?.RealType)" />
            <h6 class="garment-type">{{selectedGarmentFromList?.Name}}</h6>
        </div>
        <div *ngIf="this.selectedGarmentFromList" class="text-center">
            <button (click)="selectGarment()" class="transparent-button search-button">Select</button>
        </div>
    </div>
</div>

